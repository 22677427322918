import React, { useCallback, useEffect, useState } from "react";
import { Button, Card, OverlayTrigger, Tooltip } from "react-bootstrap";
import DataTable from "react-data-table-component";
import { ToastContainer } from "react-toastify";
import { useNavigate } from "react-router-dom";
import _ from "lodash";
import Loader from "../../../Components/Common/Loader";
import { useSelector } from "react-redux";
import { ACCESS_RIGHTS } from "../../../Components/Common/ModuleName";
import { CustomTableStyle } from "../../../Components/Common/constants";
import { Box } from "@mui/material";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import { deleteRoleById, getRoles } from "../../../helpers/Help/CommomApi";
import common from "../../../Components/Common/common";
import Swal from "sweetalert2";
import { DeleteIcon } from "../../../Components/Common/SvgIcons";

const RoleManager = () => {
  const navigate = useNavigate();

  const permissionData = useSelector(
    (state) => state.userDetails.userPermission
  );
  const hasPermission = permissionData?.find(
    (item) => item.module_name === ACCESS_RIGHTS
  );
  const userData = useSelector((state) => state.userDetails.userData);

  const userRole = userData?.role;

  const [data, setData] = useState([]);
  const [query, setQuery] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const fetchData = useCallback(async (query) => {
    try {
      setIsLoading(true);
      const response = await getRoles(query);
      const decryptedData = common.decrypt(response.data);
      response.data = decryptedData;
      setData(response.data);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    if (query === "") {
      fetchData(query);
    } else {
      const debouncedFetchData = _.debounce(() => {
        fetchData(query);
      }, 500);

      debouncedFetchData();

      return () => {
        debouncedFetchData.cancel();
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query, fetchData]);

  const handleDeleteAlert = (id) => {
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: "btn btn-dark mx-2", // Bootstrap classes for buttons
        cancelButton: "btn btn-primary mx-2",
      },
      buttonsStyling: false,
    });

    swalWithBootstrapButtons
      .fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
        cancelButtonText: "No, cancel!",
        reverseButtons: true,
      })
      .then(async (result) => {
        if (result.isConfirmed) {
          try {
            let response = await deleteRoleById(id);
            if (response.status === "fail" && response.data === "E0044") {
              swalWithBootstrapButtons.fire(
                "Cannot Delete",
                response.message,
                "error"
              );
            } else {
              swalWithBootstrapButtons.fire(
                "Deleted!",
                "Role has been deleted.",
                "success"
              );
              fetchData("");
            }
          } catch (error) {
            swalWithBootstrapButtons.fire(
              "Error!",
              "Failed to delete role.",
              "error"
            );
            console.error("Error deleting role:", error);
          }
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          swalWithBootstrapButtons.fire(
            "Cancelled",
            "Role is safe :)",
            "error"
          );
        }
      });
  };

  const columns = [
    {
      name: "Role",
      selector: (row) => row.name,
      grow: 1.5,
    },
    {
      name: "Is Admin",
      selector: (row) => (row?.chr_is_admin?.includes("Y") ? "YES" : "NO"),
      center: true,
    },
    {
      name: (
        <span className="text-center">
          {hasPermission && hasPermission?.canModify != null ? "Actions" : ""}
        </span>
      ),
      cell: (row) => (
        <Box
          className="report-table-menu"
          sx={{ gap: "25px", display: "inline-flex" }}
        >
          {hasPermission && hasPermission?.canModify != null ? (
            <>
              <OverlayTrigger
                placement="top"
                overlay={
                  <Tooltip id={`tooltip-edit-${row.id}`}>
                    {userRole === "Super Admin" || row.name !== userRole
                      ? "Edit"
                      : "You cannot edit your own role"}
                  </Tooltip>
                }
              >
                <span
                  className={` ${
                    userRole === "Super Admin" || row.name !== userRole
                      ? "cursor-pointer"
                      : ""
                  }`}
                  style={
                    userRole === "Super Admin" || row.name !== userRole
                      ? {}
                      : { opacity: 0.6 }
                  }
                  onClick={() =>
                    userRole === "Super Admin" || row.name !== userRole
                      ? navigate(`/role-manager/add/${row.id}`)
                      : undefined
                  }
                >
                  <EditOutlinedIcon />
                </span>
              </OverlayTrigger>
            </>
          ) : (
            " "
          )}
          {hasPermission && hasPermission?.canDelete != null ? (
            <OverlayTrigger
              placement="top"
              overlay={
                <Tooltip id={`tooltip-delete-${row.id}`}>
                  {row.isUserAvailableInThisRole
                    ? "This role is assigned to user so can't be deleted."
                    : "Delete"}
                  {/* {userRole === "Super Admin" || row.name !== userRole
                    ? "Delete"
                    : "You cannot delete your own role"} */}
                </Tooltip>
              }
            >
              <span
                className={`${
                  userRole === "Super Admin" || row.name !== userRole
                    ? "cursor-pointer"
                    : ""
                }`}
                style={
                  // userRole === "Super Admin" || row.name !== userRole
                  //   ? {}
                  //   : { opacity: 0.6 }
                  row.isUserAvailableInThisRole ? { opacity: 0.6 } : {}
                }
                onClick={
                  () =>
                    row.isUserAvailableInThisRole
                      ? undefined
                      : handleDeleteAlert(row.id)
                  // userRole === "Super Admin" || row.name !== userRole
                  //   ? handleDeleteAlert(row.id)
                  //   : undefined
                }
              >
                <DeleteIcon />
              </span>
            </OverlayTrigger>
          ) : (
            " "
          )}
        </Box>
      ),
      center: true,
    },
  ];

  return (
    <>
      <ToastContainer />

      <Card className="table-main-card">
        <Box className="table-card-header">
          <Box className="left-side">
            <Box className="search-box">
              <input
                type="text"
                className="form-control"
                placeholder="Search role..."
                onChange={(e) => setQuery(e.target.value)}
              />
              <i className="ri-search-line search-icon"></i>
            </Box>
          </Box>

          {hasPermission && hasPermission?.canCreate != null && (
            <Button
              onClick={() => navigate("/role-manager/add")}
              className="btn btn-primary view-btn"
              style={{ minWidth: "162px" }}
            >
              Create Role
            </Button>
          )}
        </Box>
        <Card.Body>
          {data?.length <= 0 && isLoading ? (
            <Loader />
          ) : (
            <DataTable
              fixedHeight="500px"
              pagination
              columns={columns}
              data={data}
              customStyles={CustomTableStyle}
              className="table-design"
            />
          )}
        </Card.Body>
      </Card>
    </>
  );
};

export default RoleManager;

import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Col, Form, Label, Row, Spinner } from "reactstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import { changePasswordReq } from "../../../../Api/MyProfile/PagesProfileSetting/PagesProfileSettingReq";
import { ChangePasswordRes } from "../../../../Api/MyProfile/PagesProfileSetting/PagesProfileSettingRes";
import { IconButton, InputAdornment, TextField } from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { Button } from "react-bootstrap";

const ChangePassword = () => {
  const params = useParams();
  const navigate = useNavigate();

  const [errors, setErrors] = useState(null);
  const [success, setSuccess] = useState(null);
  const [showOldPassword, setShowOldPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmNewPassword, setShowConfirmNewPassword] = useState(false);
  const [postIsLoading, setPostIsLoading] = useState(false);

  const changePasswordValidation = useFormik({
    enableReinitialize: true,

    initialValues: {
      oldPassword: "",
      newPassword: "",
      confirmNewPassword: "",
    },

    validationSchema: Yup.object({
      oldPassword: Yup.string().max(255).required("Old password is required."),
      newPassword: Yup.string()
        .min(8, "Password must be at least 8 characters")
        .matches(RegExp("(.*[a-z].*)"), "At least lowercase letter")
        .matches(RegExp("(.*[A-Z].*)"), "At least uppercase letter")
        .matches(RegExp("(.*[0-9].*)"), "At least one number")
        .required("Confirm Password required")
        .test(
          "notSameAsOldPassword",
          "New password must be different from the old password",
          function (value) {
            return value !== this.parent.oldPassword;
          }
        ),
      confirmNewPassword: Yup.string()
        .oneOf([Yup.ref("newPassword"), null], "Passwords must match")
        .required("Confirm Password Required"),
    }),

    onSubmit: (values) => {
      changePassword(values);
    },
  });

  const changePassword = async (values) => {
    try {
      setPostIsLoading(true);
      const { url, reqData } = changePasswordReq(values);

      let result = await fetch(url, reqData);
      let response = await result.json();

      ChangePasswordRes(
        changePasswordValidation,
        response,
        setErrors,
        setSuccess,
        navigate,
        params.id
      );
      setPostIsLoading(false);
    } catch (error) {
      setPostIsLoading(false);
      return null;
    } finally {
      setPostIsLoading(false);
    }
  };

  return (
    <Form onSubmit={changePasswordValidation.handleSubmit}>
      <Row className="g-4">
        <div
          className={
            !errors
              ? "d-none"
              : "alert alert-borderless alert-danger text-center mb-2 mx-2"
          }
          role="alert"
        >
          {errors && errors}
        </div>
        <div
          className={
            !success
              ? "d-none"
              : "alert alert-borderless alert-success text-center mb-2 mx-2"
          }
          role="alert"
        >
          {success && success}
        </div>
        <Col md={6} xl={4}>
          <Label htmlFor="oldpasswordInput" className="form-label">
            Old Password*
          </Label>
          {/* <div className="position-relative auth-pass-inputgroup">
            <Input
              type={showOldPassword ? "text" : "password"}
              className="form-control pe-5 password-input"
              id="oldpasswordInput"
              placeholder="Enter current password"
              {...changePasswordValidation.getFieldProps("oldPassword")}
              invalid={
                changePasswordValidation.errors.oldPassword &&
                changePasswordValidation.touched.oldPassword
                  ? true
                  : false
              }
            />
            {changePasswordValidation.touched.oldPassword &&
            changePasswordValidation.errors.oldPassword ? (
              <div style={{ color: "red" }}>
                {changePasswordValidation.errors.oldPassword}
              </div>
            ) : null}
            <Button
              color="link"
              onClick={() => setShowOldPassword(!showOldPassword)}
              className="position-absolute end-0 top-0 text-decoration-none text-muted password-old"
              id="password-old"
            >
              <i className="ri-eye-fill align-middle"></i>
            </Button>
          </div> */}
          <div className="position-relative auth-pass-inputgroup">
            <TextField
              type={showOldPassword ? "text" : "password"}
              id="oldpasswordInput"
              placeholder="Enter current password"
              {...changePasswordValidation.getFieldProps("oldPassword")}
              error={Boolean(
                changePasswordValidation.errors.oldPassword &&
                  changePasswordValidation.touched.oldPassword
              )}
              helperText={
                changePasswordValidation.touched.oldPassword &&
                changePasswordValidation.errors.oldPassword
              }
              fullWidth
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() => setShowOldPassword(!showOldPassword)}
                      edge="end"
                    >
                      {showOldPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </div>
        </Col>

        <Col md={6} xl={4}>
          <Label htmlFor="newpasswordInput" className="form-label">
            New Password*
          </Label>
          {/* <div className="position-relative auth-pass-inputgroup">
            <Input
              type={showNewPassword ? "text" : "password"}
              className="form-control"
              id="newpasswordInput"
              placeholder="Enter new password"
              {...changePasswordValidation.getFieldProps("newPassword")}
              invalid={
                changePasswordValidation.errors.newPassword &&
                changePasswordValidation.touched.newPassword
                  ? true
                  : false
              }
            />
            {changePasswordValidation.touched.newPassword &&
            changePasswordValidation.errors.newPassword ? (
              <div style={{ color: "red" }}>
                {changePasswordValidation.errors.newPassword}
              </div>
            ) : null}
            <Button
              color="link"
              onClick={() => setShowNewPassword(!showNewPassword)}
              className="position-absolute end-0 top-0 text-decoration-none text-muted password-new"
              id="password-new"
            >
              <i className="ri-eye-fill align-middle"></i>
            </Button>
          </div> */}
          <div className="position-relative auth-pass-inputgroup">
            <TextField
              type={showNewPassword ? "text" : "password"}
              id="newpasswordInput"
              placeholder="Enter new password"
              {...changePasswordValidation.getFieldProps("newPassword")}
              error={Boolean(
                changePasswordValidation.errors.newPassword &&
                  changePasswordValidation.touched.newPassword
              )}
              helperText={
                changePasswordValidation.touched.newPassword &&
                changePasswordValidation.errors.newPassword
              }
              fullWidth
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() => setShowNewPassword(!showNewPassword)}
                      edge="end"
                    >
                      {showNewPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </div>
        </Col>

        <Col md={6} xl={4}>
          <Label htmlFor="confirmpasswordInput" className="form-label">
            Confirm Password*
          </Label>
          {/* <div className="position-relative auth-pass-inputgroup">
            <Input
              type={showConfirmNewPassword ? "text" : "password"}
              className="form-control"
              id="confirmpasswordInput"
              placeholder="Confirm password"
              name="confirmNewPassword"
              {...changePasswordValidation.getFieldProps("confirmNewPassword")}
              invalid={
                changePasswordValidation.errors.confirmNewPassword &&
                changePasswordValidation.touched.confirmNewPassword
                  ? true
                  : false
              }
            />
            {changePasswordValidation.touched.confirmNewPassword &&
            changePasswordValidation.errors.confirmNewPassword ? (
              <div style={{ color: "red" }}>
                {changePasswordValidation.errors.confirmNewPassword}
              </div>
            ) : null}
            <Button
              color="link"
              onClick={() => setShowConfirmNewPassword(!showConfirmNewPassword)}
              className="position-absolute end-0 top-0 text-decoration-none text-muted password-confirm"
              id="password-confirm"
            >
              <i className="ri-eye-fill align-middle"></i>
            </Button>
          </div> */}
          <div className="position-relative auth-pass-inputgroup">
            <TextField
              type={showConfirmNewPassword ? "text" : "password"}
              id="confirmpasswordInput"
              placeholder="Confirm password"
              name="confirmNewPassword"
              {...changePasswordValidation.getFieldProps("confirmNewPassword")}
              error={Boolean(
                changePasswordValidation.errors.confirmNewPassword &&
                  changePasswordValidation.touched.confirmNewPassword
              )}
              helperText={
                changePasswordValidation.touched.confirmNewPassword &&
                changePasswordValidation.errors.confirmNewPassword
              }
              fullWidth
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() =>
                        setShowConfirmNewPassword(!showConfirmNewPassword)
                      }
                      edge="end"
                    >
                      {showConfirmNewPassword ? (
                        <VisibilityOff />
                      ) : (
                        <Visibility />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </div>
        </Col>

        <Col lg={12}>
          <div className="text-end">
            <Button
              type="submit"
              className="btn btn-dark"
              disabled={postIsLoading}
            >
              {postIsLoading ? (
                <Spinner size="sm" variant="light" />
              ) : (
                "Change Password"
              )}
            </Button>
          </div>
        </Col>
      </Row>
    </Form>
  );
};
export default ChangePassword;

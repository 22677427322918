import { put, takeEvery, call, all, fork } from "redux-saga/effects";
import * as types from "./actionType";
import * as actions from "./action";
import axios from "axios";
import common from "../../Components/Common/common";

const fetchAnalyticsRealtimeData = async (payload) => {
  try {
    let data = JSON.stringify({ assets_id: payload.id });
    const response = await axios.post(
      `/site-data/analytics-realtime-data`,
      data
    );
    const decryptedData = common.decrypt(response.data);
    return decryptedData;
  } catch (error) {
    return error.message;
  }
};

const fetchAnalyticsRealtimeDurationData = async (payload) => {
  try {
    let data = JSON.stringify({ assets_id: payload.id });
    let response = await axios.post(
      `/site-data/analytics-realtime-duration-data`,
      data
    );
    const decryptedData = common.decrypt(response.data);
    response.data = decryptedData;
    return response.data;
  } catch (error) {
    return error.message;
  }
};

const fetchAnalyticsData = async (payload) => {
  try {
    let data = JSON.stringify({ assets_id: payload.assetID, day: payload.day });
    let response = await axios.post(`/asset/analytics-data`, data);
    const decryptedData = common.decrypt(response.data);
    response.data = decryptedData;
    return response.data;
  } catch (error) {
    return error.message;
  }
};

const fetchUserSessionChartData = async (payload) => {
  try {
    let data = JSON.stringify({ assets_id: payload.assetID });
    let response = await axios.post(`/asset/analytics-total-users`, data);
    const decryptedData = common.decrypt(response.data);
    response.data = decryptedData;
    return response.data;
  } catch (error) {
    return error.message;
  }
};

const fetchAnalyticsChartData = async (payload) => {
  try {
    let data = JSON.stringify({ assets_id: payload.assetID, day: payload.day });
    let response = await axios.post(`/asset/analytics-chart-data  `, data);
    const decryptedData = common.decrypt(response.data);
    response.data = decryptedData;
    return response.data;
  } catch (error) {
    return error.message;
  }
};

const fetchHitReportData = async (payload) => {
  try {
    const response = await axios.post(
      `asset/analytics-hit-report?searchParams=`
    );
    return response.data;
  } catch (error) {
    return error.message;
  }
};

const fetchVisitorsData = async (payload) => {
  try {
    const response = await axios.post(`asset/analytics-visitors-data/182`);
    return response.data;
  } catch (error) {
    return error.message;
  }
};

function* fetchAnalyticsRealtimeSaga(action) {
  try {
    const response = yield call(fetchAnalyticsRealtimeData, action.payload);
    yield put(actions.fetchAnalyticsRealtimeDataSuccess(response));
  } catch (error) {
    yield put(actions.fetchAnalyticsRealtimeDatafailure(error.response));
  }
}

function* fetchAnalyticsRealtimeDurationDataSaga(action) {
  try {
    const response = yield call(
      fetchAnalyticsRealtimeDurationData,
      action.payload
    );
    yield put(actions.fetchAnalyticsRealtimeDurationDataSuccess(response));
  } catch (error) {
    yield put(
      actions.fetchAnalyticsRealtimeDurationDatafailure(error.response)
    );
  }
}

function* fetchAnalyticsDataSaga(action) {
  try {
    const response = yield call(fetchAnalyticsData, action.payload);
    yield put(actions.fetchAnalyticsDataSuccess(response));
  } catch (error) {
    yield put(actions.fetchAnalyticsDatafailure(error.response));
  }
}

function* fetchUserSessionChartDataSaga(action) {
  try {
    const response = yield call(fetchUserSessionChartData, action.payload);
    yield put(actions.fetchUserSessionChartDataSuccess(response));
  } catch (error) {
    yield put(actions.fetchUserSessionChartDatafailure(error.response));
  }
}

function* fetchAnalyticsChartDataSaga(action) {
  try {
    const response = yield call(fetchAnalyticsChartData, action.payload);
    yield put(actions.fetchChartDataSuccess(response));
  } catch (error) {
    yield put(actions.fetchChartDatafailure(error.response));
  }
}

function* fetchHitReportDataSaga(action) {
  try {
    const response = yield call(fetchHitReportData, action.payload);
    yield put(actions.fetchHitReportDataSuccess(response));
  } catch (error) {
    yield put(actions.fetchHitReportDatafailure(error.response));
  }
}

function* fetchVisitorsDataSaga(action) {
  try {
    const response = yield call(fetchVisitorsData, action.payload);
    yield put(actions.fetchVisitorsDataSuccess(response));
  } catch (error) {
    yield put(actions.fetchVisitorsDatafailure(error.response));
  }
}

export function* watchAnalyticsReport() {
  yield takeEvery(
    types.FETCH_ANALYTICS_REALTIME_DATA_REQUEST,
    fetchAnalyticsRealtimeSaga
  );
  yield takeEvery(
    types.FETCH_ANALYTICS_REALTIME_DURATION_DATA_REQUEST,
    fetchAnalyticsRealtimeDurationDataSaga
  );
  yield takeEvery(types.FETCH_ANALYTICS_DATA_REQUEST, fetchAnalyticsDataSaga);
  yield takeEvery(
    types.FETCH_USER_SESSION_CHART_REQUEST,
    fetchUserSessionChartDataSaga
  );
  yield takeEvery(types.FETCH_CHART_DATA_REQUEST, fetchAnalyticsChartDataSaga);
  yield takeEvery(types.FETCH_HIT_REPORT_REQUEST, fetchHitReportDataSaga);
  yield takeEvery(types.FETCH_VISITORS_DATA_REQUEST, fetchVisitorsDataSaga);
}

function* analyticsReortSaga() {
  yield all([fork(watchAnalyticsReport)]);
}

export default analyticsReortSaga;

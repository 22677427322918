import React from "react";
import { Navigate } from "react-router-dom";

//AuthenticationInner pages
// import CoverSignIn from "../pages/AuthenticationInner/Login/CoverSignIn";
// import CoverSignUp from "../pages/AuthenticationInner/Register/CoverSignUp";

//pages
import Settings from "../pages/Pages/Profile/Settings/Settings";
import Team from "../pages/Pages/Team/Team";
import Maintenance from "../pages/Pages/Maintenance/Maintenance";
import ComingSoon from "../pages/Pages/ComingSoon/ComingSoon";

// import CoverPasswReset from "../pages/AuthenticationInner/PasswordReset/CoverPasswReset";
import CoverLockScreen from "../pages/AuthenticationInner/LockScreen/CoverLockScr";
// import CoverLogout from "../pages/AuthenticationInner/Logout/CoverLogout";
import CoverSuccessMsg from "../pages/AuthenticationInner/SuccessMessage/CoverSuccessMsg";
import CoverTwosVerify from "../pages/AuthenticationInner/TwoStepVerification/CoverTwosVerify";
import Cover404 from "../pages/AuthenticationInner/Errors/Cover404";
import Alt404 from "../pages/AuthenticationInner/Errors/Alt404";
import Error500 from "../pages/AuthenticationInner/Errors/Error500";

import CoverPasswCreate from "../pages/AuthenticationInner/PasswordCreate/CoverPasswCreate";
import Offlinepage from "../pages/AuthenticationInner/Errors/Offlinepage";

//login
import Logout from "../pages/Authentication/Logout";

// User Profile
import UserProfile from "../pages/Authentication/user-profile";

// Control Base
import Announcements from "../pages/Announcements/Announcements";
import NewDashboard from "../pages/NewDashboard/NewDashboard";
// import Users from "../pages/userManagement/Users/Users";
import InternalUsers from "../pages/userManagement/DepartmentUser/InternalUsers";

import DeveloperProfile from "../pages/userManagement/DeveloperProfile/DeveloperProfile";
import DeveloperProfileDetails from "../pages/userManagement/DeveloperProfile/DeveloperProfileDetails";
import OnlineAssets from "../pages/WebsiteManagement/OnlineAssets";
import TicketingSystem from "../pages/TicketingSystem/TicketingSystem";
import UptimeReport from "../pages/Reporting/UptimeReport";
import HitReport from "../pages/Reporting/HitReport";

import Setting from "../pages/Setting/Setting";

import WebsiteGuideline from "../pages/GuidelinesAndpolicies/WebsiteGuideline";
import BrandingGuidelines from "../pages/GuidelinesAndpolicies/BrandingGuideline";
import WebsitePolicies from "../pages/GuidelinesAndpolicies/WebsitePolicies";

import DVDL from "../pages/NewDashboard/DashboardAnalyticTab";
import RoleManager from "../pages/AccessRights/RoleManager/RoleManager";
import AddRole from "../pages/AccessRights/RoleManager/AddRole";
import TicketDetail from "../pages/TicketingSystem/TicketDetail/TicketDetail";

import BlockedIp from "../pages/BlockedIp/BlockedIp";
import SimplePage from "../pages/Pages/Profile/SimplePage/Profile";
import VectorMaps from "../pages/Maps/VectorMaps/VectorMaps";
import UptimeReportDetails from "../pages/Reporting/UptimeReportDetail";
import HitReportDetails from "../pages/Reporting/HitReportDetails";
// import PerformanceDetailPage from "../pages/Reporting/PerformanceDetailPage";
import PerformancePage from "../pages/Reporting/PerformancePage";
import WebSecurity from "../pages/Reporting/WebSecurity";
import WebSecurityDetail from "../pages/Reporting/WebSecurityDetail";
import Index from "../pages/AuthenticationInner";
import PerformanceDetail from "../pages/NewDashboard/DashboardAnalyticTab/Tabs/PerformanceDetail";
import AuditLogs from "../pages/AuditLogs/AuditLogs";
import NotificationSetting from "../pages/NotificationSetting/NotificationSetting";
import Compliance from "../pages/Compliance/index";
import NotificationPage from "../pages/NotificationSetting/NotificationPage";
import Cover401 from "../pages/AuthenticationInner/Errors/Cover401";
import EmailLogs from "../pages/EmailLogs/EmailLogs";

const authProtectedRoutes = [
  // COMMON
  { path: "*", component: <NewDashboard /> },
  { path: "/", component: <NewDashboard /> },
  { path: "/dashboard", component: <NewDashboard /> },
  { path: "/online-assets/:id", component: <DVDL /> },

  // ROLE_MANAGER
  { path: "/role-manager", component: <RoleManager />, moduleCode: 22 },
  { path: "/role-manager/add", component: <AddRole />, moduleCode: 23 },
  { path: "/role-manager/add/:id", component: <AddRole />, moduleCode: 24 },

  // website management
  { path: "/online-assets", component: <OnlineAssets />, moduleCode: 1 },

  /// user-Management
  // {
  //   moduleCode: 2,
  //   operation: "list",
  //   path: "/internal-users",
  //   component: <Users />,
  // },
  { path: "/core-users", component: <InternalUsers />, moduleCode: 2 },
  {
    path: "/developer-profile",
    component: <DeveloperProfile />,
    moduleCode: 7,
  },
  {
    path: "/developer-profile/:id",
    component: <DeveloperProfileDetails />,
    moduleCode: 7,
  },

  // Guidelines & Policies
  {
    path: "/website-guideline",
    component: <WebsiteGuideline />,
    moduleCode: 10,
  },
  {
    path: "/branding-guideline",
    component: <BrandingGuidelines />,
    moduleCode: 12,
  },
  { path: "/website-policies", component: <WebsitePolicies />, moduleCode: 19 },

  // announcements
  { path: "/announcements", component: <Announcements />, moduleCode: 14 },

  //Ticketing systems
  { path: "/ticketing-system", component: <TicketingSystem />, moduleCode: 16 },
  {
    path: "/ticketing-system/:id",
    component: <TicketDetail />,
    moduleCode: 16,
  },

  // Reporting
  { path: "/uptime-report", component: <UptimeReport />, moduleCode: 26 },
  {
    path: "/uptime-report/:id",
    component: <UptimeReportDetails />,
    moduleCode: 26,
  },

  { path: "/hit-report", component: <HitReport />, moduleCode: 27 },
  { path: "/hit-report/:id", component: <HitReportDetails />, moduleCode: 27 },

  { path: "/performance", component: <PerformancePage />, moduleCode: 78 },
  {
    path: "/performance/:id",
    component: <PerformanceDetail />,
    moduleCode: 78,
  },

  { path: "/web-security", component: <WebSecurity />, moduleCode: 79 },
  {
    path: "/web-security/:id",
    component: <WebSecurityDetail />,
    moduleCode: 79,
  },

  // setting
  { path: "/settings", component: <Setting /> },
  { path: "/vector-maps", component: <VectorMaps /> },

  //Pages
  { path: "/pages-profile/:id", component: <SimplePage /> },
  { path: "/pages-profile-settings/:id", component: <Settings /> },
  { path: "/pages-team", component: <Team /> },

  //User Profile
  { path: "/profile", component: <UserProfile /> },

  // this route should be at the end of all other routes
  // eslint-disable-next-line react/display-name
  {
    path: "/",
    // exact: true,
    component: <Navigate to="/dashboard" />,
  },
  { path: "*", component: <Navigate to="/dashboard" /> },
  { path: "/blocked-ip", component: <BlockedIp />, moduleCode: 66 },
  { path: "/audit-logs", component: <AuditLogs /> },
  { path: "/email-logs", component: <EmailLogs /> },
  { path: "/notification-setting", component: <NotificationSetting /> },

  ///
  { path: "/compliance", component: <Compliance /> },

  //Notification Page
  { path: "/notifications", component: <NotificationPage /> },
];

const publicRoutes = [
  // Authentication Page
  { path: "/logout", component: <Logout /> },
  { path: "/login", component: <Index /> },

  //AuthenticationInner pages
  // { path: "/auth-signup-cover", component: <CoverSignUp /> },
  // { path: "/auth-pass-reset-cover", component: <CoverPasswReset /> },
  // { path: "/auth-lockscreen-cover", component: <CoverLockScreen /> },
  // { path: "/auth-logout-cover", component: <CoverLogout /> },
  // { path: "/auth-success-msg-cover", component: <CoverSuccessMsg /> },
  // { path: "/auth-twostep-cover", component: <CoverTwosVerify /> },
  // { path: "/auth-404-cover", component: <Cover404 /> },
  // { path: "/auth-404-alt", component: <Alt404 /> },
  // { path: "/auth-500", component: <Error500 /> },
  // { path: "/pages-maintenance", component: <Maintenance /> },
  // { path: "/pages-coming-soon", component: <ComingSoon /> },

  // { path: "/auth-pass-change-cover", component: <CoverPasswCreate /> },
  // { path: "/auth-offline", component: <Offlinepage /> },
];

const accessRoute = [
  //AuthenticationInner pages
  // { path: "/auth-signup-cover", component: <CoverSignUp /> },
  { path: "/auth-pass-reset-cover", component: <Index /> },
  { path: "/auth-lockscreen-cover", component: <CoverLockScreen /> },
  { path: "/auth-logout-cover", component: <Index /> },
  { path: "/auth-success-msg-cover", component: <CoverSuccessMsg /> },
  { path: "/auth-twostep-cover", component: <CoverTwosVerify /> },
  { path: "/auth-404-cover", component: <Cover404 /> },
  { path: "/auth-401-cover", component: <Cover401 /> },
  { path: "/auth-404-alt", component: <Alt404 /> },
  { path: "/auth-500", component: <Error500 /> },
  { path: "/pages-maintenance", component: <Maintenance /> },
  { path: "/pages-coming-soon", component: <ComingSoon /> },

  { path: "/auth-pass-change-cover", component: <CoverPasswCreate /> },
  { path: "/auth-offline", component: <Offlinepage /> },
];

export { authProtectedRoutes, publicRoutes, accessRoute };

import { Box } from "@mui/material";
import React, { useState, useEffect } from "react";
import { Card } from "react-bootstrap";
import { useSelector } from "react-redux";
import "react-toastify/dist/ReactToastify.css";
import { DeleteIcon } from "../../../Components/Common/SvgIcons";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import MoreVertOutlinedIcon from "@mui/icons-material/MoreVertOutlined";

const BootstrapCards = ({
  user,
  color,
  mapKey,
  updateGuideline,
  handleDeleteAlert,
  hasPermission,
}) => {
  const [logoImage, setLogoImage] = useState("");

  const settingsData = useSelector(
    (state) =>
      state?.settingsData?.settingsData?.find(
        (a) => a?.field_name === "GUIDELINE_PDF_IMAGE"
      )?.field_value
  );
  useEffect(() => {
    setLogoImage(`${process.env.REACT_APP_BASE_URL}${settingsData}`);
  }, [settingsData]);

  const href = user.website_guideline_image
    ? `${process.env.REACT_APP_BASE_URL}${user.website_guideline_image}`
    : logoImage;

  return (
    <>
      <Box className="col-12 col-sm-6 col-md-6 col-xl-4 col-xxxl-3" key={mapKey}>
        <Card className="team-box h-100 mb-0">
          <a
            href={href}
            // href={`${process.env.REACT_APP_BASE_URL}${user.website_guideline_image}`}
            target="_blank"
            rel="noreferrer noopener"
            title={user.website_guideline_title}
          >
            <Box className="thumbnail-container">
              <Box className="thumbnail">
                {user.website_guideline_image != null ? (
                  <img
                    src={`${process.env.REACT_APP_BASE_URL}${user.website_guideline_image}`}
                    alt="img"
                    className="img-fluid"
                  />
                ) : (
                  <img src={logoImage} alt="" className="img-fluid" />
                )}
              </Box>
            </Box>
          </a>

          <Card.Body className="p-4">
            {hasPermission &&
            (hasPermission?.canModify != null ||
              hasPermission.canDelete != null) ? (
              <>
                <Box className="team-settings">
                  <Box className="text-end dropdown">
                    <span
                      id="dropdownMenuLink2"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                      className="cursor-pointer"
                    >
                      <MoreVertOutlinedIcon />
                    </span>
                    <ul
                      className="dropdown-menu dropdown-menu-end"
                      aria-labelledby="dropdownMenuLink2"
                    >
                      {hasPermission && hasPermission?.canModify != null ? (
                        <>
                          <li>
                            <span
                              onClick={() => updateGuideline(user)}
                              className="dropdown-item"
                            >
                              <span className="me-2 align-middle">
                                <EditOutlinedIcon />
                              </span>
                              Edit
                            </span>
                          </li>
                        </>
                      ) : (
                        ""
                      )}
                      {hasPermission && hasPermission?.canDelete != null ? (
                        <>
                          <li
                            onClick={() =>
                              handleDeleteAlert(user.id, user.email)
                            }
                            className="dropdown-item"
                          >
                            <span className="me-2 align-middle">
                              <DeleteIcon />
                            </span>
                            Delete
                          </li>
                        </>
                      ) : (
                        ""
                      )}
                    </ul>
                  </Box>
                </Box>
              </>
            ) : (
              ""
            )}
            <Box className="team-content mb-3">
              <h4 className="pe-2">
                <a
                  href={`${process.env.REACT_APP_BASE_URL}${user.website_guideline_pdf}`}
                  target="_blank"
                  rel="noreferrer noopener"
                  title="GOV.KY"
                >
                  {user.website_guideline_title}
                </a>
              </h4>
              <p>
                <a
                  href={`${process.env.REACT_APP_BASE_URL}${user.website_guideline_pdf}`}
                  target="_blank"
                  rel="noreferrer noopener"
                  title="GOV.KY"
                >
                  {user.website_guideline_description}
                </a>
              </p>
            </Box>
            <a
              href={`${process.env.REACT_APP_BASE_URL}${user.website_guideline_pdf}`}
              target="_blank"
              rel="noreferrer noopener"
              className="btn btn-outline-primary btn-sm mt-auto"
            >
              View Guidelines
            </a>
          </Card.Body>
        </Card>
      </Box>
    </>
  );
};

export default BootstrapCards;

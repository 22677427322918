import { put, takeEvery, call, all, fork } from "redux-saga/effects";
import * as types from "./actionType";
import * as actions from "./action";
import axios from "axios";
import common from "../../Components/Common/common";

const fetchSiteStatusByAssetId = async (id) => {
  try {
    const response = await axios.post(`/asset/asset-site-status/${id}`);
    if (response.status === 400) throw new Error(response.error.message);
    const decryptedData = common.decrypt(response.data);

    return decryptedData;
  } catch (error) {
    throw error.message;
  }
};

// Filtered Data
const fetchFilteredData = async (data) => {
  try {
    const { id, filter, customStartDate, customEndDate } = data;
    const response = await axios.post(
      `/asset/filtered-site-status-data/${id}`,
      { response_filter: parseInt(filter), customStartDate, customEndDate }
    );
    const decryptedData = common.decrypt(response.data);
    return decryptedData;
  } catch (error) {
    throw error.message;
  }
};

function* fetchSiteStatusSaga(action) {
  try {
    const response = yield call(fetchSiteStatusByAssetId, action.payload);
    yield put(actions.fetchSiteStatusSuccess(response));
  } catch (error) {
    yield put(actions.fetchSiteStatusFailure(error.response));
  }
}

// Filtered Data
function* fetchFilteredDataSaga(action) {
  try {
    const response = yield call(fetchFilteredData, action.payload);
    yield put(actions.fetchFilteredDataSuccess(response));
  } catch (error) {
    yield put(actions.fetchFilteredDataFailure(error.response));
  }
}

export function* watchUptimeReport() {
  yield takeEvery(types.FETCH_SITE_STATUS_REQUEST, fetchSiteStatusSaga);
  yield takeEvery(types.FETCH_FILTERED_DATA_REQUEST, fetchFilteredDataSaga);
}

function* uptimeReportSaga() {
  yield all([fork(watchUptimeReport)]);
}

export default uptimeReportSaga;

export const FETCH_ANALYTICS_REALTIME_DATA_REQUEST =
  "FETCH_ANALYTICS_REALTIME_DATA_REQUEST";
export const FETCH_ANALYTICS_REALTIME_SUCCESS =
  "FETCH_ANALYTICS_REALTIME_DATA_SUCCESS";
export const FETCH_ANALYTICS_REALTIME_FAILURE =
  "FETCH_ANALYTICS_REALTIME_DATA_FAILURE";

export const FETCH_ANALYTICS_REALTIME_DURATION_DATA_REQUEST =
  "FETCH_ANALYTICS_REALTIME_DURATION_DATA_REQUEST";
export const FETCH_ANALYTICS_REALTIME_DURATION_DATA_SUCCESS =
  "FETCH_ANALYTICS_REALTIME_DURATION_DATA_SUCCESS";
export const FETCH_ANALYTICS_REALTIME_DURATION_DATA_FAILURE =
  "FETCH_ANALYTICS_REALTIME_DURATION_DATA_FAILURE";

export const FETCH_ANALYTICS_DATA_REQUEST = "FETCH_ANALYTICS_DATA_REQUEST";
export const FETCH_ANALYTICS_DATA_SUCCESS = "FETCH_ANALYTICS_DATA_SUCCESS";
export const FETCH_ANALYTICS_DATA_FAILURE = "FETCH_ANALYTICS_DATA_FAILURE";

export const FETCH_USER_SESSION_CHART_REQUEST =
  "FETCH_USER_SESSION_CHART_REQUEST";
export const FETCH_USER_SESSION_CHART_SUCCESS =
  "FETCH_USER_SESSION_CHART_SUCCESS";
export const FETCH_USER_SESSION_CHART_FAILURE =
  "FETCH_USER_SESSION_CHART_FAILURE";

export const FETCH_CHART_DATA_REQUEST = "FETCH_CHART_DATA_REQUEST";
export const FETCH_CHART_DATA_SUCCESS = "FETCH_CHART_DATA_SUCCESS";
export const FETCH_CHART_DATA_FAILURE = "FETCH_CHART_DATA_FAILURE";

export const FETCH_HIT_REPORT_REQUEST = "FETCH_HIT_REPORT_REQUEST";
export const FETCH_HIT_REPORT_SUCCESS = "FETCH_HIT_REPORT_SUCCESS";
export const FETCH_HIT_REPORT_FAILURE = "FETCH_HIT_REPORT_FAILURE";

export const FETCH_VISITORS_DATA_REQUEST = "FETCH_HIT_REPORT_REQUEST";
export const FETCH_VISITORS_DATA_SUCCESS = "FETCH_HIT_REPORT_SUCCESS";
export const FETCH_VISITORS_DATA_FAILURE = "FETCH_HIT_REPORT_FAILURE";

export const SET_INITIAL_STATE = "SET_INITIAL_STATE";


export const PostNotesReq = (props) => {
    const { notesData, token, userId, id} = props;
    let userData = {
        user_id: userId,
        online_asset_id: id,
        notes: notesData,
    };
    let url = `${process.env.REACT_APP_BASE_URL}website-management/post-notes`;
    let reqData = {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(userData),
    };

    return { url, reqData };
};

export const getNotesReq = (props) => {
    const {  days, searchNotes, token, id } = props;

    let userData = {
        online_asset_id: id,
        days: days,
    };
    let url = `${process.env.REACT_APP_BASE_URL}website-management/get-notes?searchParams=${searchNotes}`;
    let reqData = {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(userData),
    };

    return { url, reqData };
}
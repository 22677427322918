import { call, put, takeLatest } from "redux-saga/effects";
import axios from "axios";
import {
  FETCH_DASHBOARD_SETTINGS_REQUEST,
  UPDATE_DASHBOARD_SETTINGS_REQUEST,
} from "./actionTypes";
import {
  fetchDashboardSettingsSuccess,
  fetchDashboardSettingsFailure,
  updateDashboardSettingsSuccess,
  updateDashboardSettingsFailure,
} from "./action";
import common from "../../Components/Common/common";

// Fetch Dashboard Settings Saga
function* fetchDashboardSettingsSaga(action) {
  try {
    let response = yield call(
      axios.get,
      `/dashboard/dashboard-settings/${action.payload}`
    );

    const decryptedData = common.decrypt(response.data);
    response.data = decryptedData;

    yield put(fetchDashboardSettingsSuccess(response.data));
  } catch (error) {
    yield put(fetchDashboardSettingsFailure(error.message));
  }
}

// Update Dashboard Settings Saga
function* updateDashboardSettingsSaga(action) {
  try {
    const { userId, updatedSettings } = action.payload;
    yield call(axios.post, `/dashboard/dashboard-settings`, {
      user_id: userId,
      settings: updatedSettings,
    });
    yield put(updateDashboardSettingsSuccess(updatedSettings));
  } catch (error) {
    yield put(updateDashboardSettingsFailure(error.message));
  }
}

export default function* dashboardSaga() {
  yield takeLatest(
    FETCH_DASHBOARD_SETTINGS_REQUEST,
    fetchDashboardSettingsSaga
  );
  yield takeLatest(
    UPDATE_DASHBOARD_SETTINGS_REQUEST,
    updateDashboardSettingsSaga
  );
}

import React, { useState, useEffect, useCallback } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Button,
  Form,
  Col,
  FormFeedback,
  Input,
  Row,
  Spinner,
} from "reactstrap";
import DeleteModal from "../../../Components/Common/DeleteModal";
import axios from "axios";

import { useSelector } from "react-redux";
// import Select from "react-select";

import { useFormik } from "formik";
import * as Yup from "yup";
import { Modal, ModalBody, ModalHeader, ModalTitle } from "react-bootstrap";
import { ToastMessage } from "../../../Components/Common/ToastMessage";
import { ToastContainer } from "react-toastify";
import TeamCard from "./TeamCard";
import _ from "lodash";
import { DEVELOPER_PROFILE } from "../../../Components/Common/ModuleName";
import { socket } from "../../../Components/Common/socket";
import {
  Box,
  Checkbox,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  TextField,
  Tooltip,
} from "@mui/material";
import { MenuHeight } from "../../NewDashboard/constant";
import common from "../../../Components/Common/common";

const Team = () => {
  const token = localStorage.getItem("token");
  const userId = localStorage.getItem("user_id");

  const permissionData = useSelector(
    (state) => state.userDetails.userPermission
  );
  const hasPermission = permissionData?.find(
    (item) => item.module_name === DEVELOPER_PROFILE
  );

  const navigate = useNavigate();

  const [deleteModal, setDeleteModal] = useState(false);
  const [teamList, setTeamlist] = useState([]);
  const [query, setQuery] = useState("");
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [assetSelectedOptions, setAssetSelectedOptions] = useState([]);
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [totalRows, setTotalRows] = useState(0);
  const [skillsIds, setAllSkillsIds] = useState("");
  const [postIsLoading, setPostIsLoading] = useState(false);

  const location = useLocation();
  useEffect(() => {
    if (location.state?.globalSearch) {
      setQuery(location.state?.globalSearch);
    }
  }, [location.state]);

  const handleSelectChange = (event) => {
    const {
      target: { value },
    } = event;
    const selected = typeof value === "string" ? value.split(",") : value;
    setSelectedOptions(selected);
    validation.setFieldValue(
      "skills",
      selected.map(
        (item) => skillsIds.find((option) => option.label === item).value
      )
    );
  };

  const handleAssetsChange = (selectedOptions) => {
    validation.setFieldValue(
      "asset_url_management",
      selectedOptions.map((item) => item.value)
    );
    setAssetSelectedOptions(selectedOptions);
  };

  const validation = useFormik({
    enableReinitialize: true,

    initialValues: {
      name: "",
      organization: "",
      contact_no: "",
      email: "",
      organization_url: "",
      designation: "",
      skills: null,
      projects: "",
      tasks: "",
      location: "",
      description: "",
      asset_url_management: null,
      imageFile: null,
    },

    validationSchema: Yup.object({
      name: Yup.string()
        .trim()
        .min(5)
        .max(255)
        .required("Please enter the name."),
      organization: Yup.string()
        .trim()
        .required("Please enter the organization."),
      contact_no: Yup.string().matches(
        /^(\(\d{3}\)[- .]?)?\d{3}[- .]?\d{4}|\d{10}$/,
        "Please enter a valid phone number"
      ),
      // .required("Please enter the 10-digit contact number"),
      email: Yup.string()
        .email("Must be a valid email.")
        .max(70)
        .required("Please enter the Email."),
      organization_url: Yup.string()
        .test("is-valid-url", "Invalid URL format", (value) => {
          if (!value) {
            return true; // Allow empty values (you can change this if needed)
          }
          const urlPattern =
            /^(https?:\/\/)?(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/g;
          return urlPattern.test(value);
        })
        .required("Please enter the organization URL."),
      asset_url_management: Yup.array()
        .min(1, "Please select at least one website.")
        .required("Please select the webiste."),
      designation: Yup.string().trim().min(5).max(255),
      // .required("Please enter the designation."),
      projects: Yup.number().min(1).max(10000),
      // .required("Please enter the projects."),
      tasks: Yup.number().min(1).max(10000),
      // .required("Please enter the tasks."),
      location: Yup.string().trim(),
      description: Yup.string()
        .min(5, "Description must be at least 5 characters.")
        .max(255, "Description must be less than 255 characters."),
      skills: Yup.array()
        .min(1, "Please select at least one skill.")
        .required("Please select the skills."),
      // imageFile: Yup.mixed()
      //   .required('Please select the Image.')
      //   .test('fileSize', 'Failed to upload an image. The image maximum size is 1MB.', (value) => {
      //     return value && value.size <= 1024 * 1024;
      //   })
      //   .test('fileType', 'Invalid file format. Only JPEG, JPG, GIF, PNG, and SVG files are allowed. Please upload a file with one of these formats.', (value) => {
      //     return value && ['image/jpeg', 'image/jpg', 'image/png', 'image/SVG'].includes(value.type);
      //   }),
    }),

    onSubmit: (values, { resetForm }) => {
      postUserManagement(values);
      resetForm();
      setSelectedOptions([]);
      setAssetSelectedOptions([]);
    },
  });

  const handleClose = () => {
    validation.resetForm();
    setSelectedOptions([]);
    setAssetSelectedOptions([]);
    setShow(false);
  };

  let team = null;

  const handleDeleteTeamData = () => {
    if (team) {
      // dispatch(onDeleteTeamData(team));
      setDeleteModal(false);
    }
  };

  const postUserManagement = async (values) => {
    setPostIsLoading(true);
    try {
      let formData = new FormData();
      formData.append("user_id", userId);
      formData.append("organization", values.organization);
      formData.append("user_name", values.name);
      formData.append("contact_no", values.contact_no);
      formData.append("email", values.email);
      formData.append("organization_url", values.organization_url);
      formData.append("designation", values.designation);
      formData.append("skills", values.skills);
      formData.append("location", values.location);
      formData.append("projects", values.projects);
      formData.append("tasks", values.tasks);
      formData.append("description", values.description);
      formData.append("asset_url_management", values.asset_url_management);

      if (values.imageFile && values.imageFile.constructor === File) {
        formData.append("developers_profile_image", values.imageFile);
      }

      let reqData = {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: formData,
      };

      let url = `${process.env.REACT_APP_BASE_URL}developer-profile/post-developer-profile`;
      let result = await fetch(url, reqData);
      let response = await result.json();

      if (response.code === 403) {
        navigate("/auth-404-cover");
      }

      if (response.status === "success") {
        getInternalUsers(1, 5, query);
        setShow(false);
        navigate("/developer-profile");
        socket.emit("addDeveloperProfile");
        ToastMessage.Success("Developer Profile added Successfully");
      } else {
        setShow(false);
        getInternalUsers(1, 5, query);
        navigate("/developer-profile");
        ToastMessage.Error(response.message);
      }
    } catch (error) {
      console.error("Error:", error);
      ToastMessage.Error(
        "An error occurred while adding the developer profile."
      );
    } finally {
      setPostIsLoading(false);
    }
  };

  const getInternalUsers = useCallback(
    async (page, limit, query, isDelete = false, deleteId = null) => {
      try {
        setLoading(true);
        let userData = {};

        if (isDelete) {
          userData = {
            page: page * limit,
            limit: 1,
          };
        } else {
          userData = { page: page, limit: limit };
        }

        let reqData = {
          method: "POST",
          url: `${process.env.REACT_APP_BASE_URL}developer-profile/get-developer-profile?searchParams=${query}`,
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          data: JSON.stringify(userData),
        };

        // Await the axios request to ensure loading state is correctly handled
        const response = await axios.request(reqData);

        const decryptedData = common.decrypt(response.data);
        response.data = decryptedData;

        if (isDelete) {
          if (response.code === 403) {
            navigate("/auth-404-cover");
          }
          if (response.status === "success") {
            setTeamlist((prevData) => {
              const updatedData = prevData.filter(
                (data) => data.id !== deleteId
              );
              return [...updatedData, ...response.data.rows];
            });
            return response.data;
          } else if (response.status === "fail") {
            setTotalRows(0);
            setTeamlist([]);
          }
        } else {
          if (response.code === 403) {
            navigate("/auth-404-cover");
          }
          if (response.status === "success") {
            setTotalRows(response.data.count);
            setTeamlist(response.data.rows);
          } else if (response.status === "fail") {
            setTotalRows(0);
            setTeamlist([]);
          }
        }
      } catch (error) {
        setTotalRows(0);
        setTeamlist([]);
        return null;
      } finally {
        setLoading(false);
      }
    },
    [navigate, token]
  );

  useEffect(() => {
    const debouncedFetchData = _.debounce(() => {
      getInternalUsers(1, 5, query);
    }, 500);

    debouncedFetchData();

    return () => {
      debouncedFetchData.cancel();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query]);

  const fetchNextPage = async () => {
    try {
      setLoading(true);

      let userData = { page: page + 1, limit: 5 };

      let reqData = {
        method: "POST",
        url: `${process.env.REACT_APP_BASE_URL}developer-profile/get-developer-profile`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        data: JSON.stringify(userData),
      };

      // Await axios request to ensure completion before moving forward
      const response = await axios.request(reqData);

      const decryptedData = common.decrypt(response.data);
      response.data = decryptedData;

      if (response.status === "success") {
        setPage((prevPage) => prevPage + 1);
        return response.data;
      }
    } catch (error) {
      setTotalRows(0);
      setTeamlist([]);
    } finally {
      setLoading(false); // This will now execute after the axios request finishes
    }
  };

  const handleLoadMore = async () => {
    const nextPageOrders = await fetchNextPage();
    setTeamlist((prevData) => [...prevData, ...nextPageOrders.rows]);
    setTotalRows(nextPageOrders?.count);
  };

  const skillsEndPoint = `${process.env.REACT_APP_BASE_URL}skills/get-skills`;

  const getSkills = useCallback(async () => {
    fetch(skillsEndPoint, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        const responseData = common.decrypt(data.data);
        const skillIds = responseData?.map((item) => ({
          label: item.skill_name,
          value: item.id,
        }));
        setAllSkillsIds(skillIds);
      });
  }, [skillsEndPoint, token]);

  useEffect(() => {
    getSkills();
  }, [getSkills]);

  const [allAssetIds, setAllAssetIds] = useState("");

  const assetsEndPoint = `${process.env.REACT_APP_BASE_URL}online-assets/online-assets-list`;

  const getAssets = useCallback(async () => {
    fetch(assetsEndPoint, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        const responseData = common.decrypt(data.data);
        const assetsIds = responseData?.map((item) => ({
          label: item.website_url,
          value: item.id,
        }));
        setAllAssetIds(assetsIds);
      });
  }, [assetsEndPoint, token]);

  useEffect(() => {
    getAssets();
  }, [getAssets]);

  return (
    <Box className="developerProfile-page">
      <ToastContainer closeButton={false} />
      <DeleteModal
        show={deleteModal}
        onDeleteClick={() => handleDeleteTeamData()}
        onCloseClick={() => setDeleteModal(false)}
      />
      <Box className="profile-header mb-4">
        <Row className="g-2">
          <Col sm={6} lg={4}>
            <Box className="search-box">
              <Input
                type="text"
                className="search bg-weight border-0"
                placeholder="Search for Developer..."
                onChange={(e) => setQuery(e.target.value)}
              />
              <i className="ri-search-line search-icon"></i>
            </Box>
          </Col>
          <Col className="col-sm-auto ms-auto">
            <Box className="list-grid-nav hstack gap-1">
              {hasPermission && hasPermission?.canCreate != null ? (
                <>
                  <Button color="primary" onClick={() => setShow(true)}>
                    <i className="ri-add-fill me-1 align-bottom"></i>Add Members
                  </Button>
                </>
              ) : null}
            </Box>
          </Col>
        </Row>
      </Box>

      <Box id="teamlist">
        <Box className="row g-3 team-list list-view-filter flex-row">
          {loading ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "50vh",
              }}
            >
              <Spinner height={100} width={100} />
            </div>
          ) : teamList?.length > 0 ? (
            <TeamCard
              isLoading={loading}
              totalRows={totalRows}
              handleLoadMore={handleLoadMore}
              query={query}
              teamList={teamList}
              getInternalUsers={getInternalUsers}
              page={page}
              hasPermission={hasPermission}
            />
          ) : (
            <li
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "50vh",
              }}
              className="list-group-item ps-0 pe-0 mt-4 text-center h5"
            >
              No Record Found
            </li>
          )}
        </Box>

        <Modal
          show={show}
          onHide={handleClose}
          centered
          scrollable
          size="lg"
          contentClassName="border-0"
        >
          <ModalHeader closeButton>
            <ModalTitle> Add New Members</ModalTitle>
          </ModalHeader>
          <ModalBody>
            <Form
              onSubmit={validation.handleSubmit}
              className="needs-validation"
              noValidate
              action="index"
            >
              <Row className="g-4">
                <Col lg={12}>
                  {/* <label>Name *</label> */}
                  <TextField
                    fullWidth
                    label="Name *"
                    id="outlined-size-small"
                    name="name"
                    placeholder="Enter Developer name."
                    value={validation.values.name}
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                  />
                  {validation.errors.name && validation.touched.name ? (
                    <span className="error-text">{validation.errors.name}</span>
                  ) : null}
                </Col>
                <Col lg={6}>
                  {/* <label>Contact No.</label> */}
                  <TextField
                    fullWidth
                    label="Contact No."
                    id="outlined-size-small"
                    name="contact_no"
                    placeholder="Enter Developer contact_no."
                    value={validation.values.contact_no}
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                  />
                  {validation.errors.contact_no &&
                  validation.touched.contact_no ? (
                    <span className="error-text">
                      {validation.errors.contact_no}
                    </span>
                  ) : null}
                </Col>
                <Col lg={6}>
                  {/* <label>Email *</label> */}
                  <TextField
                    label="Email *"
                    fullWidth
                    name="email"
                    placeholder="Enter Developer email."
                    value={validation.values.email}
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                  />
                  {validation.errors.email && validation.touched.email ? (
                    <span className="error-text">
                      {validation.errors.email}
                    </span>
                  ) : null}
                </Col>
                <Col lg={6}>
                  {/* <label>Organization *</label> */}
                  <TextField
                    fullWidth
                    label="Organization *"
                    id="outlined-size-small"
                    name="organization"
                    placeholder="Enter Developer organization."
                    value={validation.values.organization}
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                  />
                  {validation.errors.organization &&
                  validation.touched.organization ? (
                    <span className="error-text">
                      {validation.errors.organization}
                    </span>
                  ) : null}
                </Col>
                <Col lg={6}>
                  {/* <label>Organization URL *</label> */}
                  <TextField
                    label="Organizaion URL *"
                    fullWidth
                    id="outlined-size-small"
                    name="organization_url"
                    placeholder="Enter Developer organization_url."
                    value={validation.values.organization_url}
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                  />
                  {validation.errors.organization_url &&
                  validation.touched.organization_url ? (
                    <span className="error-text">
                      {validation.errors.organization_url}
                    </span>
                  ) : null}
                </Col>
                <Col lg={6}>
                  {/* <label>Designation</label> */}
                  <TextField
                    fullWidth
                    label="Designation"
                    id="outlined-size-small"
                    name="designation"
                    placeholder="Enter Developer designation."
                    value={validation.values.designation}
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                  />
                  {validation.errors.designation &&
                  validation.touched.designation ? (
                    <span className="error-text">
                      {validation.errors.designation}
                    </span>
                  ) : null}
                </Col>
                <Col lg={6}>
                  {/* <label>Address</label> */}
                  <TextField
                    label="Location"
                    fullWidth
                    id="outlined-size-small"
                    name="location"
                    placeholder="Enter Developer location."
                    value={validation.values.location}
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                  />
                  {validation.errors.location && validation.touched.location ? (
                    <span className="error-text">
                      {validation.errors.location}
                    </span>
                  ) : null}
                </Col>
                <Col lg={6}>
                  {/* <Label
                    htmlFor="asset_url_managementInput"
                    className="form-label"
                  >
                    Assign Websites *
                  </Label> */}
                  {/* <Select
                    isMulti
                    name="asset_url_management"
                    options={allAssetIds}
                    onChange={handleAssetsChange}
                    value={assetSelectedOptions}
                    onBlur={validation.handleBlur}
                    invalid={
                      validation.errors.asset_url_management &&
                      validation.touched.asset_url_management
                        ? true
                        : false
                    }
                  /> */}
                  <FormControl fullWidth>
                    <InputLabel>Asset URL Management *</InputLabel>
                    <Select
                      label="Asset URL Management *"
                      id="asset-url-management"
                      multiple
                      MenuProps={MenuHeight}
                      value={assetSelectedOptions}
                      onChange={(event) =>
                        handleAssetsChange(event.target.value)
                      }
                      onBlur={validation.handleBlur}
                      renderValue={(selected) =>
                        selected?.map((item) => item.label).join(", ")
                      } // Display only labels
                    >
                      {Array.isArray(allAssetIds) &&
                        allAssetIds?.map((asset) => (
                          <MenuItem key={asset.value} value={asset}>
                            {asset.label}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                  {validation.errors.asset_url_management &&
                  validation.touched.asset_url_management ? (
                    <span className="error-text">
                      {validation.errors.asset_url_management}
                    </span>
                  ) : null}
                </Col>
                <Col lg={6}>
                  {/* <Label htmlFor="skillsInput" className="form-label">
                    Select Skills *
                  </Label> */}
                  {/* <Select
                    isMulti
                    name="skills"
                    options={skillsIds}
                    onChange={handleSelectChange}
                    value={selectedOptions}
                    onBlur={validation.handleBlur}
                    invalid={
                      validation.errors.skills && validation.touched.skills
                        ? true
                        : false
                    }
                  /> */}
                  <FormControl fullWidth>
                    <InputLabel id="skills-label">Skills *</InputLabel>
                    <Select
                      labelId="skills-label"
                      multiple
                      MenuProps={MenuHeight}
                      value={selectedOptions}
                      onChange={handleSelectChange}
                      onBlur={validation.handleBlur}
                      renderValue={(selected) => selected.join(", ")}
                      label="Skills *"
                    >
                      {Array.isArray(skillsIds) &&
                        skillsIds.map((option) => (
                          <MenuItem
                            key={option.value}
                            value={option.label}
                            sx={{ whiteSpace: "break-spaces" }}
                          >
                            <Checkbox
                              checked={
                                selectedOptions.indexOf(option.label) > -1
                              }
                            />
                            <ListItemText primary={option.label} />
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                  {validation.errors.skills && validation.touched.skills ? (
                    <span className="error-text">
                      {validation.errors.skills}
                    </span>
                  ) : null}
                </Col>
                <Col lg={6}>
                  {/* <label>Projects</label> */}
                  <TextField
                    label="Projects"
                    fullWidth
                    id="outlined-size-small"
                    name="projects"
                    placeholder="Enter Developer projects."
                    value={validation.values.projects}
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                  />
                  {validation.errors.projects && validation.touched.projects ? (
                    <span className="error-text">
                      {validation.errors.projects}
                    </span>
                  ) : null}
                </Col>
                <Col lg={6}>
                  {/* <label>Tasks</label> */}
                  <TextField
                    label="Tasks"
                    fullWidth
                    id="outlined-size-small"
                    name="tasks"
                    placeholder="Enter Developer tasks."
                    value={validation.values.tasks}
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                  />
                  {validation.errors.tasks && validation.touched.tasks ? (
                    <span className="error-text">
                      {validation.errors.tasks}
                    </span>
                  ) : null}
                </Col>
                <Col lg={12}>
                  <TextField
                    label="Short Description"
                    id="outlined-size-small"
                    name="description"
                    placeholder="Enter Short Description"
                    variant="outlined"
                    multiline
                    fullWidth
                    rows={2}
                    value={validation.values.description}
                    onBlur={validation.handleBlur}
                    onChange={validation.handleChange}
                  />
                  {validation.errors.description &&
                    validation.touched.description && (
                      <span className="error-text">
                        {validation.errors.description}
                      </span>
                    )}
                </Col>
                <Col lg={12}>
                  <label className="d-flex align-items-center">
                    Developer Image{" "}
                    <Tooltip
                      title={common.LOGOTOOLTIP}
                      placement="top-start"
                      arrow
                    >
                      <i
                        className="ri-information-line fs-18 cursor-pointer ms-2"
                        style={{
                          color: "#ced4da",
                        }}
                      ></i>
                    </Tooltip>{" "}
                  </label>
                  <Input
                    className="form-control"
                    type="file"
                    id="imageFile"
                    name="imageFile"
                    accept="image/*"
                    onChange={(event) => {
                      validation.setFieldValue(
                        "imageFile",
                        event.currentTarget.files[0]
                      );
                    }}
                  />
                  {validation.errors.imageFile &&
                    validation.touched.imageFile && (
                      <FormFeedback type="invalid" style={{ color: "red" }}>
                        {validation.errors.imageFile}
                      </FormFeedback>
                    )}
                </Col>
              </Row>
            </Form>
          </ModalBody>
          <Modal.Footer>
            <Form
              onSubmit={validation.handleSubmit}
              className="needs-validation"
              noValidate
              action="index"
            >
              <Button className="btn btn-primary me-2" onClick={handleClose}>
                Close
              </Button>
              <Button
                className="btn btn-dark"
                type="submit"
                disabled={postIsLoading}
              >
                {postIsLoading ? (
                  <Spinner size="sm" variant="light" />
                ) : (
                  "Add Member"
                )}
              </Button>
            </Form>
          </Modal.Footer>
        </Modal>
      </Box>
    </Box>
  );
};

export default Team;

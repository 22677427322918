import common from "../../../Components/Common/common";

export const WebsiteCategoriesListRes = (props) => {
  const { result, setWebsiteGroupOptions } = props;
  const responseData = common.decrypt(result.data);

  if (result.status === "success" && responseData) {
    setWebsiteGroupOptions(
      responseData.map((item) => {
        return {
          label: item.category,
          value: item.category,
          id: item.id,
        };
      })
    );
  }
};

import React, { useEffect, useState } from 'react'

function IpAddress() {
    const [ipAddress, setIpAddress] = useState('');
    

    useEffect(() => {
        const fetchIpAddress = async () => {
            const response = await fetch('https://api.ipify.org/?format=json');
            const data = await response.json();
            localStorage.setItem('ip', data.ip)
            setIpAddress(data.ip);
        };

        fetchIpAddress();
    }, []);


    return (
        <div>                           
            {ipAddress}
        </div >
    )
}

export default IpAddress

export const UptimeReportListReq = (props) => {
    const { startDate, endDate, params } = props;
    const url = new URL(`${process.env.REACT_APP_BASE_URL}asset/site-status?searchParams=${params}`);

    let userData = {
        startDate: startDate,
        endDate: endDate,
    };

    let reqData = {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        body: JSON.stringify(userData),
    };
    return { url, reqData };
}
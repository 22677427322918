export const LightbulbOnIcon = ({ width = 20, height = 20 }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 23.072 23.093"
  >
    <path
      id="lightbulb-on"
      d="M16.143,2.379,17.33.446a.961.961,0,1,1,1.638,1.007L17.78,3.386A.961.961,0,1,1,16.143,2.38ZM5.22,3.385a.961.961,0,1,0,1.64-1L5.674.447a.962.962,0,0,0-1.64,1L5.219,3.385Zm-2.1,2.123L1.388,4.68a.961.961,0,1,0-.83,1.734l1.729.828a.961.961,0,1,0,.83-1.733ZM11.41,3.847a7.72,7.72,0,0,0-6.626,11.37A8.175,8.175,0,0,0,6.568,17.4a3.282,3.282,0,0,1,.644.867h3.367V13.285a2.881,2.881,0,0,1-1.923-2.708.962.962,0,1,1,1.923,0,.962.962,0,1,0,1.923,0,.962.962,0,1,1,1.923,0A2.881,2.881,0,0,1,12.5,13.285v4.985h3.335a3.89,3.89,0,0,1,.872-1.04,5.946,5.946,0,0,0,1.6-1.858,7.887,7.887,0,0,0-1.371-9.32A7.651,7.651,0,0,0,11.41,3.846ZM7.686,20.463a2.428,2.428,0,0,0,2.391,2.618h2.905a2.4,2.4,0,0,0,2.4-2.4V20.2H7.676c0,.09.016.175.011.267ZM23,5.1a.959.959,0,0,0-1.271-.481l-1.858.839a.962.962,0,0,0,.4,1.838.944.944,0,0,0,.394-.086l1.858-.839A.96.96,0,0,0,23,5.1Z"
      transform="translate(-0.012 0.013)"
      fill="#1a2232"
    />
  </svg>
);

export const WindowAltIcon = ({ width = 20, height = 20 }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 23.093 21.169"
  >
    <path
      id="window-alt"
      d="M23.093,7.736V5.811A4.813,4.813,0,0,0,18.282,1H4.811A4.813,4.813,0,0,0,0,5.811V7.736Zm-4.33-4.811A1.443,1.443,0,1,1,17.32,4.368,1.441,1.441,0,0,1,18.763,2.924Zm-3.849,0a1.443,1.443,0,1,1-1.443,1.443A1.441,1.441,0,0,1,14.914,2.924Zm-3.849,0A1.443,1.443,0,1,1,9.622,4.368,1.441,1.441,0,0,1,11.066,2.924ZM23.093,9.66v7.7a4.813,4.813,0,0,1-4.811,4.811H4.811A4.813,4.813,0,0,1,0,17.358V9.66Z"
      transform="translate(0 -1)"
      fill="#1a2232"
    />
  </svg>
);
export const WindowAltOutlineIcon = ({ width = 20, height = 20 }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 18 16.5"
  >
    <path
      id="window-alt_1_"
      data-name="window-alt (1)"
      d="M14.25,1H3.75A3.751,3.751,0,0,0,0,4.75v9A3.751,3.751,0,0,0,3.75,17.5h10.5A3.751,3.751,0,0,0,18,13.75v-9A3.751,3.751,0,0,0,14.25,1ZM3.75,2.5h10.5A2.257,2.257,0,0,1,16.5,4.75v1.5H1.5V4.75A2.257,2.257,0,0,1,3.75,2.5ZM14.25,16H3.75A2.257,2.257,0,0,1,1.5,13.75v-6h15v6A2.257,2.257,0,0,1,14.25,16ZM13.5,4.375A1.125,1.125,0,1,1,14.625,5.5,1.123,1.123,0,0,1,13.5,4.375Zm-3,0A1.125,1.125,0,1,1,11.625,5.5,1.123,1.123,0,0,1,10.5,4.375Zm-3,0A1.125,1.125,0,1,1,8.625,5.5,1.123,1.123,0,0,1,7.5,4.375Z"
      transform="translate(0 -1)"
      fill="#4c525e"
    />
  </svg>
);
export const DiscoverIcon = ({ width = 20, height = 20 }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 23.093 23.093"
  >
    <path
      id="discover"
      d="M22.811,21.451,20.37,19.009a4.815,4.815,0,1,0-1.361,1.361l2.441,2.441a.962.962,0,0,0,1.361-1.361ZM18.282,0H4.811A4.817,4.817,0,0,0,0,4.811V6.735H23.093V4.811A4.817,4.817,0,0,0,18.282,0ZM4.33,4.811A1.443,1.443,0,1,1,5.773,3.368,1.444,1.444,0,0,1,4.33,4.811Zm3.849,0A1.443,1.443,0,1,1,9.622,3.368,1.444,1.444,0,0,1,8.179,4.811ZM23.091,16.482c0-.041,0-.083,0-.124C23.093,16.4,23.093,16.44,23.091,16.482ZM0,8.66H23.093v7.7a6.735,6.735,0,1,0-11.445,4.811H4.811A4.817,4.817,0,0,1,0,16.358Z"
      fill="#1a2232"
    />
  </svg>
);
export const DiscoverOutlineIcon = ({ width = 20, height = 20 }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 16 16"
  >
    <path
      id="discover_1_"
      data-name="discover (1)"
      d="M14.113,13.171a3.336,3.336,0,1,0-.943.943L14.862,15.8a.667.667,0,1,0,.943-.943l-1.691-1.691Zm-4.78-1.837a2,2,0,1,1,2,2A2,2,0,0,1,9.333,11.333ZM4,3A1,1,0,1,1,3,2,1,1,0,0,1,4,3ZM6.667,3a1,1,0,1,1-1-1A1,1,0,0,1,6.667,3Zm6-3H3.333A3.337,3.337,0,0,0,0,3.333v8a3.337,3.337,0,0,0,3.333,3.333H6.667a.667.667,0,1,0,0-1.333H3.333a2,2,0,0,1-2-2V6H14.667V8A.667.667,0,0,0,16,8V3.333A3.337,3.337,0,0,0,12.667,0ZM1.333,4.667V3.333a2,2,0,0,1,2-2h9.333a2,2,0,0,1,2,2V4.667Z"
      fill="#1a2232"
    />
  </svg>
);
export const StatsIcon = ({ width = 20, height = 20 }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    id="stats"
    width={width}
    height={height}
    viewBox="0 0 18 18"
  >
    <path
      id="Path_164"
      data-name="Path 164"
      d="M4.125,15.75A1.875,1.875,0,0,1,2.25,13.875V1.125A1.125,1.125,0,0,0,1.125,0h0A1.125,1.125,0,0,0,0,1.125v12.75A4.125,4.125,0,0,0,4.125,18h12.75A1.125,1.125,0,0,0,18,16.875h0a1.125,1.125,0,0,0-1.125-1.125Z"
      fill="#4c525e"
    />
    <path
      id="Path_165"
      data-name="Path 165"
      d="M19.125,15.75a1.125,1.125,0,0,0,1.125-1.125v-4.5a1.125,1.125,0,0,0-2.25,0v4.5A1.125,1.125,0,0,0,19.125,15.75Z"
      transform="translate(-4.5 -2.25)"
      fill="#4c525e"
    />
    <path
      id="Path_166"
      data-name="Path 166"
      d="M7.125,15.75A1.125,1.125,0,0,0,8.25,14.625v-4.5a1.125,1.125,0,0,0-2.25,0v4.5A1.125,1.125,0,0,0,7.125,15.75Z"
      transform="translate(-1.5 -2.25)"
      fill="#4c525e"
    />
    <path
      id="Path_167"
      data-name="Path 167"
      d="M13.125,14.5a1.125,1.125,0,0,0,1.125-1.125V5.125a1.125,1.125,0,0,0-2.25,0v8.25A1.125,1.125,0,0,0,13.125,14.5Z"
      transform="translate(-3 -1)"
      fill="#4c525e"
    />
  </svg>
);
export const DeleteIcon = ({ width = 20, height = 20 }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 16.667 20"
  >
    <g id="trash" transform="translate(-42.667)">
      <path
        id="Path_172"
        data-name="Path 172"
        d="M58.5,3.333H55.917A4.174,4.174,0,0,0,51.834,0H50.167a4.174,4.174,0,0,0-4.083,3.333H43.5A.833.833,0,0,0,43.5,5h.833V15.833A4.172,4.172,0,0,0,48.5,20h5a4.172,4.172,0,0,0,4.167-4.167V5H58.5a.833.833,0,0,0,0-1.667ZM50.167,14.167a.833.833,0,0,1-1.667,0v-5a.833.833,0,0,1,1.667,0v5Zm3.333,0a.833.833,0,0,1-1.667,0v-5a.833.833,0,0,1,1.667,0ZM47.809,3.333a2.505,2.505,0,0,1,2.358-1.667h1.667a2.505,2.505,0,0,1,2.357,1.667Z"
        transform="translate(0)"
        fill="#4c525e"
      />
    </g>
  </svg>
);
export const SiteAltIcon = ({ width = 20, height = 20 }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 18 17.999"
  >
    <path
      id="site-alt"
      d="M17.559,12.066l-6.5-2.958c-.022-.01-.045-.019-.067-.027a1.492,1.492,0,0,0-1.546.359,1.511,1.511,0,0,0-.331,1.613l2.958,6.506a.749.749,0,0,0,.669.439h.014a.752.752,0,0,0,.671-.415l1.388-2.776,2.776-1.388a.75.75,0,0,0-.025-1.354Zm-7.876,4.4c-.129.011-.259.019-.39.025a14.638,14.638,0,0,1-2.718-3.744h.549a.75.75,0,0,0,0-1.5H5.977A7.7,7.7,0,0,1,5.624,9a7.676,7.676,0,0,1,.354-2.25h6.042a8.527,8.527,0,0,1,.221.89.75.75,0,0,0,1.473-.28c-.039-.206-.085-.409-.137-.61h2.575A7.469,7.469,0,0,1,16.5,9q0,.345-.031.682a.75.75,0,0,0,1.494.135C17.984,9.548,18,9.275,18,9a9,9,0,1,0-9,9H9c.275,0,.547-.013.816-.037a.75.75,0,1,0-.132-1.494ZM4.419,11.249H1.845a7.514,7.514,0,0,1,0-4.5H4.419a8.756,8.756,0,0,0,0,4.5Zm2.161-6A14.628,14.628,0,0,1,9,1.809,14.524,14.524,0,0,1,11.423,5.25H6.579Zm8.913,0H13.071a14.748,14.748,0,0,0-2.124-3.492A7.535,7.535,0,0,1,15.492,5.25ZM7.049,1.758A14.733,14.733,0,0,0,4.926,5.25H2.506A7.526,7.526,0,0,1,7.049,1.758ZM2.506,12.749H4.926a14.718,14.718,0,0,0,2.124,3.492A7.535,7.535,0,0,1,2.506,12.749Z"
      fill="#000"
    />
  </svg>
);
export const GuidAltIcon = ({ width = 20, height = 20 }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 18 18"
  >
    <path
      id="guide-alt"
      d="M1.8,12.046a3.716,3.716,0,0,0-1.8.723V3.75A3.751,3.751,0,0,1,3,.075V12H2.25A2.25,2.25,0,0,0,1.8,12.046ZM13.5,7.5a5.964,5.964,0,0,1,1.5.2V3.75A3.75,3.75,0,0,0,11.25,0H4.5V12H7.7a6.005,6.005,0,0,1,5.8-4.5Zm-6,6H2.25a2.25,2.25,0,0,0,0,4.5H9.545A5.982,5.982,0,0,1,7.5,13.5Zm10.5,0A4.5,4.5,0,1,1,13.5,9,4.5,4.5,0,0,1,18,13.5Zm-3.75.75a.75.75,0,1,0-1.5,0v1.5a.75.75,0,1,0,1.5,0Zm.375-2.625A1.125,1.125,0,1,0,13.5,12.75,1.125,1.125,0,0,0,14.625,11.625Z"
      fill="#000"
    />
  </svg>
);
export const LabelIcon = ({ width = 20, height = 20 }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 15 18"
  >
    <path
      id="label"
      d="M2,5.383V14.25A3.754,3.754,0,0,0,5.75,18h7.5A3.754,3.754,0,0,0,17,14.25V5.383a2.25,2.25,0,0,0-1.157-1.966L9.864.094a.75.75,0,0,0-.729,0L3.157,3.416A2.25,2.25,0,0,0,2,5.383ZM9.5,4.125A1.125,1.125,0,1,1,8.375,5.25,1.125,1.125,0,0,1,9.5,4.125Z"
      transform="translate(-2 0)"
      fill="#000"
    />
  </svg>
);
export const FlieChartLineIcon = ({ width = 20, height = 20 }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 15 18"
  >
    <path
      id="file-chart-line"
      d="M11,5.25V.345a5.233,5.233,0,0,1,1.849,1.193l2.613,2.615A5.215,5.215,0,0,1,16.655,6H11.75A.751.751,0,0,1,11,5.25Zm6,2.614V14.25A3.754,3.754,0,0,1,13.25,18H5.75A3.754,3.754,0,0,1,2,14.25V3.75A3.754,3.754,0,0,1,5.75,0H9.136c.122,0,.243.01.364.018V5.25A2.253,2.253,0,0,0,11.75,7.5h5.232C16.99,7.621,17,7.741,17,7.864ZM7.25,13.5a.75.75,0,0,0-1.5,0V15a.75.75,0,0,0,1.5,0Zm3-3.75a.75.75,0,0,0-1.5,0V15a.75.75,0,0,0,1.5,0Zm3,2.25a.75.75,0,0,0-1.5,0v3a.75.75,0,0,0,1.5,0Z"
      transform="translate(-2)"
      fill="#fff"
    />
  </svg>
);
export const HomeIcon = ({ width = 20, height = 20 }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 18 17.989"
  >
    <g id="home" transform="translate(0 -0.16)">
      <path
        id="Path_129"
        data-name="Path 129"
        d="M194.25,319.841a2.25,2.25,0,0,0-2.25,2.25v4.5h4.5v-4.5A2.25,2.25,0,0,0,194.25,319.841Z"
        transform="translate(-185.25 -308.442)"
        fill="#f99f1e"
      />
      <g id="Group_26223" data-name="Group 26223" transform="translate(0 0.16)">
        <path
          id="Path_130"
          data-name="Path 130"
          d="M12.75,13.649v4.5h3A2.25,2.25,0,0,0,18,15.9V9.058a1.5,1.5,0,0,0-.422-1.044L11.2,1.124A3,3,0,0,0,6.965.957q-.087.08-.167.167L.436,8.012A1.5,1.5,0,0,0,0,9.069V15.9a2.25,2.25,0,0,0,2.25,2.25h3v-4.5a3.75,3.75,0,1,1,7.5,0Z"
          transform="translate(0 -0.16)"
          fill="#000"
        />
        <path
          id="Path_131"
          data-name="Path 131"
          d="M194.25,319.841a2.25,2.25,0,0,0-2.25,2.25v4.5h4.5v-4.5A2.25,2.25,0,0,0,194.25,319.841Z"
          transform="translate(-185.25 -308.602)"
          fill="#000"
        />
      </g>
    </g>
  </svg>
);
export const EditIcon = ({ width = 20, height = 20 }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    version="1.1"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width={width}
    height={height}
    x="0"
    y="0"
    viewBox="0 0 477.873 477.873"
    xmlSpace="preserve"
    className=""
  >
    <g>
      <path
        d="M392.533 238.937c-9.426 0-17.067 7.641-17.067 17.067V426.67c0 9.426-7.641 17.067-17.067 17.067H51.2c-9.426 0-17.067-7.641-17.067-17.067V85.337c0-9.426 7.641-17.067 17.067-17.067H256c9.426 0 17.067-7.641 17.067-17.067S265.426 34.137 256 34.137H51.2C22.923 34.137 0 57.06 0 85.337V426.67c0 28.277 22.923 51.2 51.2 51.2h307.2c28.277 0 51.2-22.923 51.2-51.2V256.003c0-9.425-7.641-17.066-17.067-17.066z"
        fill="#000000"
        opacity="1"
        data-original="#000000"
        className=""
      ></path>
      <path
        d="M458.742 19.142A65.328 65.328 0 0 0 412.536.004a64.85 64.85 0 0 0-46.199 19.149L141.534 243.937a17.254 17.254 0 0 0-4.113 6.673l-34.133 102.4c-2.979 8.943 1.856 18.607 10.799 21.585 1.735.578 3.552.873 5.38.875a17.336 17.336 0 0 0 5.393-.87l102.4-34.133c2.515-.84 4.8-2.254 6.673-4.13l224.802-224.802c25.515-25.512 25.518-66.878.007-92.393z"
        fill="#000000"
        opacity="1"
        data-original="#000000"
        className=""
      ></path>
    </g>
  </svg>
);

export const LinkIcon = ({ width = 20, height = 20 }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 24.497 24.503"
  >
    <g id="link" transform="translate(0.249 0.25)">
      <path
        id="Path_44533"
        data-name="Path 44533"
        d="M13.845,17.267l-3.262,3.262A5.028,5.028,0,1,1,3.472,13.42l3.262-3.265A1,1,0,1,0,5.319,8.741L2.058,12.006A7.028,7.028,0,0,0,12,21.943l3.262-3.262a1,1,0,1,0-1.414-1.414Z"
        fill="#000"
        stroke="#000"
        strokeWidth="0.5"
      />
      <path
        id="Path_44534"
        data-name="Path 44534"
        d="M21.944,2.061A6.979,6.979,0,0,0,16.975,0h0a6.983,6.983,0,0,0-4.968,2.057L8.74,5.32a1,1,0,1,0,1.414,1.415l3.265-3.262A4.993,4.993,0,0,1,16.973,2h0a5.028,5.028,0,0,1,3.554,8.583l-3.262,3.262A1,1,0,0,0,18.68,15.26L21.942,12a7.037,7.037,0,0,0,0-9.939Z"
        fill="#000"
        stroke="#000"
        strokeWidth="0.5"
      />
      <path
        id="Path_44535"
        data-name="Path 44535"
        d="M14.293,8.293l-6,6a1,1,0,1,0,1.414,1.414l6-6a1,1,0,1,0-1.414-1.414Z"
        fill="#000"
        stroke="#000"
        strokeWidth="0.5"
      />
    </g>
  </svg>
);
export const SquarePhoneIcon = ({ width = 20, height = 20 }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 16 16"
  >
    <path
      id="square-phone-hangup"
      d="M12.667,0H3.333A3.337,3.337,0,0,0,0,3.333v9.333A3.337,3.337,0,0,0,3.333,16h9.333A3.337,3.337,0,0,0,16,12.667V3.333A3.337,3.337,0,0,0,12.667,0Zm-.035,9.27a.73.73,0,0,1-.73.73l-1.04-.137a.73.73,0,0,1-.73-.73l-.1-1.052a4.869,4.869,0,0,0-4.066,0l-.1,1.052a.73.73,0,0,1-.73.73L4.1,10a.73.73,0,0,1-.73-.73l-.035-.692a1.793,1.793,0,0,1,.536-1.271c1.807-1.807,6.583-1.678,8.261,0a1.79,1.79,0,0,1,.536,1.271l-.035.692Z"
      fill="#000"
    />
  </svg>
);
export const EnvelopeIcon = ({ width = 20, height = 20 }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 17.455 16"
  >
    <g id="envelope" transform="translate(0 -1)">
      <path
        id="Path_44536"
        data-name="Path 44536"
        d="M17.421,5.542,11.3,11.664a3.641,3.641,0,0,1-5.143,0L.033,5.542C.023,5.657,0,5.761,0,5.875V14.6a3.641,3.641,0,0,0,3.636,3.636H13.818A3.641,3.641,0,0,0,17.455,14.6V5.875C17.455,5.761,17.431,5.657,17.421,5.542Z"
        transform="translate(0 -1.239)"
        fill="#1848bf"
      />
      <path
        id="Path_44537"
        data-name="Path 44537"
        d="M10.473,9.4l6.643-6.644A3.626,3.626,0,0,0,14.021,1H3.839a3.626,3.626,0,0,0-3.1,1.753L7.387,9.4a2.187,2.187,0,0,0,3.087,0Z"
        transform="translate(-0.203)"
        fill="#1848bf"
      />
    </g>
  </svg>
);

export const BrowserIcon = ({ width = 20, height = 20 }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 16 14.667"
  >
    <path
      id="browser"
      d="M12.667,1H3.333A3.337,3.337,0,0,0,0,4.333v8a3.337,3.337,0,0,0,3.333,3.333h9.333A3.337,3.337,0,0,0,16,12.333v-8A3.337,3.337,0,0,0,12.667,1ZM3.333,2.333h9.333a2,2,0,0,1,2,2V5H1.333V4.333a2,2,0,0,1,2-2Zm9.333,12H3.333a2,2,0,0,1-2-2v-6H14.667v6A2,2,0,0,1,12.667,14.333Zm0-5.333A.667.667,0,0,1,12,9.667H4A.667.667,0,1,1,4,8.333h8A.667.667,0,0,1,12.667,9ZM10,11.667a.667.667,0,0,1-.667.667H4A.667.667,0,0,1,4,11H9.333A.667.667,0,0,1,10,11.667Zm-8-8a.667.667,0,1,1,.667.667A.667.667,0,0,1,2,3.667Zm2,0a.667.667,0,1,1,.667.667A.667.667,0,0,1,4,3.667Zm2,0a.667.667,0,1,1,.667.667A.667.667,0,0,1,6,3.667Z"
      transform="translate(0 -1)"
      fill="#1a2232"
    />
  </svg>
);

export const DashboardIcon = ({ width = 20, height = 20 }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 16 14.676"
  >
    <path
      id="dashboard"
      d="M15.93,7.951A8,8,0,0,0,0,8.993,7.917,7.917,0,0,0,2.506,14.8a3.226,3.226,0,0,0,2.236.859h6.51a3.329,3.329,0,0,0,2.339-.952,7.961,7.961,0,0,0,2.34-6.755Zm-3.272,5.8a2,2,0,0,1-1.407.575H4.741a1.908,1.908,0,0,1-1.32-.494A6.6,6.6,0,0,1,1.333,8.993a6.675,6.675,0,0,1,2.225-4.97,6.586,6.586,0,0,1,4.431-1.7,7.034,7.034,0,0,1,.783.044,6.661,6.661,0,0,1,3.887,11.382ZM5.2,11.849a.667.667,0,0,1-.933.953A5.333,5.333,0,0,1,8.76,3.714a.667.667,0,0,1,.565.755.659.659,0,0,1-.755.565A4,4,0,0,0,5.2,11.849Zm8.073-3.618a5.381,5.381,0,0,1,.054.762,5.286,5.286,0,0,1-1.605,3.81.667.667,0,1,1-.933-.954,3.986,3.986,0,0,0,1.162-3.427.667.667,0,1,1,1.32-.191Zm-3.995.421a1.355,1.355,0,1,1-.943-.943l2.52-2.52a.666.666,0,1,1,.943.943Z"
      transform="translate(0 -0.983)"
      fill="#1a2232"
    />
  </svg>
);
export const PageViewIcon = ({ width = 20, height = 20 }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    version="1.1"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width={width}
    height={height}
    x="0"
    y="0"
    viewBox="0 0 96 96"
    xmlSpace="preserve"
    className=""
  >
    <g>
      <path
        d="M18.294 92h10.893c1.711 0 3.099-1.289 3.099-3s-1.387-3-3.099-3H18.294C13.708 86 10 81.93 10 77.169V33h75v11.282c0 1.711 1.789 3.099 3.5 3.099s3.5-1.387 3.5-3.099V18.894C92 10.821 84.413 4 76.426 4H18.294C10.291 4 4 10.681 4 18.894v58.275C4 85.347 10.291 92 18.294 92zM10 18.894C10 14.18 13.786 10 18.294 10h58.132C81.053 10 85 14.18 85 18.894V27H10z"
        fill="#000000"
        opacity="1"
        data-original="#000000"
        className=""
      ></path>
      <path
        d="M74.427 16h-1.239c-1.711 0-3.099 1.789-3.099 3.5s1.387 3.5 3.099 3.5h1.239c1.711 0 3.099-1.789 3.099-3.5S76.138 16 74.427 16zM63.892 16h-1.239c-1.711 0-3.099 1.789-3.099 3.5s1.387 3.5 3.099 3.5h1.239c1.711 0 3.099-1.789 3.099-3.5S65.603 16 63.892 16zM52.737 16h-1.239c-1.711 0-3.099 1.789-3.099 3.5s1.387 3.5 3.099 3.5h1.239c1.711 0 3.099-1.789 3.099-3.5S54.448 16 52.737 16zM60.997 47.135C47.409 47.135 35 55.668 30 68.369c0 0 6.505 23.954 30.997 23.432C86.875 91.25 92 68.369 92 68.369c-5-12.701-17.411-21.234-31.003-21.234zm.221 38.469c-10.634 0-20.346-6.437-24.536-16.136 4.189-9.699 13.902-16.136 24.536-16.136 10.637 0 20.351 6.437 24.541 16.136-4.189 9.699-13.904 16.136-24.541 16.136z"
        fill="#000000"
        opacity="1"
        data-original="#000000"
        className=""
      ></path>
      <path
        d="M61.221 57.445c-6.63 0-12.024 5.393-12.024 12.023s5.394 12.023 12.024 12.023 12.023-5.393 12.023-12.023-5.393-12.023-12.023-12.023zm0 17.849c-3.213 0-5.827-2.614-5.827-5.826s2.614-5.826 5.827-5.826 5.826 2.614 5.826 5.826-2.613 5.826-5.826 5.826z"
        fill="#000000"
        opacity="1"
        data-original="#000000"
        className=""
      ></path>
    </g>
  </svg>
);

export const VisitTimeIcon = ({ width = 20, height = 20 }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    version="1.1"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width={width}
    height={height}
    x="0"
    y="0"
    viewBox="0 0 682.667 682.667"
    // style="enable-background:new 0 0 512 512"
    style={{ enableBackground: "new 0 0 512 512" }}
    xmlSpace="preserve"
    className=""
  >
    <g>
      <defs>
        <clipPath id="a" clipPathUnits="userSpaceOnUse">
          <path
            d="M0 512h512V0H0Z"
            fill="#000000"
            opacity="1"
            data-original="#000000"
          ></path>
        </clipPath>
      </defs>
      <path
        d="M0 0h-106.224"
        // style="stroke-width:40;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
        style={{
          strokeWidth: 40,
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeMiterlimit: 10,
          strokeDasharray: "none",
          strokeOpacity: 1,
        }}
        transform="matrix(1.33333 0 0 -1.33333 282.73 394.984)"
        fill="none"
        stroke="#000000"
        stroke-width="40"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-miterlimit="10"
        stroke-dasharray="none"
        stroke-opacity=""
        data-original="#000000"
        className=""
      ></path>
      <path
        d="M0 0h-85.255"
        // style="stroke-width:40;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
        style={{
          strokeWidth: 40,
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeMiterlimit: 10,
          strokeDasharray: "none",
          strokeOpacity: 1,
        }}
        transform="matrix(1.33333 0 0 -1.33333 254.77 510.355)"
        fill="none"
        stroke="#000000"
        stroke-width="40"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-miterlimit="10"
        stroke-dasharray="none"
        stroke-opacity=""
        data-original="#000000"
        className=""
      ></path>
      <path
        d="M0 0h-187.01"
        // style="stroke-width:40;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
        style={{
          strokeWidth: 40,
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeMiterlimit: 10,
          strokeDasharray: "none",
          strokeOpacity: 1,
        }}
        transform="matrix(1.33333 0 0 -1.33333 390.444 279.613)"
        fill="none"
        stroke="#000000"
        stroke-width="40"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-miterlimit="10"
        stroke-dasharray="none"
        stroke-opacity=""
        data-original="#000000"
        className=""
      ></path>
      <g clip-path="url(#a)" transform="matrix(1.33333 0 0 -1.33333 0 682.667)">
        <path
          d="M251.021 411.357H147.633V492h103.388z"
          // style="stroke-width:40;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
          style={{
            strokeWidth: 40,
            strokeLinecap: "round",
            strokeLinejoin: "round",
            strokeMiterlimit: 10,
            strokeDasharray: "none",
            strokeOpacity: 1,
          }}
          fill="none"
          stroke="#000000"
          stroke-width="40"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-miterlimit="10"
          stroke-dasharray="none"
          stroke-opacity=""
          data-original="#000000"
          className=""
        ></path>
        <path
          d="M0 0h-142.98c-33.137 0-60 26.863-60 60v303.524c0 33.137 26.863 60 60 60h63.235"
          // style="stroke-width:40;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
          style={{
            strokeWidth: 40,
            strokeLinecap: "round",
            strokeLinejoin: "round",
            strokeMiterlimit: 10,
            strokeDasharray: "none",
            strokeOpacity: 1,
          }}
          transform="translate(225.434 20)"
          fill="none"
          stroke="#000000"
          stroke-width="40"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-miterlimit="10"
          stroke-dasharray="none"
          stroke-opacity=""
          data-original="#000000"
          className=""
        ></path>
        <path
          d="M0 0h63.278c33.138 0 60-26.863 60-60v-65.147"
          // style="stroke-width:40;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
          style={{
            strokeWidth: 40,
            strokeLinecap: "round",
            strokeLinejoin: "round",
            strokeMiterlimit: 10,
            strokeDasharray: "none",
            strokeOpacity: 1,
          }}
          transform="translate(252.923 443.524)"
          fill="none"
          stroke="#000000"
          stroke-width="40"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-miterlimit="10"
          stroke-dasharray="none"
          stroke-opacity=""
          data-original="#000000"
          className=""
        ></path>
        <path
          d="M0 0c0-60.328-48.905-109.234-109.234-109.234-60.328 0-109.234 48.906-109.234 109.234 0 60.328 48.906 109.234 109.234 109.234C-48.905 109.234 0 60.328 0 0Z"
          // style="stroke-width:40;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
          style={{
            strokeWidth: 40,
            strokeLinecap: "round",
            strokeLinejoin: "round",
            strokeMiterlimit: 10,
            strokeDasharray: "none",
            strokeOpacity: 1,
          }}
          transform="translate(489.546 129.234)"
          fill="none"
          stroke="#000000"
          stroke-width="40"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-miterlimit="10"
          stroke-dasharray="none"
          stroke-opacity=""
          data-original="#000000"
          className=""
        ></path>
        <path
          d="M0 0v-41.708h29.791"
          // style="stroke-width:40;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
          style={{
            strokeWidth: 40,
            strokeLinecap: "round",
            strokeLinejoin: "round",
            strokeMiterlimit: 10,
            strokeDasharray: "none",
            strokeOpacity: 1,
          }}
          transform="translate(376.423 163.06)"
          fill="none"
          stroke="#000000"
          stroke-width="40"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-miterlimit="10"
          stroke-dasharray="none"
          stroke-opacity=""
          data-original="#000000"
          className=""
        ></path>
      </g>
    </g>
  </svg>
);

export const VisitsIcon = ({ width = 20, height = 20 }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    version="1.1"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width={width}
    height={height}
    x="0"
    y="0"
    viewBox="0 0 50 50"
    xmlSpace="preserve"
    className=""
  >
    <g>
      <path
        fill="#000000"
        d="M41.667 36.604a8.959 8.959 0 1 0-7.646 4.292 9.105 9.105 0 0 0 4.667-1.313l3.583 3.604a2.082 2.082 0 0 0 2.958 0 2.084 2.084 0 0 0 0-2.958zM37.5 35.417a4.938 4.938 0 0 1-6.813 0 4.833 4.833 0 0 1 0-6.813 4.813 4.813 0 0 1 6.813 0 4.833 4.833 0 0 1 0 6.813zM39.583 6.25H10.417a6.25 6.25 0 0 0-6.25 6.25v18.75a6.25 6.25 0 0 0 6.25 6.25h8.333a2.083 2.083 0 0 0 0-4.167h-8.333a2.083 2.083 0 0 1-2.084-2.083v-12.5h33.334v2.083a2.083 2.083 0 1 0 4.166 0V12.5a6.25 6.25 0 0 0-6.25-6.25zm2.084 8.333H8.333V12.5a2.083 2.083 0 0 1 2.084-2.083h29.166a2.083 2.083 0 0 1 2.084 2.083zm-20.834 8.334h-6.25a2.083 2.083 0 0 0 0 4.166h6.25a2.083 2.083 0 0 0 0-4.166z"
        opacity="1"
        data-original="#000000"
      ></path>
    </g>
  </svg>
);
export const DesktopIcon = ({ width = 20, height = 20 }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 20 18.333"
  >
    <path
      id="computer"
      d="M15.833,1H4.167A4.172,4.172,0,0,0,0,5.167v6.667A4.172,4.172,0,0,0,4.167,16h5v1.667H5.833a.833.833,0,1,0,0,1.667h8.333a.833.833,0,1,0,0-1.667H10.833V16h5A4.172,4.172,0,0,0,20,11.833V5.167A4.172,4.172,0,0,0,15.833,1ZM4.167,2.667H15.833a2.5,2.5,0,0,1,2.5,2.5V11H1.667V5.167a2.5,2.5,0,0,1,2.5-2.5ZM15.833,14.333H4.167A2.5,2.5,0,0,1,1.82,12.667H18.18A2.5,2.5,0,0,1,15.833,14.333Z"
      transform="translate(0 -1)"
      fill="#1a2232"
    />
  </svg>
);
export const TabletIcon = ({ width = 20, height = 20 }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 16.667 20"
  >
    <path
      id="tablet"
      d="M14.5,0H6.167A4.172,4.172,0,0,0,2,4.167V15.833A4.172,4.172,0,0,0,6.167,20H14.5a4.172,4.172,0,0,0,4.167-4.167V4.167A4.172,4.172,0,0,0,14.5,0ZM6.167,1.667H14.5a2.5,2.5,0,0,1,2.5,2.5v10H3.667v-10a2.5,2.5,0,0,1,2.5-2.5ZM14.5,18.333H6.167a2.5,2.5,0,0,1-2.5-2.5H9.5v.833a.833.833,0,1,0,1.667,0v-.833H17A2.5,2.5,0,0,1,14.5,18.333Z"
      transform="translate(-2)"
      fill="#1a2232"
    />
  </svg>
);
export const MobileIcon = ({ width = 20, height = 20 }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 13.333 20"
  >
    <path
      id="mobile-button_1_"
      data-name="mobile-button (1)"
      d="M13.167,20h-5A4.171,4.171,0,0,1,4,15.833V4.167A4.171,4.171,0,0,1,8.167,0h5a4.171,4.171,0,0,1,4.167,4.167V15.833A4.171,4.171,0,0,1,13.167,20Zm-5-18.333a2.5,2.5,0,0,0-2.5,2.5V15.833a2.5,2.5,0,0,0,2.5,2.5h5a2.5,2.5,0,0,0,2.5-2.5V4.167a2.5,2.5,0,0,0-2.5-2.5Zm4.167,14.167h0A.834.834,0,0,0,11.5,15H9.833A.834.834,0,0,0,9,15.833H9a.834.834,0,0,0,.833.833H11.5A.834.834,0,0,0,12.333,15.833Z"
      transform="translate(-4)"
      fill="#1a2232"
    />
  </svg>
);

export const CalenderClockIcon = ({ width = 20, height = 20 }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 20 20"
  >
    <path
      id="calendar-clock"
      d="M14.167,8.366A5.817,5.817,0,1,0,20,14.167,5.84,5.84,0,0,0,14.167,8.366Zm0,9.967a4.15,4.15,0,1,1,4.167-4.167A4.155,4.155,0,0,1,14.167,18.333Zm1.423-3.922a.833.833,0,1,1-1.178,1.178l-.833-.833a.833.833,0,0,1-.244-.589V12.5A.833.833,0,0,1,15,12.5v1.322ZM20,5.833V7.5a.833.833,0,0,1-1.667,0V5.833a2.5,2.5,0,0,0-2.5-2.5H4.167a2.5,2.5,0,0,0-2.5,2.5v.833h7.5a.833.833,0,1,1,0,1.667h-7.5v7.5a2.5,2.5,0,0,0,2.5,2.5H7.5A.833.833,0,0,1,7.5,20H4.167A4.171,4.171,0,0,1,0,15.833v-10A4.171,4.171,0,0,1,4.167,1.667H5V.833a.833.833,0,0,1,1.667,0v.833h6.667V.833A.833.833,0,1,1,15,.833v.833h.833A4.171,4.171,0,0,1,20,5.833Z"
      fill="#1a2232"
    />
  </svg>
);
export const InboxInIcon = ({ width = 20, height = 20 }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 20 20"
  >
    <path
      id="inbox-in"
      d="M17.5,10H15a1.668,1.668,0,0,0-1.667,1.667,1.668,1.668,0,0,1-1.667,1.667H8.333a1.668,1.668,0,0,1-1.667-1.667A1.668,1.668,0,0,0,5,10H2.5A2.5,2.5,0,0,0,0,12.5v3.333A4.171,4.171,0,0,0,4.167,20H15.833A4.171,4.171,0,0,0,20,15.833V12.5A2.5,2.5,0,0,0,17.5,10Zm.833,5.833a2.5,2.5,0,0,1-2.5,2.5H4.167a2.5,2.5,0,0,1-2.5-2.5V12.5a.834.834,0,0,1,.833-.833l2.5,0v0A3.337,3.337,0,0,0,8.333,15h3.333A3.337,3.337,0,0,0,15,11.667h2.5a.834.834,0,0,1,.833.833ZM6.077,5.934A.833.833,0,0,1,7.256,4.756L9.167,6.667V.833a.833.833,0,0,1,1.667,0V6.667l1.911-1.911a.833.833,0,1,1,1.178,1.178L11.178,8.678a1.661,1.661,0,0,1-1.171.487l-.007,0-.008,0a1.661,1.661,0,0,1-1.171-.487L6.078,5.934Z"
      fill="#1a2232"
    />
  </svg>
);
export const SiteIcon = ({ width = 20, height = 20 }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 30 30"
  >
    <path
      id="site"
      d="M15,0A15,15,0,1,0,30,15,15.017,15.017,0,0,0,15,0ZM25.823,8.75H21.785a24.65,24.65,0,0,0-3.541-5.822A12.552,12.552,0,0,1,25.823,8.75Zm-14.86,12.5h8.075A24.36,24.36,0,0,1,15,26.985a24.36,24.36,0,0,1-4.037-5.735Zm8.075-12.5H10.963A24.36,24.36,0,0,1,15,3.015,24.36,24.36,0,0,1,19.038,8.75Zm-.909,3.859-.392,4.574a.968.968,0,0,1-.883.974.865.865,0,0,1-.751-.5l-1.039-2-1.039,2a.862.862,0,0,1-.751.5.969.969,0,0,1-.882-.974L12,12.609a.647.647,0,1,1,1.289-.106l.265,3.351.938-1.8a.646.646,0,0,1,1.148,0l.938,1.8.262-3.351a.647.647,0,0,1,.645-.6h0a.647.647,0,0,1,.644.7ZM8.074,14.05l.938,1.8L9.274,12.5a.647.647,0,0,1,.645-.6h0a.647.647,0,0,1,.644.7l-.393,4.574a.968.968,0,0,1-.882.974.865.865,0,0,1-.751-.5l-1.039-2-1.039,2a.862.862,0,0,1-.751.5.969.969,0,0,1-.882-.974l-.393-4.574A.647.647,0,1,1,5.724,12.5l.265,3.351.938-1.8A.646.646,0,0,1,8.074,14.05Zm13.388,3.606a.862.862,0,0,1-.751.5.969.969,0,0,1-.882-.974l-.392-4.574a.647.647,0,1,1,1.289-.106l.265,3.351.938-1.8a.646.646,0,0,1,1.147,0l.938,1.8.262-3.351a.647.647,0,0,1,.645-.6h0a.647.647,0,0,1,.644.7l-.392,4.574a.968.968,0,0,1-.882.974.865.865,0,0,1-.751-.5l-1.039-2-1.039,2Zm-9.7-14.729A24.676,24.676,0,0,0,8.216,8.75H4.179A12.556,12.556,0,0,1,11.757,2.928ZM4.179,21.25H8.216a24.65,24.65,0,0,0,3.541,5.823A12.552,12.552,0,0,1,4.179,21.25Zm14.065,5.823a24.676,24.676,0,0,0,3.541-5.823h4.037A12.556,12.556,0,0,1,18.244,27.073Z"
      fill="#000"
    />
  </svg>
);
export const ShieldKeyholeIcon = ({ width = 20, height = 20 }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 25 30"
  >
    <path
      id="shield-keyhole_1_"
      data-name="shield-keyhole (1)"
      d="M22.717,2.658,14.893.064a1.244,1.244,0,0,0-.786,0L6.283,2.658A6.241,6.241,0,0,0,2,8.59v6.464c0,8.2,8.756,13.221,11.44,14.562A2.17,2.17,0,0,0,14.5,30a2.061,2.061,0,0,0,.989-.3C18.19,28.614,27,24.349,27,15.054V8.59a6.241,6.241,0,0,0-4.284-5.932Zm1.784,12.4c0,7.727-7.61,11.389-9.941,12.326C12.2,26.2,4.5,21.825,4.5,15.054V8.59A3.747,3.747,0,0,1,7.07,5.03L14.5,2.566,21.93,5.03A3.747,3.747,0,0,1,24.5,8.59Zm-6.875-3.179a3.123,3.123,0,0,1-1.875,2.864V18.75a1.25,1.25,0,0,1-2.5,0V14.739a3.125,3.125,0,1,1,4.375-2.864Z"
      transform="translate(-1.999)"
      fill="#000"
    />
  </svg>
);
export const PadelockCheckIcon = ({ width = 20, height = 20 }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 30 30"
  >
    <path
      id="padlock-check"
      d="M21.25,12.5a8.758,8.758,0,0,0-8.02,5.263,1.22,1.22,0,0,0-.73-.263,1.25,1.25,0,0,0-1.25,1.25v2.5A1.25,1.25,0,0,0,12.5,22.5a.9.9,0,0,0,.1-.02A8.745,8.745,0,1,0,21.25,12.5Zm0,15a6.25,6.25,0,1,1,6.25-6.25A6.257,6.257,0,0,1,21.25,27.5Zm4.026-7.765A1.249,1.249,0,0,1,25.24,21.5l-2.765,2.655A2.935,2.935,0,0,1,20.409,25a2.972,2.972,0,0,1-2.08-.843l-1.415-1.385a1.25,1.25,0,0,1,1.749-1.787l1.415,1.385a.468.468,0,0,0,.652,0L23.509,19.7A1.251,1.251,0,0,1,25.276,19.735ZM12.5,28.75A1.25,1.25,0,0,1,11.25,30h-5A6.257,6.257,0,0,1,0,23.75v-7.5a6.254,6.254,0,0,1,3.75-5.72V8.75a8.75,8.75,0,1,1,17.5,0,1.25,1.25,0,0,1-2.5,0,6.25,6.25,0,0,0-12.5,0V10h10a1.25,1.25,0,0,1,0,2.5h-10A3.755,3.755,0,0,0,2.5,16.25v7.5A3.755,3.755,0,0,0,6.25,27.5h5a1.25,1.25,0,0,1,1.25,1.25Z"
      fill="#000"
    />
  </svg>
);
export const SensorAlertIcon = ({ width = 20, height = 20 }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 29.999 30"
  >
    <path
      id="sensor-alert"
      d="M27.5,30H23.749a1.25,1.25,0,1,1,0-2.5H27.5L20.008,14.993,12.494,27.511l3.755-.013a1.25,1.25,0,1,1,0,2.5H12.5a2.5,2.5,0,0,1-2.16-3.76l7.5-12.531a2.5,2.5,0,0,1,4.314.012l7.49,12.507A2.5,2.5,0,0,1,27.5,30Zm-6.247-5v-3.75a1.25,1.25,0,1,0-2.5,0V25a1.25,1.25,0,0,0,2.5,0ZM7.5,28.749A1.25,1.25,0,0,0,6.25,27.5a3.754,3.754,0,0,1-3.75-3.75V6.25A3.754,3.754,0,0,1,6.25,2.5h17.5A3.754,3.754,0,0,1,27.5,6.25V17.5a1.25,1.25,0,0,0,2.5,0V6.25A6.257,6.257,0,0,0,23.749,0H6.25A6.257,6.257,0,0,0,0,6.25v17.5A6.257,6.257,0,0,0,6.25,30,1.25,1.25,0,0,0,7.5,28.749ZM6.25,5A1.25,1.25,0,1,0,7.5,6.25,1.25,1.25,0,0,0,6.25,5Zm5,0A1.25,1.25,0,1,0,12.5,6.25,1.25,1.25,0,0,0,11.25,5ZM20,27.5a1.25,1.25,0,1,0,1.25,1.25A1.25,1.25,0,0,0,20,27.5Z"
      fill="#000"
    />
  </svg>
);
export const DownloadIcon = ({ width = 20, height = 20, opacity = 1 }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 18 18"
  >
    <path
      id="inbox-in"
      d="M5.47,5.341A.75.75,0,0,1,6.53,4.28L8.25,6V.75a.75.75,0,0,1,1.5,0V6l1.72-1.72A.75.75,0,0,1,12.53,5.341l-2.47,2.47a1.5,1.5,0,0,1-1.054.438L9,8.25l-.007,0A1.5,1.5,0,0,1,7.94,7.81L5.47,5.341ZM15.75,9H13.5A1.5,1.5,0,0,0,12,10.5,1.5,1.5,0,0,1,10.5,12h-3A1.5,1.5,0,0,1,6,10.5,1.5,1.5,0,0,0,4.5,9H2.25A2.253,2.253,0,0,0,0,11.25v3A3.754,3.754,0,0,0,3.75,18h10.5A3.754,3.754,0,0,0,18,14.25v-3A2.253,2.253,0,0,0,15.75,9Z"
      fill="#000"
      opacity={opacity}
    />
  </svg>
);

export const ComplianceDocumentIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="60"
    height="60"
    viewBox="0 0 60 60"
  >
    <g
      id="Group_26586"
      data-name="Group 26586"
      transform="translate(-1728 -197)"
    >
      <rect
        id="Rectangle_6300"
        data-name="Rectangle 6300"
        width="60"
        height="60"
        rx="16"
        transform="translate(1728 197)"
        fill="#e4e8eb"
      />
      <path
        id="compliance-document"
        d="M14.991,27.484H6.246A3.752,3.752,0,0,1,2.5,23.736V6.246A3.752,3.752,0,0,1,6.246,2.5h5.64c.2,0,.406.01.606.029V8.745a3.752,3.752,0,0,0,3.748,3.748H23.5a1.25,1.25,0,0,0,1.211-1.559,8.746,8.746,0,0,0-2.29-4.019L18.071,2.562A8.689,8.689,0,0,0,11.887,0H6.246A6.253,6.253,0,0,0,0,6.246v17.49a6.253,6.253,0,0,0,6.246,6.246h8.745a1.249,1.249,0,1,0,0-2.5Zm0-24.162A6.3,6.3,0,0,1,16.3,4.329l4.352,4.352a6.282,6.282,0,0,1,1,1.313H16.241a1.25,1.25,0,0,1-1.249-1.249V3.322ZM13.742,22.487a1.248,1.248,0,0,1-1.249,1.249H6.246a1.249,1.249,0,0,1,0-2.5h6.246A1.248,1.248,0,0,1,13.742,22.487Zm0-5a1.248,1.248,0,0,1-1.249,1.249H6.246a1.249,1.249,0,1,1,0-2.5h6.246A1.248,1.248,0,0,1,13.742,17.49Zm14.529-2.105L23.505,13.8a1.243,1.243,0,0,0-.786,0l-4.766,1.58a2.493,2.493,0,0,0-1.712,2.369v4.153c0,4.46,4.641,7.125,6.059,7.833a1.638,1.638,0,0,0,.813.261,1.431,1.431,0,0,0,.727-.195c1.44-.58,6.143-2.863,6.143-7.9V17.755a2.493,2.493,0,0,0-1.712-2.369Zm-.787,6.522c0,3.224-3.027,4.9-4.315,5.471-1.142-.612-4.43-2.621-4.43-5.471v-4.15l4.372-1.449,4.372,1.447Z"
        transform="translate(1743 212)"
        fill="#1a2232"
      />
    </g>
  </svg>
);

export const PrintIcon = ({ width = 20, height = 20, opacity = 1 }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    id="print"
    width={width}
    height={height}
    viewBox="0 0 18 18"
  >
    <rect
      id="Rectangle_6492"
      data-name="Rectangle 6492"
      width="10.5"
      height="3.75"
      transform="translate(3.75)"
      fill="#4c525e"
      opacity={opacity}
    />
    <rect
      id="Rectangle_6493"
      data-name="Rectangle 6493"
      width="9"
      height="6.75"
      transform="translate(4.5 11.25)"
      fill="#4c525e"
      opacity={opacity}
    />
    <path
      id="Path_44545"
      data-name="Path 44545"
      d="M15.75,7H2.25A2.25,2.25,0,0,0,0,9.25v7.5H3V11.5H15v5.25h3V9.25A2.25,2.25,0,0,0,15.75,7Zm-1.5,3h-3V8.5h3Z"
      transform="translate(0 -1.75)"
      fill="#000"
      opacity={opacity}
    />
  </svg>
);
export const ShareIcon = ({ width = 20, height = 20, opacity = 1 }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 18.003 18"
  >
    <path
      id="paper-plane-top"
      d="M.13,2.782A2.068,2.068,0,0,1,2.975.212l13.57,6.334A2.689,2.689,0,0,1,18,8.252H3.037L.172,2.878C.157,2.847.142,2.815.13,2.782Zm2.918,6.97L.232,15.135a.708.708,0,0,0-.041.092,2.065,2.065,0,0,0,2.844,2.567l13.511-6.33a2.687,2.687,0,0,0,1.459-1.71H3.048Z"
      transform="translate(-0.003 -0.003)"
      fill="#000"
      opacity={opacity}
    />
  </svg>
);
export const InterrogationIcon = ({ width = 20, height = 20 }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
  >
    <path
      id="interrogation"
      d="M8,0a8,8,0,1,0,8,8A8,8,0,0,0,8,0ZM8,13.333a.667.667,0,1,1,.667-.667A.667.667,0,0,1,8,13.333Zm1.287-5A1.321,1.321,0,0,0,8.667,9.5V10a.667.667,0,1,1-1.333,0V9.5A2.636,2.636,0,0,1,8.643,7.171a1.333,1.333,0,0,0,.667-1.417A1.349,1.349,0,0,0,8.243,4.691,1.333,1.333,0,0,0,6.667,6,.667.667,0,1,1,5.333,6,2.667,2.667,0,1,1,9.287,8.337Z"
      fill="#ced4da"
    />
  </svg>
);

import React, { useState, Suspense, useEffect } from "react";
import TileBox from "./TileBox";
import TicketingSystem from "../TicketingSystem/TicketingSystem";
import Loader from "../../Components/Common/Loader";
import { useDispatch, useSelector } from "react-redux";
import {
  TICKETING_SYSTEM,
  ONLINE_ASSETS,
} from "../../Components/Common/ModuleName";
import { Box, Grid } from "@mui/material";
import OnlineAssets from "../WebsiteManagement/OnlineAssets";
import { fetchDashboardSettingsRequest } from "../../store/actions";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import axios from "axios";
import { toast } from "react-toastify";

const initialComponents = [
  { id: "1", type: "OnlineAssets" },
  { id: "2", type: "TicketingSystem" },
];

const NewDashboard = () => {
  const dispatch = useDispatch();
  const permissionData = useSelector(
    (state) => state.userDetails.userPermission
  );
  const userData = useSelector((state) => state.userDetails.userData);
  const settings = useSelector((state) => state.dashboard.settings);
  const id = localStorage.getItem("user_id");

  const [components, setComponents] = useState([]);

  const userId = userData?.id;
  const checkPermission = permissionData?.find(
    (item) => item.module_name === TICKETING_SYSTEM
  );
  const permissioncheck = permissionData?.find(
    (item) => item.module_name === ONLINE_ASSETS
  );

  const [tileBoxTickets, setTileBoxTickets] = useState([]);

  const options = {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
  };
  const today = new Date().toLocaleDateString(undefined, options);

  useEffect(() => {
    if (userId) {
      dispatch(fetchDashboardSettingsRequest(userId));
    }
  }, [userId, dispatch]);

  const fetchThePreferenceTables = async () => {
    try {
      const response = await axios.get(
        `/dashboard/getDashboardPreference/${id}/${"table"}`
      );
      if (response.status === "success") {
        const data =
          response.data !== null
            ? JSON.parse(response.data?.preferenceTable)
            : null;

        setComponents(data === null ? initialComponents : data);
      }
    } catch (error) {
      console.log("Error while preferences", error.message);
    }
  };

  const setThePreferenceTables = async (data) => {
    try {
      const response = await axios.post(
        `/dashboard/setDashboardPreference/${id}`,
        {
          preferenceType: "tables",
          preferenceData: JSON.stringify(data),
        }
      );
      if (response.status === "success") {
        toast.success(response.message);
        fetchThePreferenceTables();
      }
    } catch (error) {
      console.log("Internal server error", error.message);
    }
  };

  useEffect(() => {
    fetchThePreferenceTables();
  }, []);

  const onDragEnd = async (result) => {
    if (!result.destination) return;

    const reorderedComponents = Array.from(components);
    const [movedItem] = reorderedComponents.splice(result.source.index, 1);
    reorderedComponents.splice(result.destination.index, 0, movedItem);

    setComponents(reorderedComponents);

    try {
      await setThePreferenceTables(reorderedComponents);
    } catch (error) {
      console.log("Error while changing the preference");
    }
  };

  return (
    <Box className="dashboard-page">
      <Box className="page-content">
        <h1 className="page-title">Hello, {userData?.user_name}</h1>
        <p className="page-subtitle">Today is {today}</p>
        <Grid container spacing={5}>
          <Grid item xs={12}>
            <TileBox tileBoxTickets={tileBoxTickets} settings={settings} />
          </Grid>
          <Grid item xs={12}>
            <DragDropContext onDragEnd={onDragEnd}>
              <Droppable droppableId="ROOT" direction="vertical">
                {(provided) => (
                  <div
                    ref={provided.innerRef}
                    {...provided.droppableProps}
                  >
                    {components.map((component, index) => {
                      return (
                        <Draggable
                          key={component.id}
                          draggableId={component.id}
                          index={index}
                        >
                          {(provided, snapshot) => (
                            <div
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              style={{
                                ...provided.draggableProps.style,
                                margin: "0 0 30px 0",
                              }}
                            >
                              {component.type === "OnlineAssets" &&
                                settings.onlineAssetList &&
                                permissioncheck?.canList != null && (
                                  
                                    <OnlineAssets
                                      isDashboardView={true}
                                      paginationDisable={true}
                                    />
                                  
                                )}
                              {component.type === "TicketingSystem" &&
                                settings.ticketList &&
                                checkPermission?.canList != null && (
                                
                                    <Suspense fallback={<Loader />}>
                                      <TicketingSystem
                                        paginationDisable={true}
                                        isDashboardView={true}
                                        setTileBoxTickets={setTileBoxTickets}
                                      />
                                    </Suspense>
                                  
                                )}
                            </div>
                          )}
                        </Draggable>
                      );
                    })}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default NewDashboard;

import React, { useState } from "react";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import WebsiteSecurityHistory from "./WebsiteSecurityHistory";
import WebSecurityDetailPage from "../../../Reporting/webSecurityDetail/WebSecurityDetailPage";
import { WebSecuTabStyle } from "../../constant";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

const WebsiteSecurity = () => {
  const params = useParams();
  const [value, setValue] = useState("2");

  const handleChangeTabs = (event, newValue) => {
    setValue(newValue);
  };

  const { apiDataLoading, apiLoadingAsset } = useSelector(
    (state) => state.onlineAssets
  );

  return (
    <Box className="websecurity">
      <TabContext value={value}>
        <Box>
          <TabList
            onChange={handleChangeTabs}
            variant="fullWidth"
            sx={WebSecuTabStyle}
          >
            <Tab label="Web. Security History" value="1" />
            <Tab label="Web. Security Scanning" value="2" />
          </TabList>
        </Box>
        <TabPanel value="1" className="tab-panel">
          <WebsiteSecurityHistory />
        </TabPanel>
        <TabPanel value="2" className="tab-panel">
          {apiDataLoading &&
          apiLoadingAsset === (params?.id && JSON.parse(params?.id)) ? (
            <div className="card mt-5">
              <div className="card-body d-flex flex-column flex-grow-1 justify-content-center align-items-center">
                <h5 className="text-center">
                  We are in the process of scanning your website. Please be
                  patient as we prepare your report.
                </h5>
              </div>
            </div>
          ) : (
            <WebSecurityDetailPage />
          )}
        </TabPanel>
      </TabContext>
    </Box>
  );
};

export default WebsiteSecurity;

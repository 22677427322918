import React, { useEffect, useState } from 'react';
import { Spinner } from 'reactstrap';
import { useNavigate, useLocation } from "react-router-dom";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const SpinnerLoader = (props) => {

    const [count, setCount] = useState(2);
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        const interval = setInterval(() => {
            setCount((prevValue) => --prevValue);
        }, 500);
        count === 0 &&
            navigate(`/login`, {
                state: location.pathname,
            });
        return () => clearInterval(interval);
    }, [count, navigate, location]);

    return (
        <React.Fragment>
            <div className="d-flex justify-content-center mx-2 mt-2">
                <Spinner style={{ marginTop: "400px" }} color="primary"> Loading... </Spinner>
            </div>
            {toast.error(props.error, { position: "top-right", hideProgressBar: false, progress: undefined, toastId: "" })}
        </React.Fragment>
    );
};

export default SpinnerLoader;
import {
  CHANGE_LAYOUT,
  CHANGE_SIDEBAR_THEME,
  CHANGE_LAYOUT_MODE,
  CHANGE_LAYOUT_WIDTH,
  CHANGE_LAYOUT_POSITION,
  CHANGE_TOPBAR_THEME,
  CHANGE_SIDEBAR_SIZE_TYPE,
  CHANGE_SIDEBAR_VIEW,
  CHANGE_SIDEBAR_IMAGE_TYPE,
  RESET_VALUE,
  CHANGE_PRELOADER,
  SIDEBAR_CURRENT_STATE,
  SET_REPORTING,
  SET_TICKETING_SYSTEM,
  SET_ANNOUNCEMENTS,
  SET_GUIDELINES_AND_POLICIES,
  SET_PAGES,
  SET_ACCESS_RIGHTS,
  SET_USER_MANAGEMENT,
  SET_WEBSITE_MANAGEMENT,
  SET_DASHBOARD,
} from "./actionType";

export const changeLayout = (layout) => ({
  type: CHANGE_LAYOUT,
  payload: layout,
});

export const changeLayoutMode = (mode) => ({
  type: CHANGE_LAYOUT_MODE,
  payload: mode,
});

export const changeSidebarTheme = (theme) => ({
  type: CHANGE_SIDEBAR_THEME,
  payload: theme,
});

export const changeLayoutWidth = (layoutWidth) => ({
  type: CHANGE_LAYOUT_WIDTH,
  payload: layoutWidth,
});

export const changeLayoutPosition = (layoutposition) => ({
  type: CHANGE_LAYOUT_POSITION,
  payload: layoutposition,
});

export const changeTopbarTheme = (topbarTheme) => ({
  type: CHANGE_TOPBAR_THEME,
  payload: topbarTheme,
});

export const changeLeftsidebarSizeType = (leftsidebarSizetype) => ({
  type: CHANGE_SIDEBAR_SIZE_TYPE,
  payload: leftsidebarSizetype,
});

export const changeLeftsidebarViewType = (leftsidebarViewtype) => ({
  type: CHANGE_SIDEBAR_VIEW,
  payload: leftsidebarViewtype,
});

export const changeSidebarImageType = (leftsidebarImagetype) => ({
  type: CHANGE_SIDEBAR_IMAGE_TYPE,
  payload: leftsidebarImagetype,
});

export const resetValue = (value) => ({
  type: RESET_VALUE,
  payload: value,
});

export const changePreLoader = (preloader) => {
  return {
    type: CHANGE_PRELOADER,
    payload: preloader,
  };
};

export const changeSidebarState = (value) => ({
  type: SIDEBAR_CURRENT_STATE,
  payload: value,
});

export const setDashboard = (value) => ({
  type: SET_DASHBOARD,
  payload: value,
});

export const setWebsiteManagement = (value) => ({
  type: SET_WEBSITE_MANAGEMENT,
  payload: value,
});

export const setUserManagement = (value) => ({
  type: SET_USER_MANAGEMENT,
  payload: value,
});

export const setAccessRights = (value) => ({
  type: SET_ACCESS_RIGHTS,
  payload: value,
});

export const setPages = (value) => ({
  type: SET_PAGES,
  payload: value,
});

export const setGuidelinesAndPolicies = (value) => ({
  type: SET_GUIDELINES_AND_POLICIES,
  payload: value,
});

export const setAnnouncements = (value) => ({
  type: SET_ANNOUNCEMENTS,
  payload: value,
});

export const setTicketingSystem = (value) => ({
  type: SET_TICKETING_SYSTEM,
  payload: value,
});

export const setReporting = (value) => ({
  type: SET_REPORTING,
  payload: value,
});

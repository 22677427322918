// import common from "../../../Components/Common/common";
import { API_ROUTES } from "../../ApiRoutes";

export const AddEditBrandingGuidelineReq = (props) => {
  const { id, userId, values, token } = props;

  let url = !id
    ? API_ROUTES.ADD_BRANDING_GUIDELINE
    : API_ROUTES.UPDATE_BRANDING_GUIDELINE + id;

  const formData = new FormData();
  if (values.imageFile && values.imageFile.constructor === File) {
    formData.append("branding-guideline-image", values.imageFile);
  }
  if (values.pdfFile && values.pdfFile.constructor === File) {
    formData.append("branding-guideline-pdf", values.pdfFile);
  }

  formData.append("user_id", userId);
  formData.append("branding_guideline_title", values.title);
  formData.append("assets_id", values.asset_url_management);
  formData.append("branding_guideline_description", values.description);

  // const formDataObject = {
  //   user_id: userId,
  //   branding_guideline_title: values.title,
  //   assets_id: values.asset_url_management,
  //   branding_guideline_description: values.description,
  // };

  // const encryptedData = common.encrypt(formDataObject);
  // formData.append("encryptedData", encryptedData.data);

  const reqData = {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
    },
    body: formData,
  };

  return { url: url, reqData };
};

export const ListBrandingGuidelineReq = (props) => {
  const { query } = props;
  let url = `${API_ROUTES.GET_BRANDING_GUIDELINE}?searchParams=${query}`;

  let reqData = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  };

  return { url: url, reqData };
};

export const DeleteBrandingGuidelineReq = (props) => {
  let { id, email, token, userId } = props;
  let url = API_ROUTES.DELETE_BRANDING_GUIDELINE + id;

  let reqData = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({ email: email, userId: userId }),
  };

  return { url: url, reqData };
};

import { Box, Grid, Skeleton, Tooltip } from "@mui/material";
import HelpIcon from "@mui/icons-material/Help";
import React from "react";
import { SummaryData } from "../constants";

import {
  PageSizeBarChart,
  TotalRequestPageSizeBarChart,
} from "../../../Charts/ApexCharts/LineCharts/LineCharts";
import { Col, Row } from "react-bootstrap";
import StructureTab from "./StructureTab";

const SummaryTab = (props) => {
  // const Summary = SummaryData(props.scores);
  const Summary = props.scores ? SummaryData(props.scores) : null;
  const pageDetail = props.scores
    ? JSON.parse(props.scores.page_details)
    : null;

  const renderSummaryCard = Summary?.map((data) => {
    const { id, title, value, subtitle, bgcolor } = data;
    return (
      <Grid item xs={12} sm={4} lg={4} xl={2} key={id}>
        {props.dataLoading ? (
          <>
            <Skeleton
              variant="text"
              width={200}
              height={100}
              style={{ background: bgcolor, borderRadius: "20px" }}
            />
          </>
        ) : (
          <>
            <Box
              className="performance-summary-card"
              style={{ background: bgcolor }}
            >
              <p className="title">{title}</p>
              <p className="value">{value}</p>
              <p className="subtitle">{subtitle}</p>
            </Box>
          </>
        )}
      </Grid>
    );
  });
  const getByteOrMega = (size) => {
    const byte = size / 1024;
    if (byte < 1000) return `${byte.toFixed(2)}KB`;
    return `${(byte / 1000).toFixed(2)}MB`;
  };

  return (
    <Box className="performance-summary">
      <Grid container spacing={{ xs: 3, xl: 4 }}>
        {renderSummaryCard}
      </Grid>
      <Grid container spacing={{ xs: 3, xl: 8 }} className="mt-4">
        {props?.auditReport?.length > 0 && (
          <Grid item xs={12} xl={6}>
            <StructureTab
              auditReport={props?.auditReport}
              maxItems={5}
              isSummary={true}
            />
          </Grid>
        )}

        <Grid item xs={12} xl={6}>
          <Box>
            <h1 className="card-title">
              Page Details{" "}
              <Tooltip
                title="Page Details graphs break down your page content by request type and size. 
Hover over a segment to see additional information on that request type so that you can understand if your page is heavy on JavaScript, images, video, etc."
                placement="top-start"
                arrow
              >
                <HelpIcon />
              </Tooltip>
            </h1>
            <p className="card-description">
              Pages with smaller total sizes and fewer requests tend to load
              faster.
            </p>

            <Box className="report-summary-loaded">
              <Box className="report-summary-loaded-text">
                <Box className="report-summary-loaded-line"></Box>
                <h4>
                  <span>
                    {pageDetail?.pageFullyLoaded
                      ? `${pageDetail.pageFullyLoaded}s`
                      : "-"}

                    {/* {pageDetail ? (
                      pageDetail?.pageFullyLoaded ? (
                        `${pageDetail.pageFullyLoaded}s`
                      ) : (
                        "-"
                      )
                    ) : (
                      <Skeleton variant="circular" width={80} height={80} />
                    )} */}
                  </span>
                </h4>
              </Box>
              <p className="fw-medium">Fully Loaded Time</p>
            </Box>

            <Row>
              {pageDetail && (
                <Col xs={12}>
                  <h4 className="fw-medium text-primary mb-0 fs-20">
                    Total Page Size -
                    {pageDetail.totalPageSize != null
                      ? getByteOrMega(pageDetail.totalPageSize)
                      : "-"}
                  </h4>
                  <PageSizeBarChart pageDetail={pageDetail} />
                </Col>
              )}
              {pageDetail && pageDetail?.totalRequest != null && (
                <Col xs={12}>
                  <h4 className="fw-medium text-primary mb-0 fs-20">
                    Total Page Requests -{" "}
                    {pageDetail?.totalRequest != null
                      ? pageDetail?.totalRequest
                      : "-"}
                  </h4>
                  <TotalRequestPageSizeBarChart pageDetail={pageDetail} />
                </Col>
              )}
            </Row>
            <p>
              Look into reducing JavaScript, reducing web-fonts, and image
              optimization to ensure a lightweight and streamlined website.
            </p>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default SummaryTab;

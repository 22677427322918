import React from "react";
import "./assets/css/bootstrap.min.css";
import "./assets/scss/themes.scss";
import Route from "./Routes";
import "./assets/css/icons.min.css";
import "./assets/css/custom.min.css";
import "./assets/js/layout.js";
import { createTheme } from "@mui/material/styles";
import { ThemeProvider } from "@mui/material/styles";
import { I18nextProvider } from "react-i18next";
import i18n from "./i18n.js";

const App = () => {
  document.title = "CONTROL BASE";

  const theme = createTheme({
    typography: {
      fontFamily: '"Open Sans", sans-serif',
    },
  });
  return (
    <React.Fragment>
      <ThemeProvider theme={theme}>
        <I18nextProvider i18n={i18n}>
          <Route />
        </I18nextProvider>
      </ThemeProvider>
    </React.Fragment>
  );
};

export default App;

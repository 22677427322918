import React, { useCallback, useEffect } from "react";
import { Button, Card, Col, Row } from "react-bootstrap";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { TextField } from "@mui/material";
import common from "../../Components/Common/common";
import { useState } from "react";
import { Spinner } from "reactstrap";

const Seo = () => {
  const token = localStorage.getItem("token");

  const [isLoading, setIsLoading] = useState(false);

  const intialValues = {
    GOOGLE_ANALYTIC_CODE: "",
    GOOGLE_TAG_MANAGER_FOR_BODY: "",
    ROBOT_TXT_FILE_CONTENT: "",
  };
  const navigate = useNavigate();

  const values = intialValues;
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: values,

    onSubmit: async (values, { setSubmitting }) => {
      try {
        await PostData(values);
      } catch (error) {
        console.error(error);
      }
    },
  });

  const PostData = async ({
    GOOGLE_ANALYTIC_CODE,
    GOOGLE_TAG_MANAGER_FOR_BODY,
    ROBOT_TXT_FILE_CONTENT,
  }) => {
    try {
      setIsLoading(true);
      let url = `${process.env.REACT_APP_BASE_URL}settings/add-settings`;
      let reqData = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          GOOGLE_ANALYTIC_CODE: GOOGLE_ANALYTIC_CODE,
          GOOGLE_TAG_MANAGER_FOR_BODY: GOOGLE_TAG_MANAGER_FOR_BODY,
          ROBOT_TXT_FILE_CONTENT: ROBOT_TXT_FILE_CONTENT,
        }),
      };
      let result = await fetch(url, reqData);
      let response = await result.json();

      if (response.code === 403) {
        navigate("/auth-404-cover");
      }
      if (response.code === "200") {
        toast.success(response.message);
      }
    } catch (error) {
      setIsLoading(false);
      toast.error("An error occurred while processing your request.");
    } finally {
      setIsLoading(false);
    }
  };

  const getSettings = useCallback(async () => {
    try {
      let url = `${process.env.REACT_APP_BASE_URL}settings/get-settings`;
      let reqData = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };
      let result = await fetch(url, reqData);
      let response = await result.json();

      const decryptedData = common.decrypt(response.data);
      response.data = decryptedData;

      if (response.code === 403) {
        navigate("/auth-404-cover");
      }

      if (response.code === "200") {
        const fieldValuesMap = {};
        response.data.rows.forEach((row) => {
          fieldValuesMap[row.field_name] = row.field_value;
        });
        formik.setValues({
          ...formik.values,
          GOOGLE_ANALYTIC_CODE: fieldValuesMap["GOOGLE_ANALYTIC_CODE"] || "",
          GOOGLE_TAG_MANAGER_FOR_BODY:
            fieldValuesMap["GOOGLE_TAG_MANAGER_FOR_BODY"] || "",
          ROBOT_TXT_FILE_CONTENT:
            fieldValuesMap["ROBOT_TXT_FILE_CONTENT"] || "",
        });
      } else {
        toast.error(response.message);
      }
    } catch (error) {
      toast.error("An error occurred while fetching API key.");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navigate, token]);

  useEffect(() => {
    getSettings();
  }, [getSettings]);

  return (
    <React.Fragment>
      <Card>
        <Card.Body className="setting-card-body">
          <form noValidate onSubmit={formik.handleSubmit}>
            <Row className="g-4">
              <Col lg={6}>
                <TextField
                  multiline
                  rows={4}
                  fullWidth
                  id="outlined-multiline-static"
                  className="form-control"
                  label="Google Analytic Code / Google Tag Manager for Head Tag "
                  name="GOOGLE_ANALYTIC_CODE"
                  placeholder="Enter Google Analytic Code / Google Tag Managet for Head Tag"
                  value={formik.values.GOOGLE_ANALYTIC_CODE}
                  {...formik.getFieldProps("GOOGLE_ANALYTIC_CODE")}
                />
              </Col>
              <Col lg={6}>
                <TextField
                  multiline
                  rows={4}
                  fullWidth
                  id="outlined-multiline-static"
                  label="Google Tag Manager For
                Body Tag"
                  placeholder="Enter Google Tag Manager For Body Tag"
                  name="GOOGLE_TAG_MANAGER_FOR_BODY"
                  value={formik.values.GOOGLE_TAG_MANAGER_FOR_BODY}
                  {...formik.getFieldProps("GOOGLE_TAG_MANAGER_FOR_BODY")}
                />
              </Col>
              <Col lg={8}>
                <TextField
                  multiline
                  rows={8}
                  fullWidth
                  label="Robot TXT File Content"
                  placeholder="Enter Robot TXT File Content"
                  name="ROBOT_TXT_FILE_CONTENT"
                  type="email"
                  value={formik.values.ROBOT_TXT_FILE_CONTENT}
                  {...formik.getFieldProps("ROBOT_TXT_FILE_CONTENT")}
                />
              </Col>
              <Col lg={12}>
                <Button
                  className="btn btn-primary ms-auto d-block"
                  type="submit"
                  disabled={isLoading}
                >
                  {isLoading ? (
                    <Spinner size="sm" variant="light" />
                  ) : (
                    <>Save &amp; Keep Editing</>
                  )}
                </Button>
              </Col>
            </Row>
          </form>
        </Card.Body>
      </Card>
    </React.Fragment>
  );
};

export default Seo;

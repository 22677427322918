// Layout Type
export const CHANGE_LAYOUT = "CHANGE_LAYOUT";
export const CHANGE_SIDEBAR_THEME = "CHANGE_SIDEBAR_THEME";
export const CHANGE_LAYOUT_MODE = "CHANGE_LAYOUT_MODE";
export const CHANGE_LAYOUT_WIDTH = "CHANGE_LAYOUT_WIDTH";
export const CHANGE_LAYOUT_POSITION = "CHANGE_LAYOUT_POSITION";
export const CHANGE_TOPBAR_THEME = "CHANGE_TOPBAR_THEME";
export const CHANGE_SIDEBAR_SIZE_TYPE = "CHANGE_SIDEBAR_SIZE_TYPE";
export const CHANGE_SIDEBAR_VIEW = "CHANGE_SIDEBAR_VIEW";
export const CHANGE_SIDEBAR_IMAGE_TYPE = "CHANGE_SIDEBAR_IMAGE_TYPE";
export const RESET_VALUE = "RESET_VALUE";
export const CHANGE_PRELOADER = "CHANGE_PRELOADER";
export const SIDEBAR_CURRENT_STATE = "SIDEBAR_CURRENT_STATE";

export const SET_DASHBOARD = "SET_DASHBOARD";
export const SET_WEBSITE_MANAGEMENT = "SET_WEBSITE_MANAGEMENT";
export const SET_USER_MANAGEMENT = "SET_USER_MANAGEMENT";
export const SET_ACCESS_RIGHTS = "SET_ACCESS_RIGHTS";
export const SET_PAGES = "SET_PAGES";
export const SET_GUIDELINES_AND_POLICIES = "SET_GUIDELINES_AND_POLICIES";
export const SET_ANNOUNCEMENTS = "SET_ANNOUNCEMENTS";
export const SET_TICKETING_SYSTEM = "SET_TICKETING_SYSTEM";
export const SET_REPORTING = "SET_REPORTING";

import {
  FETCH_DEVELOPERS_PROFILE,
  FETCH_DEVELOPERS_PROFILE_SUCCESS,
  FETCH_DEVELOPERS_PROFILE_FAIL,
} from "./actionType";

// Fetch Developers Profile
export const fetchDevelopersProfile = () => ({
  type: FETCH_DEVELOPERS_PROFILE,
});

export const fetchDevelopersProfileSuccess = (websitepolicy) => ({
  type: FETCH_DEVELOPERS_PROFILE_SUCCESS,
  payload: websitepolicy,
});

export const fetchDevelopersProfileFail = (error) => ({
  type: FETCH_DEVELOPERS_PROFILE_FAIL,
  payload: error,
});


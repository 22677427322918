import { API_ROUTES } from "../../ApiRoutes";

export const changePasswordReq = (props) => {

    const { oldPassword, newPassword } = props

    let url = API_ROUTES.CHANGE_PASSWORD

    let formData = {
        oldPassword: oldPassword,
        newPassword: newPassword
    }

    const reqData = {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        body: JSON.stringify(formData),
    };

    return { url: url, reqData };
}

import React, { useEffect, useState } from "react";
import { VectorMap } from "react-jvectormap";
import "./jquery-jvectormap.scss";
import axios from "axios";
import { useParams } from "react-router-dom";
import common from "../../../Components/Common/common";
import { Skeleton } from "@mui/material";
import { useSelector } from "react-redux";

const Vectormap = (props) => {
  const map = React.createRef(null);
  const params = useParams();
  // let token = localStorage.getItem("token");

  const [loading, setLoading] = useState(false);
  const [gdpData, setGdpData] = useState({});

  const {
    fetch_analytics_realtime_data_loading,
    fetch_analytics_realtime_duration_data_loading,
    fetch_analytics_data_loading,
    fetch_user_session_chart_loading,
    fetch_chart_data_loading,
  } = useSelector((state) => ({
    fetch_analytics_realtime_data_loading:
      state?.analyticsData?.fetch_analytics_realtime_data_loading,
    fetch_analytics_realtime_duration_data_loading:
      state?.analyticsData?.fetch_analytics_realtime_duration_data_loading,
    fetch_analytics_data_loading:
      state.analyticsData.fetch_analytics_data_loading,
    fetch_user_session_chart_loading:
      state.analyticsData.fetch_user_session_chart_loading,
    fetch_chart_data_loading: state.analyticsData.fetch_chart_data_loading,
  }));

  const fetchCountryData = async () => {
    setLoading(true);
    try {
      await axios
        .post("site-data/analytics-realtime-data", {
          assets_id: params.id,
        })
        .then((response) => {
          const decryptedData = common.decrypt(response?.data);
          if (response.status === "success") {
            const newData = {};
            decryptedData?.forEach((country) => {
              newData[country?.shortName?.toUpperCase()] = parseFloat(
                country.liveUsers
              );
            });
            setGdpData(newData);
          }
        });
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchCountryData();
    // eslint-disable-next-line
  }, [params.id]);

  return (
    <>
      {/* <div style={{ width: props.width, height: 420 }}> */}

      {loading ||
      fetch_analytics_realtime_data_loading ||
      fetch_analytics_realtime_duration_data_loading ||
      fetch_analytics_data_loading ||
      fetch_user_session_chart_loading ||
      fetch_chart_data_loading ? (
        <div
          style={{
            display: "flex",
            height: "100%",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Skeleton variant="rounded" width={500} height={300}></Skeleton>
        </div>
      ) : (
        <VectorMap
          map={props.value}
          backgroundColor="transparent"
          ref={map}
          containerStyle={{
            width: "100%",
            height: "100%",
          }}
          markerStyle={{
            initial: {
              fill: "red",
              stroke: "#383f47",
            },
          }}
          series={{
            // scale: ["#FEE5D9", "#A50F15"],
            // values: Object.values(gdpData),
            // min: 3.7,
            // max: 16.3
            regions: [
              {
                scale: ["#08519C"],
                attribute: "fill",
                values: gdpData,
                normalizeFunction: "polynomial",
                min: 1,
                max: 2050,
              },
            ],
          }}
          regionStyle={{
            initial: {
              fill: props.color,
              stroke: "none",
              "stroke-width": 0,
              "stroke-opacity": 0,
            },
            hover: {
              "fill-opacity": 0.8,
              cursor: "pointer",
            },
            selected: {
              fill: "#DEEBF7", //what colour clicked country will be
            },
            selectedHover: {},
          }}
          onRegionTipShow={function (event, label, code, ...props) {
            let count = gdpData[code];
            if (count !== undefined && count !== null) {
              label.html(
                '<div style="background-color: white; border: 1px solid white; outline: 10px solid white; border-radius: 6px; min-height: 70px; width: 150px; color: black; padding-left: 10px">' +
                  "<p>" +
                  "<b>" +
                  label.html() +
                  "</b>" +
                  "</p>" +
                  "<p>" +
                  "Count: " +
                  "<b>" +
                  count +
                  "</b>" +
                  "</p>" +
                  "</div>"
              );
            } else {
              label.html(
                '<div style="background-color: white; border: 1px solid white; outline: 10px solid white; border-radius: 6px; min-height: 30px; width: 150px; color: black; padding-left: 10px">' +
                  label.html()
              );
            }
          }}
          containerClassName="map"
        />
      )}
      {/* </div> */}
    </>
  );
};

// const Vectormap = (props) => {
//   const map = React.createRef(null);
//   const params = useParams();
//   let token = localStorage.getItem("token");

//   const [loading, setLoading] = useState(false);
//   const [gdpData, setGdpData] = useState({});

//   const fetchCountryData = async () => {
//     setLoading(true);
//     try {
//       let data = JSON.stringify({
//         assets_id: params.id,
//       });

//       let config = {
//         method: "post",
//         maxBodyLength: Infinity,
//         url: `${process.env.REACT_APP_BASE_URL}site-data/analytics-realtime-data`,
//         headers: {
//           "Content-Type": "application/json",
//           Authorization: `Bearer ${token}`,
//         },
//         data: data,
//       };

//       axios.request(config).then((response) => {
//         const decryptedData = common.decrypt(response?.data);

//         if (response.status === "success") {
//           const newData = {};
//           decryptedData?.forEach((country) => {
//             newData[country?.shortName?.toUpperCase()] = parseFloat(
//               country.liveUsers
//             );
//           });

//           setGdpData(newData);
//         }
//       });
//     } catch (error) {
//       console.error(error);
//     } finally {
//       setLoading(false);
//     }
//   };

//   useEffect(() => {
//     fetchCountryData();
//     // eslint-disable-next-line
//   }, [params.id, token]);

//   const handleRegionTipShow = (event, label, code) => {
//     let count = gdpData[code];
//     if (count !== undefined && count !== null) {
//       label.html(
//         `<div style="
//           background-color: white;
//           border: 1px solid white;
//           padding: 10px;
//           border-radius: 6px;
//           width: 150px;
//           color: black;
//         ">
//           <p><b>${label.html()}</b></p>
//           <p>Count: <b>${count}</b></p>
//         </div>`
//       );
//     } else {
//       label.html(
//         `<div style="
//           background-color: white;
//           border: 1px solid white;
//           padding: 10px;
//           border-radius: 6px;
//           width: 150px;
//           color: black;
//         ">
//           ${label.html()}
//         </div>`
//       );
//     }

//     // Manually set tooltip position
//     const tooltip = document.querySelector(".jvectormap-tip");
//     tooltip.style.left = `${event.pageX + 10}px`;
//     tooltip.style.top = `${event.pageY + 10}px`;

//     // Ensure tooltip is fully visible
//     const mapContainer = document.querySelector(".map");
//     const mapRect = mapContainer.getBoundingClientRect();
//     const tooltipRect = tooltip.getBoundingClientRect();

//     if (tooltipRect.right > mapRect.right) {
//       tooltip.style.left = `${event.pageX - tooltipRect.width - 10}px`;
//     }
//     if (tooltipRect.bottom > mapRect.bottom) {
//       tooltip.style.top = `${event.pageY - tooltipRect.height - 10}px`;
//     }
//   };

//   const handleRegionOut = () => {
//     const tooltip = document.querySelector(".jvectormap-tip");
//     if (tooltip) {
//       tooltip.innerHTML = "";
//       tooltip.style.left = `-9999px`; // Move it offscreen
//       tooltip.style.top = `-9999px`; // Move it offscreen
//     }
//   };

//   return (
//     <>
//       {loading ? (
//         <div
//           style={{
//             display: "flex",
//             height: "100%",
//             justifyContent: "center",
//             alignItems: "center",
//           }}
//         >
//           <Skeleton variant="rounded" width={500} height={300}></Skeleton>
//         </div>
//       ) : (
//         <VectorMap
//           map={props.value}
//           backgroundColor="transparent"
//           ref={map}
//           containerStyle={{
//             width: "100%",
//             height: "100%",
//           }}
//           markerStyle={{
//             initial: {
//               fill: "red",
//               stroke: "#383f47",
//             },
//           }}
//           series={{
//             regions: [
//               {
//                 scale: ["#08519C"],
//                 attribute: "fill",
//                 values: gdpData,
//                 normalizeFunction: "polynomial",
//                 min: 1,
//                 max: 2050,
//               },
//             ],
//           }}
//           regionStyle={{
//             initial: {
//               fill: props.color,
//               stroke: "none",
//               "stroke-width": 0,
//               "stroke-opacity": 0,
//             },
//             hover: {
//               "fill-opacity": 0.8,
//               cursor: "pointer",
//             },
//             selected: {
//               fill: "#DEEBF7", // Color when a country is clicked
//             },
//             selectedHover: {},
//           }}
//           onRegionTipShow={handleRegionTipShow}
//           onRegionOut={handleRegionOut}
//           containerClassName="map"
//         />
//       )}
//     </>
//   );
// };

export default Vectormap;

// if (count !== undefined && count !== null) {
//   label.html(
//     `<div style="background-color: white; border: 1px solid white; outline: 10px solid white; border-radius: 6px; min-height: 70px; width: 150px; color: black; padding-left: 10px">
//       <p><b>${label.html()}</b></p>
//       <p>Count: <b>${count}</b></p>
//     </div>`
//   );
// } else {
//   label.html(
//     `<div style="background-color: white; border: 1px solid white; outline: 10px solid white; border-radius: 6px; min-height: 30px; width: 150px; color: black; padding-left: 10px">
//       ${label.html()}
//     </div>`
//   );
// }

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export const ToastMessage = {
  Error: (message) => {
    toast.error(message, {
      position: toast.POSITION.TOP_RIGHT,
      autoClose: 3000, // Close the toast after 3 seconds
      hideProgressBar: true, // Hide the progress bar
    });
  },

  Success: (message, autoCloseTime = 3000) => {
    toast.success(message, {
      position: toast.POSITION.TOP_RIGHT,
      autoClose: autoCloseTime, // Close the toast after 3 seconds
      hideProgressBar: true, // Hide the progress bar
    });
  },
};

export const VALIDATION_MESSAGE = {

    TITLE: {
        MAX_LENGTH: 60,
        MAX_LENGTH_MESSAGE: "Title not be greater that 60 characters.",
        TITLE_MESSAGE: "Please enter the title.",
        CHARACTERS: "Allow only A to Z and only",
        EMPTY_SPACE: "Remove / trim field starting and end time spacing"
    },

    DESCRIPTION: {
        MAX_LENGTH: 500,
        MAX_LENGTH_MESSAGE: "Description not be greater that 500 characters.",
        DESCRIPTION_MESSAGE: "Please enter the desciption.",
        CHARACTERS: "Allow only A to Z and only",
        EMPTY_SPACE: "Remove / trim field starting and end time spacing"
    },

    FULL_NAME: {
        MAX_LENGTH: 60,
        CHARACTERS: "Allow only A to Z and only",
        EMPTY_SPACE: "Remove / trim field starting and end time spacing"
    },

    FIRST_NAME: {
        MAX_LENGTH: 30,
        CHARACTERS: "Allow only A to Z and only",
        EMPTY_SPACE: "Remove / trim field starting and end time spacing"
    },

    LAST_NAME: {
        MAX_LENGTH: 30,
        CHARACTERS: "Allow only A to Z and only",
        EMPTY_SPACE: "Remove / trim field starting and end time spacing"
    },

    EMAIL: {
        MAX_LENGTH: 70,
        EMPTY_SPACE: "Remove / trim field starting and end time spacing"
    },

    WHATSAPP_NUMBER: {
        MAX_LENGTH: 20,
        ALL_ZEROS: " don't allow only zero digits (all digits should not be 0)",
    },

    PHONE: {
        MAX_LENGTH: 20,
        ALL_ZEROS: " don't allow only zero digits (all digits should not be 0)",
    },

    DATE_OF_BIRTH: {
        DOB: "Don't show the featured date selection option"
    },

    PASSWORD: {
        MIN_LENGTH: 8,
        MAX_LENGTH: 20,
        EMPTY_SPACE: "Don't allow the space",
    },

    CONFIRM_PASSWORD: {
        MAX_LENGTH: 20,
        EMPTY_SPACE: "Don't allow the space",
        SAME_PASSWORD: "Password and confirm password must be same"
    },

    DELIVERY_INSTRUCTIONS: {
        MAX_LENGTH: 500
    },

    CREDIT_CARD: {
        CARD_NUMBER: {
            MAX_LENGTH: 16,
            ALLOWED_DIGITS: "allow only digits(0 - 9)"
        },

        CARD_HOLDER_NAME: {
            MAX_LENGTH: 32,
            ALLOWED_CHARACTERS: "allow only Alphabetic characters and space"
        },

        EXPIRATION_DATE: {
            MAX_LENGTH: 7, // i.e 05 / 2024
            MONTH: "Month should be 1 to 12"
        },

        CVV: {
            MAX_LENGTH: 4,
            MAX_LENGTH_MESSAGE: "CVV must be 3 or 4 digits",
            ALLOWED_DIGITS: "allow only digits(0 - 9)",
            ALL_ZEROS: "don't allow only zero digits",
        },
    },

    GENERAL_VALIDATION: {
        ALLOWED_LANGUAGE: "Allow only english language",
        EMPTY_SPACE: "Remove / trim field starting and end time spacing",
        // - Copy / Paste allow but validation should not be miss.
    },

    // Note: Don't change length for First Name, Last Name, Email, Password, Address, City, Zip etc. because it will need to make changes in website, Mobile App, Dashboard, Local POS everwhere..

    SIGNUP: {
        FIRST_NAME: "Please enter first name",
        VALID_FIRST_NAME: "Please enter valid first name",
        LAST_NAME: " Please enter last name",
        VALID_LAST_NAME: "Please enter valid last name",
        EMAIL_ID_MESSAGE: "Please enter email id",
        VALID_EMAIL_ID: "Please enter valid email id",
        ALREADY_EXIST_EMAIL_ID: "Entered email id is already exist",
        VALID_WHATSAPP_NUMBER: "Please enter valid whatsapp number",
        MIN_LENGTH_WHATSAPP_NUMBER: 10,
        MIN_LENGTH_WHATSAPP_NUMBER_MESSAGE: "Whatsapp number must be at least 10 digits",
        VALID_PHONE_NUMBER: "Please enter valid phone number",
        MIN_LENGTH_PHONE_NUMBER: "Phone number must be at least 10 digits",
        BIRTH_DATE_LESS_THEN_CURRENT_DATE_MESSAGE: "Birth date should not be later than today",
        PASSWORD_MESSAGE: "Please enter password",
        MIN_LENGTH_PASSWORD_MESSAGE: "Password must be at least 6 characters",
        CONFIRM_PASSWORD_MESSAGE: "Please enter confirm password",
        SAME_CONFIRM_PASSWORD_MESSAGE: "Password and confirm password does not match"
    },

    LOGIN_PAGE: {
        EMAIL_ID_MESSAGE: "Please enter your email id",
        VALID_EMAIL_ID_MESSAGE: "Please enter valid email id",
        EMAIL_NOT_EXIST_MESSAGE: "Your entered email id doesn't exist.",
        PASSWORD_MESSAGE: "Please enter your passsword",
        INCORRECT_PASSWORD_MESSAGE: "Your entered passsword is incorrect.",
    },

    FORGOT_PASSWORD: {
        EMAIL_ID_MESSAGE: "Please enter your email id",
        VALID_EMAIL_ID_MESSAGE: "Please enter valid email id",
        EMAIL_NOT_EXIST_MESSAGE: "Your entered email id doesn't exist.",

        SUCCESSFULLY_SENT_LINK_MESSAGE: "The link has been sent, please check your email to reset your password.",

        ENTER_NEW_PASSWORD_MESSAGE: " Please enter new password",
        MIN_LENGTH_PASSWORD_MESSAGE: "Password must be at least 6 characters",
        CONFIRM_PASSWORD_MESSAGE: "Please enter confirm password",
        SAME_CONFIRM_PASSWORD_MESSAGE: "New password and confirm password does not match"
    },

    ADDRESS: {
        ADDRESS_TITLE: "Please enter address title",
        VALID_ADDRESS_TITLE: "Please enter valid address title",
        FIRST_NAME: "Please enter first name",
        VALID_FIRST_NAME: "Please enter valid first name",
        LAST_NAME: " Please enter last name",
        VALID_LAST_NAME: "Please enter valid last name",
        EMAIL_ID_MESSAGE: "Please enter email id",
        VALID_EMAIL_ID: "Please enter valid email id",
        ALREADY_EXIST_EMAIL_ID: "Entered email id is already exist",
        VALID_PHONE_NUMBER: "Please enter valid phone number",
        MIN_LENGTH_PHONE_NUMBER: "Phone number must be at least 10 digits",
        MIN_LENGTH_PHONE_NUMBER_MESSAGE: "Please enter phone number",
        ADDRESS1_MESSAGE: "Please enter address1",
        VALID_ADDRESS1_MESSAGE: "Please enter valid address1",
        ADDRESS2_MESSAGE: "Please enter address2",
        VALID_ADDRESS2_MESSAGE: "Please enter valid address2",
        CITY_NAME_MESSAGE: "Please enter city name",
        VALID_CITY_NAME_MESSAGE: "Please enter valid city name",
        STATE_MESSAGE: "Please select a state",
        VALID_STATE_MESSAGE: "Please enter valid state",
        ZIP_COE_MESSAGE: "Please enter zipcode",
        VALID_ZIP_CODE_MESSAGE: "Please enter valid zipcode",
    },
}
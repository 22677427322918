const PCIDSSData = [
  {
    id: 1,
    title: "REQUIREMENT 6.2",
    description:
      "Website CMS or its components seem to be outdated. Check for available updates.",
    label: "Misconfiguration or weakness",
    status: "orange-label",
  },
  {
    id: 2,
    title: "REQUIREMENT 6.5",
    description:
      "No publicly known vulnerabilities seem to be present in the fingerprinted versions the website CMS and its components.",
    label: "Good configuration",
    status: "green-label",
  },
  {
    id: 3,
    title: "REQUIREMENT 6.6",
    description:
      "No WAF was detected on the website. Implement a WAF to protect the website against common web attacks.",
    label: "Misconfiguration or weakness",
    status: "orange-label",
  },
];

function createData(name, description, alerts) {
  return { name, description, alerts };
}

const DirectivesRows = [
  createData(
    "max-age",
    "Sets the time browsers must enforce the use of HTTPS to browse the website.",
    "No problems found"
  ),
];

const PolicyDirectivesRows = [
  createData(
    "object-src",
    "Restricts the URLs from which plugin content may be loaded.",
    "No problems found"
  ),
];

const getMsOrSec = (value) =>
  value < 1000 ? value + "ms" : (value / 1000).toFixed(1) + "s";

const SummaryData = (scores) => {
  let arr = [
    {
      id: 1,
      title: "TTFB",
      value:
        scores?.time_to_first_byte !== null
          ? `${getMsOrSec(scores.time_to_first_byte)}`
          : "-",
      subtitle:
        scores?.time_to_first_byte !== null
          ? scores.time_to_first_byte < 1000
            ? `Redirect ${scores.time_to_first_byte}ms`
            : `Redirect ${(scores.time_to_first_byte / 1000).toFixed(1)}s`
          : "-",
      bgcolor: "#8A98C1",
    },
    {
      id: 2,
      title: "Time to Interactive",
      value:
        scores?.time_to_interactive !== null
          ? `${getMsOrSec(scores.time_to_interactive)}`
          : "-",
      bgcolor: "#B06DC9",
    },
    {
      id: 3,
      title: "Fully Loaded Time",
      value:
        scores?.fully_loaded_time !== null
          ? `${getMsOrSec(scores.fully_loaded_time)}`
          : "-",
      bgcolor: "#B22F69",
    },
    {
      id: 4,
      title: "First Contentful Paint",
      value:
        scores?.first_contentful_paint !== null
          ? `${getMsOrSec(scores.first_contentful_paint)}`
          : "-",
      bgcolor: "#2EBBF6",
    },
    {
      id: 5,
      title: "Onload Time",
      value:
        scores?.onload_time !== null
          ? `${getMsOrSec(scores.onload_time)}`
          : "-",
      bgcolor: "#A85C84",
    },
    {
      id: 6,
      title: "Largest Contentful Paint",
      value:
        scores?.largest_contentful_paint !== null
          ? `${getMsOrSec(scores.largest_contentful_paint)}`
          : "-",
      bgcolor: "#417BE7",
    },
  ];
  return arr;
};

const IssueTablistStyle = {
  "&.MuiTabs-root": {
    minHeight: "auto",
  },
  "& .MuiTabs-indicator": {
    height: 0,
  },
  "& .MuiButtonBase-root": {
    background: "#F1F3F6",
    color: "#757575",
    fontSize: "14px",
    borderRadius: "4px",
    margin: "0 3px",
    minWidth: "50px",
    minHeight: "30px",
    padding: "5px",
  },
  "& .Mui-selected": {
    background: "#CED4DA",
    color: "#1A2232",
    fontWeight: 600,
  },
};
const IssueTableStyle = {
  "& .MuiTableCell-head": {
    color: "#757a84",
  },
  "& .MuiTableCell-body": {
    color: "#1a2232",
  },
  "& .MuiTableBody-root": {
    "& .MuiTableRow-root": {
      "&:nth-child(odd)": {
        background: "#fff",
      },
    },
  },
  "& .MuiTableCell-root": {
    borderBottom: "0",
    padding: "7px 14px",
  },
};

const TableColumns = [
  {
    id: 1,
    statistic: "Total DOM Elements",
    element: '<rect fill="none" height="24" width="24">',
    value: 150,
  },
  {
    id: 2,
    statistic: "Maximum DOM Depth",
    element: '<div jsname="aajZCb" className="aajZCb">',
    value: 18,
  },
  {
    id: 2,
    statistic: "Maximum Child Elements",
    element: '<div jsname="aajZCb" className="aajZCb">',
    value: 8,
  },
];

export {
  PCIDSSData,
  DirectivesRows,
  PolicyDirectivesRows,
  SummaryData,
  IssueTablistStyle,
  IssueTableStyle,
  TableColumns,
};

import React, { useState } from "react";
import DataTable from "react-data-table-component";
import { CustomTableStyle } from "../../../Components/Common/constants";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import DownloadIcon from "@mui/icons-material/Download";
import { Visibility } from "@mui/icons-material";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

const TicketAttachments = ({ attachments = [] }) => {
  const [modalOpen, setModalOpen] = useState(true);
  const [selectedFile, setSelectedFile] = useState(null);

  const toggleModal = () => setModalOpen(!modalOpen);

  const handleFileClick = (file) => {
    setSelectedFile(file);
    setModalOpen(true);
  };

  const downloadFile = async (file) => {
    const url = `${process.env.REACT_APP_BASE_URL}Newticketing-system/${file.imageName}`;

    try {
      const response = await fetch(url);
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const blob = await response.blob();
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = file.name;
      link.click();
      window.URL.revokeObjectURL(link.href);
    } catch (error) {
      console.error("Error downloading the file:", error);
    }
  };

  const columns = [
    {
      name: <span className="font-weight-bold fs-13">File Name</span>,
      selector: (row) => (
        <span
        // onClick={() => handleFileClick(row)}
        // style={{ cursor: "pointer" }}
        >
          {row.originalName?.charAt(0).toUpperCase() +
            row.originalName?.slice(1) ||
            row.name?.charAt(0).toUpperCase() + row.name?.slice(1)}
        </span>
      ),
    },
    {
      name: <span className="font-weight-bold fs-13">Type</span>,
      cell: (row) => <span>{row.type.toUpperCase() + " File"}</span>,
    },
    {
      name: <span className="text-center">Action</span>,
      cell: (row) => (
        <span className="table-action">
          <OverlayTrigger
            placement="top"
            overlay={<Tooltip id={`tooltip-view-${row.id}`}>View</Tooltip>}
          >
            <span
              className="cursor-pointer ms-2"
              onClick={() => handleFileClick(row)}
            >
              <Visibility />
            </span>
          </OverlayTrigger>
          <OverlayTrigger
            placement="top"
            overlay={
              <Tooltip id={`tooltip-download-${row.id}`}>Download</Tooltip>
            }
          >
            <span
              className="cursor-pointer ms-2"
              onClick={() => downloadFile(row)}
              style={{ marginLeft: "20px", cursor: "pointer" }}
            >
              <DownloadIcon />
            </span>
          </OverlayTrigger>
        </span>
      ),
      width: "85px",
      center: true,
    },
  ];

  return (
    <>
      <DataTable
        highlightOnHover
        // pointerOnHover
        columns={columns}
        data={attachments}
        customStyles={CustomTableStyle}
        className="table-design"
      />

      {selectedFile && (
        <Modal
          isOpen={modalOpen}
          toggle={toggleModal}
          size="lg"
          backdrop="static"
        >
          <ModalHeader toggle={toggleModal}>
            {selectedFile.originalName || selectedFile.name}
          </ModalHeader>
          <ModalBody>
            <iframe
              src={`${process.env.REACT_APP_BASE_URL}Newticketing-system/${selectedFile.imageName}`}
              width="100%"
              height="500px"
              title="File Viewer"
              className="file-viewer"
              style={{
                marginTop: "50px",
              }}
            />
          </ModalBody>
        </Modal>
      )}
    </>
  );
};

export default TicketAttachments;

import React from 'react';
import { Spinner } from 'reactstrap';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Loader = (props) => {
  
  let color = props.color || "primary"
  let style = props.style || null

  return (
    <React.Fragment>
      <div className="d-flex justify-content-center mx-2 mt-2">
        <Spinner style={style} color={color}> Loading... </Spinner>
      </div>
      {toast.error(props.error, { position: "top-right", hideProgressBar: false, progress: undefined, toastId: "" })}
    </React.Fragment>
  );
};

export default Loader;
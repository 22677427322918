import {
  FETCH_DASHBOARD_SETTINGS_REQUEST,
  FETCH_DASHBOARD_SETTINGS_SUCCESS,
  FETCH_DASHBOARD_SETTINGS_FAILURE,
  UPDATE_DASHBOARD_SETTINGS_REQUEST,
  UPDATE_DASHBOARD_SETTINGS_SUCCESS,
  UPDATE_DASHBOARD_SETTINGS_FAILURE,
} from "./actionTypes";

// Action creators for fetching dashboard settings
export const fetchDashboardSettingsRequest = (userId) => ({
  type: FETCH_DASHBOARD_SETTINGS_REQUEST,
  payload: userId,
});

export const fetchDashboardSettingsSuccess = (settings) => ({
  type: FETCH_DASHBOARD_SETTINGS_SUCCESS,
  payload: settings,
});

export const fetchDashboardSettingsFailure = (error) => ({
  type: FETCH_DASHBOARD_SETTINGS_FAILURE,
  payload: error,
});

// Action creators for updating dashboard settings
export const updateDashboardSettingsRequest = (userId, updatedSettings) => ({
  type: UPDATE_DASHBOARD_SETTINGS_REQUEST,
  payload: { userId, updatedSettings },
});

export const updateDashboardSettingsSuccess = (settings) => ({
  type: UPDATE_DASHBOARD_SETTINGS_SUCCESS,
  payload: settings,
});

export const updateDashboardSettingsFailure = (error) => ({
  type: UPDATE_DASHBOARD_SETTINGS_FAILURE,
  payload: error,
});

import { call, put, takeEvery, all, fork } from "redux-saga/effects";
import {
  fetchDevelopersProfileFail,
  fetchDevelopersProfileSuccess,
} from "./action";

import { FETCH_DEVELOPERS_PROFILE } from "./actionType";

import axios from "axios";
import common from "../../Components/Common/common";

const fetchDevelopersProfileAPI = async () => {
  try {
    let response = await axios.post(`/developer-profile/get-developer-profile`);

    const decryptedData = common.decrypt(response.data);
    response.data = decryptedData;

    return response.data; // Return the response data
  } catch (error) {
    throw error;
  }
};

function* getDevelopersProfile() {
  try {
    const queryParams = {
      websitepolicyContent: "websitepolicyContent", // Example: Add query parameters
    };
    const response = yield call(fetchDevelopersProfileAPI, queryParams);
    yield put(fetchDevelopersProfileSuccess(response));
  } catch (error) {
    yield put(fetchDevelopersProfileFail(error.response));
  }
}

export function* watchGetDevelopersProfile() {
  yield takeEvery(FETCH_DEVELOPERS_PROFILE, getDevelopersProfile);
}

function* DevelopersProfileSaga() {
  yield all([fork(watchGetDevelopersProfile)]);
}

export default DevelopersProfileSaga;

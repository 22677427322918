import React, { useState } from "react";
import ProfileImage from "../../../Components/Common/ProfileImage";
import { Form, FormFeedback, Button } from "reactstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import TextEditor from "../../../Components/Common/TextEditor";
import SendIcon from "@mui/icons-material/Send";
import { Box } from "@mui/material";

const Comments = ({
  comment,
  nestedComments,
  postComments,
  ticketId,
  openCommentId,
  setOpenCommentId,
  showReplyButton,
}) => {
  // function displayCommentTime(commentTime) {
  //   let secondsInMinute = 60;
  //   let scondsInHour = 60 * secondsInMinute; // 3600
  //   let scondsInDay = 24 * scondsInHour; // 86400
  //   let scondsInWeek = 7 * scondsInDay; // 604800
  //   let scondsInMonth = 30 * scondsInDay; // 2592000 and Assuming a month is 30 days
  //   let scondsInYear = 365 * scondsInDay; // 31536000 and Assuming a year is 365 days

  //   let timeDiff = new Date() - new Date(commentTime);

  //   const timeDifferenceInSeconds = Math.floor(timeDiff / 1000);

  //   if (timeDifferenceInSeconds < secondsInMinute) {
  //     return `${timeDifferenceInSeconds} second${
  //       timeDifferenceInSeconds === 1 ? "" : "s"
  //     } ago`;
  //   } else if (timeDifferenceInSeconds < scondsInHour) {
  //     const minuteAgo = Math.floor(timeDifferenceInSeconds / secondsInMinute);
  //     return `${minuteAgo} minute${minuteAgo === 1 ? "" : "s"} ago`;
  //   } else if (timeDifferenceInSeconds < scondsInDay) {
  //     const hoursAgo = Math.floor(timeDifferenceInSeconds / scondsInHour);
  //     return `${hoursAgo} hour${hoursAgo === 1 ? "" : "s"} ago`;
  //   } else if (timeDifferenceInSeconds < scondsInWeek) {
  //     const daysAgo = Math.floor(timeDifferenceInSeconds / scondsInDay);
  //     return `${daysAgo} day${daysAgo === 1 ? "" : "s"} ago`;
  //   } else if (timeDifferenceInSeconds < scondsInMonth) {
  //     const weeksAgo = Math.floor(timeDifferenceInSeconds / scondsInWeek);
  //     return `${weeksAgo} week${weeksAgo === 1 ? "" : "s"} ago`;
  //   } else if (timeDifferenceInSeconds < scondsInYear) {
  //     const monthsAgo = Math.floor(timeDifferenceInSeconds / scondsInMonth);
  //     return `${monthsAgo} week${monthsAgo === 1 ? "" : "s"} ago`;
  //   } else {
  //     const yearsAgo = Math.floor(timeDifferenceInSeconds / scondsInYear);
  //     return `${yearsAgo} week${yearsAgo === 1 ? "" : "s"} ago`;
  //   }
  // }

  function displayCommentTime(commentTime) {
    let secondsInMinute = 60;
    let secondsInHour = 60 * secondsInMinute; // 3600
    let secondsInDay = 24 * secondsInHour; // 86400
    let secondsInWeek = 7 * secondsInDay; // 604800

    let currentTime = new Date();
    let commentDateTime = new Date(commentTime);

    let timeDiff = currentTime - commentDateTime;

    if (timeDiff < 0) {
      return "In the future";
    }

    const timeDifferenceInSeconds = Math.floor(timeDiff / 1000);

    if (timeDifferenceInSeconds < secondsInMinute) {
      return `${timeDifferenceInSeconds} second${
        timeDifferenceInSeconds === 1 ? "" : "s"
      } ago`;
    } else if (timeDifferenceInSeconds < secondsInHour) {
      const minutesAgo = Math.floor(timeDifferenceInSeconds / secondsInMinute);
      return `${minutesAgo} minute${minutesAgo === 1 ? "" : "s"} ago`;
    } else if (timeDifferenceInSeconds < secondsInDay) {
      const hoursAgo = Math.floor(timeDifferenceInSeconds / secondsInHour);
      return `${hoursAgo} hour${hoursAgo === 1 ? "" : "s"} ago`;
    } else if (timeDifferenceInSeconds < secondsInWeek) {
      const daysAgo = Math.floor(timeDifferenceInSeconds / secondsInDay);
      return `${daysAgo} day${daysAgo === 1 ? "" : "s"} ago`;
    } else {
      const weeksAgo = Math.floor(timeDifferenceInSeconds / secondsInWeek);
      const remainingSeconds = timeDifferenceInSeconds % secondsInWeek;
      const daysAgo = Math.floor(remainingSeconds / secondsInDay);

      if (daysAgo === 0) {
        return `${weeksAgo} week${weeksAgo === 1 ? "" : "s"} ago`;
      } else {
        return `${weeksAgo} week${weeksAgo === 1 ? "" : "s"} - ${daysAgo} day${
          daysAgo === 1 ? "" : "s"
        } ago`;
      }
    }
  }
  const [replayId, setReplayId] = useState(null);

  const formik = useFormik({
    enableReinitialize: true,

    initialValues: {
      replayComments: "",
    },

    validationSchema: Yup.object().shape({
      replayComments: Yup.string().required("Please enter the comment."),
    }),

    onSubmit: (values) => {
      const { replayComments } = values;
      const data = {
        replayComments,
        replayId,
        ticketId,
      };

      document.getElementById(`collapseExample${comment.id}`).remove();

      postComments(data);
      formik.resetForm();
    },
  });

  return (
    <Box className="comment-page">
      <Box className="d-flex mb-4">
        <Box className="flex-shrink-0 profile">
          {comment.user.user_image ? (
            <img
              src={`${process.env.REACT_APP_BASE_URL}${comment.user.user_image}`}
              className="profile-img"
              alt="profile"
            />
          ) : (
            <ProfileImage username={comment.user.user_name} />
          )}
        </Box>
        <Box className="flex-grow-1 ms-3">
          <h5>
            <a href="*">{comment.user.user_name}</a>
            <small className="text-muted ms-3 fs-12">
              {comment.createdAt
                ? `${displayCommentTime(comment.createdAt)}`
                : ""}
            </small>
          </h5>
          <Box
            dangerouslySetInnerHTML={{
              __html: comment.comments,
            }}
          />

          {showReplyButton && nestedComments?.length === 0 && (
            <a
              className="badge text-muted bg-light"
              data-bs-toggle="collapse"
              href={`#collapseExample${comment.id}`}
              aria-expanded={openCommentId === comment.id ? "true" : "false"}
              aria-controls={`#collapseExample${comment.id}`}
              onClick={() => setOpenCommentId(comment.id)}
              id="reply"
            >
              <i className="mdi mdi-reply"></i> Reply
            </a>
          )}

          <Box
            className={`collapse ${openCommentId === comment.id ? "show" : ""}`}
            id={`collapseExample${comment.id}`}
          >
            <Form onSubmit={formik.handleSubmit} style={{ margin: "10px" }}>
              <TextEditor
                value={formik.values.replayComments}
                onChange={(data) => {
                  formik.setFieldValue("replayComments", data);
                  setReplayId(
                    comment.comment_id === null
                      ? comment.id
                      : comment.comment_id
                  );
                }}
              />
              {formik.errors.replayComments && formik.touched.replayComments ? (
                <FormFeedback
                  type="invalid"
                  className="d-block"
                  style={{ color: "red" }}
                >
                  {formik.errors.replayComments}
                </FormFeedback>
              ) : null}

              <Button
                type="submit"
                className="btn btn-primary ms-auto mt-2 send-btn btn-sm"
                data-toggle="collapse"
                data-target={`#collapseExample${comment.id}`}
              >
                Reply <SendIcon />
              </Button>
            </Form>
          </Box>

          {nestedComments && nestedComments.length > 0 && (
            <Box className="replies" style={{ margin: "15px 10px" }}>
              {nestedComments.map((nestedComment, idx, arr) => (
                <Comments
                  key={nestedComment.id}
                  comment={nestedComment}
                  nestedComments={[]}
                  postComments={postComments}
                  ticketId={ticketId}
                  openCommentId={openCommentId}
                  setOpenCommentId={setOpenCommentId}
                  showReplyButton={arr[arr.length - 1].id === nestedComment.id}
                />
              ))}
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default Comments;

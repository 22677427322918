import {
  INITIALNOTIFICATION_LIST,
  LOADMORENOTIFICATION_LIST,
  DEDUCTNOTIFICATION_COUNT,
  INITIAL_NOTIFICATION_READ,
  LOADMORE_NOTIFICATION_READ,
  RESET_INITIALNOTIFICATION_LIST,
} from "./actionType";

export const initList = (notification) => ({
  type: INITIALNOTIFICATION_LIST,
  payload: notification,
});

export const resetInitList = () => ({
  type: RESET_INITIALNOTIFICATION_LIST,
});

export const initalListRead = (notification) => ({
  type: INITIAL_NOTIFICATION_READ,
  payload: notification,
});

export const loadMoreList = (notification) => ({
  type: LOADMORENOTIFICATION_LIST,
  payload: notification,
});

export const loadMoreListRead = (notification) => ({
  type: LOADMORE_NOTIFICATION_READ,
  payload: notification,
});

export const notificationCountDeduct = (unreadCount) => ({
  type: DEDUCTNOTIFICATION_COUNT,
  payload: unreadCount,
});

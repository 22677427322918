import {
    ADD_GENERAL_SETTING_DATA,
    ADD_SETTINGS_DATA,
    SET_TIME_ZONE
} from "./actionType";

export const addSettingsData = (payload) => ({
    type: ADD_SETTINGS_DATA,
    payload,
});

export const addGeneralSettingData = (payload) => ({
    type: ADD_GENERAL_SETTING_DATA,
    payload,
});

export const setTimeZone = (payload) => ({
    type: SET_TIME_ZONE,
    payload,
});

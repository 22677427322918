import React from "react";
import { Modal, Button } from "react-bootstrap";
import { Table } from "reactstrap";
import ProfileImage from "../../../Components/Common/ProfileImage";

const UserDetailModal = ({ show, onHide, developer, team, dataOf }) => {
  const extractTextFromHTML = (htmlData) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlData, "text/html");
    const textContent = doc.body.textContent;
    return textContent;
  };

  const getImageSrc = () => {
    const imageUrl = developer?.image || team?.Image;
    return imageUrl ? `${process.env.REACT_APP_BASE_URL}${imageUrl}` : null;
  };

  return (
    <Modal show={show} onHide={onHide} centered keyboard={false}>
      <Modal.Header>
        <Modal.Title className="text-capitalize">{dataOf} Details</Modal.Title>
      </Modal.Header>
      {/* <Modal.Body>
        <div className="table-responsive table-design">
          <Table className="table-borderless mb-0">
            <tbody>
              <div className="flex-shrink-0">
                {getImageSrc() ? (
                  <>
                    <img
                      src={getImageSrc()}
                      alt="developers"
                      className="rounded-circle"
                      height={100}
                      width={100}
                    />{" "}
                  </>
                ) : (
                  <div>
                    <ProfileImage
                      username={developer?.name || team?.name || ""}
                    />{" "}
                  </div>
                )}
              </div>
              {team?.name || developer?.name ? (
                <tr>
                  <th className="text-nowrap" scope="row">
                    Name:
                  </th>
                  <td className="text-muted">
                    {team?.name}
                    {developer?.name}
                  </td>
                </tr>
              ) : null}
              {team?.contact_no || developer?.contact_no ? (
                <tr>
                  <th className="text-nowrap" scope="row">
                    Mobile:
                  </th>
                  <td className="text-muted">
                    {team?.contact_no}
                    {developer?.contact_no}
                  </td>
                </tr>
              ) : null}
              {team?.email || developer?.email ? (
                <tr>
                  <th className="text-nowrap" scope="row">
                    E-mail:
                  </th>
                  <td className="text-muted">
                    {team?.email}
                    {developer?.email}
                  </td>
                </tr>
              ) : null}
              {team?.designation || developer?.designation ? (
                <tr>
                  <th className="text-nowrap" scope="row">
                    Designation:
                  </th>
                  <td className="text-muted">
                    {team?.designation}
                    {developer?.designation}
                  </td>
                </tr>
              ) : null}
              {team?.organization || developer?.organization ? (
                <tr>
                  <th className="text-nowrap" scope="row">
                    Company Name:
                  </th>
                  <td className="text-muted">
                    {team?.organization}
                    {developer?.organization}
                  </td>
                </tr>
              ) : null}
              {dataOf === "team" && (
                <>
                  {team?.organization_address ? (
                    <tr>
                      <th className="text-nowrap" scope="row">
                        Company Address:
                      </th>
                      <td className="text-muted">
                        {team?.organization_address}
                      </td>
                    </tr>
                  ) : null}
                </>
              )}
              {dataOf === "developer" && (
                <>
                  {developer?.organization_url ? (
                    <tr>
                      <th className="text-nowrap" scope="row">
                        Company URL:
                      </th>
                      <td className="text-muted">
                        {developer?.organization_url}
                      </td>
                    </tr>
                  ) : null}
                  {developer?.location ? (
                    <tr>
                      <th className="text-nowrap" scope="row">
                        Developer Address:
                      </th>
                      <td className="text-muted">{developer?.location}</td>
                    </tr>
                  ) : null}

                  {developer?.description &&
                  extractTextFromHTML(developer.description) ? (
                    <tr>
                      <th className="text-nowrap" scope="row">
                        Description:
                      </th>
                      <td className="text-muted">
                        {extractTextFromHTML(developer.description)}
                      </td>
                    </tr>
                  ) : null}
                </>
              )}
            </tbody>
          </Table>
        </div>
      </Modal.Body> */}
      <Modal.Body>
        <div className="table-responsive table-design">
          <Table className="table-borderless mb-0">
            <tbody>
              <tr>
                <td className="flex-shrink-0">
                  {getImageSrc() ? (
                    <img
                      src={getImageSrc()}
                      alt="developers"
                      className="rounded-circle"
                      height={100}
                      width={100}
                    />
                  ) : (
                    <div>
                      <ProfileImage
                        username={developer?.name || team?.name || ""}
                      />
                    </div>
                  )}
                </td>
              </tr>
              {team?.name || developer?.name ? (
                <tr>
                  <th className="text-nowrap" scope="row">
                    Name:
                  </th>
                  <td className="text-muted">
                    {team?.name}
                    {developer?.name}
                  </td>
                </tr>
              ) : null}
              {team?.contact_no || developer?.contact_no ? (
                <tr>
                  <th className="text-nowrap" scope="row">
                    Mobile:
                  </th>
                  <td className="text-muted">
                    {team?.contact_no && (
                      <a
                        href={`tel:${team.contact_no}`}
                        style={{ marginRight: "8px" }}
                      >
                        {team?.contact_no}
                      </a>
                    )}
                    {developer?.contact_no && (
                      <a
                        href={`tel:${developer.contact_no}`}
                        style={{ marginRight: "8px" }}
                      >
                        {developer?.contact_no}
                      </a>
                    )}
                    {/* {team?.contact_no}
                    {developer?.contact_no} */}
                  </td>
                </tr>
              ) : null}
              {team?.email || developer?.email ? (
                <tr>
                  <th className="text-nowrap" scope="row">
                    E-mail:
                  </th>
                  <td className="text-muted">
                    {team?.email && (
                      <a
                        href={`mailto:${team.email}`}
                        style={{ marginRight: "8px" }}
                      >
                        {team?.email}
                      </a>
                    )}
                    {developer?.email && (
                      <a
                        href={`mailto:${developer.email}`}
                        style={{ marginRight: "8px" }}
                      >
                        {developer?.email}
                      </a>
                    )}
                    {/* {team?.email}
                    {developer?.email} */}
                  </td>
                </tr>
              ) : null}
              {team?.designation || developer?.designation ? (
                <tr>
                  <th className="text-nowrap" scope="row">
                    Designation:
                  </th>
                  <td className="text-muted">
                    {team?.designation}
                    {developer?.designation}
                  </td>
                </tr>
              ) : null}
              {team?.organization || developer?.organization ? (
                <tr>
                  <th className="text-nowrap" scope="row">
                    Company Name:
                  </th>
                  <td className="text-muted">
                    {team?.organization}
                    {developer?.organization}
                  </td>
                </tr>
              ) : null}
              {dataOf === "team" && (
                <>
                  {team?.organization_address ? (
                    <tr>
                      <th className="text-nowrap" scope="row">
                        Company Address:
                      </th>
                      <td className="text-muted">
                        {team?.organization_address}
                      </td>
                    </tr>
                  ) : null}
                </>
              )}
              {dataOf === "developer" && (
                <>
                  {developer?.organization_url ? (
                    <tr>
                      <th className="text-nowrap" scope="row">
                        Company URL:
                      </th>
                      <td className="text-muted">
                        {developer?.organization_url}
                      </td>
                    </tr>
                  ) : null}
                  {developer?.location ? (
                    <tr>
                      <th className="text-nowrap" scope="row">
                        Developer Address:
                      </th>
                      <td className="text-muted">{developer?.location}</td>
                    </tr>
                  ) : null}

                  {developer?.description &&
                  extractTextFromHTML(developer.description) ? (
                    <tr>
                      <th className="text-nowrap" scope="row">
                        Description:
                      </th>
                      <td className="text-muted">
                        {extractTextFromHTML(developer.description)}
                      </td>
                    </tr>
                  ) : null}
                </>
              )}
            </tbody>
          </Table>
        </div>
      </Modal.Body>

      <Modal.Footer>
        <Button className="btn btn-primary" onClick={onHide}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default UserDetailModal;

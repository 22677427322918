import {
  UPDATE_WEBSITEPOLICIES,
  UPDATE_WEBSITEPOLICIES_FAIL,
  UPDATE_WEBSITEPOLICIES_SUCCESS,
  FETCH_WEBSITEPOLICIES,
  FETCH_WEBSITEPOLICIES_SUCCESS,
  FETCH_WEBSITEPOLICIES_FAIL,
} from "./actionType";

export const fetchWebsitePolicies = () => ({
  type: FETCH_WEBSITEPOLICIES,
});

export const fetchWebsitePoliciesSuccess = (websitepolicy) => ({
  type: FETCH_WEBSITEPOLICIES_SUCCESS,
  payload: websitepolicy,
});

export const fetchWebsitePoliciesFail = (error) => ({
  type: FETCH_WEBSITEPOLICIES_FAIL,
  payload: error,
});

export const updateWebsitePolicies = (websitepolicy) => ({
  type: UPDATE_WEBSITEPOLICIES,
  payload: websitepolicy,
});

export const updateWebsitePoliciesSuccess = (websitepolicy) => ({
  type: UPDATE_WEBSITEPOLICIES_SUCCESS,
  payload: websitepolicy,
});

export const updateWebsitePoliciesFail = (error) => ({
  type: UPDATE_WEBSITEPOLICIES_FAIL,
  payload: error,
});

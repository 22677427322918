import axios from "axios";
import React, { useEffect, useCallback, useState } from "react";
import { Button, Card, Col, Row } from "react-bootstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { TextField } from "@mui/material";
import common from "../../Components/Common/common";
import { Spinner } from "reactstrap";

const Security = () => {
  const token = localStorage.getItem("token");
  const [limitedAccess, setLimitedAccessIp] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const intialValues = {
    MAX_LOGIN_ATTEMPTS: "",
    LIMITED_POWERPANEL_ACCESS: "",
    LOGOUT_TIME: "",
    BLOCKED_IP: "",
  };
  const navigate = useNavigate();

  const values = intialValues;

  const validationSchema = Yup.object().shape({
    MAX_LOGIN_ATTEMPTS: Yup.string()
      .max(3, "You reached the max number of length")
      .matches(/^[1-9]+$/, "Please enter digit between 1 to 9")
      .required("Please enter the maximum login attempts"),
    LOGOUT_TIME: Yup.number()
      .typeError("LOGOUT_TIME must be a number")
      .required("LOGOUT_TIME is required"),
    BLOCKED_IP: Yup.string()
      .matches(
        /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/,
        "BLOCKED_IP must be a valid IP address"
      )
      .nullable(),
    //   .required("BLOCKED_IP is required"),
  });

  const PostData = async ({
    MAX_LOGIN_ATTEMPTS,
    BLOCKED_IP,
    LOGOUT_TIME,
    LIMITED_POWERPANEL_ACCESS,
  }) => {
    try {
      setIsLoading(true);
      let url = `${process.env.REACT_APP_BASE_URL}settings/add-settings`;

      let formData = new FormData();
      formData.append("MAX_LOGIN_ATTEMPTS", MAX_LOGIN_ATTEMPTS);
      formData.append("LOGOUT_TIME", LOGOUT_TIME);
      formData.append("BLOCKED_IP", BLOCKED_IP);
      formData.append("LIMITED_POWERPANEL_ACCESS", LIMITED_POWERPANEL_ACCESS);

      let reqData = {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: formData,
      };

      let result = await fetch(url, reqData);
      let response = await result.json();

      if (response.code === 403) {
        navigate("/auth-404-cover");
      }
      if (response.code === "200") {
        toast.success(response.message);
        getSettings();
      }
    } catch (error) {
      setIsLoading(false);
      toast.error("An error occurred while processing your request.");
    } finally {
      setIsLoading(false);
    }
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: values,
    validationSchema: validationSchema,
    onSubmit: async (values, { setSubmitting }) => {
      try {
        await PostData(values);
      } catch (error) {
        console.error(error);
      }
    },
  });

  const getSettings = useCallback(async () => {
    try {
      let url = `${process.env.REACT_APP_BASE_URL}settings/get-settings`;
      let reqData = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };
      let result = await fetch(url, reqData);
      let response = await result.json();

      const decryptedData = common.decrypt(response.data);
      response.data = decryptedData;

      if (response.code === 403) {
        navigate("/auth-404-cover");
      }

      if (response.code === "200") {
        const fieldValuesMap = {};
        response.data.rows.forEach((row) => {
          fieldValuesMap[row.field_name] = row.field_value;
        });
        formik.setValues({
          ...formik.values,
          MAX_LOGIN_ATTEMPTS: fieldValuesMap["MAX_LOGIN_ATTEMPTS"] || "",
          LOGOUT_TIME: fieldValuesMap["LOGOUT_TIME"] || "",
          BLOCKED_IP: fieldValuesMap["BLOCKED_IP"] || "",
        });

        if (fieldValuesMap["LIMITED_POWERPANEL_ACCESS"]) {
          if (fieldValuesMap["LIMITED_POWERPANEL_ACCESS"]) {
            setLimitedAccessIp(
              JSON.parse(fieldValuesMap["LIMITED_POWERPANEL_ACCESS"])
            );
          }
        }
      } else {
        toast.error(response.message);
      }
    } catch (error) {
      toast.error("An error occurred while fetching API key.");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navigate, token]);

  useEffect(() => {
    getSettings();
  }, [getSettings]);

  const handleDelete = async (ind) => {
    try {
      const response = await axios.delete(
        `${process.env.REACT_APP_BASE_URL}settings/delete?fieldName=LIMITED_POWERPANEL_ACCESS&settingName=security&index=${ind}`
      );
      if (response) {
        toast.success(" Limited Access record deleted successfully.");
        getSettings();
      } else {
        toast.error(
          "Failed to delete limited Access data. Please try again later."
        );
      }
    } catch (error) {
      toast.error("Something went wrong. Please try again later.");
    }
  };

  return (
    <React.Fragment>
      <Card>
        <Card.Body className="setting-card-body">
          <form noValidate onSubmit={formik.handleSubmit}>
            <Row className="g-4">
              <Col lg={6}>
                <TextField
                  required
                  fullWidth
                  type="text"
                  label="Max Login Attempts"
                  placeholder="Enter Max Login Attempts"
                  name="MAX_LOGIN_ATTEMPTS"
                  value={formik.values.MAX_LOGIN_ATTEMPTS}
                  {...formik.getFieldProps("MAX_LOGIN_ATTEMPTS")}
                />
              </Col>
              <Col lg={6}>
                <TextField
                  required
                  fullWidth
                  type="text"
                  label="Blocked IP"
                  placeholder="Enter IP"
                  name="BLOCKED_IP"
                  value={formik.values.BLOCKED_IP}
                  {...formik.getFieldProps("BLOCKED_IP")}
                />
                {formik.touched.BLOCKED_IP && formik.errors.BLOCKED_IP ? (
                  <div className="error" style={{ color: "red" }}>
                    {formik.errors.BLOCKED_IP}
                  </div>
                ) : null}
              </Col>
              <Col lg={6}>
                <TextField
                  required
                  fullWidth
                  type="text"
                  label="LOGOUT_TIME"
                  placeholder="Enter log out time"
                  name="LOGOUT_TIME"
                  value={formik.values.LOGOUT_TIME}
                  {...formik.getFieldProps("LOGOUT_TIME")}
                />
                {formik.touched.LOGOUT_TIME && formik.errors.LOGOUT_TIME ? (
                  <div className="error" style={{ color: "red" }}>
                    {formik.errors.LOGOUT_TIME}
                  </div>
                ) : null}
              </Col>
              <Col lg={6}>
                <div
                  style={{
                    display: "flex",
                    flexWrap: "wrap",
                    marginBottom: "10px",
                  }}
                >
                  {limitedAccess &&
                    limitedAccess.map((item, ind) => (
                      <span onClick={() => handleDelete(ind)} key={ind}>
                        {item}{" "}
                        <span style={{ color: "dark", fontSize: "15px" }}>
                          ,
                        </span>
                      </span>
                    ))}

                  <TextField
                    multiline
                    rows={4}
                    fullWidth
                    id="outlined-multiline-static"
                    label="Limited PowerPanel Access: (Enter IP Addresses)"
                    placeholder="Enter Limited PowerPanel Access: (Enter IP Addresses)"
                    name="LIMITED_POWERPANEL_ACCESS"
                    type="text"
                    value={formik.values.LIMITED_POWERPANEL_ACCESS}
                    {...formik.getFieldProps("LIMITED_POWERPANEL_ACCESS")}
                  />
                  <p className="mb-0 mt-2">
                    <strong>Note:</strong> You can enter multiple IP addresses
                    separated by commas (e.g:
                    115.42.150.37,192.168.0.1,110.234.52.124) who will access
                    the PowerPanel.
                  </p>
                </div>
              </Col>
              <Col lg={12}>
                <Button
                  className="btn btn-primary ms-auto d-block"
                  type="submit"
                  disabled={isLoading}
                >
                  {isLoading ? (
                    <Spinner size="sm" variant="light" />
                  ) : (
                    <>Save &amp; Keep Editing</>
                  )}
                </Button>
              </Col>
            </Row>
          </form>
        </Card.Body>
      </Card>
    </React.Fragment>
  );
};

export default Security;

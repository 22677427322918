import * as React from "react";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";

import WebSecurityDetailPage from "./webSecurityDetail/WebSecurityDetailPage";
import { WebSecuTabStyle } from "../NewDashboard/constant";
import WebsiteSecurityHistory from "../NewDashboard/DashboardAnalyticTab/WebsiteSecurity/WebsiteSecurityHistory";

const WebSecurityDetail = () => {
  const [value, setValue] = React.useState("2");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box className="websecurity">
      <TabContext value={value}>
        <Box>
          <TabList
            onChange={handleChange}
            variant="fullWidth"
            sx={WebSecuTabStyle}
          >
            <Tab label="Web. Security History" value="1" />
            <Tab label="Web. Security Scanning" value="2" />
          </TabList>
        </Box>
        <TabPanel value="1" className="tab-panel">
          <WebsiteSecurityHistory />
        </TabPanel>
        <TabPanel value="2" className="tab-panel">
          <WebSecurityDetailPage />
        </TabPanel>
      </TabContext>
    </Box>
  );
};

export default WebSecurityDetail;

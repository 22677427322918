export const ChangePasswordRes = (changePasswordValidation, response, setErrors, setSuccess, navigate, params) => {
    if (response.code === "403") {
        navigate('/auth-404-cover');
    }
    if (response.status === "success" || response.status === "fail") {

        if (response.status === "success") {
            setErrors(null)
            setSuccess(response.message)
            changePasswordValidation.resetForm();
        };

        if (response.status === "fail") {
            setSuccess(null)
            setErrors(response.message)
        };

    }

}

import {
    ADD_USER_DATA_AFTER_LOGIN,
    ADD_USER_PERMISSION_AFTER_LOGIN
} from "./actionType";

export const addUserData = (payload) => ({
    type: ADD_USER_DATA_AFTER_LOGIN,
    payload,
});

export const addUserPermissionData = (payload) => ({
    type: ADD_USER_PERMISSION_AFTER_LOGIN,
    payload,
});

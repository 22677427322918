import { socket } from "../../Components/Common/socket";

export const PostNotesRes = (props) => {
  const {
    response,
    ToastMessage,
    getNotes,
    searchNotes,
    onNotesAdded,
    setModal,
  } = props;
  if (response.status === "success") {
    ToastMessage.Success("Note added successfully");
    socket.emit("addNote");
    getNotes(null, searchNotes);
    setModal(false);
    onNotesAdded();
  }
  if (response.status === "fail") {
    ToastMessage.Error(response.message);
  }
};

import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

//import actions
import {
  changeLayout,
  changeSidebarTheme,
  changeLayoutMode,
  changeLayoutWidth,
  changeLayoutPosition,
  changeTopbarTheme,
  changeLeftsidebarSizeType,
  changeLeftsidebarViewType,
  changeSidebarImageType,
} from "../../store/actions";
import General from "./General";
import SmtpMail from "./SmtpMail";
import Seo from "./Seo";
import Other from "./Other";
import Security from "./Security";
import ApiKey from "./ApiKey";
import WebsiteCategory from "./WebsiteCategory";
import DeveloperSkills from "./DeveloperSkills";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import Tab from "@mui/material/Tab";
import { Box } from "@mui/material";
import { TabStyle } from "../NewDashboard/constant";
import { useLocation } from "react-router-dom";

let columnsData = [
  {
    label: "General",
    value: "1",
    component: <General />,
  },
  {
    label: "SMTP-Mail",
    value: "2",
    component: <SmtpMail />,
  },
  {
    label: "SEO",
    value: "3",
    component: <Seo />,
  },
  {
    label: "Security Settings",
    value: "4",
    component: <Security />,
  },
  {
    label: "Other Settings",
    value: "5",
    component: <Other />,
  },
  {
    label: "Third Party API settings",
    value: "6",
    component: <ApiKey />,
  },
  {
    label: "Website Category",
    value: "7",
    component: <WebsiteCategory />,
  },
  {
    label: "Skills",
    value: "8",
    component: <DeveloperSkills />,
  },
];

const Setting = (props) => {
  const location = useLocation();
  const userData = useSelector((state) => state.userDetails.userData);

  const dispatch = useDispatch();

  const {
    layoutType,
    leftSidebarType,
    layoutModeType,
    layoutWidthType,
    layoutPositionType,
    topbarThemeType,
    leftsidbarSizeType,
    leftSidebarViewType,
    leftSidebarImageType,
  } = useSelector((state) => ({
    layoutType: state.Layout.layoutType,
    leftSidebarType: state.Layout.leftSidebarType,
    layoutModeType: state.Layout.layoutModeType,
    layoutWidthType: state.Layout.layoutWidthType,
    layoutPositionType: state.Layout.layoutPositionType,
    topbarThemeType: state.Layout.topbarThemeType,
    leftsidbarSizeType: state.Layout.leftsidbarSizeType,
    leftSidebarViewType: state.Layout.leftSidebarViewType,
    leftSidebarImageType: state.Layout.leftSidebarImageType,
  }));

  useEffect(() => {
    if (
      layoutType ||
      leftSidebarType ||
      layoutModeType ||
      layoutWidthType ||
      layoutPositionType ||
      topbarThemeType ||
      leftsidbarSizeType ||
      leftSidebarViewType ||
      leftSidebarImageType
    ) {
      dispatch(changeLeftsidebarViewType(leftSidebarViewType));
      dispatch(changeLeftsidebarSizeType(leftsidbarSizeType));
      dispatch(changeSidebarTheme(leftSidebarType));
      dispatch(changeLayoutMode(layoutModeType));
      dispatch(changeLayoutWidth(layoutWidthType));
      dispatch(changeLayoutPosition(layoutPositionType));
      dispatch(changeTopbarTheme(topbarThemeType));
      dispatch(changeLayout(layoutType));
      dispatch(changeSidebarImageType(leftSidebarImageType));
    }
  }, [
    layoutType,
    leftSidebarType,
    layoutModeType,
    layoutWidthType,
    layoutPositionType,
    topbarThemeType,
    leftsidbarSizeType,
    leftSidebarViewType,
    leftSidebarImageType,
    dispatch,
  ]);

  const [settingvalue, setSettingvalue] = useState("1");
  const [filteredData, setFilteredData] = useState([]);

  const handleChangeAssets = (event, newValue) => {
    setSettingvalue(newValue);
  };

  useEffect(() => {
    if (location.state === "Security Settings") {
      setSettingvalue("4");
    }
  }, [location]);

  useEffect(() => {
    const role = userData?.role;
    const filterAdminLabels = ["General", "Skills", "Website Category"];

    if (role === "Super Admin") {
      setFilteredData(columnsData);
    } else if (role === "Admin") {
      const filtered = columnsData.filter((item) =>
        filterAdminLabels.includes(item.label)
      );
      setSettingvalue(filtered[0]?.value || "");
      setFilteredData(filtered);
    }
  }, [userData]);

  return (
    <Box className="setting-page">
      <TabContext value={settingvalue}>
        <Box className="mb-4">
          <TabList
            onChange={handleChangeAssets}
            variant="scrollable"
            scrollButtons
            allowScrollButtonsMobile
            sx={TabStyle}
          >
            {filteredData.map((item) => (
              <Tab key={item.value} label={item.label} value={item.value} />
            ))}
          </TabList>
        </Box>

        {filteredData.map((item) => (
          <TabPanel key={item.value} value={item.value} className="tabpanel">
            {item.component}
          </TabPanel>
        ))}
      </TabContext>
    </Box>
  );
};

export default Setting;

import { useParams } from "react-router-dom";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchAnalyticsRealtimeData,
  fetchAnalyticsRealtimeDurationData,
  
} from "../../../../store/actions";
import Vectormap from "../../../Maps/VectorMaps/MapVector";
import { Box, Skeleton } from "@mui/material";
import { Card } from "react-bootstrap";

//Modified one
const LiveUsersByCountry = ({ HitReport }) => {
  const params = useParams();
  const dispatch = useDispatch();

  const {
    analyticsRealtimeData,
    fetch_analytics_realtime_data_loading,
    fetch_analytics_realtime_duration_data_loading,
  } = useSelector((state) => ({
    analyticsRealtimeData: state?.analyticsData?.analyticsRealtimeData,
    loading: state?.analyticsData?.loading,
    fetch_analytics_realtime_data_loading:
      state?.analyticsData?.fetch_analytics_realtime_data_loading,
    fetch_analytics_realtime_duration_data_loading:
      state?.analyticsData?.fetch_analytics_realtime_duration_data_loading,
  }));

  const { apiDataLoading, apiLoadingAsset } = useSelector(
    (state) => state.onlineAssets
  );

  useEffect(() => {
    // Dispatch actions only if params are defined
    if (params) {
      dispatch(fetchAnalyticsRealtimeData(params));
      dispatch(fetchAnalyticsRealtimeDurationData(params));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Card>
        <Card.Header className="d-flex align-items-center justify-content-between flex-wrap">
          <h4 className="card-title">Live Users By Country</h4>
        </Card.Header>
        {apiDataLoading &&
        apiLoadingAsset === (params?.id && JSON.parse(params?.id)) ? (
          <div className="d-flex flex-column flex-grow-1 justify-content-center align-items-center mt-5">
            <h5 className="card-body text-center">
              We are currently retrieving the analytics data. Please hold on for
              a moment while we gather all the details.
            </h5>
          </div>
        ) : (
          <>
            {" "}
            {HitReport ? (
              <Box className="card-body pb-4">
                <Box className="text-center" style={{ height: "250px" }}></Box>
                <Box className="table-responsive table-card mt-3">
                  <table className="table table-borderless table-sm table-centered align-middle table-nowrap mb-1">
                    <thead className="text-muted border-dashed border border-start-0 border-end-0 bg-soft-light">
                      <tr>
                        <th>Country</th>
                        <th style={{ width: "30%" }}>Live Users</th>
                        <th style={{ width: "30%" }}>Page Views</th>
                      </tr>
                    </thead>
                    <tbody className="border-0">
                      {Array.isArray(analyticsRealtimeData) &&
                        analyticsRealtimeData.length > 0 &&
                        analyticsRealtimeData.map((item, index) => (
                          <tr key={index}>
                            <td>{item.countryName || "-"} </td>
                            <td>{item.liveUsers || "-"} </td>
                            <td>{item.pageViews || "-"}</td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </Box>
              </Box>
            ) : fetch_analytics_realtime_data_loading ||
              fetch_analytics_realtime_duration_data_loading ? (
              <div
                style={{
                  display: "flex",
                  height: "100%",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Skeleton variant="rounded" width={500} height={300}></Skeleton>
              </div>
            ) : (
              <Box className="card-body pb-4">
                <Box style={{ height: "400px" }}>
                  <Vectormap
                    value="world_mill"
                    width="100%"
                    height="200"
                    color="rgb(212, 218, 221)"
                  />
                </Box>
              </Box>
            )}
          </>
        )}
      </Card>
    </>
  );
};

export default LiveUsersByCountry;

export const ADD_POWERPANEL_SITES_ID = "ADD_POWERPANEL_SITES_ID";

//Developer
export const FETCH_DEVELOPERS_REQUEST = "FETCH_DEVELOPERS_REQUEST";
export const FETCH_DEVELOPERS_SUCCESS = "FETCH_DEVELOPERS_SUCCESS";
export const FETCH_DEVELOPERS_FAILURE = "FETCH_DEVELOPERS_FAILURE";

// Fetch Api Data
export const FETCH_API_DATA_REQUEST = "FETCH_API_DATA_REQUEST";
export const FETCH_API_DATA_SUCCESS = "FETCH_API_DATA_SUCCESS";
export const FETCH_API_DATA_FAILURE = "FETCH_API_DATA_FAILURE";

import React, { useCallback, useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import dvdl from "../../../assets/images/dvdl.png";
import { useNavigate, useParams } from "react-router-dom";
import * as Yup from "yup";
import { useFormik } from "formik";
import { ToastMessage } from "../../../Components/Common/ToastMessage";
import OnlineAssetModal from "../../WebsiteManagement/OnlineAssetModal";
import { WebsiteCategoriesListReq } from "../../../Api/WebsiteManagement/OnlineAssets/OnlineAssetsReq";
import { WebsiteCategoriesListRes } from "../../../Api/WebsiteManagement/OnlineAssets/OnlineAssetsRes";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import { registerPlugin } from "react-filepond";
import "filepond/dist/filepond.min.css";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import Skeleton from "@mui/material/Skeleton";
import { Avatar, Badge, Box, Button, IconButton } from "@mui/material";
import { EditIcon, LinkIcon } from "../../../Components/Common/SvgIcons";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { useSelector } from "react-redux";
import { ONLINE_ASSETS } from "../../../Components/Common/ModuleName";
import { socket } from "../../../Components/Common/socket";
import common from "../../../Components/Common/common";
import { formatDateTime } from "../../../Components/Common/FormatDateTIme";

registerPlugin(FilePondPluginImagePreview);
const DvdlHeader = ({ onUpdate }) => {
  const token = localStorage.getItem("token");

  const permissionData = useSelector(
    (state) => state.userDetails.userPermission
  );
  const hasPermission = permissionData?.find(
    (item) => item.module_name === ONLINE_ASSETS
  );

  const generalSettingData = useSelector(
    (state) => state.settingsData.settingsData
  );

  const otherSettingsData = generalSettingData?.filter(
    (item) => item.settings_category === "other"
  );

  const dateFormat = otherSettingsData?.find(
    (date) => date.field_name === "DEFAULT_DATE_FORMAT"
  )?.field_value;

  const timeZone = otherSettingsData?.find(
    (time) => time.field_name === "DEFAULT_TIME_FORMAT"
  )?.field_value;

  const timeFormat = otherSettingsData?.find(
    (zone) => zone.field_name === "DEFAULT_TIME_ZONE"
  )?.field_value;

  const [show, setShow] = useState(false);
  const [detailShow, setDetailShow] = useState(false);
  const [data, setData] = useState("");
  // const [websiteCategory, setWebsiteCategory] = useState([]);
  const [selectedCoverImage, setSelectedCoverImage] = useState(null);
  const [assetTypeOpt, setAssetTypeOpt] = useState(null);
  const [websiteGroupOpt, setWebsiteGroupOpt] = useState(null);
  const [dataLoading, setDataLoading] = useState(false);
  const [postIsLoading, setPostIsLoading] = useState(false);

  const navigate = useNavigate();
  const params = useParams();

  const validation = useFormik({
    enableReinitialize: true,

    initialValues: {
      website_logo: data ? data.website_logo : null,
      website_short_name: data ? data.website_short_name : "",
      website_full_name: data ? data.website_full_name : "",
      organization: data ? data.organization : "",
      assets_type: data ? data.assets_type : "",
      website_url: data ? data.website_url : "",
      department_id: data ? data.department_id : "",
      ga_view_id: data ? data.ga_view_id : "",
      status: data ? data.status : "Active",
      short_description: data ? data.short_description : "",
    },

    validationSchema: Yup.object({
      organization: Yup.string()
        .min(5, "Organization short name must be at least 5 characters.")
        .max(255, "Organization short name must be less than 255 characters."),
      assets_type: Yup.string().required("Please select asset type."),
      website_url: Yup.string()
        .test("is-valid-url", "Invalid URL format.", (value) => {
          if (!value) {
            return true;
          }
          const urlPattern =
            /^(https?:\/\/)?(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/g;
          return urlPattern.test(value);
        })
        .min(5, "Website URL must be at least 5 characters.")
        .max(255)
        .required("Please enter website URL."),
      website_short_name: Yup.string()
        .min(5, "Website short name must be at least 5 characters.")
        .max(255)
        .required("Please enter website short name."),
      website_full_name: Yup.string()
        .min(5, "Website Full Name must be at least 5 characters.")
        .max(255)
        .required("Please enter website full name."),
      department_id: Yup.string().required("Please select website category."),
      ga_view_id: Yup.string()
        .min(5, "Google Analytics ID must be at least 5 characters.")
        .max(255)
        .required("Please enter google analytics ID."),
      short_description: Yup.string()
        .min(5, "Short Description must be at least 5 characters.")
        .max(255, "Short Description must be less than 255 characters."),
    }),

    onSubmit: (values) => {
      updateUsers(values);
    },
  });

  const handleClose = () => {
    validation.setFieldValue("departmentImage", null);
    setShow(false);
    handleDetailShow(false);
    setDetailShow(false);
    setAssetTypeOpt(null);
    setSelectedCoverImage(false);
  };

  const handleShow = () => setShow(true);
  const handleDetailShow = () => setDetailShow(true);

  const fetchData = useCallback(async () => {
    try {
      setDataLoading(true);
      const reqData = {
        assets_id: params.id,
      };
      let userData = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(reqData),
      };
      let result = await fetch(
        `${process.env.REACT_APP_BASE_URL}online-assets/online-assets-list`,
        userData
      );
      let response = await result.json();

      if (response.code === 403) {
        navigate("/auth-404-cover");
      }
      if (response.status === "success") {
        const responseData = common.decrypt(response.data);
        setData(responseData);
        setDataLoading(false);
      }
      if (response.status === "fail") {
        console.error("response", response.status);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setDataLoading(false);
    }
  }, [navigate, params.id, token]);

  // const getWebsiteData = useCallback(async () => {
  //   try {
  //     const response = await axios.get(
  //       `${process.env.REACT_APP_BASE_URL}websiteCategories/get-category`
  //     );

  //     if (response.status === "success" && response.data?.length > 0) {
  //       const formattedData = response.data.map((item) => ({
  //         id: item.id,
  //         category: item.category,
  //       }));
  //       setWebsiteCategory(formattedData);
  //     } else {
  //       console.error("No data found for website categories.");
  //     }
  //   } catch (error) {
  //     console.error("Error fetching website data:", error);
  //   }
  // }, []);

  useEffect(() => {
    fetchData();
    // getWebsiteData();
    // eslint-disable-next-line
  }, [fetchData]);

  const [websiteGroupOptions, setWebsiteGroupOptions] = useState([]);

  useEffect(() => {
    const fetchWebsiteCategories = async () => {
      try {
        const { url, reqData } = WebsiteCategoriesListReq();

        const response = await fetch(url, reqData);
        const result = await response.json();

        WebsiteCategoriesListRes({ result, setWebsiteGroupOptions });
      } catch (err) {
        console.error(err);
        setWebsiteGroupOptions([]);
      }
    };

    fetchWebsiteCategories();
  }, []);

  const updateUsers = async (values) => {
    setPostIsLoading(true);
    try {
      let data = new FormData();
      if (values.website_logo && values.website_logo.constructor === File) {
        data.append("website_logo", values.website_logo);
      }
      data.append("website_short_name", values.website_short_name);
      data.append("website_full_name", values.website_full_name);
      data.append("organization", values.organization);
      data.append("assets_type", values.assets_type);
      data.append("short_description", values.short_description);
      data.append("department_id", values.department_id);
      data.append("website_url", values.website_url);
      data.append("ga_view_id", values.ga_view_id);
      data.append("status", values.status);
      let reqData = {
        method: "PUT",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: data,
      };

      let result = await fetch(
        `${process.env.REACT_APP_BASE_URL}online-assets/online-assets-update/${params.id}`,
        reqData
      );

      let response = await result.json();

      if (response.status === "success") {
        socket.emit("updateOnlineAsset");
        ToastMessage.Success("Assets details have been updated successfully.");
        setDetailShow(false);
        fetchData();
        onUpdate();
        setPostIsLoading(false);
      } else {
        setPostIsLoading(false);
        ToastMessage.Error("An error occurred. Please try again later.");
      }
    } catch (error) {
      setPostIsLoading(false);
      console.error("Error updating Assets details", error);
    } finally {
      setPostIsLoading(false);
    }
  };

  const handleSaveChanges = () => {
    validation.handleSubmit();
    handleClose();
  };

  const handleChangeCoverImage = (event) => {
    const file = event.target.files[0];
    if (file) {
      const allowedFormats = [
        "image/jpeg",
        "image/jpg",
        "image/png",
        "image/svg+xml",
      ];
      const validSize = file.size <= 1024 * 1024;

      if (!allowedFormats.includes(file.type)) {
        validation.setFieldError(
          "website_logo",
          "Invalid file format. Only JPEG, JPG, PNG, and SVG files are allowed."
        );
        event.target.value = null;
      } else if (!validSize) {
        validation.setFieldError(
          "website_logo",
          "Failed to upload an image. The image maximum size is 1MB."
        );
        event.target.value = null;
      } else {
        validation.setFieldError("website_logo", "");
        validation.setFieldValue("website_logo", file);
        const imageUrl = URL.createObjectURL(file);

        setSelectedCoverImage(imageUrl);
      }
    }
  };

  const handleClick = () => {
    const url = data?.website_url || "";
    if (url) {
      window.open(url, "_blank", "noopener,noreferrer");
    }
  };

  return (
    <React.Fragment>
      <Box className="row g-4 assets-top-details">
        <Box className="col-12 col-xl-5" xs={12} xxl={5}>
          {/* <Box className="avatar-md">
          <Box className="avatar-title bg-white rounded">
            <Link onClick={handleShow} className="p-0 border-0">
              {dataLoading ? (
                <Skeleton variant="circular" width={60} height={60} />
              ) : (
                <>
                  {data.website_logo != null ? (
                    <img
                      src={`${process.env.REACT_APP_BASE_URL}${data.website_logo}`}
                      alt=""
                      className="img-fluid"
                    />
                  ) : (
                    <img src={dvdl} alt="" className="img-fluid" />
                  )}
                </>
              )}
            </Link>
          </Box>
        </Box> */}
          <Box className="left-side">
            {dataLoading ? (
              <Skeleton variant="circular" width={70} height={70} />
            ) : (
              // <Badge
              //   overlap="circular"
              //   anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
              //   badgeContent={
              //     <span className="cursor-pointer" onClick={handleShow}>
              //       <EditIcon />
              //     </span>
              //   }
              //   sx={{
              //     "& .MuiBadge-badge": {
              //       width: "24px",
              //       height: "24px",
              //       background: "#9ea3ab",
              //       outline: "2px solid #fff",
              //       padding: "4px",
              //       borderRadius: "50%",
              //       "svg path": {
              //         fill: "#fff",
              //       },
              //       svg: {
              //         width: "12px",
              //       },
              //     },
              //   }}
              // >
              //   <Avatar
              //     alt="company logo"
              //     src={`${process.env.REACT_APP_BASE_URL}${data.website_logo}`}
              //     sx={{ width: 74, height: 74, background: "#1A2232" }}
              //   />
              // </Badge>
              <>
                {hasPermission && hasPermission?.canModify != null ? (
                  <Badge
                    overlap="circular"
                    anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                    badgeContent={
                      <span className="cursor-pointer" onClick={handleShow}>
                        <EditIcon />
                      </span>
                    }
                    sx={{
                      "& .MuiBadge-badge": {
                        width: "24px",
                        height: "24px",
                        background: "#9ea3ab",
                        outline: "2px solid #fff",
                        padding: "4px",
                        borderRadius: "50%",
                        "svg path": {
                          fill: "#fff",
                        },
                        svg: {
                          width: "12px",
                        },
                      },
                    }}
                  >
                    <Avatar
                      alt="company logo"
                      src={`${process.env.REACT_APP_BASE_URL}${data.website_logo}`}
                      sx={{ width: 74, height: 74, background: "#1A2232" }}
                    />
                  </Badge>
                ) : (
                  <Avatar
                    alt="company logo"
                    src={`${process.env.REACT_APP_BASE_URL}${data.website_logo}`}
                    sx={{ width: 74, height: 74, background: "#1A2232" }}
                  />
                )}
              </>
            )}
            <Box>
              {dataLoading ? (
                <Skeleton variant="text" width={100} height={30} />
              ) : (
                <h4 className="company-name">
                  {data?.website_short_name || ""}
                </h4>
              )}
              <Box className="hstack gap-3 flex-wrap">
                {dataLoading ? (
                  <>
                    <Skeleton variant="text" width={40} height={30} />
                    <Skeleton variant="text" width={60} height={30} />
                  </>
                ) : (
                  <>
                    <Box className="badge rounded-pill bg-success fs-12 py-2 px-3">
                      <span className="fw-medium">{data?.status || ""}</span>
                    </Box>
                  </>
                )}
                {/* <Box className="vr"></Box> */}
                {dataLoading ? (
                  <Skeleton variant="text" width={100} height={30} />
                ) : (
                  <>
                    <span className="fw-medium fs-15">
                      Create Date :&nbsp;
                      {data &&
                        formatDateTime(
                          data.createdAt,
                          dateFormat,
                          timeZone,
                          timeFormat
                        ).timeZone.formattedDateFinal}
                    </span>
                  </>
                )}
              </Box>
            </Box>
          </Box>
        </Box>
        <Box className="col-12 col-xl-7" xs={12} xxl={7}>
          {dataLoading ? (
            <Skeleton
              variant="text"
              width={200}
              height={30}
              style={{ marginTop: "25px", marginRight: "40px" }}
            />
          ) : (
            <Box className="web-link">
              {/* <Box role="alert d-flex align-items-center"> */}
              <a
                href={data?.website_url || ""}
                target="_blank"
                rel="noreferrer"
              >
                {data?.website_url || ""}
              </a>
              <IconButton
                onClick={handleClick}
                style={{
                  color: "#98a2b5",
                  marginLeft: "20px",
                }}
                sx={{
                  "&.MuiButtonBase-root": {
                    "svg path": {
                      fill: "#98a2b5",
                      stroke: "#98a2b5",
                    },
                  },
                }}
              >
                <LinkIcon width={24} height={24} />
              </IconButton>
              {/* </Box> */}
            </Box>
          )}
        </Box>
        <Box className="col-12" xs={12}>
          <Box className="company-info">
            {dataLoading ? (
              <>
                <Skeleton variant="text" width={900} height={30} />
                <Skeleton variant="text" width={500} height={30} />
              </>
            ) : (
              <>
                <p className="mb-0 text-break">{data.short_description}</p>
              </>
            )}
            {hasPermission && hasPermission?.canModify != null ? (
              <>
                <Button className="edit-button" onClick={handleDetailShow}>
                  <EditIcon />
                </Button>
              </>
            ) : (
              ""
            )}
          </Box>
        </Box>
      </Box>
      <Modal show={show} onHide={handleClose}>
        <Modal.Body className="text-center p-3 position-relative">
          <Box className="profile-setting-img">
            {selectedCoverImage || data.website_logo ? (
              <img
                name="website_logo"
                src={
                  selectedCoverImage ||
                  `${process.env.REACT_APP_BASE_URL}${data.website_logo}`
                }
                className="profile-wid-img"
                alt="user-profile"
                style={{ height: "100%", width: "100%" }}
              />
            ) : (
              <img
                name="website_logo"
                src={dvdl}
                className="profile-wid-img"
                alt="user-profile"
              />
            )}
            <Box className="overlay-content">
              <Box className="text-end">
                <Box className="p-0 ms-auto rounded-circle profile-photo-edit">
                  <input
                    id="profile-cover-img-file-input"
                    type="file"
                    className="profile-foreground-img-file-input"
                    name="website_logo"
                    accept="image/*"
                    onChange={(e) => {
                      handleChangeCoverImage(e);
                      validation.setFieldTouched("website_logo", true, false);
                    }}
                    onBlur={validation.handleBlur}
                  />
                  <label
                    htmlFor="profile-cover-img-file-input"
                    className="profile-photo-edit d-flex gap-2"
                  >
                    <CloudUploadIcon />
                    Upload
                  </label>
                </Box>
              </Box>
              {validation.errors.website_logo &&
              validation.touched.website_logo ? (
                <span style={{ color: "red" }}>
                  {validation.errors.website_logo}
                </span>
              ) : null}
            </Box>
          </Box>
        </Modal.Body>
        <Modal.Footer>
          <button className="btn btn-primary" onClick={handleClose}>
            Close
          </button>
          <button
            className="btn btn-dark"
            type="submit"
            onClick={handleSaveChanges}
          >
            Save Changes
          </button>
        </Modal.Footer>
      </Modal>

      <OnlineAssetModal
        show={detailShow}
        id={params.id}
        validation={validation}
        postIsLoading={postIsLoading}
        handleClose={handleClose}
        isAssetDetail={true}
        assetTypeOpt={assetTypeOpt}
        setAssetTypeOpt={setAssetTypeOpt}
        websiteGroupOptions={websiteGroupOptions}
        websiteGroupOpt={websiteGroupOpt}
        setWebsiteGroupOpt={setWebsiteGroupOpt}
      />
    </React.Fragment>
  );
};

export default DvdlHeader;

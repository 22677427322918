import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Form, FormFeedback, Label, Input } from "reactstrap";
import "react-toastify/dist/ReactToastify.css";
import { useFormik } from "formik";
import * as Yup from "yup";
import { ForgetPasswordReq } from "../../../Api/Authentication/ForgetPasswordReq";
import { ForgetPasswordRes } from "../../../Api/Authentication/ForgetPasswordRes";
import { Box } from "@mui/material";

const CoverPasswReset = () => {
  const navigate = useNavigate();
  const [errors, setErrors] = useState(null);
  const [extra, setExtra] = useState(null);
  const [success, setSuccess] = useState(null);

  const validation = useFormik({
    enableReinitialize: true,

    initialValues: {
      email: "",
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .required("Email is required")
        .email("Must be a valid email"),
    }),
    onSubmit: (values) => {
      procategory(values);
    },
  });

  const procategory = async (values) => {
    try {
      let { url, reqData } = ForgetPasswordReq(values);

      let result = await fetch(url, reqData);
      let response = await result.json();

      ForgetPasswordRes({
        response,
        setExtra,
        setErrors,
        setSuccess,
        validation,
      });
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <React.Fragment>
      <Box className="right-side">
        <Box>
          <h5 className="main-title">Forgot Password?</h5>
          <p className="main-subtitle">Reset password with Controlbase.</p>

          <Box className="mt-2 text-center">
            <lord-icon
              src="https://cdn.lordicon.com/rhvddzym.json"
              trigger="loop"
              colors="primary:#0ab39c"
              className="avatar-xl"
              style={{ width: "120px", height: "120px" }}
            ></lord-icon>
          </Box>

          <Box
            className="alert alert-borderless alert-warning text-center mb-2 mx-2"
            role="alert"
          >
            Enter your email and instructions will be sent to you!
          </Box>
          <Box
            className={
              !errors
                ? "d-none"
                : "alert alert-borderless alert-danger text-center mb-2 mx-2"
            }
            role="alert"
          >
            {errors && errors} <br /> {extra !== null && ` ${extra}`}
          </Box>
          <Box
            className={
              !success
                ? "d-none"
                : "alert alert-borderless alert-success text-center mb-2 mx-2"
            }
            role="alert"
          >
            {success && success}
          </Box>
        </Box>

        <Box className="form-container">
          <Form onSubmit={validation.handleSubmit}>
            <Box className="mb-4">
              <Label className="form-label">Email</Label>
              <Input
                type="email"
                className="form-control"
                id="email"
                placeholder="Enter email address"
                name="email"
                value={validation.values.email}
                onBlur={validation.handleBlur}
                onChange={validation.handleChange}
                invalid={
                  validation.errors.email && validation.touched.email
                    ? true
                    : false
                }
              />
              {validation.errors.email && validation.touched.email ? (
                <FormFeedback type="invalid">
                  {validation.errors.email}
                </FormFeedback>
              ) : null}
            </Box>

            <Box className="mt-5">
              <Button color="primary" className="submit-btn" type="submit">
                Send Reset Link
              </Button>
            </Box>
          </Form>
        </Box>

        <Box className="mt-5 text-center">
          <p className="mb-0 text-muted">
            Wait, I remember my password...{" "}
            <span
              onClick={() => navigate("/login")}
              className="fw-bold text-primary text-decoration-underline cursor-pointer"
            >
              Click headers
            </span>
          </p>
        </Box>
      </Box>
    </React.Fragment>
  );
};

export default CoverPasswReset;

import React, { useCallback, useEffect, useState } from "react";

import { Card, CardBody } from "reactstrap";
import CountUp from "react-countup";
import FeatherIcon from "feather-icons-react";
import { Box, IconButton, Skeleton } from "@mui/material";
import ChevronRightRoundedIcon from "@mui/icons-material/ChevronRightRounded";
import { useDispatch, useSelector } from "react-redux";
import { addCompliance, fetchDevelopersProfile } from "../../store/actions";
import common from "../../Components/Common/common";
import { useFormik } from "formik";
import { ToastMessage } from "../../Components/Common/ToastMessage";
import {
  Button,
  Col,
  Form,
  FormControl,
  InputGroup,
  Modal,
  ModalHeader,
  ModalTitle,
  Row,
} from "react-bootstrap";
import Select from "react-select";
import { ListHitReportReq } from "../../Api/Reporting/HitReport/HitReportReq";
import { ListHitReportRes } from "../../Api/Reporting/HitReport/HitReportRes";
import ExtractDomain from "../../Components/Common/extractDomain";
import {
  getAllOnlineAssets,
  getAllUserData,
  getBrandingGuideline,
  getWebsiteGuideline,
} from "../../helpers/Help/CommomApi";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import axios from "axios";
import { toast } from "react-toastify";
// import { ComplianceDocumentIcon } from "../../Components/Common/SvgIcons";

const TileBox = ({
  tileBoxTickets = [],
  isDashboardView = true,
  settings = {},
}) => {
  const token = localStorage.getItem("token");
  const id = localStorage.getItem("user_id");
  const dispatch = useDispatch();

  const [data, setData] = useState([]);
  const [query, setQuery] = useState("");
  const [dataLoading, setDataLoading] = useState(false);

  const fetchHItReportData = useCallback(
    async (startDate, endDate, query, isDashboardView = true) => {
      try {
        setDataLoading(true);
        const { url, requestOptions } = ListHitReportReq({
          query,
          startDate,
          endDate,
          token,
          isDashboardView,
        });

        const response = await fetch(url.toString(), requestOptions);
        const result = await response.json();
        const responseData = common.decrypt(result.data);
        ListHitReportRes({ result, response, setData, responseData });
        setDataLoading(false);
      } catch (error) {
        setDataLoading(false);
        return null;
      }
    },
    [token]
  );
  useEffect(() => {
    fetchHItReportData(null, null, query, isDashboardView);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchHItReportData]);

  var [usersHits, setUsersHits] = useState([]);
  const [LodingSetUsersHits, setLoadingSetUsersHits] = useState(false);
  var [allWebsites, setAllWebsites] = useState([]);
  const [loadingAllWebsites, setLoadingSetAllWebsites] = useState(false);

  // Tickets Tile Box
  const ActiveTicket = tileBoxTickets.active?.length || 0;
  const InactiveTicket = tileBoxTickets.inactive?.length || 0;

  const currentDate = new Date();

  // Combine active and inactive tickets into one array
  const allTickets = [
    ...(tileBoxTickets.active || []),
    ...(tileBoxTickets.inactive || []),
  ];

  // Tickets for the current month
  const currentMonthTickets = allTickets.filter((ticket) => {
    const ticketDate = new Date(ticket.createdAt);
    return (
      ticketDate.getMonth() === currentDate.getMonth() &&
      ticketDate.getFullYear() === currentDate.getFullYear()
    );
  });

  // Tickets for the previous month
  const previousMonthTickets = allTickets.filter((ticket) => {
    const ticketDate = new Date(ticket.createdAt);
    const previousMonth = currentDate.getMonth() - 1;
    const previousYear =
      previousMonth < 0
        ? currentDate.getFullYear() - 1
        : currentDate.getFullYear();
    return (
      ticketDate.getMonth() === (previousMonth + 12) % 12 &&
      ticketDate.getFullYear() === previousYear
    );
  });

  // Count active tickets for the current month
  const currentMonthActiveCount = currentMonthTickets.filter(
    (currentTicket) => currentTicket.status === "active"
  ).length;

  // Count active tickets for the previous month
  const previousMonthActiveCount = previousMonthTickets.filter(
    (previousTicket) => previousTicket.status === "active"
  ).length;

  // Calculate percentage difference
  const percentage =
    previousMonthActiveCount === 0
      ? 0
      : ((currentMonthActiveCount - previousMonthActiveCount) /
          previousMonthActiveCount) *
        100;

  const absolutePercentage = Math.abs(percentage);

  // Active Users

  const fetchData = useCallback(async () => {
    try {
      setLoadingSetUsersHits(true);
      let response = await getAllUserData();

      if (response.status === "success") {
        const responseData = common.decrypt(response.data);
        setUsersHits(responseData);
      }
    } catch (error) {
      console.error(error.message);
    } finally {
      setLoadingSetUsersHits(false);
    }
  }, []);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const currentMonthUsers = usersHits.filter((user) => {
    const userDate = new Date(user.createdAt);
    return (
      userDate.getMonth() === currentDate.getMonth() &&
      userDate.getFullYear() === currentDate.getFullYear()
    );
  });

  const previousMonthUsers = usersHits.filter((user) => {
    const userDate = new Date(user.createdAt);
    const userMonth = userDate.getMonth();
    const userYear = userDate.getFullYear();

    const currentMonth = currentDate.getMonth();
    const currentYear = currentDate.getFullYear();
    if (currentMonth === 0) {
      return userMonth === 11 && userYear === currentYear - 1;
    } else {
      return userMonth === currentMonth - 1 && userYear === currentYear;
    }
  });

  const percentageDifference =
    previousMonthUsers.length === 0
      ? 0
      : ((currentMonthUsers.length - previousMonthUsers.length) /
          Math.abs(previousMonthUsers.length)) *
        100;

  const usersInCurrentMonth = usersHits.filter((user) => {
    const userCreatedAt = new Date(user.createdAt);
    return (
      userCreatedAt.getMonth() === currentDate.getMonth() &&
      userCreatedAt.getFullYear() === currentDate.getFullYear()
    );
  });

  const daysInCurrentMonth = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth() + 1,
    0
  ).getDate();
  const averageUsersPerWeek =
    usersInCurrentMonth.length / (daysInCurrentMonth / 30);

  let finalResult;
  if (averageUsersPerWeek === 0) {
    finalResult = 0;
  } else if (averageUsersPerWeek > 0 && averageUsersPerWeek < 1) {
    finalResult = 1;
  } else {
    finalResult = Math.round(averageUsersPerWeek);
  }

  /////////////////////////////////////////////////////////////

  // ACTIVE WEBSITES //

  const fetchWebsites = useCallback(async () => {
    try {
      setLoadingSetAllWebsites(true);
      let response = await getAllOnlineAssets();
      const responseData = common.decrypt(response.data);
      if (response.status === "success") {
        setAllWebsites(responseData);
      }
    } catch (error) {
      console.error(error.message);
    } finally {
      setLoadingSetAllWebsites(false);
    }
  }, []);

  useEffect(() => {
    fetchWebsites();
  }, [fetchWebsites]);

  const websiteCounts = allWebsites?.reduce(
    (accumulator, currentWebsite) => {
      if (currentWebsite.status === "Active") {
        accumulator.active++;
      } else if (currentWebsite.status === "In-Active") {
        accumulator.inActive++;
      }
      return accumulator;
    },
    { active: 0, inActive: 0 }
  );

  // const currentMonthWebsites = allWebsites?.filter(
  //   (website) =>
  //     new Date(website.createdAt).getMonth() === currentDate.getMonth()
  // );
  const currentMonthWebsites = allWebsites?.filter((website) => {
    const websiteDate = new Date(website.createdAt);
    return (
      websiteDate.getMonth() === currentDate.getMonth() &&
      websiteDate.getFullYear() === currentDate.getFullYear()
    );
  });

  // const previousMonthWebsites = allWebsites?.filter((website) => {
  //   const websiteDate = new Date(website.createdAt);
  //   const websiteMonth = websiteDate.getMonth();
  //   const currentMonth = currentDate.getMonth();

  //   return websiteMonth === (currentMonth === 0 ? 11 : currentMonth - 1);
  // });
  const previousMonthWebsites = allWebsites?.filter((website) => {
    const websiteDate = new Date(website.createdAt);
    const websiteMonth = websiteDate.getMonth();
    const websiteYear = websiteDate.getFullYear();

    const currentMonth = currentDate.getMonth();
    const currentYear = currentDate.getFullYear();

    if (currentMonth === 0) {
      return websiteMonth === 11 && websiteYear === currentYear - 1;
    } else {
      return websiteMonth === currentMonth - 1 && websiteYear === currentYear;
    }
  });

  const WebsitePercentageDifference =
    previousMonthWebsites?.length === 0
      ? 0
      : ((currentMonthWebsites?.length - previousMonthWebsites?.length) /
          Math.abs(previousMonthWebsites?.length)) *
        100;

  // compliance

  const [lgShow, setLgShow] = useState(false);
  const [developersSelectedOptions, setDevelopersSelectedOptions] = useState(
    []
  );
  const [websiteSelectedOptions, setWebsiteSelectedOptions] = useState([]);
  const [brandingSelectedOptions, setBrandingSelectedOptions] = useState([]);
  const [showWebsiteGuidelineSelect, setShowWebsiteGuidelineSelect] =
    useState(false);
  const [showBrandingGuidelineSelect, setShowBrandingGuidelineSelect] =
    useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [websiteGuideline, setWebsiteGuideline] = useState({});
  const [brandingGuideline, setBrandingGuideline] = useState({});

  const { developersProfileDetails } = useSelector(
    (state) => state.developersProfile
  );

  // const developersProfileDetail = common?.decrypt(developersProfileDetails);
  const { addComplianceError, addComplianceLoading } = useSelector(
    (state) => state.compliance
  );

  const getData = useCallback(async () => {
    try {
      let response = await getWebsiteGuideline();
      if (response.status === "success") {
        const responseData = common.decrypt(response.data);
        let guidelineOptions = responseData?.map((item) => ({
          label: item.website_guideline_title,
          value: item.id,
        }));
        setWebsiteGuideline(guidelineOptions);
      }
    } catch (error) {
      return null;
    }
  }, []);

  const getBrandingData = useCallback(async () => {
    try {
      let response = await getBrandingGuideline();
      if (response.status === "success") {
        const responseData = common.decrypt(response.data);
        let brandingOptions = responseData.map((item) => ({
          label: item.branding_guideline_title,
          value: item.id,
        }));
        setBrandingGuideline(brandingOptions);
      }
    } catch (error) {
      return null;
    }
  }, []);

  useEffect(() => {
    getData();
    getBrandingData();
  }, [getData, getBrandingData]);

  useEffect(() => {
    dispatch(fetchDevelopersProfile());
  }, [dispatch]);

  const complianceValidation = useFormik({
    enableReinitialize: true,

    initialValues: {
      website_guideline_id: null,
      branding_guideline_id: null,
      website_policy: false,
      developers_id: [],
      other_email: "",
    },

    validate: (values) => {
      const errors = {};

      if (showWebsiteGuidelineSelect && !values.website_guideline_id) {
        errors.website_guideline_id = "Website Guideline is required.";
      }

      if (showBrandingGuidelineSelect && !values.branding_guideline_id) {
        errors.branding_guideline_id = "Branding Guideline is required.";
      }

      if (
        complianceValidation.touched.developers_id &&
        values.developers_id.length <= 0
      ) {
        errors.developers_id = "Developers is required.";
      } else {
        delete errors.developers_id;
      }

      if (values.developers_id.some((i) => i === "other")) {
        delete errors.other_email;
        if (!values.other_email) {
          errors.other_email = "Please type the others email id.";
        } else {
          delete errors.other_email;
        }
      } else {
        delete errors.other_email;
      }

      if (values.developers_id.length === 0) {
        if (!values.other_email) {
          errors.other_email = "Please type the others email id.";
        } else {
          delete errors.other_email;
        }
      }

      return errors;
    },

    onSubmit: (values) => {
      if (addComplianceError !== null) {
        ToastMessage.Error(addComplianceError);
      }

      setErrorMessage(null);
      dispatch(addCompliance(values));
      if (addComplianceLoading === false) {
        handleClose();
        ToastMessage.Success("compliance sent successfully.");
      }
    },
  });

  const handleWebsiteSelectChange = (selectedOptions) => {
    complianceValidation.setFieldValue(
      "website_guideline_id",
      selectedOptions.map((item) => item.value)
    );
    setWebsiteSelectedOptions(selectedOptions);
  };

  const handleBrandingSelectChange = (selectedOptions) => {
    complianceValidation.setFieldValue(
      "branding_guideline_id",
      selectedOptions.map((item) => item.value)
    );
    setBrandingSelectedOptions(selectedOptions);
  };

  const handleWebsiteSwitchChange = (selected) => {
    setShowWebsiteGuidelineSelect(selected.target.checked);
    if (!selected.target.checked) {
      setWebsiteSelectedOptions([]);
    }
  };

  const handleBrandingSwitchChange = (selected) => {
    setShowBrandingGuidelineSelect(selected.target.checked);
    if (!selected.target.checked) {
      setBrandingSelectedOptions([]);
    }
  };

  const handlePolicySwitchChange = async (selected) => {
    complianceValidation.setFieldValue(
      "website_policy",
      selected.target.checked
    );
  };

  const handleDevelopersSelectChange = (selected) => {
    const isSelected = selected && selected.some((i) => i.label === "other");

    if (isSelected) {
      setDevelopersSelectedOptions(selected.filter((i) => i.label === "other"));
      complianceValidation.setFieldValue(
        "developers_id",
        selected.map((item) => item.value)
      );
    } else {
      setDevelopersSelectedOptions(selected.filter((i) => i.label !== "other"));
      complianceValidation.setFieldValue(
        "developers_id",
        selected.map((item) => item.value)
      );
    }
  };

  const handleClose = () => {
    setLgShow(false);
    setErrorMessage(null);
    setBrandingSelectedOptions([]);
    setWebsiteSelectedOptions([]);
    setDevelopersSelectedOptions([]);
    setShowWebsiteGuidelineSelect(false);
    setShowBrandingGuidelineSelect(false);
    complianceValidation.resetForm();
    setQuery("");
  };

  const handleSubmit = () => {
    if (
      !complianceValidation.values.website_guideline_id &&
      !complianceValidation.values.branding_guideline_id &&
      !complianceValidation.values.website_policy
    ) {
      setErrorMessage(
        "Please select at least one value in Website Guideline, Branding Guideline, and Website Policies."
      );
    } else {
      setErrorMessage(null);
      complianceValidation.submitForm();
    }
  };

  // const handleRedirect = (id) => {
  //   switch (id) {
  //     case 1:
  //       navigate("/online-assets");
  //       break;
  //     case 2:
  //       navigate("/core-users");
  //       break;
  //     case 3:
  //       navigate("/ticketing-system");
  //       break;
  //     case 4:
  //       navigate("/hit-report");
  //       break;
  //     default:
  //       break;
  //   }
  // };

  const tileBoxs3 = [
    {
      id: 1,
      label: "Active / In-Active Websites",
      bgColor:
        "transparent linear-gradient(297deg, #E77B72 0%, #DF4F43 100%) 0% 0% no-repeat padding-box",
      labelClass: "muted",
      badgeClass:
        WebsitePercentageDifference >= 0
          ? "bg-light text-success"
          : "bg-light text-info",
      badge:
        WebsitePercentageDifference > 0
          ? "ri-arrow-up-line"
          : "ri-arrow-down-line",
      percentage: `${
        WebsitePercentageDifference > 0
          ? WebsitePercentageDifference.toFixed(2)
          : Math.abs(WebsitePercentageDifference.toFixed(2))
      } %`,
      iconClass: "info",
      feaIcon: "globe",
      subCounter: {
        id: 1,
        counter: websiteCounts?.active,
        decimals: 0,
      },
      subCounter2: {
        id: 2,
        counter: websiteCounts?.inActive,
        decimals: 0,
      },
      bgAngle: "#DF4F43",
      visible: settings.activeWebsiteCard,
    },
    {
      id: 2,
      label: "Average users per site a Month",
      bgColor:
        "transparent linear-gradient(299deg, #7490C6 0%, #466BB3 100%) 0% 0% no-repeat padding-box",
      labelClass: "muted",
      badgeClass:
        percentageDifference > 0
          ? "bg-light text-success"
          : "bg-light text-danger",
      badge:
        percentageDifference > 0 ? "ri-arrow-up-line" : "ri-arrow-down-line",
      percentage: `${
        percentageDifference > 0
          ? percentageDifference.toFixed(2)
          : Math.abs(percentageDifference.toFixed(2))
      } %`,
      iconClass: "success",
      feaIcon: "users",
      subCounter: [
        {
          id: 1,
          counter: finalResult,
          decimals: 0,
          suffix: finalResult > 1 ? " Users" : " User",
          prefix: "",
        },
      ],
      bgAngle: "#466BB3",
      visible: settings.avgUserCard,
    },
    {
      id: 3,
      label: "Active / In-Active Tickets",
      bgColor:
        "transparent linear-gradient(118deg, #B21F5F 0%, #C55787 100%) 0% 0% no-repeat padding-box",
      labelClass: "muted",
      counterClass: "",
      badgeClass:
        percentage >= 0 ? "bg-light text-success" : "bg-light text-danger",
      badge: percentage >= 0 ? "ri-arrow-up-line" : "ri-arrow-down-line",
      percentage: `${absolutePercentage.toFixed(2)} %`,
      iconClass: percentage >= 0 ? "info" : "danger",
      feaIcon: "tag",
      decimals: 0,
      suffix: "",
      prefix: "",
      subCounter: {
        id: 1,
        counter: ActiveTicket,
        decimals: 0,
      },

      subCounter2: {
        id: 2,
        counter: InactiveTicket,
        decimals: 0,
      },
      bgAngle: "#B21F5F",
      visible: settings.activeTicketCard,
    },
    {
      id: 4,
      label: ExtractDomain(data[0]?.url),
      bgColor:
        "transparent linear-gradient(120deg, #F99F1E 0%, #FAB756 100%) 0% 0% no-repeat padding-box",
      labelClass: "muted",
      counterClass: "",
      badgeClass: "bg-light text-danger",
      badge: "ri-arrow-down-line",
      percentage: `${percentage.toFixed(2)} %`,
      iconClass: "warning",
      feaIcon: "file-text",
      decimals: 0,
      suffix: "",
      prefix: "",
      subCounter: {
        id: 1,
        counter: ActiveTicket,
        decimals: 0,
      },
      subCounter2: {
        id: 2,
        counter: InactiveTicket,
        decimals: 0,
      },
      bgAngle: "#F99F1E",
      visible: settings.hitDetailCard,
    },
    { id: "comp" },
  ];

  const [tileBoxsData, setTileBoxsData] = useState([]);

  const customStyles = {
    menu: (provided) => ({
      ...provided,
      zIndex: 9999,
    }),
  };

  const returnObjectIds = (data) => {
    const allIds = [];
    data.forEach((obj) => allIds.push(obj.id.toString()));
    return allIds;
  };

  const sortObjectsById = (objects, ids) => {
    const objectMap = new Map(objects.map((obj) => [obj.id.toString(), obj]));
    return ids.map((id) => objectMap.get(id));
  };

  const fetchThePreferenceTileBoxs = async () => {
    try {
      const { data, status } = await axios.get(
        `/dashboard/getDashboardPreference/${id}/tiles`
      );

      if (status === "success" && data) {
        const preferenceTiles = data?.preferenceTiles ?? null;
        const result = preferenceTiles ? JSON.parse(preferenceTiles) : null;

        const sortedObjects = result
          ? sortObjectsById(tileBoxs3, result)
          : tileBoxs3;
        setTileBoxsData(sortedObjects);
      } else {
        setTileBoxsData(tileBoxs3);
      }
    } catch (error) {
      console.error("Error while fetching preferences:", error.message);
    }
  };

  const setThePreferenceTileBoxs = async (data) => {
    try {
      const response = await axios.post(
        `/dashboard/setDashboardPreference/${id}`,
        {
          preferenceType: "tiles",
          preferenceData: JSON.stringify(data),
        }
      );
      if (response.status === "success") {
        toast.success(response.message);
        fetchThePreferenceTileBoxs();
      }
    } catch (error) {
      console.log("Internal server error", error.message);
    }
  };

  useEffect(() => {
    fetchThePreferenceTileBoxs();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadingAllWebsites, LodingSetUsersHits, tileBoxTickets, settings]);

  const handleDragDrop = async (result) => {
    const { source, destination, type } = result;

    if (!destination) return;

    if (
      source.droppableId === destination.droppableId &&
      source.index === destination.index
    )
      return;

    if (type === "group1") {
      const reorderedData = Array.from(tileBoxsData);
      const [movedItem] = reorderedData.splice(source.index, 1);
      reorderedData.splice(destination.index, 0, movedItem);
      setTileBoxsData(reorderedData);
      const allIds = returnObjectIds(reorderedData);
      try {
        await setThePreferenceTileBoxs(allIds);
      } catch (error) {
        console.log("Error while changing the preference");
      }
    }
  };

  return (
    <>
      <div>
        <DragDropContext onDragEnd={handleDragDrop}>
          <Droppable droppableId="ROOT" direction="horizontal" type="group1">
            {(provided) => (
              <div
                className="topcard-grid-container"
                {...provided.droppableProps}
                ref={provided.innerRef}
              >
                {(tileBoxsData || []).map((item, index) => {
                  return (
                    <Draggable
                      key={item.id}
                      draggableId={item.id.toString()}
                      index={index}
                    >
                      {(provided, snapshot) => (
                        <div
                          className={
                            item.id === "comp" ? "item send-item" : "item"
                          }
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          style={{
                            ...provided.draggableProps.style,
                            cursor: "grab",
                          }}
                        >
                          {item.id === "comp"
                            ? // Render Compliance Card when item.id === "comp"
                              settings.complianceCard && (
                                <Card
                                  className="dashboard-top-card w-100 h-100 cursor-pointer"
                                  style={{
                                    background:
                                      "transparent linear-gradient(139deg, #CED4DA 0%, #DADEE3 100%) 0% 0% no-repeat padding-box",
                                  }}
                                  onClick={() => setLgShow(true)}
                                >
                                  <CardBody className="d-flex flex-column align-items-center justify-content-center">
                                    <Box
                                      className="avatar flex-shrink-0"
                                      sx={{ marginBottom: "18px" }}
                                    >
                                      <span
                                        className="avatar-title"
                                        style={{
                                          background: "rgba(255,255,255,0.4)",
                                        }}
                                      >
                                        <FeatherIcon
                                          icon="file-plus"
                                          className="text-primary"
                                        />
                                      </span>
                                    </Box>
                                    <p className="subtitle text-primary fs-18 mb-0 text-center">
                                      Send <br /> Compliance
                                    </p>
                                    <IconButton
                                      disabled
                                      className="icon-button"
                                      style={{
                                        background: "#e4e8eb",
                                        pointerEvents: "unset",
                                      }}
                                    >
                                      <ChevronRightRoundedIcon />
                                    </IconButton>
                                  </CardBody>
                                </Card>
                              )
                            : // Render the normal item card for other IDs

                              item?.visible && (
                                <Card
                                  className="dashboard-top-card w-100 h-100"
                                  style={{ background: item.bgColor }}
                                >
                                  <CardBody>
                                    <Box className="body-top">
                                      <Box className="avatar flex-shrink-0">
                                        <span className="avatar-title">
                                          <FeatherIcon
                                            icon={item.feaIcon}
                                            className="text-white"
                                          />
                                        </span>
                                      </Box>
                                      {item.id !== 4 ? (
                                        <Box className="body-right">
                                          {!Object.keys(item).includes(
                                            "subCounter2"
                                          ) && (
                                            <h1
                                              className={`title ${item.counterClass}`}
                                            >
                                              {item.subCounter.map(
                                                (subItem, key) => (
                                                  <span
                                                    className="counter-value"
                                                    key={key}
                                                  >
                                                    <CountUp
                                                      start={0}
                                                      prefix={subItem.prefix}
                                                      suffix={subItem.suffix}
                                                      separator={
                                                        subItem.separator
                                                      }
                                                      end={subItem.counter}
                                                      decimals={
                                                        subItem.decimals
                                                      }
                                                      duration={4}
                                                    />
                                                  </span>
                                                )
                                              )}
                                            </h1>
                                          )}
                                          {Object.keys(item).includes(
                                            "subCounter2"
                                          ) && (
                                            <h1
                                              className={`title ${item.counterClass}`}
                                            >
                                              <span className="counter-value">
                                                <CountUp
                                                  start={0}
                                                  end={item.subCounter.counter}
                                                  decimals={
                                                    item.subCounter.decimals
                                                  }
                                                  duration={2}
                                                />
                                              </span>
                                              &nbsp;/&nbsp;
                                              <span className="counter-value">
                                                <CountUp
                                                  start={0}
                                                  end={item.subCounter2.counter}
                                                  decimals={
                                                    item.subCounter2.decimals
                                                  }
                                                  duration={2}
                                                />
                                              </span>
                                            </h1>
                                          )}
                                          <p
                                            className={`subtitle ${item.labelClass}`}
                                          >
                                            {item.label}
                                          </p>
                                        </Box>
                                      ) : (
                                        <Box className="link-box">
                                          <p
                                            className={`subtitle mb-0 ${item.labelClass}`}
                                          >
                                            {item.label}
                                          </p>
                                        </Box>
                                      )}
                                    </Box>
                                    {item.id !== 4 ? (
                                      <p className="mb-0 text-white">
                                        <span className="mb-0 badge">
                                          <i
                                            className={`align-middle ${item.badge}`}
                                          />
                                          {item.percentage}
                                        </span>
                                        &nbsp; vs. previous month
                                      </p>
                                    ) : (
                                      <Box className="hits-box">
                                        <Box>
                                          <p className="title">Desktop Hits</p>
                                          {dataLoading ? (
                                            <Skeleton
                                              variant="text"
                                              width={100}
                                              height={30}
                                            />
                                          ) : (
                                            <h3 className="value">
                                              {data[0] &&
                                              data[0].desktop_hits !== undefined
                                                ? common.formatNumberWithCommas(
                                                    data[0].desktop_hits
                                                  )
                                                : "-"}
                                            </h3>
                                          )}
                                        </Box>
                                        <Box>
                                          <p className="title">Mobile Hits</p>
                                          {dataLoading ? (
                                            <Skeleton
                                              variant="text"
                                              width={100}
                                              height={30}
                                            />
                                          ) : (
                                            <h3 className="value">
                                              {data[0] &&
                                              data[0].mobile_hits !== undefined
                                                ? common.formatNumberWithCommas(
                                                    data[0].mobile_hits
                                                  )
                                                : "-"}
                                            </h3>
                                          )}
                                        </Box>
                                      </Box>
                                    )}
                                    <IconButton
                                      disabled
                                      className="icon-button"
                                      style={{ background: item.bgAngle }}
                                    >
                                      <ChevronRightRoundedIcon />
                                    </IconButton>
                                  </CardBody>
                                </Card>
                              )}
                        </div>
                      )}
                    </Draggable>
                  );
                })}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>

        <Modal
          size="md"
          show={lgShow}
          onHide={handleClose}
          aria-labelledby="example-modal-sizes-title-lg"
        >
          <ModalHeader closeButton>
            <ModalTitle className="h5">Send Compliance</ModalTitle>
          </ModalHeader>

          <Modal.Body>
            <Form
              className="needs-validation"
              noValidate
              action="index"
              onSubmit={complianceValidation.handleSubmit}
            >
              <Row className="g-3">
                <Col lg={12} className="align-items-center">
                  <Box className="d-flex">
                    <label className="me-2">Website Guideline: &nbsp;</label>
                    <Form.Check
                      type="switch"
                      id="website-guideline-switch"
                      onChange={handleWebsiteSwitchChange}
                    />
                  </Box>
                  {showWebsiteGuidelineSelect && (
                    <>
                      <Select
                        isMulti
                        name="website-guideline-select"
                        placeholder="please select the website guideline."
                        options={websiteGuideline}
                        value={websiteSelectedOptions}
                        styles={customStyles}
                        onChange={handleWebsiteSelectChange}
                      />
                      {complianceValidation.errors.website_guideline_id && (
                        <Box
                          className="invalid-feedback d-block"
                          style={{ color: "red" }}
                        >
                          {complianceValidation.errors.website_guideline_id}
                        </Box>
                      )}
                    </>
                  )}
                </Col>

                <Col lg={12} className="align-items-center">
                  <Box className="d-flex">
                    <label className="me-2">Branding Guideline: &nbsp;</label>
                    <Form.Check
                      type="switch"
                      id="branding-guideline-switch"
                      onChange={handleBrandingSwitchChange}
                    />
                  </Box>
                  {showBrandingGuidelineSelect && (
                    <>
                      <Select
                        isMulti
                        name="branding-guideline-select"
                        placeholder="please select the branding guideline."
                        options={brandingGuideline}
                        value={brandingSelectedOptions}
                        styles={customStyles}
                        onChange={handleBrandingSelectChange}
                      />
                      {complianceValidation.errors.branding_guideline_id && (
                        <Box
                          className="invalid-feedback d-block"
                          style={{ color: "red" }}
                        >
                          {complianceValidation.errors.branding_guideline_id}
                        </Box>
                      )}
                    </>
                  )}
                </Col>

                <Col lg={12} className="d-flex align-items-center">
                  <label className="me-2">Website Policies: &nbsp;</label>
                  <Form.Check
                    type="switch"
                    id="website-policies-switch"
                    onChange={handlePolicySwitchChange}
                  />
                </Col>

                <Col lg={12}>
                  <Box className="d-flex align-items-start">
                    <label className="me-2">Developers: &nbsp;</label>
                    <span className="w-100" style={{ zIndex: 9 }}>
                      <Select
                        isMulti
                        name="website-guideline-select"
                        placeholder="please select the developers."
                        options={[
                          { label: "other", value: "other" }, // Static value
                          ...((developersProfileDetails &&
                            Array.isArray(developersProfileDetails.rows) &&
                            developersProfileDetails.rows.map((item) => ({
                              label: item.user_name,
                              value: item.id,
                            }))) ||
                            []),
                        ]}
                        value={developersSelectedOptions}
                        onChange={handleDevelopersSelectChange}
                      />
                      {complianceValidation.errors.developers_id && (
                        <Box
                          className="invalid-feedback d-block"
                          style={{ color: "red" }}
                        >
                          {complianceValidation.errors.developers_id}
                        </Box>
                      )}
                    </span>
                  </Box>
                </Col>

                <Col lg={12}>
                  {developersSelectedOptions &&
                    developersSelectedOptions.some(
                      (i) => i.label === "other"
                    ) && (
                      <Box className="d-flex align-items-start">
                        <label className="me-2">Email:</label>
                        <Box className="w-100">
                          <InputGroup>
                            <FormControl
                              type="email"
                              name="other_email"
                              placeholder="Enter email"
                              value={complianceValidation.values.other_email}
                              onChange={complianceValidation.handleChange}
                              onBlur={complianceValidation.handleBlur}
                              isInvalid={
                                !!complianceValidation.errors.other_email &&
                                !!complianceValidation.touched.other_email
                              }
                            />
                          </InputGroup>
                          {complianceValidation.errors.other_email &&
                            complianceValidation.touched.other_email && (
                              <Box
                                className="invalid-feedback d-block"
                                style={{ color: "red" }}
                              >
                                {complianceValidation.errors.other_email}
                              </Box>
                            )}
                        </Box>
                      </Box>
                    )}
                </Col>

                {errorMessage != null && (
                  <Box
                    className="alert alert-borderless alert-warning text-center mb-2 mx-2 mt-4"
                    role="alert"
                  >
                    {errorMessage}
                  </Box>
                )}

                <Col lg={12}>
                  <Box className="hstack gap-2 justify-content-center">
                    <Button className="btn btn-primary" onClick={handleClose}>
                      Close
                    </Button>
                    <Button className="btn btn-dark" onClick={handleSubmit}>
                      Send
                    </Button>
                  </Box>
                </Col>
              </Row>
            </Form>
          </Modal.Body>
        </Modal>
      </div>
    </>
  );
};

export default TileBox;
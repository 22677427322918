import { Switch, styled } from "@mui/material";
import CryptoJS from "crypto-js";

let common = {
  formatNumberWithCommas: (number) => {
    if (isNaN(number)) {
      return "Invalid number";
    }

    const formatter = new Intl.NumberFormat("en-IN");
    return formatter.format(number);
  },

  formateTime: (timeFormat) => {
    let formattedTimeFormat;
    if (timeFormat === "12") {
      formattedTimeFormat = "H:i";
    } else {
      formattedTimeFormat = "h:i K";
    }
    return formattedTimeFormat;
  },

  encrypt: (plainData) => {
    if (process.env.REACT_APP_ENCRYPTION === "true") {
      try {
        const encryptedData = CryptoJS.AES.encrypt(
          JSON.stringify(plainData),
          CryptoJS.enc.Utf8.parse(process.env.REACT_APP_CIPHERSKEY),
          {
            iv: CryptoJS.enc.Utf8.parse(process.env.REACT_APP_CIPHERVIKEY),
            mode: CryptoJS.mode.CBC,
            padding: CryptoJS.pad.Pkcs7, // Ensure consistent padding
          }
        ).ciphertext.toString(CryptoJS.enc.Hex); // Convert ciphertext to hexadecimal string
        return { data: encryptedData };
      } catch (error) {
        console.error("Encryption error:", error);
        return { error: "Encryption failed" };
      }
    } else {
      return { plainData };
    }
  },

  // encrypt: (data) => {
  //   const isEncryptionEnabled = process.env.REACT_APP_ENCRYPTION === "true";

  //   if (isEncryptionEnabled) {
  //     try {
  //       let dataToEncrypt;

  //       if (data instanceof FormData) {
  //         // Convert FormData to a regular object
  //         dataToEncrypt = {};
  //         data.forEach((value, key) => {
  //           dataToEncrypt[key] = value;
  //         });
  //       } else {
  //         dataToEncrypt = data;
  //       }

  //       const encryptedData = CryptoJS.AES.encrypt(
  //         JSON.stringify(dataToEncrypt),
  //         CryptoJS.enc.Utf8.parse(process.env.REACT_APP_CIPHERSKEY),
  //         {
  //           iv: CryptoJS.enc.Utf8.parse(process.env.REACT_APP_CIPHERVIKEY),
  //           mode: CryptoJS.mode.CBC,
  //           padding: CryptoJS.pad.Pkcs7, // Ensure consistent padding
  //         }
  //       ).ciphertext.toString(CryptoJS.enc.Hex); // Convert ciphertext to hexadecimal string

  //       return { data: encryptedData };
  //     } catch (error) {
  //       console.error("Encryption error:", error);
  //       return { error: "Encryption failed" };
  //     }
  //   } else {
  //     return { data }; // Return the original data if encryption is not enabled
  //   }
  // },

  decrypt: (encryptedData) => {
    if (process.env.REACT_APP_DECRYPTION === "true") {
      const bytes = CryptoJS.AES.decrypt(
        {
          ciphertext: CryptoJS?.enc?.Hex?.parse(encryptedData),
        },
        CryptoJS.enc.Utf8.parse(process.env.REACT_APP_CIPHERSKEY),
        {
          iv: CryptoJS.enc.Utf8.parse(process.env.REACT_APP_CIPHERVIKEY),
          mode: CryptoJS.mode.CBC,
          padding: CryptoJS.pad.Pkcs7,
        }
      );
      const decryptedData = bytes.toString(CryptoJS.enc.Utf8);
      if (decryptedData) {
        return JSON.parse(decryptedData);
      } else {
        return null;
      }
    } else {
      return encryptedData;
    }
  },

  IOSSwitch: styled(({ checked, onChange, ...props }) => (
    <div
      style={{
        position: "relative",
        display: "inline-flex",
        alignItems: "center",
      }}
    >
      <Switch
        focusVisibleClassName=".Mui-focusVisible"
        disableRipple
        checked={checked}
        onChange={onChange}
        {...props}
      />
    </div>
  ))(({ theme }) => ({
    width: 60, // Increase the width to accommodate larger labels if necessary
    height: 34, // Increase the height
    padding: 0,
    "& .MuiSwitch-switchBase": {
      padding: 0,
      margin: 4, // Adjust margin if needed
      transitionDuration: "300ms",
      "&.Mui-checked": {
        transform: "translateX(26px)", // Adjust translateX for increased width
        color: "#fff",
        "& + .MuiSwitch-track": {
          backgroundColor:
            theme.palette.mode === "dark" ? "#2ECA45" : "#65C466",
          opacity: 1,
          border: 0,
        },
        "&.Mui-disabled + .MuiSwitch-track": {
          opacity: 0.5,
        },
      },
      "&.Mui-focusVisible .MuiSwitch-thumb": {
        color: "#33cf4d",
        border: "6px solid #fff",
      },
      "&.Mui-disabled .MuiSwitch-thumb": {
        color:
          theme.palette.mode === "light"
            ? theme.palette.grey[100]
            : theme.palette.grey[600],
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
      },
    },
    "& .MuiSwitch-thumb": {
      boxSizing: "border-box",
      width: 28, // Increase thumb size
      height: 28, // Increase thumb size
    },
    "& .MuiSwitch-track": {
      borderRadius: 34 / 2,
      backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#39393D",
      opacity: 1,
      transition: theme.transitions.create(["background-color"], {
        duration: 500,
      }),
    },
  })),

  LOGOTOOLTIP:
    "Only JPEG, JPG, PNG, and SVG images are allowed. The maximum size of the image is 1MB. Recommended image dimensions are 400px in width and 200px in height.",
  // LOGOTOOLTIP:
  //   "Only JPEG, JPG, PNG, and SVG images are allowed. Maximum size of the Image is 1MB.",

  PDFTOOLTIP:
    "Only PDF Documents are allowed.Maximum size of the documents is 15MB.",
};

export default common;

import {
  INITIALNOTIFICATION_LIST,
  LOADMORENOTIFICATION_LIST,
  DEDUCTNOTIFICATION_COUNT,
  INITIAL_NOTIFICATION_READ,
  LOADMORE_NOTIFICATION_READ,
  RESET_INITIALNOTIFICATION_LIST,
} from "./actionType";

const intialState = {
  notificationList: [],
  payloadEmpty: false,
  unreadCount: 0,
};

const notification = (state = intialState, action) => {
  switch (action.type) {
    case INITIALNOTIFICATION_LIST:
      return {
        ...state,
        notificationList: [...action.payload.rows],
        unreadCount: action.payload.unreadCount,
      };
    case RESET_INITIALNOTIFICATION_LIST:
      return intialState;
    case LOADMORENOTIFICATION_LIST:
      return {
        ...state,
        notificationList: [
          ...state.notificationList,
          ...action.payload.rows.map((notification) => {
            return { ...notification, isRead: false };
          }),
        ],
        unreadCount: action.payload.unreadCount,
      };
    case INITIAL_NOTIFICATION_READ:
      return {
        ...state,
        notificationList: [
          ...action.payload.map((notification) => {
            return { ...notification, isRead: true };
          }),
        ],
      };
    case LOADMORE_NOTIFICATION_READ:
      return {
        ...state,
        notificationList: [
          ...state.notificationList,
          ...action.payload.map((notification) => {
            return { ...notification, isRead: true };
          }),
        ],
      };
    case DEDUCTNOTIFICATION_COUNT:
      return { ...state, unreadCount: action.payload.unreadCount };

    default:
      return state;
  }
};

export default notification;

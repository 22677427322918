import React from "react";
import { Card, Col, Row } from "reactstrap";
import AuthSlider from "./authCarousel";
import CoverSignIn from "./Login/CoverSignIn";
import { useLocation } from "react-router-dom";
import CoverPasswReset from "./PasswordReset/CoverPasswReset";
import CoverLogout from "./Logout/CoverLogout";


const Index = () => {
  const { pathname } = useLocation();

  const renderContent = () => {
    switch (pathname) {
      case "/auth-pass-reset-cover":
        return <CoverPasswReset />;
      case "/login":
        return <CoverSignIn />;
      case "/auth-logout-cover":
        return <CoverLogout />;
      default:
        return null;
    }
  };

  return (
    <React.Fragment>
      <div className="auth-page-wrapper auth-bg-cover min-vh-100">
        <div className="auth-page-content overflow-hidden pb-0">
          <Card className="overflow-hidden border-0 mb-0 rounded-0">
            <Row className="g-0 min-vh-100">
              <Col lg={6}>
                <AuthSlider />
              </Col>
              <Col lg={6}>{renderContent()}</Col>
            </Row>
          </Card>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Index;

import React from "react";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
// Import Vector Map
import Vector from "./MapVector";

const VectorMaps = () => {
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col lg={12}>
              <Card>
                <CardBody>
                  <div
                    id="world-map-line-markers"
                    data-colors='["--vz-light"]'
                    style={{ height: "420px", position: "relative" }}
                  >
                    <Vector
                      value="world_mill"
                      width="500"
                      color="rgb(212, 218, 221)"
                    />
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default VectorMaps;

import * as types from "./actionType";

const initialState = {
  analyticsData: [],
  userSessionChartData: [],
  analyticsChartData: [],
  analyticsRealtimeData: null,
  hitReportData: [],
  visitorsData: null,
  analyticsRealtimeDurationData: null,
  loading: false,
  fetch_analytics_data_loading: false,
  fetch_user_session_chart_loading: false,
  fetch_chart_data_loading: false,
  fetch_analytics_realtime_data_loading: false,
  fetch_analytics_realtime_duration_data_loading: false,
  error: null,
};

const analyticsDataReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.FETCH_ANALYTICS_REALTIME_DATA_REQUEST:
      return {
        ...state,
        loading: true,
        fetch_analytics_realtime_data_loading: true,
        error: null,
      };
    case types.FETCH_ANALYTICS_REALTIME_SUCCESS:
      return {
        ...state,
        loading: false,
        fetch_analytics_realtime_data_loading: false,
        analyticsRealtimeData: action.payload,
      };
    case types.FETCH_ANALYTICS_REALTIME_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case types.FETCH_ANALYTICS_REALTIME_DURATION_DATA_REQUEST:
      return {
        ...state,
        loading: true,
        fetch_analytics_realtime_duration_data_loading: true,
        error: null,
      };
    case types.FETCH_ANALYTICS_REALTIME_DURATION_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        fetch_analytics_realtime_duration_data_loading: false,
        analyticsRealtimeDurationData: action.payload,
      };
    case types.FETCH_ANALYTICS_REALTIME_DURATION_DATA_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case types.FETCH_ANALYTICS_DATA_REQUEST:
      return {
        ...state,
        loading: true,
        fetch_analytics_data_loading: true,
        error: null,
      };
    case types.FETCH_ANALYTICS_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        fetch_analytics_data_loading: false,
        analyticsData: action.payload,
      };
    case types.FETCH_ANALYTICS_DATA_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case types.FETCH_USER_SESSION_CHART_REQUEST:
      return {
        ...state,
        loading: true,
        fetch_user_session_chart_loading: true,
        error: null,
      };
    case types.FETCH_USER_SESSION_CHART_SUCCESS:
      return {
        ...state,
        loading: false,
        fetch_user_session_chart_loading: false,
        userSessionChartData: action.payload,
      };
    case types.FETCH_USER_SESSION_CHART_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case types.FETCH_CHART_DATA_REQUEST:
      return {
        ...state,
        loading: true,
        fetch_chart_data_loading: true,
        error: null,
      };
    case types.FETCH_CHART_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        fetch_chart_data_loading: false,
        analyticsChartData: action.payload,
      };
    case types.FETCH_CHART_DATA_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case types.FETCH_HIT_REPORT_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case types.FETCH_HIT_REPORT_SUCCESS:
      return {
        ...state,
        loading: false,
        hitReportData: action.payload,
      };
    case types.FETCH_HIT_REPORT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case types.FETCH_VISITORS_DATA_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case types.FETCH_VISITORS_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        visitorsData: action.payload,
      };
    case types.FETCH_VISITORS_DATA_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case types.SET_INITIAL_STATE:
      return initialState;
    default:
      return state;
  }
};

export default analyticsDataReducer;

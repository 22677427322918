import { ADD_LIST, DELETE_LIST, INITIAL_LIST, SET_SEARCH_QUERY,ADD_ANNOUNCEMENT_LIST } from "./actionType";

const INIT_STATE = {
  announcements: [],
  payloadEmpty: false,
  searchQuery: "",
};

const announcement = (state = INIT_STATE, action) => {
  switch (action.type) {
    case INITIAL_LIST: {
      return { ...state, announcements: [...action.payload] };
    }

    case ADD_LIST: {
      return {
        ...state,
        announcements: [...state.announcements, ...action.payload],
        
      };
    }

    case DELETE_LIST: {
      return {
        ...state,
        announcements: [ ...action.payload],
      };
    }


    case ADD_ANNOUNCEMENT_LIST: {
      return {
        ...state,
        announcements: [ ...action.payload],
      };
    }

    case SET_SEARCH_QUERY: {
      return { ...state, searchQuery: action.payload };
    }

    default:
      return state;
  }
};

export default announcement;

import React from "react";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Link } from "react-router-dom";

// Import Images
import netclues from "../../assets/images/logo.svg";
import { Box } from "@mui/material";

const AuthSlider = () => {
  return (
    <React.Fragment>
      <Box className="auth-one-bg h-100">
        <Box className="bg-overlay"></Box>
        <Box className="position-relative h-100 d-flex flex-column">
          <Box className="mb-4">
            <Link to="/dashboard" className="d-block">
              <img src={netclues} alt="Logo" height="30" />
            </Link>
          </Box>
          <Box
            sx={{
              margin: { lg: "auto" },
              marginY: "50px",
              height: { lg: "50%" },
              padding: { sm: "0 0", md: "0 0", lg: "0 80px" },
            }}
          >
            <Box className="mb-3">
              <i
                class="bx bxs-quote-left display-3 text-danger"
                style={{ fontSize: "40px" }}
              ></i>
            </Box>

            <Carousel
              showThumbs={false}
              autoPlay={true}
              showArrows={false}
              showStatus={false}
              infiniteLoop={true}
              className="carousel slide"
              id="qoutescarouselIndicators"
            >
              <Box className="carousel-inner text-white pb-5">
                <Box className="item">
                  <p className="fst-italic">
                    Streamline your website management with our centralized
                    control platform
                  </p>
                </Box>
              </Box>
              <Box className="carousel-inner text-white pb-5">
                <Box className="item">
                  <p className="fst-italic">
                    Controlbase offers a comprehensive suite of monitoring
                    features that allow businesses to track the performance and
                    uptime of their websites with ease
                  </p>
                </Box>
              </Box>
              <Box className="carousel-inner text-white pb-5">
                <Box className="item">
                  <p className="fst-italic">
                    Amazing product for website monitoring, offering a robust
                    set of features and intuitive functionality.
                  </p>
                </Box>
              </Box>
            </Carousel>
          </Box>
        </Box>
      </Box>
    </React.Fragment>
  );
};

export default AuthSlider;

import React, { useCallback, useEffect } from "react";
import { Navigate, Route, useNavigate } from "react-router-dom";
import { setAuthorization } from "../helpers/api_helper";
import { useDispatch, useSelector } from "react-redux";
import { Outlet } from "react-router-dom";

import { useProfile } from "../Components/Hooks/UserHooks";
import { logoutUser } from "../store/actions";
import SpinnerLoader from "../Components/Common/Spinner";
import { API_ROUTES } from "../Api/ApiRoutes";
import axios from "axios";
import common from "../Components/Common/common";

const AuthProtected = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { userProfile, loading, token } = useProfile();
  const userData = useSelector((state) => state.userDetails.userData);

  // const permissionData = useSelector(state => state.userDetails.userPermission);

  const throwInLogOutPage = () => {
    localStorage.clear();
    navigate("/auth-signin-cover");
    dispatch(logoutUser());
  };

  useEffect(() => {
    const checkTokenExpiration = () => {
      if (token) {
        const decodedToken = JSON.parse(atob(token.split(".")[1]));

        if (decodedToken.exp < Date.now() / 1000) {
          // Token has expired
          throwInLogOutPage();
        } else {
          setAuthorization(token);
        }
      }
    };

    if (userProfile && !loading && token) {
      checkTokenExpiration();
      setAuthorization(token);
    } else if (!userProfile && loading && !token) {
      throwInLogOutPage();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token, userProfile, loading, dispatch]);

  /*
    redirect is un-auth access protected routes via url
  */

  const getRolePermissionByName = useCallback(async () => {
    if (userData?.role && props.moduleCode) {
      try {
        let response = await axios.post(
          API_ROUTES.GET_ROLES_PERMISSION_BY_NAME,
          {
            name: userData?.role,
          }
        );

        const decryptedData = common.decrypt(response.data);
        response.data = decryptedData;
        const decryptedMessage = common.decrypt(response.message);
        response.message = decryptedMessage;
        if (!response.data.includes(props.moduleCode)) {
          navigate("/auth-401-cover");
        }
      } catch (error) {
        console.error("Failed to fetch role permissions:", error);
      }
    }
  }, [userData?.role, props?.moduleCode, navigate]);

  useEffect(() => {
    getRolePermissionByName();
  }, [getRolePermissionByName]);

  if (!userProfile && loading && !token) {
    return (
      <Navigate
        to={{ pathname: "/auth-logout-cover", state: { from: props.location } }}
      />
    );
  }

  return <>{props.children}</>;
};

const AccessRoute = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) => {
        return (
          <>
            {" "}
            <Component {...props} />{" "}
          </>
        );
      }}
    />
  );
};

function PrivateRoute({ ok }) {
  return ok ? <Outlet /> : <SpinnerLoader />;
}

export { AuthProtected, AccessRoute, PrivateRoute };

import {
    ADD_GENERAL_SETTING_DATA,
    ADD_SETTINGS_DATA,
    SET_TIME_ZONE
} from "./actionType";

const initialState = {
    settingsData: null,
    generalSettingData: null,
    timeZone: "Asia/Kolkata",
};

const saveSettingsReducer = (state = initialState, action) => {
    switch (action.type) {
        case ADD_SETTINGS_DATA:
            return {
                ...state,
                settingsData: action.payload,
            };
        case ADD_GENERAL_SETTING_DATA:
            return {
                ...state,
                generalSettingData: action.payload,
            };
        case SET_TIME_ZONE:
            return {
                ...state,
                timeZone: action.payload,
            };
        default:
            return state;
    }
};

export default saveSettingsReducer;
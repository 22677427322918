import * as types from './actionType';

// const initialState = {
//   siteStatus: null,
//   filteredData: null,
//   loading: false,
//   error: null,
// };
const initialState = {
  siteStatus: {
    data: null,
    loading: false,
    error: null,
  },
  filteredData: {
    data: null,
    loading: false,
    error: null,
  },
};

const siteStatusReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.FETCH_SITE_STATUS_REQUEST:
      return {
        ...state,
        siteStatus: {
          ...state.siteStatus,
          loading: true,
          error: null,
        },
      };
    case types.FETCH_SITE_STATUS_SUCCESS:
      return {
        ...state,
        siteStatus: {
          data: action.payload,
          loading: false,
          error: null,
        },
      };
    case types.FETCH_SITE_STATUS_FAILURE:
      return {
        ...state,
        siteStatus: {
          ...state.siteStatus,
          loading: false,
          error: action.payload,
        },
      };
    case types.FETCH_FILTERED_DATA_REQUEST:
      return {
        ...state,
        filteredData: {
          ...state.filteredData,
          loading: true,
          error: null,
        },
      };
    case types.FETCH_FILTERED_DATA_SUCCESS:
      return {
        ...state,
        filteredData: {
          data: action.payload,
          loading: false,
          error: null,
        },
      };
    case types.FETCH_FILTERED_DATA_FAILURE:
      return {
        ...state,
        filteredData: {
          ...state.filteredData,
          loading: false,
          error: action.payload,
        },
      };
    default:
      return state;
  }
};

export default siteStatusReducer;

import { SET_ACTIVE_TAB } from "./actionType";

const intialState = {
  activeTabName: "General",
};

const settingTab = (state = intialState, action) => {
  switch (action.type) {
    case SET_ACTIVE_TAB:
      return { ...state, activeTabName: action.payload };

    default:
      return state;
  }
};

export default settingTab;

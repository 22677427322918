import React, { useEffect, useState } from "react";
import { Grid } from "@mui/material";
import Constant from "./Constant";
import { getNotificationSetting } from "../../helpers/Help/CommomApi";

const NotificationSetting = () => {
  const { GlobalNotifications, CustomizeNotifications } = Constant;
  const [customizeData, setCustomizeData] = useState([]);
  const [globalData, setGlobalData] = useState([]);

  useEffect(() => {
    getNotificationSetting().then((data) => {
      setCustomizeData(data.data.notificationData);
      setGlobalData(data.data.globalNotification);
    });
  }, []);

  return (
    <>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <GlobalNotifications data={globalData} />
        </Grid>
        <Grid item xs={12}>
          <CustomizeNotifications data={customizeData} />
        </Grid>
      </Grid>
    </>
  );
};

export default NotificationSetting;

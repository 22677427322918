function ExtractDomain(url) {

    if (url === undefined || url === null) {
        return;
    }

    let domain = url.replace(/^https?:\/\//i, '').replace(/^www\./i, '');

    const parts = domain.split('/');
    domain = parts[0];

    return domain;
}

export default ExtractDomain;
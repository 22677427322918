import { Avatar, Box } from "@mui/material";
import { Modal } from "react-bootstrap";
import { Button } from "reactstrap";
import SimpleBar from "simplebar-react";
import ProfileImage from "../../../Components/Common/ProfileImage";
import { Link } from "react-router-dom";
import { ToastMessage } from "../../../Components/Common/ToastMessage";

const AssignedMemberModal = ({
  assignedMembersModalShow,
  handleAssignedMembersModalClose,
  setSearchQuery,
  searchQuery,
  assignedMembersToTicket,
  departmentUsersDataForAssgined,
  handleAssignedMembers,
  membersToBeAssgined,
  assginedMemebers,
  setAssignedMembersModalShow,
  setMembersToBeAssgined,
  membersToBeAssginedIDs,
}) => {
  return (
    <Modal
      show={assignedMembersModalShow}
      onHide={handleAssignedMembersModalClose}
      centered
      keyboard={false}
      contentClassName="border-0"
    >
      <Modal.Header closeButton>
        <h5 className="modal-title" id="inviteMembersModalLabel">
          Team Members
        </h5>
      </Modal.Header>
      <Modal.Body>
        <Box className="search-box mb-3">
          <input
            type="text"
            className="form-control search bg-light border-light"
            placeholder="Search here..."
            onChange={(e) => {
              setSearchQuery(e.target.value);
            }}
          />
          <i className="ri-search-line search-icon"></i>
        </Box>

        <Box className="mb-4 d-flex align-items-center">
          <Box
            className="me-2 avtars"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <h5 className="mb-0 fs-13">Members :</h5>

            <Box
              style={{
                display: "inline-flex",
                flexDirection: "row",
                alignItems: "center",
                position: "relative",
                left: "7px",
              }}
            >
              {assignedMembersToTicket?.length > 0 ? (
                assignedMembersToTicket?.map((singleUser, idx) =>
                  singleUser.user_image && singleUser.user_image !== null ? (
                    <span>
                      <img
                        src={`${process.env.REACT_APP_BASE_URL}${singleUser.user_image}`}
                        // className="img-fluid rounded-circle"
                        alt=""
                        height={"25px"}
                        width={"25px"}
                        style={{
                          color: "#fff",
                          borderRadius: "50%",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          fontSize: "14px",
                          border: "1px solid black",
                          marginRight: "-8px",
                        }}
                      />
                    </span>
                  ) : (
                    <Box key={singleUser.id}>
                      <ProfileImage
                        username={singleUser.user_name}
                        height={"25px"}
                        width={"25px"}
                        marginRight={"-8px"}
                        border={"1px solid black"}
                      />
                    </Box>
                  )
                )
              ) : (
                <span>No assigned members found</span>
              )}
            </Box>
          </Box>
        </Box>

        <SimpleBar
          className="pe-2"
          style={{ maxHeight: "280px" }}
          autoHide={false}
        >
          <Box className="vstack gap-3">
            {(departmentUsersDataForAssgined || []).filter((member) =>
              member?.user_name
                ?.replaceAll(" ", "")
                .toLowerCase()
                .includes(searchQuery)
            ).length > 0 ? (
              departmentUsersDataForAssgined
                .filter((member) =>
                  member?.user_name
                    ?.replaceAll(" ", "")
                    .toLowerCase()
                    .includes(searchQuery)
                )
                .map((singleUser) => (
                  <Box
                    className="d-flex align-items-center"
                    key={singleUser.id}
                  >
                    <Box className="flex-shrink-0 me-3">
                      {singleUser.user_image &&
                      singleUser.user_image !== null ? (
                        <Avatar
                          src={`${process.env.REACT_APP_BASE_URL}${singleUser.user_image}`}
                          alt="avatar"
                        />
                      ) : (
                        <ProfileImage
                          username={singleUser.user_name}
                          width="40px"
                          height="40px"
                        />
                      )}
                    </Box>
                    <Box className="flex-grow-1">
                      <h5 className="mb-0">
                        <Link
                          // href="javascript:void(0);"
                          className="text-body d-block"
                        >
                          {singleUser.user_name}
                        </Link>
                      </h5>
                    </Box>
                    <Box className="flex-shrink-0">
                      <button
                        type="button"
                        className="btn btn-light btn-sm"
                        onClick={() => handleAssignedMembers(singleUser.id)}
                      >
                        {membersToBeAssgined.includes(singleUser.id)
                          ? "Added"
                          : "Add"}
                      </button>
                    </Box>
                  </Box>
                ))
            ) : (
              <p>No record found</p>
            )}
          </Box>
        </SimpleBar>
      </Modal.Body>
      <Modal.Footer>
        <Button
          className="btn btn-dark"
          type="submit"
          onClick={() => {
            if (membersToBeAssgined.length === 0) {
              ToastMessage.Error("Please add the memebers");
            } else {
              assginedMemebers();
              setAssignedMembersModalShow(false);
              setSearchQuery("");
              setMembersToBeAssgined(membersToBeAssginedIDs);
            }
          }}
        >
          Assigned
        </Button>
        <Button
          className="btn btn-primary"
          onClick={() => handleAssignedMembersModalClose()}
        >
          Cancel
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default AssignedMemberModal;

import { call, put, takeEvery, all, fork } from "redux-saga/effects";
import {
  fetchWebsitePolicies,
  fetchWebsitePoliciesFail,
  fetchWebsitePoliciesSuccess,
  updateWebsitePoliciesFail,
  updateWebsitePoliciesSuccess,
} from "./action";
import { FETCH_WEBSITEPOLICIES, UPDATE_WEBSITEPOLICIES } from "./actionType";
import axiosInstance from "../../Components/Common/apiClient";
import { ToastMessage } from "../../Components/Common/ToastMessage";
import axios from "axios";
import { socket } from "../../Components/Common/socket";
import common from "../../Components/Common/common";

const token = localStorage.getItem("token");

const fetchWebsitePoliciesAPI = async (queryParams = {}) => {
  try {
    const response = await axios.get(`/websitePolicies`, {
      params: queryParams,
    });

    const decryptedData = common.decrypt(response.data);
    response.data = decryptedData;

    return response.data; // Return the response data
  } catch (error) {
    throw error;
  }
};

const updateWebsitePoliciesAPI = async (websitepolicyContent) => {
  try {
    const encryptedData = common.encrypt(websitepolicyContent);
    let response = await axiosInstance.put(
      `/websitePolicies/updatePolicy`,
      { encryptedData },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    socket.emit("policy");
    const decryptedData = common.decrypt(response.data.data[0]);
    response.data.data = decryptedData;
    return response.data; // Return the response data
  } catch (error) {
    throw error;
  }
};

function* getWebsitePolicies() {
  try {
    // const queryParams = {
    //   websitepolicyContent: "websitepolicyContent", // Example: Add query parameters
    // };
    const queryParams = {
      websitepolicyContent: "data", // Example: Add query parameters
    };
    const response = yield call(fetchWebsitePoliciesAPI, queryParams);
    yield put(fetchWebsitePoliciesSuccess(response));
  } catch (error) {
    yield put(fetchWebsitePoliciesFail(error.response));
  }
}

function* onUpdateWebsitePolicies({ payload: websitepolicy }) {
  try {
    const response = yield call(updateWebsitePoliciesAPI, websitepolicy);

    yield put(updateWebsitePoliciesSuccess(response));
    yield put(fetchWebsitePolicies());
    ToastMessage.Success(response.message);
  } catch (error) {
    yield put(updateWebsitePoliciesFail(error.response));
    ToastMessage.Error(error.response.data.message);
    // toast.error("Website Policy Updated Failed", { autoClose: 3000 });
  }
}

export function* watchGetWebsitePolicies() {
  yield takeEvery(FETCH_WEBSITEPOLICIES, getWebsitePolicies);
}

export function* watchUpdateWebsitePolicies() {
  yield takeEvery(UPDATE_WEBSITEPOLICIES, onUpdateWebsitePolicies);
}

function* websitePoliciesSaga() {
  yield all([fork(watchGetWebsitePolicies), fork(watchUpdateWebsitePolicies)]);
}

export default websitePoliciesSaga;

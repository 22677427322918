import { all, fork } from "redux-saga/effects";
//layout
import LayoutSaga from "./layouts/saga";

//Auth
import AccountSaga from "./auth/register/saga";
import AuthSaga from "./auth/login/saga";
import ForgetSaga from "./auth/forgetpwd/saga";
import ProfileSaga from "./auth/profile/saga";

//Website Policies
import websitePoliciesSaga from "./websitePolicies/saga";

// Developers Profile
import DevelopersProfileSaga from "./developersProfile/saga";

// Compliance
import ComplianceSaga from "./compliance/saga";

// uptime report
import uptimeReportSaga from "./uptimeReport/saga";

// analytics data
import analyticsReortSaga from "./analytics/saga";
import onlineAssetSaga from "./onlineAssets/saga";
import dashboardSaga from "./dashboard/saga";

export default function* rootSaga() {
  yield all([
    fork(LayoutSaga),
    fork(AccountSaga),
    fork(AuthSaga),
    fork(ForgetSaga),
    fork(ProfileSaga),
    fork(websitePoliciesSaga),
    fork(DevelopersProfileSaga),
    fork(ComplianceSaga),
    fork(uptimeReportSaga),
    fork(analyticsReortSaga),
    fork(onlineAssetSaga),
    fork(dashboardSaga),
  ]);
}

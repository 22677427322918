import React from "react";
import DataTable from "react-data-table-component";

const EmailLogs = () => {
  const data = [
    { id: 1, emailName: "john.doe@example.com", createdAt: "2023-09-10" },
    { id: 2, emailName: "jane.smith@example.com", createdAt: "2023-09-11" },
    { id: 3, emailName: "mike.jones@example.com", createdAt: "2023-09-12" },
    { id: 4, emailName: "anna.williams@example.com", createdAt: "2023-09-13" },
    { id: 5, emailName: "steve.brown@example.com", createdAt: "2023-09-14" },
  ];
  const columns = [
    {
      name: "ID",
      selector: (row) => row.id,
      sortable: true,
    },
    {
      name: "Email Name",
      selector: (row) => row.emailName,
      sortable: true,
    },
    {
      name: "Created At",
      selector: (row) => row.createdAt,
      sortable: true,
    },
  ];

  return (
    <div>
      <h2>Email Logs</h2>
      <DataTable
        columns={columns}
        data={data}
        pagination
        highlightOnHover
        striped
      />
    </div>
  );
};

export default EmailLogs;

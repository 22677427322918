// Dashboard Settings Action Types
export const FETCH_DASHBOARD_SETTINGS_REQUEST =
  "FETCH_DASHBOARD_SETTINGS_REQUEST";
export const FETCH_DASHBOARD_SETTINGS_SUCCESS =
  "FETCH_DASHBOARD_SETTINGS_SUCCESS";
export const FETCH_DASHBOARD_SETTINGS_FAILURE =
  "FETCH_DASHBOARD_SETTINGS_FAILURE";

export const UPDATE_DASHBOARD_SETTINGS_REQUEST =
  "UPDATE_DASHBOARD_SETTINGS_REQUEST";
export const UPDATE_DASHBOARD_SETTINGS_SUCCESS =
  "UPDATE_DASHBOARD_SETTINGS_SUCCESS";
export const UPDATE_DASHBOARD_SETTINGS_FAILURE =
  "UPDATE_DASHBOARD_SETTINGS_FAILURE";

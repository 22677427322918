import React, { useEffect, useState, useCallback } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Col, Form, Row, Spinner } from "reactstrap";
// import Select from "react-select";
import { ToastMessage } from "../../../../Components/Common/ToastMessage";
import { ToastContainer } from "react-toastify";
import { useFormik } from "formik";
import * as Yup from "yup";

import { changePasswordReq } from "../../../../Api/MyProfile/PagesProfileSetting/PagesProfileSettingReq";
import { ChangePasswordRes } from "../../../../Api/MyProfile/PagesProfileSetting/PagesProfileSettingRes";
// import { USERS } from "../../../../Components/Common/ModuleName";
import { useDispatch, useSelector } from "react-redux";
import { addUserData } from "../../../../store/userData/action";
import axios from "axios";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { MenuHeight } from "../../../NewDashboard/constant";
import { Button } from "react-bootstrap";
import common from "../../../../Components/Common/common";

const Settings = ({ setUpdatedValue, updatedValue, setProfilevalue }) => {
  const token = localStorage.getItem("token");
  const params = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  // const permissionData = useSelector(
  //   (state) => state.userDetails.userPermission
  // );
  // const hasPermission = permissionData?.find(
  //   (item) => item.module_name === USERS
  // );

  const userData = useSelector((state) => state.userDetails.userData);

  const [data, setData] = useState([]);

  // const [activeTab, setActiveTab] = useState("1");
  // // const [roleOptions, setRoleOptions] = useState([]);
  // const [selectedImage, setSelectedImage] = useState(null);
  // const [selectedCoverImage, setSelectedCoverImage] = useState(null);

  // const [showOldPassword, setShowOldPassword] = useState(false);
  // const [showNewPassword, setShowNewPassword] = useState(false);
  // const [showConfirmNewPassword, setShowConfirmNewPassword] = useState(false);
  const [websiteCategory, setWebsiteCategory] = useState([]);
  const [postIsLoading, setPostIsLoading] = useState(false);
  // const [departmentSelectOpt, setDepartmentSelectOpt] = useState([null]);
  // const [errors, setErrors] = useState(null);
  // const [success, setSuccess] = useState(null);
  // const [statusSelectOpt, setStatusSelectOpt] = useState({
  //   value: "Active",
  //   label: "Active",
  // });

  // const tabChange = (tab) => {
  //   if (activeTab !== tab) setActiveTab(tab);
  // };

  useEffect(() => {
    getUserData();
    // getUserType();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getUserData = async () => {
    let reqData = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    };
    let result = await fetch(
      `${process.env.REACT_APP_BASE_URL}users-website/user-management-users/${params.id}`,
      reqData
    );
    let response = await result.json();
    if (response.status === "success") {
      const responseData = common.decrypt(response.data);
      setData(responseData);
    }
    if (response.status === "fail") {
      ToastMessage.Error("Error in fetching the data, Please try again later");
    }
  };

  const changePasswordValidation = useFormik({
    enableReinitialize: true,

    initialValues: {
      oldPassword: "",
      newPassword: "",
      confirmNewPassword: "",
    },

    validationSchema: Yup.object({
      oldPassword: Yup.string().max(255).required("Old password is required."),
      newPassword: Yup.string()
        .min(8, "Password must be at least 8 characters")
        .matches(RegExp("(.*[a-z].*)"), "At least lowercase letter")
        .matches(RegExp("(.*[A-Z].*)"), "At least uppercase letter")
        .matches(RegExp("(.*[0-9].*)"), "At least one number")
        .required("This field is required")
        .test(
          "notSameAsOldPassword",
          "New password must be different from the old password",
          function (value) {
            return value !== this.parent.oldPassword;
          }
        ),
      confirmNewPassword: Yup.string()
        .oneOf([Yup.ref("newPassword"), null], "Passwords must match")
        .required("Confirm Password Required"),
    }),

    onSubmit: (values) => {
      changePassword(values);
    },
  });

  const changePassword = async (values) => {
    try {
      const { url, reqData } = changePasswordReq(values);

      let result = await fetch(url, reqData);
      let response = await result.json();

      ChangePasswordRes(
        changePasswordValidation,
        response,
        // setErrors,
        // setSuccess,
        navigate,
        params.id
      );
    } catch (error) {
      return null;
    }
  };

  const validation = useFormik({
    enableReinitialize: true,

    initialValues: {
      fullName: data ? data.user_name : "",
      contactNo: data.contact_no,
      email: data.email,
      // department: data.department_id,
      organization: data.organization,
      // role: data.role,
      organizationAddress: data.organization_address || "",
      status: data.status,
      description: data.description,
      user_image: data ? data.user_image : "",
      profile_picture: data ? data.profile_picture : "",
    },

    validationSchema: Yup.object({
      fullName: Yup.string().max(255).required("Please enter full name."),
      // contactNo: Yup.string().required("contact no. is required"),
      contactNo: Yup.string().matches(
        /^(\(\d{3}\)[- .]?)?\d{3}[- .]?\d{4}|\d{10}$/,
        "Please enter valid phone number."
      ),

      email: Yup.string()
        .email("Must be a valid email")
        .max(255)
        .required("Please enter email id."),
      // department: Yup.string().max(255).required("Please enter department."),
      organization: Yup.string()
        .max(255)
        .required("Please enter organization."),
      // role: Yup.string().max(255).required("role is required"),
      organizationAddress: Yup.string()
        .max(255)
        .required("Please enter organization address."),
      status: Yup.string().max(255).required("Please enter status."),
      description: Yup.string().max(255).nullable(),
      //   user_image: Yup.mixed().required("Please select the Image."),
      //   profile_picture: Yup.mixed().required("Please select the Cover Image."),
    }),

    onSubmit: (values) => {
      updateUsers(values);
    },
  });

  const updateUsers = async (values) => {
    try {
      setPostIsLoading(true);
      let data = new FormData();
      if (values.user_image && values.user_image.constructor === File) {
        data.append("user_image", values.user_image);
      }

      if (
        values.profile_picture &&
        values.profile_picture.constructor === File
      ) {
        data.append("profile_picture", values.profile_picture);
      }
      data.append("user_name", values.fullName);
      data.append("organization", values.organization);
      data.append("contact_no", values.contactNo);
      data.append("email", values.email);
      data.append("organization_address", values.organizationAddress);
      // data.append("department_id", values.department);
      // data.append("role", values.role);
      data.append("status", values.status);
      data.append("description", values.description);

      let reqData = {
        method: "PATCH",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: data,
      };

      let result = await fetch(
        `${process.env.REACT_APP_BASE_URL}users-website/update-user-management-users/${params.id}`,
        reqData
      );
      let response = await result.json();
      if (response.status === "success") {
        ToastMessage.Success("Details has been updated successfully.");
        dispatch(addUserData(response.data));
        localStorage.setItem("user_name", values.fullName);
        setTimeout(() => {
          navigate("/pages-profile/" + params.id);
        }, 2000);
        getUserData();
        setProfilevalue("1");
        setUpdatedValue(!updatedValue);
        setPostIsLoading(false);
      } else {
        getUserData();
        setPostIsLoading(false);
        ToastMessage.Error("An error occurred. Please try again later.");
      }
    } catch (error) {
      setPostIsLoading(false);
      return null;
    } finally {
      setPostIsLoading(false);
    }
  };

  const getWebsiteData = useCallback(async (searchParams) => {
    try {
      // let params = searchParams || ""
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}websiteCategories/get-category`
      );

      if (response.status === "success") {
        const responseData = common.decrypt(response.data);
        setWebsiteCategory(responseData);
      }
    } catch (error) {
      console.error("Error fetching website data:", error);
    }
  }, []);

  useEffect(() => {
    getWebsiteData();
  }, [getWebsiteData]);

  const departmentOptions = websiteCategory.map((d) => ({
    value: d.id,
    label: d.category,
  }));

  const options = [
    { value: "Active", label: "Active" },
    { value: "In-Active", label: "In-Active" },
  ];

  // const handleSelectChange = (event) => {
  //   const option = departmentOptions.find(
  //     (opt) => opt.value === event.target.value
  //   );
  //   validation.setFieldValue("department", option.value);
  //   setDepartmentSelectOpt(option);
  // };

  const handleStatusSelectChange = (event) => {
    const option = options.find((opt) => opt.value === event.target.value);
    validation.setFieldValue("status", option.value);
    // setStatusSelectOpt(option);
  };


  return (
    <React.Fragment>
      <ToastContainer closeButton={true} />
      <Form onSubmit={validation.handleSubmit}>
        <Row className="g-4">
          <Col md={6} xl={4}>
            {/* <Label>Full Name *</Label> */}
            <TextField
              fullWidth
              label="Full name"
              name="fullName"
              type="text"
              placeholder="Enter your firstname"
              value={validation.values.fullName}
              onChange={validation.handleChange}
              onBlur={validation.handleBlur}
              InputLabelProps={{
                shrink: true,
              }}
              // invalid={
              //   validation.errors.fullName && validation.touched.fullName
              //     ? true
              //     : false
              // }
            />
            {validation.errors.fullName && validation.touched.fullName ? (
              <span className="error-text">{validation.errors.fullName} </span>
            ) : null}
          </Col>
          <Col md={6} xl={4}>
            {/* <Label htmlFor="phonenumberInput" className="form-label">
                Phone Number
              </Label> */}
            <TextField
              fullWidth
              label="Phone number"
              name="contactNo"
              value={validation.values.contactNo}
              onChange={validation.handleChange}
              onBlur={validation.handleBlur}
              InputLabelProps={{
                shrink: true,
              }}
              placeholder="Enter your phone number"
              // invalid={
              //   validation.errors.contactNo && validation.touched.contactNo
              //     ? true
              //     : false
              // }
            />
            {validation.errors.contactNo && validation.touched.contactNo ? (
              <span className="error-text">{validation.errors.contactNo} </span>
            ) : null}
          </Col>
          <Col md={6} xl={4}>
            {/* <Label htmlFor="emailInput" className="form-label">
                Email Address *
              </Label> */}
            <TextField
              fullWidth
              label="Email"
              name="email"
              value={validation.values.email}
              onChange={validation.handleChange}
              onBlur={validation.handleBlur}
              InputLabelProps={{
                shrink: true,
              }}
              type="email"
              placeholder="Enter your email"
              // invalid={
              //   validation.errors.email && validation.touched.email
              //     ? true
              //     : false
              // }
            />
            {validation.errors.email && validation.touched.email ? (
              <span className="error-text">{validation.errors.email} </span>
            ) : null}
          </Col>
          {/* <Col md={6} xl={4}> 
            <FormControl fullWidth>
              <InputLabel id="department-select-label">Department</InputLabel>
              <Select
                labelId="department-select-label"
                id="department-select"
                name="department_id"
                value={validation.values.department || ""}
                onChange={(e) => {
                  validation.setFieldValue("department", e.target.value);
                  // const option = departmentOptions.find(
                  //   (opt) => opt.value === e.target.value
                  // );
                  // setDepartmentSelectOpt(option);
                }}
                onBlur={validation.handleBlur}
                label="Department"
                MenuProps={MenuHeight}
              >
                {departmentOptions?.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            {validation.errors.department && validation.touched.department ? (
              <span style={{ color: "red" }}>
                {validation.errors.department}{" "}
              </span>
            ) : null}
          </Col> */}
          {/* {(userData?.role === "Super Admin" || userData?.role === "Admin") && (
            <Col md={6} xl={4}>
              <FormControl fullWidth>
                <InputLabel id="department-select-label">Department</InputLabel>
                <Select
                  labelId="department-select-label"
                  id="department-select"
                  name="department_id"
                  value={validation.values.department || ""}
                  onChange={(e) => {
                    validation.setFieldValue("department", e.target.value);
                    // const option = departmentOptions.find(
                    //   (opt) => opt.value === e.target.value
                    // );
                    // setDepartmentSelectOpt(option);
                  }}
                  onBlur={validation.handleBlur}
                  label="Department"
                  MenuProps={MenuHeight}
                >
                  {departmentOptions?.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              {validation.errors.department && validation.touched.department ? (
                <span style={{ color: "red" }}>
                  {validation.errors.department}
                </span>
              ) : null}
            </Col>
          )} */}
          <Col md={6} xl={4}>
            {/* <Label htmlFor="firstnameInput" className="form-label">
                Organization *
              </Label> */}
            <TextField
              fullWidth
              label="Organization"
              type="text"
              placeholder="Enter your firstname"
              name="organization"
              value={validation.values.organization}
              onChange={validation.handleChange}
              onBlur={validation.handleBlur}
              InputLabelProps={{
                shrink: true,
              }}
              // invalid={
              //   validation.errors.organization &&
              //   validation.touched.organization
              //     ? true
              //     : false
              // }
            />
            {validation.errors.organization &&
            validation.touched.organization ? (
              <span className="error-text">
                {validation.errors.organization}{" "}
              </span>
            ) : null}
          </Col>
          {/* <Col md={6}>
                            <div className="mb-3">
                              <Label
                                htmlFor="skillsInput"
                                className="form-label"
                              >
                                Role
                              </Label>
                              <Select
                                // isMulti
                                name="role"
                                value={{ value: validation.values.role, label: validation.values.role }}
                                onChange={(selectedOption) => {
                                  validation.setFieldValue("role", selectedOption.value);
                                }}
                                onBlur={validation.handleBlur}
                                options={roleOptions}
                                className="basic-multi-select"
                                classNamePrefix="select"
                              />
                            </div>
                          </Col> */}
          <Col md={6} xl={4}>
            {/* <Label htmlFor="designationInput" className="form-label">
                organization Address *
              </Label> */}
            <TextField
              fullWidth
              label="Organization Address"
              name="organizationAddress"
              value={validation.values.organizationAddress}
              onChange={validation.handleChange}
              onBlur={validation.handleBlur}
              InputLabelProps={{
                shrink: true,
              }}
              // invalid={
              //   validation.errors.organizationAddress &&
              //   validation.touched.organizationAddress
              //     ? true
              //     : false
              // }
              type="text"
              id="designationInput"
              placeholder="Designation"
            />
            {validation.errors.organizationAddress &&
            validation.touched.organizationAddress ? (
              <span style={{ color: "red" }}>
                {validation.errors.organizationAddress}{" "}
              </span>
            ) : null}
          </Col>
          <Col md={6} xl={4}>
            {/* <Label htmlFor="websiteInput1" className="form-label">
                status
              </Label> */}
            {/* <Select
                options={options}
                placeholder="Select an option"
                isSearchable={true}
                // value={statusSelectOpt}
                value={options.find(
                  (opt) => opt.value === validation.values.status
                )}
                onChange={(option) => {
                  validation.setFieldValue("status", option.value);
                  setStatusSelectOpt(option);
                }}
              /> */}
            <FormControl fullWidth>
              <InputLabel>Status</InputLabel>
              <Select
                label="Status"
                name="status"
                value={validation.values.status || ""}
                onChange={handleStatusSelectChange}
                onBlur={validation.handleBlur}
                renderValue={(selected) =>
                  options.find((opt) => opt.value === selected)?.label ||
                  "Select an option"
                }
                displayEmpty
              >
                <MenuItem value="" disabled>
                  Select an option
                </MenuItem>
                {/* {options.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))} */}
                {options.map((option) => (
                  <MenuItem
                    key={option.value}
                    value={option.value}
                    disabled={option.value !== "Active"}
                  >
                    {option.label}
                  </MenuItem>
                ))}
              </Select>
              {validation.errors.status && validation.touched.status && (
                <span>{validation.errors.status}</span>
              )}
            </FormControl>
            {validation.errors.status && validation.touched.status ? (
              <span style={{ color: "red" }}>{validation.errors.status} </span>
            ) : null}
          </Col>
          <Col lg={12}>
            {/* <Label
                htmlFor="exampleFormControlTextarea"
                className="form-label"
              >
                Description
              </Label> */}
            {/* <textarea
                id="exampleFormControlTextarea"
                rows="3"
                value={validation.values.description}
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
                name="description"
              /> */}
            <TextField
              id="exampleFormControlTextarea"
              label="Description"
              multiline
              rows={3}
              value={validation.values.description}
              onChange={validation.handleChange}
              onBlur={validation.handleBlur}
              name="description"
              fullWidth
              variant="outlined"
              placeholder="Enter description"
              InputLabelProps={{
                shrink: true,
              }}
            />

            {validation.errors.description && validation.touched.description ? (
              <span style={{ color: "red" }}>
                {validation.errors.description}{" "}
              </span>
            ) : null}
          </Col>
          {/* {hasPermission?.canModify !== null ? ( */}
          <Col lg={12}>
            <div className="hstack gap-2 justify-content-end">
              <Button
                type="submit"
                className="btn btn-dark"
                disabled={postIsLoading}
              >
                {postIsLoading ? (
                  <Spinner size="sm" variant="light" />
                ) : (
                  "Update"
                )}
              </Button>
              <Button onClick={() => setProfilevalue("1")}>Cancel</Button>
            </div>
          </Col>
          {/* ) : (
            ""
          )} */}
        </Row>
      </Form>
    </React.Fragment>
  );
};

export default Settings;

import React, { useEffect, useState } from "react";
import Flatpickr from "react-flatpickr";
import DataTable from "react-data-table-component";
import { Card, Form } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import LoopIcon from "@mui/icons-material/Loop";
import { Box } from "@mui/material";
import { CustomTableStyle } from "../../Components/Common/constants";
import common from "../../Components/Common/common";
import { Spinner } from "react-bootstrap";
import { useSelector } from "react-redux";

const PerformancePage = () => {
  const token = localStorage.getItem("token");
  const navigate = useNavigate();

  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [query, setQuery] = useState("");
  const [selectedDate, setSelectedDate] = useState(null);

  const generalSettingData = useSelector(
    (state) => state.settingsData.settingsData
  );

  const otherSettingsData = generalSettingData?.filter(
    (item) => item.settings_category === "other"
  );

  const dateFormat = otherSettingsData?.find(
    (date) => date.field_name === "DEFAULT_DATE_FORMAT"
  )?.field_value;

  let startDate, endDate;
  if (selectedDate) {
    [startDate, endDate] = selectedDate;
    startDate = startDate ? moment(startDate).format("YYYY-MM-DD") : null;
    endDate = endDate ? moment(endDate).format("YYYY-MM-DD") : null;
  }

  const handleDateChange = (selectedDates) => {
    if (selectedDates[1] !== undefined) {
      setSelectedDate(selectedDates);
    }
  };

  const fetchData = async (startDate, endDate) => {
    // setIsLoading(true);
    try {
      let url = `${process.env.REACT_APP_BASE_URL}asset/performance-report`;
      if (startDate && endDate) {
        url += `?startDate=${startDate}&endDate=${endDate}`;
      }

      const response = await fetch(url, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const responseData = await response.json();
      const data = common.decrypt(responseData.data);
      setData(data); // Assuming the API response contains a 'data' property with an array of performance data
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchData(startDate, endDate);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startDate, endDate]);

  const handleReset = () => {
    setQuery("");
    setSelectedDate(null);
    fetchData(null, null);
  };

  const columns = [
    {
      name: <span>Website</span>,
      selector: (row) => (
        <span onClick={() => navigate(`/performance/${row.assets_id}`)}>
          <span className="fw-bold cursor-pointer">{row.url || "-"}</span>
        </span>
      ),
      grow: 2,
    },
    {
      name: <span>Page Speed</span>,
      selector: (row) => (
        <span className="badge badge-soft-success">
          {row.pagespeed_score || "-"}
        </span>
      ),
    },
  ];

  const filteredData = data.filter((item) =>
    item.url.toLowerCase().includes(query.toLowerCase())
  );

  return (
    <>
      <Card className="table-main-card">
        <Box className="table-card-header">
          <Box className="left-side">
            <Box className="search-box">
              <Form.Control
                className="form-control"
                type="text"
                placeholder="Search for URL..."
                value={query}
                onChange={(e) => setQuery(e.target.value)}
              />
              <i className="ri-search-line search-icon"></i>
            </Box>
            <Box className="dateinput">
              <Flatpickr
                placeholder="Select Date Range"
                className="form-control"
                options={{
                  mode: "range",
                  dateFormat: `${dateFormat}`,
                }}
                value={selectedDate}
                onChange={handleDateChange}
              />
            </Box>
            <button className="btn btn-primary reset-btn" onClick={handleReset}>
              <LoopIcon />
            </button>
          </Box>
        </Box>
        <Card.Body>
          {isLoading ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "50vh",
              }}
            >
              <Spinner height={100} width={100} />
            </div>
          ) : (
            <DataTable
              columns={columns}
              data={filteredData} // Use filteredData instead of data
              fixedHeader
              fixedHeaderScrollHeight="700px"
              pagination
              customStyles={CustomTableStyle}
              className="table-design"
            />
          )}
        </Card.Body>
      </Card>
    </>
  );
};

export default PerformancePage;

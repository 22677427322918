import { PDFDownloadLink } from "@react-pdf/renderer";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { Button, Card, Col, Modal, Row } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import {
  Avatar,
  Box,
  FormControl,
  FormControlLabel,
  Grid,
  RadioGroup,
  Skeleton,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { ToastMessage } from "../../../Components/Common/ToastMessage";
import Choices from "choices.js";
import "choices.js/public/assets/styles/choices.min.css";
import {
  fetchOnlineAssetsReq,
  fetchSecurityHistoryReq,
  fetchSecurityScanTimeReq,
  postScheduleDataReq,
} from "../../../Api/NewDashboard/DashboardAnalyticTab/DvdlTabsReq";
import {
  fetchOnlineAssetsRes,
  fetchSecurityHistoryRes,
  fetchSecurityScanTimeRes,
  postScheduleDataRes,
} from "../../../Api/NewDashboard/DashboardAnalyticTab/DvdlTabsRes";
import { API_ROUTES } from "../../../Api/ApiRoutes";
import { useSelector } from "react-redux";
import { formatDateTime } from "../../../Components/Common/FormatDateTIme";
import Flatpickr from "react-flatpickr";
import { Form, ModalBody } from "reactstrap";
import DataTable from "react-data-table-component";
import DesktopWindowsRoundedIcon from "@mui/icons-material/DesktopWindowsRounded";
import {
  CalenderClockIcon,
  InboxInIcon,
} from "../../../Components/Common/SvgIcons";
import UpdateRoundedIcon from "@mui/icons-material/UpdateRounded";
import Person2OutlinedIcon from "@mui/icons-material/Person2Outlined";
import Radio from "@mui/material/Radio";

import CONSTANT from "./Constant";
import PDFDocument from "../../NewDashboard/DashboardAnalyticTab/WebsiteReport/PDFDocument";
import SendOutlinedIcon from "@mui/icons-material/SendOutlined";
import common from "../../../Components/Common/common";
const MyListItem = styled("li")(({ theme }) => ({
  margin: theme.spacing(0.5),
}));

const WebSecurityDetailPage = () => {
  const {
    SummaryCardOneComponent,
    SummaryCardTwoComponent,
    SecurityTestCardComponent,
    WebSoftwareSecurityTestComponent,
    GDPRComplianceTestComponent,
    PCIDSSComplianceTestComponent,
    ContentSecurityPolicyTestComponent,
    CookiePrivacyAndSecurityTestComponent,
    HTTPHeaderSecurityComponent,
  } = CONSTANT;

  const token = localStorage.getItem("token");
  const params = useParams();
  const choicesRef = useRef(null);
  const navigate = useNavigate();
  const userId = localStorage.getItem("user_id");

  let daysdata = "30";
  let analyticDaysData = "1";

  const [immuniWeb, setImmuniWeb] = useState([]);

  const [iwTest, setTest] = useState([]);
  const [userName, setUserName] = useState("");

  const [scheduleDate, setScheduleDate] = useState("");
  const [securityHistory, setSecurityHistory] = useState([]);
  const [securityHistoryCount, setSecurityHistoryCount] = useState(null);
  const [securityHistoryScanTime, setSecurityHistoryScanTime] = useState([]);
  const [show, setShow] = useState(false);
  const [emailData, setEmailData] = useState([]);
  const [error, setError] = useState("");
  const [showFirstModal, setShowFirstModal] = useState(false);
  const [scheduleErrorMessage, setScheduleErrorMessage] = useState(null);
  const [scanType, setScanType] = useState("false");
  const [assetsData, setAssetsData] = useState("");
  const [dataLoading, setDataLoading] = useState(false);

  const MemoizedMyHeaders = useMemo(() => {
    const headers = new Headers();
    headers.append("Content-Type", "application/json");
    headers.append("Authorization", `Bearer ${token}`);
    return headers;
  }, [token]);

  const MemoizedUrlencoded = useMemo(() => {
    const URLSearch = {};

    if (emailData?.length > 0) {
      URLSearch.sendResponse = true;
      URLSearch.email = emailData;
    }
    if (daysdata !== undefined) {
      URLSearch.uptimeDays = daysdata.toString();
    }
    if (analyticDaysData !== undefined) {
      URLSearch.days = analyticDaysData.toString();
    }
    // if (typedata !== undefined) {
    //   URLSearch.system_type = typedata.toString();
    // }
    URLSearch.assets_id = params.id;
    URLSearch.users_id = userId;

    return URLSearch;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [emailData, daysdata, analyticDaysData, params.id]);

  const memoizedRequestOptions = useMemo(() => {
    return {
      method: "POST",
      headers: MemoizedMyHeaders,
      body: JSON.stringify(MemoizedUrlencoded),
      redirect: "follow",
    };
  }, [MemoizedMyHeaders, MemoizedUrlencoded]);

  const fetchOnlineAssets = useCallback(async () => {
    try {
      const { url, userData } = fetchOnlineAssetsReq({ params, token });

      let result = await fetch(url, userData);
      let response = await result.json();

      fetchOnlineAssetsRes({
        response,
        setAssetsData,
        navigate,
      });
    } catch (error) {
      console.error(error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.id, navigate, token]);

  useEffect(() => {
    fetchOnlineAssets();
  }, [fetchOnlineAssets]);

  const fetchImmuniWeb = useCallback(async () => {
    try {
      setDataLoading(true);
      let immuni = await fetch(
        API_ROUTES.GET_IMMUNI_WEB_V2_DATA,
        memoizedRequestOptions
      );
      let data1 = await immuni.json();
      const responseData = common.decrypt(data1.data);
      data1.data = responseData;
      const responseMessage = common.decrypt(data1.message);
      data1.message = responseMessage;

      if (data1.status === "success") {
        setUserName(data1.userName);
        setImmuniWeb(data1.data);
        setTest(data1.data.updatedAt);

        setDataLoading(false);
        // socket.emit("sendwebsecurityreport");
      }
      if (data1.status === "fail") {
        setDataLoading(false);
        return null;
      }
    } catch (error) {
      console.error(error);
      setDataLoading(false);
    } finally {
      setDataLoading(false);
    }
  }, [memoizedRequestOptions]);

  const fetchSecurityHistory = useCallback(async () => {
    try {
      const { url, userData } = fetchSecurityHistoryReq({
        params,
        userId,
        token,
      });

      let schedule = await fetch(url, userData);
      let response = await schedule.json();

      fetchSecurityHistoryRes({
        response,
        setSecurityHistory,
        setSecurityHistoryCount,
      });
    } catch (error) {
      console.error(error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.id, userId, token]);

  const fetchSecurityScanTime = useCallback(async () => {
    try {
      const { url, userData } = fetchSecurityScanTimeReq({
        params,
        userId,
        token,
      });

      let scheduleSecurityTime = await fetch(url, userData);
      let response = await scheduleSecurityTime.json();

      const decryptedData = common.decrypt(response.data);
      response.data = decryptedData;

      fetchSecurityScanTimeRes({
        response,
        setSecurityHistoryScanTime,
      });
    } catch (error) {
      console.error(error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.id, userId, token]);

  const postScheduleData = async (recurringScan) => {
    try {
      const { url, userData } = postScheduleDataReq({
        token,
        userName,
        userId,
        params,
        scheduleDate,
        recurringScan,
      });

      let result = await fetch(url, userData);
      let response = await result.json();

      postScheduleDataRes({
        response,
        ToastMessage,
        fetchSecurityHistory,
        fetchSecurityScanTime,
        setScheduleDate,
        setShowFirstModal,
        setScanType,
      });
    } catch (error) {
      console.error(error);
    }
  };

  const [showModal, setShowModal] = React.useState(false);

  // const handleShow = () => setShowModal(true);
  const handleClose = () => setShowModal(false);

  // send reports
  const handleShowInput = () => setShow(true);
  const handleCloseInput = () => {
    setError("");
    setShow(false);
  };
  const handleSaveChangesClick = useCallback(() => {
    const selectedItems = choicesRef.current.getValue(true);
    if (selectedItems.length === 0) {
      setError("Please enter email address.");
    } else {
      setEmailData(selectedItems);
      handleCloseInput();
      ToastMessage.Success("report sent successfully.");
    }
  }, []);

  useEffect(() => {
    if (show) {
      const element = document.getElementById("choices-email-input");
      if (element) {
        const choicesInstance = new Choices(element, {
          removeItemButton: true,
          duplicateItemsAllowed: true,
          maxItemCount: 10,
          placeholderValue: "Enter email address",
          addItemFilter: (value) => {
            const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            if (emailRegex.test(value)) {
              setError("");
              return true;
            } else {
              return false;
            }
          },
        });
        choicesRef.current = choicesInstance;
      }
    }
  }, [show]);

  // Schedule test
  const handleFirstModalClose = () => {
    setShowFirstModal(false);
  };

  const handleScanTypeChange = (event) => {
    setScanType(event.target.value);
  };

  const toggleShow = () => {
    setScheduleDate("");
    setShowFirstModal(!showFirstModal);
  };

  const handleDate = (date) => {
    setScheduleDate(date[0]);
    if (date[0]) {
      setScheduleErrorMessage(null);
    }
  };

  const currentDateTime = new Date();

  let closestTimeDifference = Infinity;

  let nearestDate = null;
  let nearestData = null;

  securityHistory.forEach((item) => {
    const createdAtDateTime = new Date(item.createdAt);
    const timeDifference = Math.abs(currentDateTime - createdAtDateTime);

    if (timeDifference < closestTimeDifference) {
      closestTimeDifference = timeDifference;
    }
  });

  securityHistoryScanTime.forEach((dateTimeString) => {
    const date = new Date(dateTimeString.schedule_date_time);

    if (date > currentDateTime && (!nearestDate || date < nearestDate)) {
      nearestDate = date;
      nearestData = dateTimeString;
    }
  });

  useEffect(() => {
    fetchImmuniWeb();
    fetchSecurityHistory();
    fetchSecurityScanTime();
  }, [
    fetchSecurityHistory,
    fetchImmuniWeb,
    fetchSecurityScanTime,
    daysdata,
    analyticDaysData,
    iwTest,
  ]);

  const currentRunningDate = new Date();

  const isCurrentDate = (selectedDate) => {
    const selectedDateTime = new Date(selectedDate);

    return (
      selectedDateTime.getDate() === currentRunningDate.getDate() &&
      selectedDateTime.getMonth() === currentRunningDate.getMonth() &&
      selectedDateTime.getFullYear() === currentRunningDate.getFullYear()
    );
  };

  const getMinTime = (selectedDate) => {
    return isCurrentDate(selectedDate)
      ? currentRunningDate.toLocaleTimeString([], {
          hour12: false,
          hour: "2-digit",
          minute: "2-digit",
        })
      : "00:00";
  };
  const getDefaultMinTime = () => {
    const date = new Date();
    date.setMinutes(date.getMinutes() + 15); // Add 15 minutes
    return date.toLocaleTimeString([], {
      hour12: false,
      hour: "2-digit",
      minute: "2-digit",
    });
  };

  const intialMinTime = getDefaultMinTime();

  // const intialMinTime = currentRunningDate.toLocaleTimeString([], {
  //   hour12: false,
  //   hour: "2-digit",
  //   minute: "2-digit",
  // });

  const summaryData = immuniWeb?.summary ? JSON.parse(immuniWeb.summary) : null;
  const webServerSecurityTest = immuniWeb?.web_server_security_test
    ? JSON.parse(immuniWeb.web_server_security_test)
    : null;

  const webSoftwareSecurityTest = immuniWeb?.web_software_security_test
    ? JSON.parse(immuniWeb.web_software_security_test)
    : null;

  const gdprComplianceTest = immuniWeb?.gdpr_compliance
    ? JSON.parse(immuniWeb.gdpr_compliance)
    : null;

  const pcidssComplianceTest = immuniWeb?.pci_dss_compliance
    ? JSON.parse(immuniWeb.pci_dss_compliance)
    : null;

  const httpHeaderSecurityData = immuniWeb?.http_header_security_data
    ? JSON.parse(immuniWeb.http_header_security_data)
    : null;

  const contentSecurityPolicyTest = immuniWeb?.content_security_policy_test
    ? JSON.parse(immuniWeb.content_security_policy_test)
    : null;

  const cookiesPrivacySecurityTest = immuniWeb?.cookies_privacy_security_test
    ? JSON.parse(immuniWeb.cookies_privacy_security_test)
    : null;

  let immuni = immuniWeb?.length === 1 ? immuniWeb : undefined;
  const testedLength = securityHistoryCount || 0;
  const testCount = testedLength ?? "-";

  const generalSettingData = useSelector(
    (state) => state.settingsData.settingsData
  );
  const otherSettingsData = generalSettingData?.filter(
    (item) => item.settings_category === "other"
  );
  const dateFormat = otherSettingsData?.find(
    (date) => date.field_name === "DEFAULT_DATE_FORMAT"
  )?.field_value;
  const timeZone = otherSettingsData?.find(
    (time) => time.field_name === "DEFAULT_TIME_FORMAT"
  )?.field_value;
  const timeFormat = otherSettingsData?.find(
    (zone) => zone.field_name === "DEFAULT_TIME_ZONE"
  )?.field_value;
  const time = common.formateTime(timeZone);

  const columns = [
    {
      name: "Date",
      selector: (row) =>
        formatDateTime(
          row?.schedule_date_time,
          dateFormat,
          timeZone,
          timeFormat
        ).timeZone.formattedDateFinal || "-",
      sortable: true,
    },
    {
      name: "Time",
      selector: (row) =>
        formatDateTime(
          row?.schedule_date_time,
          dateFormat,
          timeZone,
          timeFormat
        ).timeZone.formattedTimed || "-",
      sortable: true,
    },
    {
      name: "Done By",
      selector: (row) => row?.tested_by,
      sortable: true,
    },
    {
      name: "Completed Date",
      selector: (row) =>
        row.completed_date_time
          ? formatDateTime(
              row?.completed_date_time,
              dateFormat,
              timeZone,
              timeFormat
            ).timeZone.formattedDateFinal
          : "-",
      sortable: true,
    },
    {
      name: "Time",
      selector: (row) =>
        row.completed_date_time
          ? formatDateTime(
              row?.completed_date_time,
              dateFormat,
              timeZone,
              timeFormat
            ).timeZone.formattedTimed
          : "-",
      sortable: true,
    },
    {
      name: "Report",
      cell: (row) => (
        <a href="*">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            className="feather feather-file-text icon-sm"
          >
            <path d="M14 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8z"></path>
            <polyline points="14 2 14 8 20 8"></polyline>
            <line x1="16" y1="13" x2="8" y2="13"></line>
            <line x1="16" y1="17" x2="8" y2="17"></line>
            <polyline points="10 9 9 9 8 9"></polyline>
          </svg>
        </a>
      ),
    },
  ];

  const ReportData = [
    {
      id: 1,
      icon: <SendOutlinedIcon />,
      title: "Send Report",
      onclick: handleShowInput,
    },
    {
      id: 2,
      icon: <InboxInIcon />,
      title: "Download Report",
    },
    {
      id: 3,
      icon: <CalenderClockIcon />,
      title: "Schedule Security Scan",
      onclick: toggleShow,
    },
  ];

  const rensderReportCard = ReportData?.map((data) => {
    // if (data.id === 3 && !webPermission?.includes("execute")) {
    //   return null; // Do not render the "Schedule Security Scan" card if the condition is not met
    // }
    return (
      <Col xs={12} key={data.id}>
        <Card className="report-card">
          {data.id !== 2 ? (
            <Card.Body onClick={data.onclick}>
              {data.icon}
              <p className="title">{data.title}</p>
            </Card.Body>
          ) : (
            <PDFDownloadLink
              document={
                <PDFDocument
                  onlineAssetData={assetsData}
                  immuniWeb={immuniWeb}
                  userName={userName}
                  testCount={testCount}
                />
              }
              fileName="Website Security Report.pdf"
            >
              <Card.Body onClick={data.onclick}>
                {data.icon}
                <p className="title">{data.title}</p>
              </Card.Body>
            </PDFDownloadLink>
            // <></>
          )}
        </Card>
      </Col>
    );
  });

  const ListCardData = [
    {
      id: 1,
      icon: <CalenderClockIcon />,
      title: "Security Tested On",
      time: (
        <span>
          {iwTest?.length > 0 ? (
            <>
              {dataLoading ? (
                <Skeleton variant="text" width={150} height={35} />
              ) : (
                <>
                  {formatDateTime(iwTest, dateFormat, timeZone, timeFormat)
                    .timeZone.formattedDateFinal || "-"}{" "}
                  {formatDateTime(iwTest, dateFormat, timeZone, timeFormat)
                    .timeZone.formattedTimed || "-"}
                </>
              )}
            </>
          ) : (
            <>
              {formatDateTime(
                immuni?.updatedAt,
                dateFormat,
                timeZone,
                timeFormat
              ).timeZone.formattedDateFinal || "-"}{" "}
              {formatDateTime(
                immuni?.updatedAt,
                dateFormat,
                timeZone,
                timeFormat
              ).timeZone.formattedTimed || "-"}
            </>
          )}
        </span>
      ),
    },
    {
      id: 2,
      icon: <UpdateRoundedIcon />,
      title: "Next Security Test on",
      time: (
        <span>
          {nearestData ? (
            <>
              {dataLoading ? (
                <Skeleton variant="text" width={150} height={35} />
              ) : (
                <>
                  {formatDateTime(
                    nearestData?.schedule_date_time,
                    dateFormat,
                    timeZone,
                    timeFormat
                  ).timeZone.formattedDateFinal || "-"}{" "}
                  {formatDateTime(
                    nearestData?.schedule_date_time,
                    dateFormat,
                    timeZone,
                    timeFormat
                  ).timeZone.formattedTimed || "-"}
                </>
              )}
            </>
          ) : (
            "Date Not Scheduled"
          )}
        </span>
      ),
    },
    {
      id: 3,
      icon: <Person2OutlinedIcon />,
      title: "Scan By",
      time: (
        <span>
          {userName !== undefined ? (
            <>
              {dataLoading ? (
                <Skeleton variant="text" width={150} height={35} />
              ) : (
                `${userName}`
              )}
            </>
          ) : (
            " "
          )}
        </span>
      ),
    },
  ];

  const renderListCard = ListCardData.map((data) => {
    return (
      <Col xs={12} key={data.id}>
        <Card className="list-card">
          {data.icon}
          <Box>
            <p className="title">{data.title}</p>
            <p className="subtitle">{data.time}</p>
          </Box>
        </Card>
      </Col>
    );
  });

  return (
    <Box className="websecurity-scanning">
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Card className="summary-container main-card">
            <Card.Body>
              <Box className="text-center mb-2">
                {dataLoading ? (
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      height: "50px",
                    }}
                  >
                    <Skeleton variant="text" width={500} height={50} />
                  </Box>
                ) : (
                  // <h4 className="main-title">
                  //   Summary of {immuniWeb?.url} Website Security Test
                  // </h4>
                  <h4 className="main-title">
                    Summary of{" "}
                    <a
                      href={immuniWeb?.url || ""}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {immuniWeb?.url}
                    </a>{" "}
                    Website Security Test
                  </h4>
                )}
                {dataLoading ? (
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      height: "50px",
                    }}
                  >
                    <Skeleton variant="text" width={400} height={50} />
                  </Box>
                ) : (
                  <p className="main-subtitle">
                    <a
                      href={immuniWeb?.url || ""}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {immuniWeb?.url}
                    </a>{" "}
                    was tested {testCount} times during the last 12 months.
                  </p>
                )}

                <Row className="g-3 g-xxxl-5 mt-3">
                  <Col md={12} xl={5}>
                    <Card className="mb-0 grade-card">
                      <Card.Body>
                        {dataLoading ? (
                          <Skeleton variant="text" width={100} height={200} />
                        ) : (
                          <>
                            <i className="grade">
                              {summaryData && summaryData?.grade !== null
                                ? `${summaryData?.grade}`
                                : "-"}
                            </i>
                          </>
                        )}
                        {/* <i className="grade">
                          {summaryData && summaryData?.grade !== undefined
                            ? `${summaryData?.grade}`
                            : "-"}
                        </i> */}
                        <Box className="text-center">
                          <p className="title">Your Final Score</p>
                          <p className="subtitle">Grade</p>
                        </Box>
                      </Card.Body>
                      <Avatar variant="rounded" className="icon-box">
                        <DesktopWindowsRoundedIcon />
                      </Avatar>
                    </Card>
                  </Col>
                  {/* {webPermission?.includes("execute") ? (
                  ) : (
                    <> </>
                  )} */}
                  <Col md={5} xl={3}>
                    <Row className="g-4">{rensderReportCard}</Row>
                  </Col>

                  <Col md={7} xl={4}>
                    <Row className="g-4">{renderListCard}</Row>
                    {/* <Row>
                  <Col xs={12}>
                    <Button
                      variant="link"
                      className="border-0 mt-3 bg-transparent d-inline-flex align-items-center justify-content-center text-primary"
                      onClick={handleShow}
                    >
                      <span>
                        List of Security Scan List{" "}
                        <CallMadeRoundedIcon
                          sx={{
                            fontSize: "20px",
                            color: "#1e283b",
                            marginLeft: "5px",
                          }}
                        />
                      </span>
                    </Button>
                  </Col>
                </Row> */}
                  </Col>
                </Row>
              </Box>
              <Box className="row g-3 g-xxxl-5 mt-3">
                <Box className="col-12 col-xxl-5">
                  <Box className="row g-3 g-xxxl-5">
                    {/* {renderSummaryCardOne} */}
                    <SummaryCardOneComponent summaryData={summaryData} />
                  </Box>
                </Box>
                <Box className="col-12 col-xxl-7">
                  <Box className="row g-3 g-xxxl-5">
                    {/* {renderSummaryCardTwo} */}
                    <SummaryCardTwoComponent summaryData={summaryData} />
                  </Box>
                </Box>
              </Box>
            </Card.Body>
          </Card>
        </Grid>
        {webServerSecurityTest && (
          <Grid item xs={12}>
            <SecurityTestCardComponent
              webServerSecurityTest={webServerSecurityTest}
            />
          </Grid>
        )}

        {webSoftwareSecurityTest && (
          <Grid item xs={12} xl={6}>
            <WebSoftwareSecurityTestComponent
              webSoftwareSecurityTest={webSoftwareSecurityTest}
            />
          </Grid>
        )}
        {gdprComplianceTest?.length > 0 ? (
          <Grid item xs={12} xl={6}>
            <GDPRComplianceTestComponent
              gdprComplianceTest={gdprComplianceTest}
            />
          </Grid>
        ) : (
          " "
        )}
        {pcidssComplianceTest?.length > 0 ? (
          <Grid item xs={12} xl={6}>
            <PCIDSSComplianceTestComponent
              pcidssComplianceTest={pcidssComplianceTest}
            />
          </Grid>
        ) : (
          " "
        )}
        {/* <Grid item xs={12} xl={6}>
          <PCIDSSComplianceTestComponent
            pcidssComplianceTest={pcidssComplianceTest}
          />
        </Grid> */}
        {/* {httpHeaderSecurityData && (
          <Grid item xs={12} xl={6}>
            <HTTPHeaderSecurityComponent
              httpHeaderSecurityData={httpHeaderSecurityData}
              MyListItem={MyListItem}
            />
          </Grid>
        )} */}
        {httpHeaderSecurityData &&
          httpHeaderSecurityData?.bodyInfo &&
          httpHeaderSecurityData?.bodyInfo?.length > 0 && (
            <Grid item xs={12} xl={6}>
              <HTTPHeaderSecurityComponent
                httpHeaderSecurityData={httpHeaderSecurityData}
                MyListItem={MyListItem}
              />
            </Grid>
          )}

        {contentSecurityPolicyTest &&
          contentSecurityPolicyTest?.content_security_policy &&
          contentSecurityPolicyTest?.content_security_policy_report_only && (
            <Grid item xs={12} xl={6}>
              <ContentSecurityPolicyTestComponent
                contentSecurityPolicyTest={contentSecurityPolicyTest}
              />
            </Grid>
          )}
        {/* {cookiesPrivacySecurityTest && (
          <Grid item xs={12} xl={6}>
            <CookiePrivacyAndSecurityTestComponent
              cookiesPrivacySecurityTest={cookiesPrivacySecurityTest}
            />
          </Grid>
        )} */}
        {cookiesPrivacySecurityTest &&
          cookiesPrivacySecurityTest?.cookieInfo &&
          cookiesPrivacySecurityTest?.bodyInfo &&
          cookiesPrivacySecurityTest?.bodyInfo?.length > 0 && (
            <Grid item xs={12} xl={6}>
              <CookiePrivacyAndSecurityTestComponent
                cookiesPrivacySecurityTest={cookiesPrivacySecurityTest}
              />
            </Grid>
          )}
      </Grid>

      <Modal
        show={showFirstModal}
        onHide={handleFirstModalClose}
        aria-labelledby="firstmodal"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Schedule Security Scan</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Flatpickr
            placeholder="Select date"
            className="form-control bg-light border-light border-0"
            value={scheduleDate}
            options={{
              enableTime: true,
              dateFormat: `${dateFormat} ${time}`,
              minDate: "today",
              minTime: scheduleDate ? getMinTime(scheduleDate) : intialMinTime,
            }}
            onChange={handleDate}
            style={{ borderRadius: "8px", padding: "0.7rem 0.9rem" }}
          />
          {scheduleErrorMessage !== null && (
            <p style={{ color: "red" }}>{scheduleErrorMessage}</p>
          )}
          {/* <label>
            <input
              name="scan-type"
              type="radio"
              value="false"
              checked={scanType === "false"}
              onChange={handleScanTypeChange}
            />
            <span style={{ paddingLeft: "5px" }}>Single Scan</span>
          </label>

          <label style={{ paddingLeft: "50px" }}>
            <input
              name="scan-type"
              type="radio"
              value="true"
              checked={scanType === "true"}
              onChange={handleScanTypeChange}
            />
            <span style={{ paddingLeft: "5px" }}>Recurring Scan</span>
          </label> */}
          <FormControl className="mt-3">
            <RadioGroup
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="row-radio-buttons-group"
              value={scanType}
              onChange={handleScanTypeChange}
            >
              <FormControlLabel
                value="false"
                control={
                  <Radio
                    sx={{
                      "&.Mui-checked": {
                        color: "#1a2232",
                      },
                    }}
                  />
                }
                label="Single Scan"
                checked={scanType === "false"}
              />
              <FormControlLabel
                value="true"
                control={
                  <Radio
                    sx={{
                      "&.Mui-checked": {
                        color: "#1a2232",
                      },
                    }}
                  />
                }
                label="Recurring Scan"
                checked={scanType === "true"}
              />
            </RadioGroup>
          </FormControl>
        </Modal.Body>
        <Modal.Footer>
          <Button
            onClick={() => {
              handleFirstModalClose();
              setScanType("false");
            }}
          >
            Cancel
          </Button>
          <Button
            className="btn btn-dark"
            onClick={() => {
              if (!scheduleDate) {
                setScheduleErrorMessage("please select the date first");
              } else {
                postScheduleData(scanType);
              }
            }}
          >
            submit
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={show} onHide={handleCloseInput} centered>
        <ModalBody>
          <Form>
            <Box className="mb-3">
              <label htmlFor="choices-email-input">Email</label>
              <input
                id="choices-email-input"
                className="form-control bg-light border-light border-0"
                type="text"
                placeholder="Enter email address"
              />
            </Box>
            {error && <Box className="text-danger">{error}</Box>}
          </Form>
        </ModalBody>
        <Modal.Footer>
          <Button className="btn btn-primary" onClick={handleCloseInput}>
            Close
          </Button>
          <Button
            className="btn btn-dark"
            type="submit"
            onClick={handleSaveChangesClick}
          >
            Send
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal
        show={showModal}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        centered
        size="lg"
        aria-labelledby="listsecuritydateLabel"
      >
        <Modal.Body className="p-2">
          <DataTable
            columns={columns}
            data={securityHistory}
            striped
            highlightOnHover
            pagination
          />
          <Box className="d-flex justify-content-center">
            <Button
              variant="link"
              className="link-success fw-medium"
              onClick={handleClose}
            >
              <i className="ri-close-line me-1 align-middle"></i> Close
            </Button>
          </Box>
        </Modal.Body>
      </Modal>
    </Box>
  );
};

export default WebSecurityDetailPage;

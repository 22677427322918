import {
  ADD_COMPLIANCE,
  ADD_COMPLIANCE_FAIL,
  ADD_COMPLIANCE_SUCCESS,
} from "./actionType";

const INIT_STATE = {
  addComplianceLoading: false,
  addComplianceError: null,
};

const Compliance = (state = INIT_STATE, action) => {
  switch (action.type) {
    case ADD_COMPLIANCE:
      return {
        ...state,
        addComplianceLoading: true,
        addComplianceError: null,
      };
    case ADD_COMPLIANCE_SUCCESS:
      return {
        ...state,
        addComplianceLoading: false,
      };
    case ADD_COMPLIANCE_FAIL:
      return {
        ...state,
        addComplianceLoading: false,
        addComplianceError: action.payload,
      };
    default:
      return { ...state };
  }
};

export default Compliance;

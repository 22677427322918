import { combineReducers } from "redux";

// Front
import Layout from "./layouts/reducer";

// Authentication
import Login from "./auth/login/reducer";
import Account from "./auth/register/reducer";
import ForgetPassword from "./auth/forgetpwd/reducer";
import Profile from "./auth/profile/reducer";

//Setting Tab
import settingTab from "./settingTab/reducer";

//Website POlicies
import WebsitePolicy from "./websitePolicies/reducer";

//Announcements
import announcement from "./announcements/reducer";

// Settings Data
import settingsData from "./settings/reducer";

//Notification
import notification from "./notification/reducer";

// User Data 
import userDetails from "./userData/reducer";

// online assets
import onlineAssets from "./onlineAssets/reducer";

// Developers Profile
import developersProfile from "./developersProfile/reducer";

// Compliance
import compliance from "./compliance/reducer";

// uptime report 
import uptimeReport from "./uptimeReport/reducer";

// Analytics Data
import analyticsData from "./analytics/reducer";

// Dashboard 
import dashboard from "./dashboard/reducer";

const rootReducer = combineReducers({
    Layout,
    Login,
    Account,
    ForgetPassword,
    Profile,
    settingTab,
    WebsitePolicy,
    announcement,
    settingsData,
    notification,
    userDetails,
    onlineAssets,
    developersProfile,
    compliance,
    uptimeReport,
    analyticsData,
    dashboard,
});

export default rootReducer;
import { API_ROUTES } from "../../ApiRoutes";


export const WebsiteCategoriesListReq = () => {
    let url = API_ROUTES.GET_WEBSITE_CATEGORIES;
    let reqData = {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
    };

    return { url, reqData };

}

export const ForgetPasswordRes = (props) => {
    const { response, setExtra, setErrors, setSuccess, validation } = props

    switch (response.status) {
        case 'success':
            setErrors(null);
            setExtra(null);
            setSuccess(response.message);
            validation.resetForm();
            break;

        case 'fail':
            setExtra(null);
            setSuccess(null);
            setErrors(response.message);

            if (response.message === "Email not sent: The domain or email address is blocked.") {
                setExtra("Please contact administrator for further assistance.");
            }
            break;

        default:
            break;
    }

}
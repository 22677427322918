import { put, call, all, fork, takeLatest } from "redux-saga/effects";
import axios from "axios";
import * as actions from "./action";
import * as types from "./actionType";
import common from "../../Components/Common/common";

function* fetchDevelopersSaga(action) {
  try {
    const assetId = action.payload;
    const response = yield call(
      axios.get,
      `developer-profile/get-developer-profile-by-assets-id/${assetId}`
    );
    const decryptedData = common.decrypt(response.data);
    response.data = decryptedData;
    yield put(actions.fetchDevelopersSuccess(response.data));
  } catch (error) {
    yield put(actions.fetchDevelopersFailure(error));
  }
}

function* fetchApiDataSaga(action) {
  try {
    const assetId = action.payload;

    // Correct call syntax
    const response = yield call(axios.post, `online-assets/fetch-api-data`, {
      assetId: assetId,
    });

    const decryptedData = common.decrypt(response.data);
    response.data = decryptedData;

    yield put(actions.fetchApiDataSuccess(response.data));
  } catch (error) {
    yield put(actions.fetchApiDataFailure(error));
  }
}

export function* watchFetchDevelopers() {
  yield takeLatest(types.FETCH_DEVELOPERS_REQUEST, fetchDevelopersSaga);
}

export function* watchApiData() {
  yield takeLatest(types.FETCH_API_DATA_REQUEST, fetchApiDataSaga);
}

function* onlineAssetSaga() {
  yield all([fork(watchFetchDevelopers), fork(watchApiData)]);
}

export default onlineAssetSaga;

import { styled } from "@mui/material/styles";
import Badge from "@mui/material/Badge";
import { Switch } from "@mui/material";

export const StyledBadge = styled(Badge)(({ theme }) => ({
  "& .MuiBadge-badge": {
    backgroundColor: "#44b700",
    color: "#44b700",
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    "&::after": {
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      borderRadius: "50%",
      animation: "ripple 1.2s infinite ease-in-out",
      border: "1px solid currentColor",
      content: '""',
    },
  },
  "@keyframes ripple": {
    "0%": {
      transform: "scale(.8)",
      opacity: 1,
    },
    "100%": {
      transform: "scale(2.4)",
      opacity: 0,
    },
  },
}));

export const CustomTableStyle = {
  tableWrapper: {
    style: {
      minHeight: "250px",
    },
  },
  headCells: {
    style: {
      paddingLeft: "9px",
      paddingRight: "9px",
      background: "#f3f6f9",
      color: "#878a99",
    },
  },
  cells: {
    style: {
      padding: "6px 9px",
    },
  },
};

export const AndroidSwitch = styled(Switch)(({ theme }) => ({
  padding: 8,
  position: "relative",
  "&.MuiSwitch-root": {
    width: 72,
    "&:has(.MuiSwitch-switchBase.Mui-checked)": {
      "& .MuiSwitch-track::after": {
        display: "none",
      },
      "& .MuiSwitch-track::before": {
        display: "block",
      },
    },
  },
  "& .MuiSwitch-track": {
    borderRadius: 22 / 2,
    background: "#E3EAEE",
    "&::before, &::after": {
      content: '""',
      position: "absolute",
      top: "50%",
      transform: "translateY(-50%)",
      width: 27,
      height: 23,
      color: "#1A2232",
      fontSize: "14px",
      fontWeight: 500,
    },
    "&::before": {
      content: '"ON"',
      left: 12,
      display: "none",
    },
    "&::after": {
      content: '"OFF"',
      right: 12,
    },
  },
  "& .MuiSwitch-thumb": {
    boxShadow: "none",
    width: 16,
    height: 16,
    margin: 2,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#9DA3AB",
    position: "relative",
    "&::after": {
      content: '""',
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      width: "100%",
      textAlign: "center",
      fontSize: "0.75rem",
      color: theme.palette.text.primary,
      pointerEvents: "none",
    },
    "&.Mui-checked::after": {
      content: '""',
    },
  },
  "& .MuiSwitch-switchBase.Mui-checked": {
    transform: "translateX(32px)",
  },
  "& .MuiSwitch-switchBase.Mui-checked+.MuiSwitch-track": {
    backgroundColor: "#1e2638",
    opacity: 1,
    "&::before": {
      color: "#fff",
    },
  },
}));

import React from "react";

const ProfileImage = ({
  username,
  height,
  width,
  marginRight,
  border,
  borderRadius,
  fontSize,
}) => {
  // Split the username into parts using space as a delimiter
  const nameParts = username && username.split(" ");

  // Extract the first letter from each part
  const firstInitial = nameParts[0]?.charAt(0).toUpperCase() || "";
  const lastInitial = nameParts[1]?.charAt(0).toUpperCase() || "";

  // Combine the initials to create a display name
  const displayName = firstInitial + lastInitial;

  // You can style the profile image with CSS or inline styles
  const imageStyle = {
    width: width ? width : "32px",
    height: height ? height : "32px",
    backgroundColor: "#e4e8eb",
    color: "#1a2232",
    borderRadius: borderRadius ? borderRadius : "50%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontSize: fontSize ? fontSize : "14px",
    marginRight: marginRight ? marginRight : "0px",
    border: border ? border : "",
    zIndex: 1,
    position: "relative",
  };

  return (
    <div style={imageStyle} className="profile-image">
      {displayName}
    </div>
  );
};

export default ProfileImage;

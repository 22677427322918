import React, { useCallback, useEffect, useState } from "react";
import { Tab, Modal, Accordion, ListGroup, Button } from "react-bootstrap";
import DvdlHeader from "./DvdlHeader";
import DvdlTabs from "./DvdlTabs";
import { Input } from "reactstrap";
import { ToastMessage } from "../../../Components/Common/ToastMessage";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import ProfileImage from "../../../Components/Common/ProfileImage";
import { API_ROUTES } from "../../../Api/ApiRoutes";
import TicketModal from "./common/TicketModal.js";
import UserDetailModal from "./UserDetailModal.js";
import { Avatar, Box } from "@mui/material";
// import EmailRoundedIcon from "@mui/icons-material/EmailRounded";
import {
  EnvelopeIcon,
  SquarePhoneIcon,
} from "../../../Components/Common/SvgIcons.js";
import SimpleBar from "simplebar-react";
import { socket } from "../../../Components/Common/socket.js";
import common from "../../../Components/Common/common.js";

const Index = () => {
  const token = localStorage.getItem("token");
  const userId = localStorage.getItem("user_id");

  const [show, setShow] = useState(false);
  const [addTicketShow, setAddTicketShow] = useState(false);
  const [ticketShow, setTicketShow] = useState(false);
  const [showActivity, setShowActivity] = useState(false);
  const [attachment, setAttachment] = useState("");
  const [teamMembers, setTeamMembers] = useState([]);
  const [developers, setDevelopers] = useState([]);
  const [selectedMember, setSelectedMember] = useState(null);
  const [activeTab, setActiveTab] = useState("1");
  const [headerUpdate, setHeaderUpdate] = useState(false);
  const [selectedDeveloperDetail, setSelectedDeveloperDetail] = useState(null);
  const [detailShow, setDetailShow] = useState(false);
  const [dataOf, setDataOf] = useState(null);
  const [originalName, setOriginalName] = useState("");
  const [websiteGroupOptions, setWebsiteGroupOptions] = useState([]);

  const params = useParams();
  const location = useLocation();
  const navigate = useNavigate();

  const handleClose = () => setShow(false);
  const DetailClose = () => setDetailShow(false);
  const handleShow = () => setShow(true);

  const handleTicketModalClose = () => {
    setAddTicketShow(false);
  };

  const handleHeaderUpdate = () => setHeaderUpdate((prevState) => !prevState);

  const projectOptions = [
    { label: "High", value: "High" },
    { label: "Medium", value: "Medium" },
    { label: "Low", value: "Low" },
  ];

  const postAttachments = async () => {
    let formData = new FormData();
    formData.append("user_id", userId);
    formData.append("assets_id", params.id);
    formData.append("attachments", attachment);
    formData.append("original_name", originalName);
    let reqData = {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      body: formData,
    };
    let url = `${process.env.REACT_APP_BASE_URL}attachments/post-attachments`;
    let result = await fetch(url, reqData);
    let response = await result.json();
    if (response.code === 403) {
      navigate("/auth-404-cover");
    }
    if (response.status === "success") {
      setShow(false);
      setShowActivity((prev) => !prev);
      setAttachment(null);
      socket.emit("assetattachment");
      setOriginalName(null);
      ToastMessage.Success("Attachment added successfully");
    }
    if (response.status === "fail") {
      setShow(false);
      ToastMessage.Error(response.message);
    }
  };

  const dashboardAnalyticAddTicket = async (values) => {
    const formData = new FormData();

    if (values.ticket && values.ticket.constructor === File) {
      formData.append("ticket-image", values.ticket);
    }

    formData.append("title", values.title);
    formData.append("description", values.description);
    formData.append("priority", values.priority);
    formData.append("assets_id", params.id);
    formData.append("department_id", values.department_id);
    formData.append("original_name", values.ticket.name);
    try {
      let config = {
        method: "post",
        url: `${process.env.REACT_APP_BASE_URL}Newticketing-system/Add-ticket`,
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token}`,
        },
        data: formData,
      };

      const ticketAddedResult = await axios.request(config);

      if (ticketAddedResult.status === "success" && ticketAddedResult.data) {
        setTicketShow((prev) => !prev);
        ToastMessage.Success("Ticket has been successfully added");
        navigate(location.pathname);
      }
    } catch (error) {
      ToastMessage.Error(error);
    }
  };

  const fetchWebsiteCategories = async () => {
    try {
      let { status, data } = await axios.get(API_ROUTES.GET_WEBSITE_CATEGORIES);
      const responseData = common.decrypt(data);
      if (status === "success" && responseData) {
        setWebsiteGroupOptions(
          responseData.map((item) => {
            return {
              label: item.category,
              value: item.category,
              id: item.id,
            };
          })
        );
      }
    } catch (err) {
      console.error(err.message);
      setWebsiteGroupOptions([]);
    }
  };

  useEffect(() => {
    fetchWebsiteCategories();
  }, []);

  const fetchTeamMembers = useCallback(async () => {
    try {
      const response = await axios.get(
        `${API_ROUTES.GET_USERS_BY_ASSET_ID}${params.id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response.status === "success") {
        const decryptedData = common.decrypt(response.data);
        response.data = decryptedData;
        setTeamMembers(response.data);
      }
    } catch (error) {
      console.error(error);
    }
  }, [params.id, token]);

  const fetchdevelopers = useCallback(async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}developer-profile/get-developer-profile-by-assets-id/${params.id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status === "success") {
        const decryptedData = common.decrypt(response.data);
        response.data = decryptedData;
        setDevelopers(response.data);
      }
    } catch (error) {
      console.error(error);
    }
  }, [params.id, token]);

  useEffect(() => {
    fetchTeamMembers();
    fetchdevelopers();
  }, [fetchTeamMembers, fetchdevelopers]);

  const handleDetailclick = (teamMember, data) => {
    setDataOf(data);
    setDetailShow(true);

    if (data === "team") {
      setSelectedMember(teamMember);
      setSelectedDeveloperDetail(null);
    } else if (data === "developer") {
      setSelectedMember(null);
      setSelectedDeveloperDetail(teamMember);
    }
  };

  return (
    <Box className="online-assets-page">
      <Tab.Container id="left-tabs-example" defaultActiveKey="Activity">
        <Box className="row g-4">
          <Box
            className={
              [1, 2].includes(parseInt(activeTab))
                ? "col-12 col-xl-12 col-xxxl-9"
                : "col-12"
            }
          >
            <DvdlHeader onUpdate={handleHeaderUpdate} />
            <DvdlTabs
              assetID={params.id}
              ticketShow={ticketShow}
              activeTab={location.state ? location.state.tab : ""}
              showActivity={showActivity}
              setActiveTab={setActiveTab}
              headerUpdate={headerUpdate}
            />
          </Box>
          {[1, 2].includes(parseInt(activeTab)) ? (
            <Box className="col-12 col-md-6 col-lg-4 col-xl-4 col-xxxl-3">
              <Accordion
                defaultActiveKey="0"
                className="assets-accordion"
                id="default-accordion-example"
              >
                <Accordion.Item eventKey="0">
                  <Accordion.Header>Tickets</Accordion.Header>
                  <Accordion.Body>
                    <p className="fs-14 text-primary">
                      Track the customer requests associated with this record.
                    </p>
                    <Button
                      title="Add a Ticket"
                      className="add-btn"
                      onClick={() => setAddTicketShow(true)}
                    >
                      <i className="ri-add-fill fs-20 me-1"></i>
                      <span>Add Ticket</span>
                    </Button>
                  </Accordion.Body>
                </Accordion.Item>

                <Accordion.Item eventKey="1">
                  <Accordion.Header>Attachments</Accordion.Header>
                  <Accordion.Body>
                    <p className="fs-14 text-primary">
                      See the files attached to your activities or uploaded to
                      this record.
                    </p>

                    <Button
                      title="Add attachments"
                      className="add-btn"
                      onClick={handleShow}
                    >
                      <i className="ri-add-fill fs-20 me-1"></i>
                      <span>Add Attachments</span>
                    </Button>
                  </Accordion.Body>
                </Accordion.Item>

                <Accordion.Item eventKey="2">
                  <Accordion.Header>Team Members</Accordion.Header>
                  <Accordion.Body>
                    <ListGroup variant="flush" className="vstack gap-3 mb-0">
                      <SimpleBar
                        style={{ maxHeight: "500px" }}
                        autoHide={false}
                      >
                        {teamMembers.length > 0 ? (
                          teamMembers.map((item, index) => (
                            <ListGroup.Item key={index}>
                              <Box className="flex-shrink-0">
                                {item.Image != null ? (
                                  <Avatar
                                    alt="img"
                                    src={`${process.env.REACT_APP_BASE_URL}${item.Image}`}
                                    sx={{ width: 50, height: 50 }}
                                  />
                                ) : (
                                  <ProfileImage
                                    username={item.name || "Demo User"}
                                    height="50px"
                                    width="50px"
                                    fontSize="30px"
                                  />
                                )}
                              </Box>
                              <Box className="flex-grow-1 ms-3">
                                <h6 className="title">
                                  {item.name}
                                  <i
                                    className="ri-information-line fs-18 fw-normal align-middle cursor-pointer ms-2"
                                    id="create-btn"
                                    onClick={() =>
                                      handleDetailclick(item, "team")
                                    }
                                    style={{
                                      color: "#ced4da",
                                    }}
                                  ></i>
                                </h6>
                                <p className="subtitle">{item.designation}</p>
                                <Box className="gap-4">
                                  {item.email ? (
                                    <a
                                      href={`mailto:${item.email}`}
                                      style={{ marginRight: "8px" }}
                                    >
                                      <EnvelopeIcon color="green" />
                                    </a>
                                  ) : (
                                    <EnvelopeIcon color="black" />
                                  )}
                                  <a
                                    href={`tel:${item.contact_no}`}
                                    style={{ marginRight: "8px" }}
                                  >
                                    <SquarePhoneIcon />
                                  </a>
                                </Box>
                              </Box>
                            </ListGroup.Item>
                          ))
                        ) : (
                          <ListGroup.Item>
                            <Box className="flex-grow-1 ms-2">
                              <h6 className="mb-1">
                                <span className="link-secondary">
                                  No Members
                                </span>
                              </h6>
                            </Box>
                          </ListGroup.Item>
                        )}
                      </SimpleBar>
                    </ListGroup>
                  </Accordion.Body>
                </Accordion.Item>

                <Accordion.Item eventKey="3">
                  <Accordion.Header>Developers</Accordion.Header>
                  <Accordion.Body>
                    <ListGroup variant="flush" className="vstack gap-3 mb-0">
                      <SimpleBar
                        style={{ maxHeight: "500px" }}
                        autoHide={false}
                      >
                        {developers.length > 0 ? (
                          developers.map((item, index) => (
                            <ListGroup.Item key={index}>
                              <Box className="flex-shrink-0">
                                {item.image != null ? (
                                  <Avatar
                                    alt="img"
                                    src={`${process.env.REACT_APP_BASE_URL}${item.image}`}
                                    sx={{ width: 50, height: 50 }}
                                  />
                                ) : (
                                  <ProfileImage
                                    username={item.name || "Demo User"}
                                    height="50px"
                                    width="50px"
                                    fontSize="30px"
                                  />
                                )}
                              </Box>
                              <Box className="flex-grow-1 ms-3">
                                <h6 className="title">
                                  {item.name}
                                  <i
                                    className="ri-information-line fs-18 fw-normal align-middle cursor-pointer ms-2"
                                    id="create-btn"
                                    onClick={() =>
                                      handleDetailclick(item, "developer")
                                    }
                                    style={{
                                      color: "#ced4da",
                                    }}
                                  ></i>
                                </h6>
                                <p className="subtitle">{item.organization}</p>
                                {/* <p className="text-muted mb-0">
                                {item.contact_no}
                              </p> */}
                                <Box className="gap-4">
                                  {item.email ? (
                                    <a
                                      href={`mailto:${item.email}`}
                                      style={{ marginRight: "8px" }}
                                    >
                                      <EnvelopeIcon color="green" />
                                    </a>
                                  ) : (
                                    <EnvelopeIcon color="black" />
                                  )}
                                  <a
                                    href={`tel:${item.contact_no}`}
                                    style={{ marginRight: "8px" }}
                                  >
                                    <SquarePhoneIcon />
                                  </a>
                                </Box>
                              </Box>
                            </ListGroup.Item>
                          ))
                        ) : (
                          <ListGroup.Item>
                            <Box className="flex-grow-1 ms-2">
                              <h6 className="mb-1">
                                <span className="link-secondary">
                                  No Developers
                                </span>
                              </h6>
                            </Box>
                          </ListGroup.Item>
                        )}
                      </SimpleBar>
                    </ListGroup>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </Box>
          ) : (
            ""
          )}
        </Box>
      </Tab.Container>

      <Modal show={show} onHide={handleClose} centered keyboard={false}>
        <Modal.Header closeButton>
          <h5 className="modal-title" id="inviteMembersModalLabel">
            Add Attachments
          </h5>
        </Modal.Header>
        <Modal.Body>
          <Box className="mb-3">
            <Input
              type="file"
              className="form-control bg-light border-light"
              onChange={(e) => setAttachment(e.currentTarget.files[0])}
            />
          </Box>
        </Modal.Body>
        <Modal.Footer>
          <Button className="btn btn-primary w-xs" onClick={handleClose}>
            Cancel
          </Button>
          <Button
            className="btn btn-dark w-xs"
            onClick={() => postAttachments()}
          >
            Submit
          </Button>
        </Modal.Footer>
      </Modal>

      <UserDetailModal
        show={detailShow}
        onHide={DetailClose}
        developer={selectedDeveloperDetail}
        team={selectedMember}
        dataOf={dataOf}
      />

      <TicketModal
        show={addTicketShow}
        onHide={handleTicketModalClose}
        handleModalClose={handleTicketModalClose}
        projectOptions={projectOptions}
        dashboardAnalyticAddTicket={dashboardAnalyticAddTicket}
        websiteGroupOptions={websiteGroupOptions}
      />
    </Box>
  );
};

export default Index;

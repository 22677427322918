import {
  UPDATE_WEBSITEPOLICIES_FAIL,
  UPDATE_WEBSITEPOLICIES_SUCCESS,
  FETCH_WEBSITEPOLICIES_SUCCESS,
  FETCH_WEBSITEPOLICIES_FAIL,
} from "./actionType";

const INIT_STATE = {
  websitepolicyContent: "",
};

const WebsitePolicy = (state = INIT_STATE, action) => {
  switch (action.type) {
    case FETCH_WEBSITEPOLICIES_SUCCESS: {
      return {
        ...state,
        websitepolicyContent: action.payload,
        isWebsitePolicyFetched: true,
        isWebsitePolicyFetchedFail: false,
      };
    }
    case FETCH_WEBSITEPOLICIES_FAIL: {
      return {
        ...state,
        error: action.payload,
        isWebsitePolicyFetched: false,
        isWebsitePolicyFetchedFail: true,
      };
    }

    case UPDATE_WEBSITEPOLICIES_SUCCESS:
      return {
        ...state,
        websitepolicyContent: action.payload,
        isWebsitePolicyUpdate: true,
        isWebsitePolicyUpdateFail: false,
      };

    case UPDATE_WEBSITEPOLICIES_FAIL:
      return {
        ...state,
        error: action.payload,
        isWebsitePolicyUpdate: false,
        isWebsitePolicyUpdateFail: true,
      };

    default:
      return { ...state };
  }
};

export default WebsitePolicy;

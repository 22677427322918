import { socket } from "../../../Components/Common/socket";

export const AddEditBrandingGuidelineRes = (props) => {
  const {
    response,
    navigate,
    getData,

    setShow,
    ToastMessage,
    validation,
    setId,
    id,
  } = props;
  if (response.code === "403") {
    navigate("/auth-404-cover");
  }
  if (response.status === "success" || response.status === "fail") {
    navigate("/branding-guideline");
    id
      ? socket.emit("updateBrandingGuideline")
      : socket.emit("addBrandingGuideline");
    getData();
    setShow(false);

    ToastMessage[response.status === "success" ? "Success" : "Error"](
      response.message
    );
    setTimeout(() => {
      validation.resetForm();
    }, 1000);
    setId("");
  }
};

export const ListBrandingGuidelineRes = (props) => {
  const { response, navigate, setData, responseData } = props;
  switch (response.status) {
    case "fail":
      console.error("something went wrong. please try again later.");
      break;
    case "success":
      setData(responseData);
      break;
    default:
      console.error("something went wrong. please try again later.");
  }
  switch (response.code) {
    case "401":
    case "403":
    case "404":
      navigate("/auth-404-cover");
      break;
    case "200":
      setData(responseData);
      break;
    default:
      console.error("something went wrong. please try again later.");
  }
};

export const DeleteBrandingGuidelineRes = (props) => {
  const { response, getData, ToastMessage, navigate } = props;
  switch (response.status) {
    case "fail":
      ToastMessage.Error(response.message);
      break;
    case "success":
      getData();
      socket.emit("deleteBrandingGuideline");
      ToastMessage.Success(response.message);
      break;
    default:
      console.error("something went wrong. please try again later.");
  }
  switch (response.code) {
    case "401":
    case "403":
    case "404":
      navigate("/auth-404-cover");
      break;
    case "200":
      getData();
      break;
    default:
      console.error("something went wrong. please try again later.");
  }
};

import React, { useEffect, useRef, useState } from 'react';

import withRouter from '../Common/withRouter';

import { useSelector } from "react-redux";

const RightSidebar = (props) => {
    // const dispatch = useDispatch();
    const leftSidebarDark = useRef(null);
    const preloaderRef = useRef(null);

    const [show, setShow] = useState(false);

    // eslint-disable-next-line
    const handleToggle = () => {
        setShow(!show);
    };


    useEffect(() => {
        if (show && leftSidebarDark.current && document.getElementById("sidebar-color-light")) {
            leftSidebarDark.current.checked = false;
            document.getElementById("sidebar-color-light").checked = false;
        }
    })

    const {

        preloader
    } = useSelector(state => ({
        layoutType: state.Layout.layoutType,
        leftSidebarType: state.Layout.leftSidebarType,
        layoutModeType: state.Layout.layoutModeType,
        layoutWidthType: state.Layout.layoutWidthType,
        layoutPositionType: state.Layout.layoutPositionType,
        topbarThemeType: state.Layout.topbarThemeType,
        leftsidbarSizeType: state.Layout.leftsidbarSizeType,
        leftSidebarViewType: state.Layout.leftSidebarViewType,
        leftSidebarImageType: state.Layout.leftSidebarImageType,
        preloader: state.Layout.preloader
    }));

    // open offcanvas
    const [open, setOpen] = useState(false);
    
    // eslint-disable-next-line
    const toggleLeftCanvas = () => {
        setOpen(!open);
    };

    window.onscroll = function () {
        scrollFunction();
    };

    const scrollFunction = () => {
        const element = document.getElementById("back-to-top");
        if (element) {
            if (document.body.scrollTop > 100 || document.documentElement.scrollTop > 100) {
                element.style.display = "block";
            } else {
                element.style.display = "none";
            }
        }
    };

    const toTop = () => {
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
    };

    const pathName = props.router.location.pathname;

    useEffect(() => {
        const preloader = preloaderRef.current;
        if (preloader) {
            preloaderRef.current.style.opacity = "1";
            preloaderRef.current.style.visibility = "visible";
            setTimeout(function () {
                preloaderRef.current.style.opacity = "0";
                preloaderRef.current.style.visibility = "hidden";
            }, 1000);
        }
    }, [preloader, pathName]);

    return (
        <React.Fragment>
            <button
                onClick={() => toTop()}
                className="btn btn-danger btn-icon" id="back-to-top">
                <i className="ri-arrow-up-line"></i>
            </button>

            {preloader === "enable" && <div id="preloader" ref={preloaderRef}>
                <div id="status">
                    <div className="spinner-border text-primary avatar-sm" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </div>
                </div>
            </div>}

        </React.Fragment>
    );
};

export default withRouter(RightSidebar);
import React, { useState, useEffect, useCallback } from "react";
import { Button, Card, Col, Row } from "react-bootstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import { FormFeedback, Spinner } from "reactstrap";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  FormControl,
  FormControlLabel,
  FormLabel,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
} from "@mui/material";
import { MenuHeight } from "../NewDashboard/constant";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import common from "../../Components/Common/common";

function SmtpMail() {
  const token = localStorage.getItem("token");

  const intialValues = {
    MAILER: "mail_api",
    MAIL_API_URL: "",
    MAIL_API_TOKEN: "",
    MAIL_API_SITE_ID: "",
    MAIL_SENDER_NAME: "",
    MAIL_SENDER_EMAIL_ID: "",
    SMTP_SERVER: "",
    SMTP_USERNAME: "",
    SMTP_PASSWORD: "",
    SMTP_ENCRYPTION: "",
    SMTP_PORT: "",
    SMTP_AUTHENTICATION: "",
    SMTP_SENDER_NAME: "",
    SMTP_SENDER_EMAIL: "",
  };

  const navigate = useNavigate();
  // const [isMailApi, setIsMailApi] = useState(false);
  // const [isSmtp, setIsSmtp] = useState(false);
  const [passwordShow, setPasswordShow] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  // const handleClickShowPassword = () => setPasswordShow(!passwordShow);

  // const [selectLogin, setSelectLogin] = useState([]);

  // const handleChangeLogin = (event) => {
  //   const {
  //     target: { value },
  //   } = event;
  //   setSelectLogin(
  //     // On autofill we get a stringified value.
  //     typeof value === "string" ? value.split(",") : value
  //   );
  // };

  const [smtpStatus, setSmtpStatus] = useState("mail_api");
  const [encryption, setEncryption] = useState("select");

  const options = {
    mailer: [
      { label: "Mail API", value: "mail_api" },
      { label: "SMTP", value: "smtp" },
    ],

    smtpEncryption: [
      { label: "None", value: "None" },
      { label: "TLS", value: "TLS" },
      { label: "SSL", value: "SSL" },
    ],
    smtpAuthentication: [
      { label: "Yes", value: "Y" },
      { label: "No", value: "N" },
    ],
  };
  const validationSchema = Yup.object().shape({
    // MAILER: Yup.string().required("Please select the mailer option"),
    // MAIL_API_URL: Yup.string().required("Please enter Mail Api url"),
    // MAIL_API_TOKEN: Yup.string().required("Please enter Mail Api token"),
    // MAIL_API_SITE_ID: Yup.string()
    //   .matches(/^[1-9]+$/, "Please enter digit between 1 to 9")
    //   .required("Please enter Mail Api site id"),
    // MAIL_SENDER_NAME: Yup.string().required("Please enter Mail sender name"),
    // MAIL_SENDER_EMAIL_ID: Yup.string().required(
    //   "Please enter Mail sender email id"
    // ),
    // SMTP_SERVER: Yup.string().required("Please enter SMTP server"),
    // SMTP_USERNAME: Yup.string().required("Please enter SMTP username"),
    // SMTP_PASSWORD: Yup.string().required("Please enter SMTP password"),
    // SMTP_ENCRYPTION: Yup.string().required("Please select SMTP encryption"),
    // SMTP_PORT: Yup.string().required("Please enter SMTP port"),
    // SMTP_AUTHENTICATION: Yup.string().required(
    //   "Please select SMTP authentication"
    // ),
    // SMTP_SENDER_NAME: Yup.string().required("Please enter SMTP sender name"),
    // SMTP_SENDER_EMAIL: Yup.string().required("Please enter SMTP sender email"),
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: intialValues,
    validationSchema: validationSchema,
    onSubmit: async (values, { setSubmitting }) => {
      try {
        await PostData(values);
      } catch (error) {
        console.error(error);
      }
    },
  });

  const PostData = async ({
    MAIL_API_URL,
    MAIL_API_TOKEN,
    MAIL_API_SITE_ID,
    MAIL_SENDER_NAME,
    MAIL_SENDER_EMAIL_ID,
    SMTP_SERVER,
    SMTP_USERNAME,
    SMTP_PASSWORD,
    SMTP_ENCRYPTION,
    SMTP_PORT,
    SMTP_AUTHENTICATION,
    SMTP_SENDER_NAME,
    SMTP_SENDER_EMAIL,
  }) => {
    try {
      setIsLoading(true);
      let url = `${process.env.REACT_APP_BASE_URL}settings/add-settings`;
      let reqData = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          MAIL_API_URL: MAIL_API_URL,
          MAIL_API_TOKEN: MAIL_API_TOKEN,
          MAIL_API_SITE_ID: MAIL_API_SITE_ID,
          MAIL_SENDER_NAME: MAIL_SENDER_NAME,
          MAIL_SENDER_EMAIL_ID: MAIL_SENDER_EMAIL_ID,
          SMTP_SERVER: SMTP_SERVER,
          SMTP_USERNAME: SMTP_USERNAME,
          SMTP_PASSWORD: SMTP_PASSWORD,
          SMTP_ENCRYPTION: SMTP_ENCRYPTION,
          SMTP_PORT: SMTP_PORT,
          SMTP_AUTHENTICATION: SMTP_AUTHENTICATION,
          SMTP_SENDER_NAME: SMTP_SENDER_NAME,
          SMTP_SENDER_EMAIL: SMTP_SENDER_EMAIL,
        }),
      };

      let result = await fetch(url, reqData);
      let response = await result.json();

      if (response.code === 403) {
        navigate("/auth-404-cover");
      }
      if (response.code === "200") {
        toast.success(response.message);
      }
    } catch (error) {
      setIsLoading(false);
      toast.error("An error occurred while processing your request.");
    } finally {
      setIsLoading(false);
    }
  };

  // const mailerOptions = options.mailer.map((option) => ({
  //   value: option.value,
  //   label: option.label,
  // }));
  // const handleFieldChange = (field, value) => {
  //   formik.setFieldValue(field, value);

  //   if (smtpStatus === "mail_api") {
  //     setIsMailApi(true);
  //     setIsSmtp(false);
  //   }

  //   if (smtpStatus === "smtp") {
  //     setIsSmtp(true);
  //     setIsMailApi(false);
  //   }
  // };

  // const handleFieldTouched = (field) => {
  //   formik.setFieldTouched(field, true);
  // };

  // useEffect(() => {
  //   setIsMailApi(true);
  // }, []);

  const getSettings = useCallback(async () => {
    try {
      let url = `${process.env.REACT_APP_BASE_URL}settings/get-settings`;
      let reqData = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };
      let result = await fetch(url, reqData);
      let response = await result.json();

      const decryptedData = common.decrypt(response.data);
      response.data = decryptedData;

      if (response.code === 403) {
        navigate("/auth-404-cover");
      }

      if (response.code === "200") {
        const fieldValuesMap = {};
        response.data.rows.forEach((row) => {
          fieldValuesMap[row.field_name] = row.field_value;
        });
        formik.setValues({
          ...formik.values,
          MAIL_API_URL: fieldValuesMap["MAIL_API_URL"] || "",
          MAIL_API_TOKEN: fieldValuesMap["MAIL_API_TOKEN"] || "",
          MAIL_API_SITE_ID: fieldValuesMap["MAIL_API_SITE_ID"] || "",
          MAIL_SENDER_NAME: fieldValuesMap["MAIL_SENDER_NAME"] || "",
          MAIL_SENDER_EMAIL_ID: fieldValuesMap["MAIL_SENDER_EMAIL_ID"] || "",
          SMTP_SERVER: fieldValuesMap["SMTP_SERVER"] || "",
          SMTP_USERNAME: fieldValuesMap["SMTP_USERNAME"] || "",
          SMTP_PASSWORD: fieldValuesMap["SMTP_PASSWORD"] || "",
          SMTP_ENCRYPTION: fieldValuesMap["SMTP_ENCRYPTION"] || "",
          SMTP_PORT: fieldValuesMap["SMTP_PORT"] || "",
          SMTP_AUTHENTICATION: fieldValuesMap["SMTP_AUTHENTICATION"] || "",
          SMTP_SENDER_NAME: fieldValuesMap["SMTP_SENDER_NAME"] || "",
          SMTP_SENDER_EMAIL: fieldValuesMap["SMTP_SENDER_EMAIL"] || "",
        });
      } else {
        toast.error(response.message);
      }
    } catch (error) {
      toast.error("An error occurred while fetching API key.");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navigate, token]);

  useEffect(() => {
    getSettings();
  }, [getSettings]);

  return (
    <React.Fragment>
      <Card>
        <Card.Body className="setting-card-body">
          <form noValidate onSubmit={formik.handleSubmit}>
            <Row className="g-4 mb-4">
              <Col lg={6}>
                <FormControl fullWidth>
                  <InputLabel>Select Mail</InputLabel>
                  <Select
                    fullWidth
                    label="Select Mail"
                    MenuProps={MenuHeight}
                    value={smtpStatus}
                    onChange={(event) => {
                      setSmtpStatus(event.target.value);
                    }}
                  >
                    <MenuItem disabled value="select">
                      select
                    </MenuItem>
                    {options.mailer.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Col>
            </Row>

            {smtpStatus === "mail_api" && (
              <>
                <Row className="g-4">
                  <Col md={6}>
                    <TextField
                      fullWidth
                      required
                      label="API URL"
                      placeholder="Enter API URL"
                      name="MAIL_API_URL"
                      value={formik.values.MAIL_API_URL}
                      {...formik.getFieldProps("MAIL_API_URL")}
                    />
                  </Col>
                  <Col md={6}>
                    <TextField
                      fullWidth
                      required
                      label="TOKEN"
                      placeholder="Enter TOKEN"
                      name="MAIL_API_TOKEN"
                      type="text"
                      value={formik.values.MAIL_API_TOKEN}
                      {...formik.getFieldProps("MAIL_API_TOKEN")}
                    />
                  </Col>
                  <Col md={6}>
                    <TextField
                      fullWidth
                      required
                      label="SITE ID"
                      placeholder="Enter SITE ID"
                      name="MAIL_API_SITE_ID"
                      type="text"
                      value={formik.values.MAIL_API_SITE_ID}
                      {...formik.getFieldProps("MAIL_API_SITE_ID")}
                    />
                  </Col>
                  <Col md={6}>
                    <TextField
                      fullWidth
                      required
                      label="Sender Name"
                      placeholder="Enter Sender Name"
                      name="MAIL_SENDER_NAME"
                      type="text"
                      value={formik.values.MAIL_SENDER_NAME}
                      {...formik.getFieldProps("MAIL_SENDER_NAME")}
                    />
                  </Col>
                  <Col md={6}>
                    <TextField
                      fullWidth
                      required
                      label="Sender Email ID"
                      placeholder="Enter Sender Email ID"
                      name="MAIL_SENDER_EMAIL_ID"
                      type="email"
                      value={formik.values.MAIL_SENDER_EMAIL_ID}
                      {...formik.getFieldProps("MAIL_SENDER_EMAIL_ID")}
                    />
                  </Col>
                </Row>
              </>
            )}
            {smtpStatus === "smtp" && (
              <>
                <Row className="g-4">
                  <Col md={6}>
                    <TextField
                      fullWidth
                      label="Host"
                      id="SMTP_SERVER"
                      name="SMTP_SERVER"
                      type="text"
                      value={formik.values.SMTP_SERVER}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                  </Col>
                  <Col md={6}>
                    <TextField
                      fullWidth
                      label="Username"
                      id="SMTP_USERNAME"
                      name="SMTP_USERNAME"
                      type="text"
                      value={formik.values.SMTP_USERNAME}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                  </Col>
                  <Col md={6}>
                    <TextField
                      fullWidth
                      label="Password"
                      id="SMTP_PASSWORD"
                      name="SMTP_PASSWORD"
                      type={passwordShow ? "text" : "password"}
                      value={formik.values.SMTP_PASSWORD}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={() => setPasswordShow(!passwordShow)}
                              edge="end"
                            >
                              {passwordShow ? (
                                <VisibilityOff />
                              ) : (
                                <Visibility />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                    {/* <button
                        onClick={() => setPasswordShow(!passwordShow)}
                        className="btn btn-link position-absolute end-0 top-0 text-decoration-none text-muted password-addon"
                        type="button"
                        id="password-addon"
                      >
                        <i className="ri-eye-fill align-middle"></i>
                      </button> */}
                  </Col>
                  <Col md={6}>
                    <FormControl fullWidth>
                      <InputLabel>Encryption</InputLabel>
                      <Select
                        fullWidth
                        label="Encryption"
                        MenuProps={MenuHeight}
                        value={encryption}
                        onChange={(event) => {
                          setEncryption(event.target.value);
                        }}
                      >
                        <MenuItem disabled value="select">
                          select
                        </MenuItem>
                        {options.smtpEncryption.map((option) => (
                          <MenuItem key={option.value} value={option.value}>
                            {option.label}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    {formik.errors.SMTP_ENCRYPTION &&
                      formik.touched.SMTP_ENCRYPTION && (
                        <FormFeedback type="d-block" style={{ color: "red" }}>
                          {formik.errors.SMTP_ENCRYPTION}
                        </FormFeedback>
                      )}
                  </Col>
                  <Col md={6}>
                    {/* {options.smtpAuthentication.map((option) => (
                      <FormGroup key={option.value}>
                        <Label>
                          <TextField
                            fullWidth
                            type="radio"
                            name="SMTP_AUTHENTICATION"
                            value={option.value}
                            checked={
                              formik.values.SMTP_AUTHENTICATION === option.value
                            }
                            onChange={(e, item) =>
                              handleFieldChange(
                                "SMTP_AUTHENTICATION",
                                e.target.value
                              )
                            }
                            onBlur={() =>
                              handleFieldTouched("SMTP_AUTHENTICATION")
                            }
                          />
                          {option.label}
                        </Label>
                      </FormGroup>
                    ))} */}
                    <FormControl>
                      <FormLabel>SMTP Authentication *</FormLabel>
                      <RadioGroup
                        row
                        name="SMTP_AUTHENTICATION"
                        value={formik.values.SMTP_AUTHENTICATION}
                        onChange={formik.handleChange}
                      >
                        <FormControlLabel
                          value="Y"
                          control={<Radio />}
                          label="Yes"
                        />
                        <FormControlLabel
                          value="N"
                          control={<Radio />}
                          label="No"
                        />
                      </RadioGroup>
                    </FormControl>
                    {formik.errors.SMTP_AUTHENTICATION &&
                      formik.touched.SMTP_AUTHENTICATION && (
                        <FormFeedback type="d-block" style={{ color: "red" }}>
                          {formik.errors.SMTP_AUTHENTICATION}
                        </FormFeedback>
                      )}
                  </Col>
                  <Col md={6}>
                    <TextField
                      fullWidth
                      label="Port"
                      aria-required
                      id="SMTP_PORT"
                      name="SMTP_PORT"
                      type="text"
                      value={formik.values.SMTP_PORT}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                    {formik.touched.SMTP_PORT && formik.errors.SMTP_PORT && (
                      <span className="error-message">
                        {formik.errors.SMTP_PORT}
                      </span>
                    )}
                  </Col>
                  <Col md={6}>
                    <TextField
                      fullWidth
                      label="Sender Name"
                      id="SMTP_SENDER_NAME"
                      name="SMTP_SENDER_NAME"
                      type="text"
                      value={formik.values.SMTP_SENDER_NAME}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                    {formik.touched.SMTP_SENDER_NAME &&
                      formik.errors.SMTP_SENDER_NAME && (
                        <span className="error-message">
                          {formik.errors.SMTP_SENDER_NAME}
                        </span>
                      )}
                  </Col>
                  <Col md={6}>
                    <TextField
                      fullWidth
                      label="Sender Email"
                      id="SMTP_SENDER_EMAIL"
                      name="SMTP_SENDER_EMAIL"
                      type="email"
                      value={formik.values.SMTP_SENDER_EMAIL}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                    {formik.touched.SMTP_SENDER_EMAIL &&
                      formik.errors.SMTP_SENDER_EMAIL && (
                        <span className="error-message">
                          {formik.errors.SMTP_SENDER_EMAIL}
                        </span>
                      )}
                  </Col>
                </Row>
              </>
            )}

            <Row className="g-4 mt-3">
              <Col sm={12}>
                <Button
                  className="btn btn-primary ms-auto d-block"
                  type="submit"
                  disabled={isLoading}
                >
                  {isLoading ? (
                    <Spinner size="sm" variant="light" />
                  ) : (
                    <>Save &amp; Keep Editing</>
                  )}
                </Button>
              </Col>
            </Row>
          </form>
        </Card.Body>
      </Card>
    </React.Fragment>
  );
}
export default SmtpMail;

import {
  CHANGE_LAYOUT,
  CHANGE_SIDEBAR_THEME,
  CHANGE_LAYOUT_MODE,
  CHANGE_LAYOUT_WIDTH,
  CHANGE_LAYOUT_POSITION,
  CHANGE_TOPBAR_THEME,
  CHANGE_SIDEBAR_SIZE_TYPE,
  CHANGE_SIDEBAR_VIEW,
  CHANGE_SIDEBAR_IMAGE_TYPE,
  RESET_VALUE,
  CHANGE_PRELOADER,
  SIDEBAR_CURRENT_STATE,
  SET_DASHBOARD,
  SET_WEBSITE_MANAGEMENT,
  SET_USER_MANAGEMENT,
  SET_ACCESS_RIGHTS,
  SET_PAGES,
  SET_GUIDELINES_AND_POLICIES,
  SET_ANNOUNCEMENTS,
  SET_TICKETING_SYSTEM,
  SET_REPORTING,
} from "./actionType";

//constants
import {
  layoutTypes,
  leftSidebarTypes,
  layoutModeTypes,
  layoutWidthTypes,
  layoutPositionTypes,
  topbarThemeTypes,
  leftsidbarSizeTypes,
  leftSidebarViewTypes,
  leftSidebarImageTypes,
  preloaderTypes,
} from "../../Components/constants/layout";

const INIT_STATE = {
  layoutType: layoutTypes.VERTICAL,
  leftSidebarType: leftSidebarTypes.DARK,
  layoutModeType: layoutModeTypes.LIGHTMODE,
  layoutWidthType: layoutWidthTypes.FLUID,
  layoutPositionType: layoutPositionTypes.FIXED,
  topbarThemeType: topbarThemeTypes.LIGHT,
  leftsidbarSizeType: leftsidbarSizeTypes.DEFAULT,
  leftSidebarViewType: leftSidebarViewTypes.DEFAULT,
  leftSidebarImageType: leftSidebarImageTypes.NONE,
  preloader: preloaderTypes.DISABLE,
  sidebarCurrentState: "Dashboard",
  isDashboard: false,
  isWebsiteManagement: false,
  isUserManagement: false,
  isAccessRights: false,
  isPages: false,
  isGuidelinesAndPolicies: false,
  isAnnouncements: false,
  isTicketingSystem: false,
  isReporting: false,
};

const Layout = (state = INIT_STATE, action) => {
  switch (action.type) {
    case CHANGE_LAYOUT:
      return {
        ...state,
        layoutType: action.payload,
      };

    case CHANGE_LAYOUT_MODE:
      return {
        ...state,
        layoutModeType: action.payload,
      };

    case CHANGE_SIDEBAR_THEME:
      return {
        ...state,
        leftSidebarType: action.payload,
      };

    case CHANGE_LAYOUT_WIDTH:
      return {
        ...state,
        layoutWidthType: action.payload,
      };

    case CHANGE_LAYOUT_POSITION:
      return {
        ...state,
        layoutPositionType: action.payload,
      };

    case CHANGE_TOPBAR_THEME:
      return {
        ...state,
        topbarThemeType: action.payload,
      };

    case CHANGE_SIDEBAR_SIZE_TYPE:
      return {
        ...state,
        leftsidbarSizeType: action.payload,
      };

    case CHANGE_SIDEBAR_VIEW:
      return {
        ...state,
        leftSidebarViewType: action.payload,
      };

    case CHANGE_SIDEBAR_IMAGE_TYPE:
      return {
        ...state,
        leftSidebarImageType: action.payload,
      };

    case RESET_VALUE:
      return {
        ...state,
        resetValue: INIT_STATE,
      };

    case CHANGE_PRELOADER:
      return {
        ...state,
        preloader: action.payload,
      };

    case SIDEBAR_CURRENT_STATE:
      return {
        ...state,
        sidebarCurrentState: action.payload,
      };
    case SET_DASHBOARD:
      return { ...state, isDashboard: action.payload };
    case SET_WEBSITE_MANAGEMENT:
      return { ...state, isWebsiteManagement: action.payload };
    case SET_USER_MANAGEMENT:
      return { ...state, isUserManagement: action.payload };
    case SET_ACCESS_RIGHTS:
      return { ...state, isAccessRights: action.payload };
    case SET_PAGES:
      return { ...state, isPages: action.payload };
    case SET_GUIDELINES_AND_POLICIES:
      return { ...state, isGuidelinesAndPolicies: action.payload };
    case SET_ANNOUNCEMENTS:
      return { ...state, isAnnouncements: action.payload };
    case SET_TICKETING_SYSTEM:
      return { ...state, isTicketingSystem: action.payload };
    case SET_REPORTING:
      return { ...state, isReporting: action.payload };
    default:
      return state;
  }
};

export default Layout;

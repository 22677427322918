function FormatDate(d) {
    // const dateString = date;

    // Create a Date object from the given string
    const date = new Date(d);

    // Define an array of month names in abbreviated form
    const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

    // Extract day, month, and year from the Date object
    const day = date.getDate();
    const monthIndex = date.getMonth();
    const year = date.getFullYear();

    // Format the date in the desired format
    const formattedDate = `${day} ${monthNames[monthIndex]}, ${year}`;

    return formattedDate
}

export default FormatDate
import {
    ADD_USER_DATA_AFTER_LOGIN,
    ADD_USER_PERMISSION_AFTER_LOGIN
} from "./actionType";

const initialState = {
    userData: null,
    userPermission: null
};

const saveUserDataReducer = (state = initialState, action) => {
    switch (action.type) {
        case ADD_USER_DATA_AFTER_LOGIN:
            return {
                ...state,
                userData: action.payload,
            };
        case ADD_USER_PERMISSION_AFTER_LOGIN:
            return {
                ...state,
                userPermission: action.payload,
            };
        default:
            return state;
    }
};

export default saveUserDataReducer;
import {
  FETCH_DASHBOARD_SETTINGS_SUCCESS,
  FETCH_DASHBOARD_SETTINGS_FAILURE,
  UPDATE_DASHBOARD_SETTINGS_SUCCESS,
  UPDATE_DASHBOARD_SETTINGS_FAILURE,
} from "./actionTypes";

const initialState = {
  settings: {
    activeWebsiteCard: true,
    avgUserCard: true,
    activeTicketCard: true,
    hitDetailCard: true,
    complianceCard: true,
    onlineAssetList: true,
    ticketList: true,
  },
  loading: false,
  error: null,
};

const dashboardReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_DASHBOARD_SETTINGS_SUCCESS:
      return {
        ...state,
        settings: {
          activeWebsiteCard: action.payload.active_website_card,
          avgUserCard: action.payload.avg_user_card,
          activeTicketCard: action.payload.active_ticket_card,
          hitDetailCard: action.payload.hit_detail_card,
          complianceCard: action.payload.compliance_card,
          onlineAssetList: action.payload.online_asset_list,
          ticketList: action.payload.ticket_list,
        },
        loading: false,
        error: null,
      };
    case FETCH_DASHBOARD_SETTINGS_FAILURE:
    case UPDATE_DASHBOARD_SETTINGS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case UPDATE_DASHBOARD_SETTINGS_SUCCESS:
      return {
        ...state,
        settings: action.payload,
        loading: false,
        error: null,
      };
    default:
      return state;
  }
};

export default dashboardReducer;

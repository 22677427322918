import { DateTime } from "luxon";

export function formatDateTime(dbTime, dateFormat, timeFormat, timeZone) {
  if (!dbTime) {
    return {
      date: "",
      time: "",
      timeZone: { formattedDateFinal: "", formattedTimed: "" },
    };
  }

  try {
    const dateObject = new Date(dbTime);
    const finaldate = dateObject.toISOString();

    const dates = DateTime.fromISO(finaldate)
      .setZone(timeZone)
      .toFormat("yyyy-MM-dd");
    const dateTime = new Date(dates);
    const timeform = DateTime.fromISO(finaldate).setZone(timeZone);

    let formattedTimed;
    if (timeFormat === "12") {
      formattedTimed = timeform.toFormat("h:mm a"); // 12-hour time format
    } else {
      formattedTimed = timeform.toFormat("HH:mm"); // 24-hour time format
    }

    const monthAbbreviation = new Intl.DateTimeFormat("en-US", {
      month: "short",
    }).format(dateTime);

    const defaultFormat = "M d Y";

    const formattedDateFinal = dateFormat
      ? dateFormat
          .replace("d", dateTime.getDate())
          .replace("m", dateTime.getMonth() + 1)
          .replace("Y", dateTime.getFullYear())
          .replace("M", monthAbbreviation)
      : defaultFormat
          .replace("d", dateTime.getDate())
          .replace("m", dateTime.getMonth() + 1)
          .replace("Y", dateTime.getFullYear())
          .replace("M", monthAbbreviation);

    return {
      date: formattedDateFinal,
      timeZone: { formattedDateFinal, formattedTimed },
      timeZoneName: timeZone,
    };
  } catch (error) {
    console.error("Error formatting date:", error);
    return {
      date: "",
      time: "",
      timeZone: { formattedDateFinal: "", formattedTimed: "" },
    };
  }
}

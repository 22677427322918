import {
  FETCH_DEVELOPERS_PROFILE_FAIL,
  FETCH_DEVELOPERS_PROFILE_SUCCESS,
} from "./actionType";

const INIT_STATE = {
  developersProfileDetails: [],
};

const DeveloperProfile = (state = INIT_STATE, action) => {
  switch (action.type) {
    case FETCH_DEVELOPERS_PROFILE_SUCCESS: {
      return {
        ...state,
        developersProfileDetails: action.payload,
        isDevelopersProfileFetched: true,
        isDevelopersProfileFetchedFail: false,
      };
    }
    case FETCH_DEVELOPERS_PROFILE_FAIL: {
      return {
        ...state,
        error: action.payload,
        isDevelopersProfileFetched: false,
        isDevelopersProfileFetchedFail: true,
      };
    }
    default:
      return { ...state };
  }
};

export default DeveloperProfile;

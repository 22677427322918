import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";
import { ToastContainer } from "react-toastify";
import { Offcanvas, Button, Card } from "react-bootstrap";

import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import {
  updateWebsitePolicies,
  fetchWebsitePolicies,
} from "../../../store/websitePolicies/action";
import { FormFeedback, Form } from "reactstrap";
import { formatDateTime } from "../../../Components/Common/FormatDateTIme";
import { WEBSITE_STATISTICS } from "../../../Components/Common/ModuleName";
import { Box } from "@mui/material";
import { socket } from "../../../Components/Common/socket";
import axios from "axios";

const WebsitePolicies = () => {
  const permissionData = useSelector(
    (state) => state.userDetails.userPermission
  );
  const hasPermission = permissionData?.find(
    (item) => item.module_name === WEBSITE_STATISTICS
  );

  const dispatch = useDispatch();
  const { websitepolicyContent } = useSelector((state) => state.WebsitePolicy);

  const generalSettingData = useSelector(
    (state) => state.settingsData.settingsData
  );

  const otherSettingsData = generalSettingData?.filter(
    (item) => item.settings_category === "other"
  );

  const dateFormat = otherSettingsData?.find(
    (date) => date.field_name === "DEFAULT_DATE_FORMAT"
  )?.field_value;

  const timeZone = otherSettingsData?.find(
    (time) => time.field_name === "DEFAULT_TIME_FORMAT"
  )?.field_value;

  const timeFormat = otherSettingsData?.find(
    (zone) => zone.field_name === "DEFAULT_TIME_ZONE"
  )?.field_value;

  const [show, setShow] = useState(false);

  const formik = useFormik({
    enableReinitialize: true,

    initialValues: {
      websitePoliciesContent: websitepolicyContent.description || "",
    },

    validationSchema: Yup.object({
      websitePoliciesContent: Yup.string()
        .min(10, "website polices content should be minimum 10 charcter long")
        .required("Pls enter the website polices content"),
    }),
    onSubmit: (values) => {
      dispatch(updateWebsitePolicies(values.websitePoliciesContent));
      setShow(false);
      socket.emit("policy");
      formik.resetForm();
    },
  });

  const handleClose = () => {
    setShow(false);
    formik.resetForm();
  };

  useEffect(() => {
    dispatch(fetchWebsitePolicies());
  }, [dispatch]);

  const uploadAdaptor = (loader) => {
    return {
      upload: async () => {
        try {
          const body = new FormData();
          const file = await loader.file;

          body.append("upload", file);

          const response = await axios.post(
            `websitePolicies/uploadImage`,
            body,
            {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            }
          );
          const { url } = response;

          if (!url) {
            throw new Error("No URL returned from server");
          }

          return {
            default: url,
          };
        } catch (error) {
          console.log("Something went wrong", error);
        }
      },
    };
  };

  function uploadPlugins(editor) {
    editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {
      return uploadAdaptor(loader);
    };
  }

  return (
    <>
      <ToastContainer closeButton={false} />

      <Card>
        <Card.Body className="cms">
          {hasPermission && hasPermission?.canCreate ? (
            <>
              <Box className="text-end mb-3">
                <Button
                  className="btn btn-primary"
                  onClick={() => setShow(true)}
                >
                  Edit Website Policies
                </Button>
              </Box>
            </>
          ) : (
            ""
          )}
          <h4>Current Updated Policies</h4>
          <p>
            <em>
              This privacy policy has last been updated on{" "}
              {
                formatDateTime(
                  websitepolicyContent.updatedAt,
                  dateFormat,
                  timeZone,
                  timeFormat
                ).timeZone.formattedDateFinal
              }
              .
            </em>
          </p>
          <Box
            dangerouslySetInnerHTML={{
              __html: websitepolicyContent.description,
            }}
          />
          <p>
            <em>
              This privacy policy has last been updated on{" "}
              {
                formatDateTime(
                  websitepolicyContent.updatedAt,
                  dateFormat,
                  timeZone,
                  timeFormat
                ).timeZone.formattedDateFinal
              }
              .
            </em>
          </p>
        </Card.Body>
      </Card>

      <Offcanvas
        show={show}
        onHide={handleClose}
        placement="end"
        backdrop="static"
        className="offcanvas_cms_size"
      >
        <Offcanvas.Header>
          <Offcanvas.Title>Website Policies</Offcanvas.Title>
        </Offcanvas.Header>

        <Offcanvas.Body>
          <Form onSubmit={formik.handleSubmit}>
            <>
              <Box className="row">
                <Box className="col-lg-12 mb-3">
                  <CKEditor
                    config={{
                      extraPlugins: [uploadPlugins],
                    }}
                    editor={ClassicEditor}
                    data={formik.values.websitePoliciesContent}
                    onReady={(editor) => {}}
                    onChange={(event, editor) => {
                      formik.setFieldValue(
                        "websitePoliciesContent",
                        editor.getData()
                      );
                    }}
                  />
                  {formik.errors.websitePoliciesContent &&
                  formik.touched.websitePoliciesContent ? (
                    <FormFeedback
                      type="invalid"
                      className="d-block"
                      style={{ color: "red" }}
                    >
                      {formik.errors.websitePoliciesContent}
                    </FormFeedback>
                  ) : null}
                </Box>
              </Box>

              <Box className="col-lg-12">
                <Button className="btn btn-dark" type="submit">
                  Submit
                </Button>
                <Button className="btn btn-primary ms-2" onClick={handleClose}>
                  Cancel
                </Button>
              </Box>
            </>
          </Form>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

export default WebsitePolicies;

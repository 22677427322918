import React, { useCallback, useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { ToastMessage } from "../../../../Components/Common/ToastMessage";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { formatDateTime } from "../../../../Components/Common/FormatDateTIme";
import { useSelector } from "react-redux";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import ImageIcon from "@mui/icons-material/Image";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import { Avatar, Box, Skeleton } from "@mui/material";
import { Button } from "react-bootstrap";
import common from "../../../../Components/Common/common";

const AttachmentComponent = ({ user }) => {
  const [attachmentSize, setAttachmentSize] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);

  const toggleModal = () => {
    setModalOpen(!modalOpen);
  };

  useEffect(() => {
    const fetchAttachmentSize = async () => {
      try {
        if (user.original_name != null) {
          const response = await fetch(
            `${process.env.REACT_APP_BASE_URL}${user.original_name}`
          );
          const size = response.headers.get("content-length");
          setAttachmentSize(`${(size / 1024).toFixed(2)} kb`);
        }
      } catch (error) {
        return null;
      }
    };

    fetchAttachmentSize();
  }, [user.original_name]);

  const getIconClass = (fileName) => {
    const extension = fileName?.split(".").pop();
    switch (extension) {
      case "pdf":
        return (
          <PictureAsPdfIcon style={{ fontSize: 22, color: "textSecondary" }} />
        );
      case "jpg":
      case "jpeg":
      case "png":
      case "gif":
        return <ImageIcon style={{ fontSize: 22, color: "textSecondary" }} />;
      // Add more cases for different file types if needed
      default:
        return (
          <InsertDriveFileIcon
            style={{ fontSize: 20, color: "textSecondary" }}
          />
        );
    }
  };

  const iconClass = getIconClass(user.attachment_name);

  return (
    <Box className="accordion-body ms-2 ps-5">
      <Box className="row g-2">
        <Box className="col-auto">
          <Box
            className="d-flex align-item-start border border-dashed p-2 position-relative"
            sx={{ borderRadius: "8px" }}
          >
            {attachmentSize ? (
              <>
                {/* <Box className="flex-shrink-0"> */}
                {iconClass}
                {/* <i className={`${iconClass} fs-17 text-secondary`}></i> */}
                {/* </Box> */}
              </>
            ) : (
              ""
            )}

            <Box className="flex-grow-1 ms-2">
              <h6>
                <Link
                  onClick={attachmentSize ? toggleModal : null}
                  className="stretched-link"
                >
                  {user.original_name}
                </Link>
              </h6>
              <small>
                {attachmentSize ? attachmentSize : "attachment unavailable."}
              </small>
            </Box>
          </Box>
        </Box>
      </Box>
      <Modal
        isOpen={modalOpen}
        toggle={toggleModal}
        size="xl"
        backdrop="static"
      >
        <ModalHeader toggle={toggleModal}>{user.original_name}</ModalHeader>
        <ModalBody>
          <iframe
            src={`${process.env.REACT_APP_BASE_URL}${user.attachment_name}`}
            width="100%"
            height="500px"
            title="PDF Viewer"
            className="pdf-viewer"
          />
        </ModalBody>
      </Modal>
    </Box>
  );
};

const Activity = (props) => {
  const token = localStorage.getItem("token");

  const [activityData, setActivityData] = useState([]);
  const [dataLoading, setDataLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [rowCount, setRowCount] = useState(0);

  const params = useParams();

  const generalSettingData = useSelector(
    (state) => state.settingsData.settingsData
  );

  const otherSettingsData = generalSettingData?.filter(
    (item) => item.settings_category === "other"
  );

  const dateFormat = otherSettingsData?.find(
    (date) => date.field_name === "DEFAULT_DATE_FORMAT"
  )?.field_value;

  const timeZone = otherSettingsData?.find(
    (time) => time.field_name === "DEFAULT_TIME_FORMAT"
  )?.field_value;

  const timeFormat = otherSettingsData?.find(
    (zone) => zone.field_name === "DEFAULT_TIME_ZONE"
  )?.field_value;

  const getActivityData = useCallback(async () => {
    setDataLoading(true);
    let userData = { assets_id: params.id, page, perpage: 4 };
    let url = `${process.env.REACT_APP_BASE_URL}activity/get-activity-logs`;
    let reqData = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(userData),
    };
    let result = await fetch(url, reqData);
    let response = await result.json();

    const decryptedData = common.decrypt(response.data);
    response.data = decryptedData;

    if (response.status === "success") {
      setActivityData((prev) => [...prev, ...response.data]);
      setRowCount(response.count);
      setDataLoading(false);
    } else {
      ToastMessage.Error(response.message);
    }
    setDataLoading(false);
  }, [params.id, token, page]);

  useEffect(() => {
    setActivityData([]);
    setPage(1);
  }, [params.id]);

  useEffect(() => {
    getActivityData();
  }, [getActivityData, page]);

  const getFirstName = (userName) => {
    const nameParts = userName ? userName.split(" ") : [];
    return nameParts[0]?.charAt(0).toUpperCase() || "";
  };

  const loadMoreActivities = () => {
    setPage((prevPage) => prevPage + 1);
  };

  // return (
  //   <React.Fragment>
  //     <Box className="profile-timeline">
  //       {activityData.length > 0 ? (
  //         activityData.map((user, index) => (
  //           <Box
  //             className="accordion accordion-item accordion-flush"
  //             key={index}
  //             id={`todayExample${user.id}`}
  //           >
  //             <Box className="accordion-header" id={`headingOne${user.id}`}>
  //               <a
  //                 className="accordion-button p-1 shadow-none"
  //                 data-bs-toggle="collapse"
  //                 href={`#collapseOne${user.id}`}
  //                 aria-expanded="true"
  //               >
  //                 <Box className="d-flex w-100">
  //                   <Avatar sx={{ background: "#e4eaee", color: "#1e2638" }}>
  //                     {getFirstName(user.user_name)}
  //                   </Avatar>
  //                   <Box className="flex-grow-1 ms-3">
  //                     {dataLoading ? (
  //                       <Skeleton variant="text" width={200} height={30} />
  //                     ) : (
  //                       <h6 className="title">{user.user_name}</h6>
  //                     )}
  //                     <Box className="sub-box">
  //                       {dataLoading ? (
  //                         <div style={{ display: "flex", gap: "10px" }}>
  //                           <Skeleton variant="text" width={300} height={30} />
  //                           <Skeleton variant="text" width={70} height={30} />
  //                         </div>
  //                       ) : (
  //                         <>
  //                           <p className="activity mb-0">{user.activity}</p>
  //                           <p className="timing">
  //                             <small>
  //                               {
  //                                 formatDateTime(
  //                                   user.updatedAt,
  //                                   dateFormat,
  //                                   timeZone,
  //                                   timeFormat
  //                                 ).timeZone.formattedDateFinal
  //                               }
  //                             </small>
  //                             <small className="me-auto">
  //                               {
  //                                 formatDateTime(
  //                                   user.updatedAt,
  //                                   dateFormat,
  //                                   timeZone,
  //                                   timeFormat
  //                                 ).timeZone.formattedTimed
  //                               }
  //                             </small>
  //                           </p>
  //                         </>
  //                       )}
  //                     </Box>
  //                   </Box>
  //                 </Box>
  //               </a>
  //             </Box>
  //             <Box
  //               id={`collapseOne${user.id}`}
  //               className={`accordion-collapse collapse ${
  //                 user.services === "attachments" ? "show" : ""
  //               }`}
  //               aria-labelledby={`headingOne${user.id}`}
  //               data-bs-parent="#accordionExample"
  //             >
  //               {(user.services === "comments" ||
  //                 user.services === "notes") && (
  //                 <Box
  //                   className="accordion-body ms-2 ps-5"
  //                   dangerouslySetInnerHTML={{ __html: user.userData }}
  //                 />
  //               )}
  //               {user.services === "attachments" && (
  //                 <AttachmentComponent user={user} />
  //               )}
  //             </Box>
  //           </Box>
  //         ))
  //       ) : (
  //         <Box className="accordion-header">
  //           <button
  //             className="accordion-button p-2 shadow-none"
  //             data-bs-toggle="collapse"
  //             aria-expanded="true"
  //             style={{
  //               border: "none",
  //               background: "none",
  //               padding: "0",
  //               cursor: "pointer",
  //             }}
  //           >
  //             <Box className="d-flex">
  //               <Box className="flex-shrink-0 avatar-xs">
  //                 <Box className="avatar-title bg-light text-success rounded-circle">
  //                   *
  //                 </Box>
  //               </Box>
  //               <Box className="flex-grow-1 ms-3">
  //                 <h6 className="fs-14 mb-1">
  //                   There is no activity in this asset.
  //                 </h6>
  //               </Box>
  //             </Box>
  //           </button>
  //         </Box>
  //       )}
  //       {rowCount > activityData.length && (
  //         <Box className="d-flex justify-content-center my-3">
  //           <Button onClick={loadMoreActivities} disabled={dataLoading}>
  //             {dataLoading ? "Loading..." : "Load More"}
  //           </Button>
  //         </Box>
  //       )}
  //     </Box>
  //   </React.Fragment>
  // );

  return (
    <React.Fragment>
      <Box className="profile-timeline">
        {dataLoading ? (
          <>
            <Box className="accordion accordion-item accordion-flush">
              <Box className="accordion-header">
                <Box className="d-flex w-100">
                  <Avatar sx={{ background: "#e4eaee", color: "#1e2638" }}>
                    {dataLoading ? (
                      <Skeleton variant="circular" width={40} height={40} />
                    ) : (
                      "-"
                    )}
                  </Avatar>
                  <Box className="flex-grow-1 ms-3">
                    {dataLoading ? (
                      <Skeleton variant="text" width={200} height={30} />
                    ) : (
                      <h6 className="title">{"-"}</h6>
                    )}
                    <Box className="sub-box">
                      {dataLoading ? (
                        <div style={{ display: "flex", gap: "10px" }}>
                          <Skeleton variant="text" width={300} height={30} />
                          <Skeleton variant="text" width={70} height={30} />
                        </div>
                      ) : (
                        <>
                          <p className="activity mb-0">{"-"}</p>
                          <p className="timing">
                            <small>{"-"}</small>
                            <small className="me-auto">{"-"}</small>
                          </p>
                        </>
                      )}
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>
          </>
        ) : activityData.length > 0 ? (
          activityData.map((user, index) => (
            <Box
              className="accordion accordion-item accordion-flush"
              key={index}
              id={`todayExample${user.id}`}
            >
              <Box className="accordion-header" id={`headingOne${user.id}`}>
                <a
                  className="accordion-button p-1 shadow-none"
                  data-bs-toggle="collapse"
                  href={`#collapseOne${user.id}`}
                  aria-expanded="true"
                >
                  <Box className="d-flex w-100">
                    <Avatar sx={{ background: "#e4eaee", color: "#1e2638" }}>
                      {getFirstName(user.user_name)}
                    </Avatar>
                    <Box className="flex-grow-1 ms-3">
                      <h6 className="title">{user.user_name}</h6>
                      <Box className="sub-box">
                        <p className="activity mb-0">{user.activity}</p>
                        <p className="timing">
                          <small>
                            {
                              formatDateTime(
                                user.updatedAt,
                                dateFormat,
                                timeZone,
                                timeFormat
                              ).timeZone.formattedDateFinal
                            }
                          </small>
                          <small className="me-auto">
                            {
                              formatDateTime(
                                user.updatedAt,
                                dateFormat,
                                timeZone,
                                timeFormat
                              ).timeZone.formattedTimed
                            }
                          </small>
                        </p>
                      </Box>
                    </Box>
                  </Box>
                </a>
              </Box>
              <Box
                id={`collapseOne${user.id}`}
                className={`accordion-collapse collapse ${
                  user.services === "attachments" ? "show" : ""
                }`}
                aria-labelledby={`headingOne${user.id}`}
                data-bs-parent="#accordionExample"
              >
                {(user.services === "comments" ||
                  user.services === "notes") && (
                  <Box
                    className="accordion-body ms-2 ps-5"
                    dangerouslySetInnerHTML={{ __html: user.userData }}
                  />
                )}
                {user.services === "attachments" && (
                  <AttachmentComponent user={user} />
                )}
              </Box>
            </Box>
          ))
        ) : (
          <Box className="accordion-header">
            <button
              className="accordion-button p-2 shadow-none"
              data-bs-toggle="collapse"
              aria-expanded="true"
              style={{
                border: "none",
                background: "none",
                padding: "0",
                cursor: "pointer",
              }}
            >
              <Box className="d-flex">
                <Box className="flex-shrink-0 avatar-xs">
                  <Box className="avatar-title bg-light text-success rounded-circle">
                    *
                  </Box>
                </Box>
                <Box className="flex-grow-1 ms-3">
                  <h6 className="fs-14 mb-1">
                    There is no activity in this asset.
                  </h6>
                </Box>
              </Box>
            </button>
          </Box>
        )}
      </Box>
      {activityData.length > 0 && activityData.length < rowCount && (
        <Box className="text-center">
          <Button
            variant="primary"
            className="mt-3 mb-1"
            onClick={loadMoreActivities}
            disabled={dataLoading}
          >
            {dataLoading ? "Loading..." : "Load More"}
          </Button>
        </Box>
      )}
    </React.Fragment>
  );
};

export default Activity;

import React, { useCallback, useEffect, useRef, useState } from "react";
import { formatDateTime } from "../../Components/Common/FormatDateTIme";
import { useSelector } from "react-redux";
import { Card, Modal, Table } from "react-bootstrap";
import { Button } from "reactstrap";
import { Box } from "@mui/material";
import LoopIcon from "@mui/icons-material/Loop";
import DataTable from "react-data-table-component";
import { CustomTableStyle } from "../../Components/Common/constants";
import { getCompliances } from "../../helpers/Help/CommomApi";
import InfoIcon from "@mui/icons-material/Info";
import Flatpickr from "react-flatpickr";
import moment from "moment";
import common from "../../Components/Common/common";

const Index = () => {
  const [data, setData] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [modalData, setModalData] = useState([]);
  const [selectedDate, setSelectedDate] = useState([]);
  const flatpickrRef = useRef();

  const generalSettingData = useSelector(
    (state) => state.settingsData.settingsData
  );

  const otherSettingsData = generalSettingData?.filter(
    (item) => item.settings_category === "other"
  );

  const dateFormat = otherSettingsData?.find(
    (date) => date.field_name === "DEFAULT_DATE_FORMAT"
  )?.field_value;

  const timeZone = otherSettingsData?.find(
    (time) => time.field_name === "DEFAULT_TIME_FORMAT"
  )?.field_value;

  const timeFormat = otherSettingsData?.find(
    (zone) => zone.field_name === "DEFAULT_TIME_ZONE"
  )?.field_value;

  let [startDate, endDate] = selectedDate;

  if (startDate) {
    startDate = moment(startDate).format("YYYY-MM-DD");
  }

  if (endDate) {
    endDate = moment(endDate).format("YYYY-MM-DD");
  }

  const handleDateChange = (selectedDates) => {
    if (selectedDates[1] !== undefined) {
      setSelectedDate(selectedDates);
    }
  };

  const fetchData = useCallback(async () => {
    try {
      let startDate = selectedDate[0]
        ? moment(selectedDate[0]).format("YYYY-MM-DD")
        : "";
      let endDate = selectedDate[1]
        ? moment(selectedDate[1]).format("YYYY-MM-DD")
        : "";

      let response = await getCompliances(startDate, endDate);
      const decryptedData = common.decrypt(response.data);
      response.data = decryptedData;

      if (response.status === "success") {
        setData(response.data);
      }
    } catch (error) {
      console.error(error.message);
    }
  }, [selectedDate]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const handleReset = () => {
    setSelectedDate("");
    fetchData();
  };

  const handleIconClick = (row) => {
    setModalData(row);
    setShowModal(true);
  };

  const columns = [
    {
      name: "User Name",
      selector: (row) => (
        <span>
          {row.developers.map((developer) => developer.user_name).join(", ") ||
            "other"}
        </span>
      ),
      minWidth: "200px",
    },
    {
      name: "Date Time",
      selector: (row) => (
        <span>
          <span>
            {" "}
            {
              formatDateTime(row.createdAt, dateFormat, timeZone, timeFormat)
                .timeZone.formattedDateFinal
            }{" "}
            {
              formatDateTime(row.createdAt, dateFormat, timeZone, timeFormat)
                .timeZone.formattedTimed
            }
          </span>
        </span>
      ),
      minWidth: "150px",
    },
    {
      name: "Details",
      selector: (row) => (
        <InfoIcon
          onClick={() => handleIconClick(row)}
          style={{ cursor: "pointer", color: "blue" }}
        />
      ),
      minWidth: "50px",
    },
  ];

  return (
    <React.Fragment>
      <Card className="table-main-card">
        <Box className="table-card-header">
          <Box className="left-side">
            <Box className="dateinput">
              <Flatpickr
                placeholder="Select Date Range"
                ref={flatpickrRef}
                className="form-control"
                options={{
                  dateFormat: "d M, Y",
                  mode: "range",
                }}
                value={selectedDate}
                onChange={handleDateChange}
              />
            </Box>
            <Button
              className="btn btn-primary reset-btn"
              onClick={() => {
                handleReset();
              }}
            >
              <LoopIcon />
            </Button>
          </Box>
        </Box>
        <Card.Body>
          <DataTable
            fixedHeight="500px"
            pointerOnHover
            columns={columns}
            data={data}
            pagination
            customStyles={CustomTableStyle}
            className="table-design"
          />
        </Card.Body>
      </Card>

      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Compliance Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {modalData && (
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>Guidelines and Policies</th>
                  <th>Title</th>
                  <th>Description</th>
                </tr>
              </thead>
              <tbody>
                {modalData.websiteGuidelines?.map((guideline) => (
                  <tr key={guideline.id}>
                    <td>Website Guideline</td>
                    <td>{guideline.website_guideline_title}</td>
                    <td>{guideline.website_guideline_description}</td>
                  </tr>
                ))}
                {modalData.brandingGuidelines?.map((guideline) => (
                  <tr key={guideline.id}>
                    <td>Branding Guideline</td>
                    <td>{guideline.branding_guideline_title}</td>
                    <td>{guideline.branding_guideline_description}</td>
                  </tr>
                ))}

                {modalData.developers?.length > 0 && (
                  <tr>
                    <td>Developer Email</td>
                    <td colSpan="2">
                      {modalData.developer_email
                        ? modalData.developer_email
                            .slice(1, -1)
                            .split(",")
                            .map((email) => email.replace(/"/g, ""))
                            .join(", ")
                        : "-"}
                    </td>
                  </tr>
                )}
                {modalData?.other_email && (
                  <tr>
                    <td>Other Email</td>
                    <td colSpan="2">{modalData.other_email || "-"}</td>
                  </tr>
                )}
                {/* {modalData.website_policy !== undefined && (
                  <tr>
                    <td>Website Policy</td>
                    <td colSpan="2">
                      {modalData.website_policy ? "Send" : "Disabled"}
                    </td>
                  </tr>
                )} */}
                {modalData?.website_policy === true && (
                  <tr>
                    <td>Website Policy</td>
                    <td colSpan="2">Send</td>
                  </tr>
                )}
              </tbody>
            </Table>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </React.Fragment>
  );
};

export default Index;

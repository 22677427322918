import React, { useCallback, useEffect, useRef, useState } from "react";
import Flatpickr from "react-flatpickr";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Offcanvas, Button, Card } from "react-bootstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Form, Spinner } from "reactstrap";
import { ToastMessage } from "../../Components/Common/ToastMessage";
import Loader from "../../Components/Common/Loader";
import moment from "moment";
import { useSelector } from "react-redux";
import { formatDateTime } from "../../Components/Common/FormatDateTIme";
import Swal from "sweetalert2";
import { socket } from "../../Components/Common/socket";
import { ANNOUNCEMENTS } from "../../Components/Common/ModuleName";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import {
  Box,
  Chip,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
} from "@mui/material";
import { DeleteIcon } from "../../Components/Common/SvgIcons";
import MoreVertOutlinedIcon from "@mui/icons-material/MoreVertOutlined";
import axios from "axios";
import { MenuHeight } from "../NewDashboard/constant";
import common from "../../Components/Common/common";

const Announcements = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const refComp = useRef(null);

  const userId = localStorage.getItem("user_id");
  const role = localStorage.getItem("role");

  const [announcementsData, setAnnouncementsData] = useState([]);

  const [show, setShow] = useState(false);
  const [id, setId] = useState("");

  const [selectedOptions, setSelectedOptions] = useState([]);

  const [query, setQuery] = useState("");
  const [selectedCategory, setSelectedCategory] = useState([]);
  const [selectedDate, setSelectedDate] = useState([]);
  const [websiteCategories, setWebsiteCategories] = useState([]);
  const [defaultSelectedChip, setDefaultSelectedChip] = useState(null);
  

  const [page, setPage] = useState(1);
  const [totalRows, setTotalRows] = useState(0);
  const [postIsLoading, setPostIsLoading] = useState(false);

  const [isLoading, setIsLoading] = useState(false);

  const permissionData = useSelector(
    (state) => state.userDetails.userPermission
  );

  const userObject = useSelector((state) => state.userDetails.userData);

  const hasPermission = permissionData?.find(
    (item) => item.module_name === ANNOUNCEMENTS
  );

  let [startDate, endDate] = selectedDate;

  useEffect(() => {
    if (location.state?.globalSearch) {
      setQuery(location.state?.globalSearch);
    }
  }, [location.state]);

  if (startDate) {
    startDate = moment(startDate).format("YYYY-MM-DD");
  }
  if (endDate) {
    endDate = moment(endDate).format("YYYY-MM-DD");
  }

  useEffect(() => {
    fetchWebsiteCategories();
  }, []);

  const fetchWebsiteCategories = async () => {
    try {
      const { data, status } = await axios.get(
        `websiteCategories/get-category`
      );
      const responseData = common.decrypt(data);
      if (status === "success" && responseData) {
        setWebsiteCategories(
          responseData.map((item) => {
            return {
              id: item.id,
              label: item.category,
              value: item.id,
            };
          })
        );
      }
    } catch (err) {
      setWebsiteCategories([]);
    }
  };

  const validation = useFormik({
    enableReinitialize: true,

    initialValues: {
      announcement_title: "",
      announcement_category: null,
      announcement_description: "",
    },

    validationSchema: Yup.object({
      announcement_title: Yup.string()
        .min(5, "Please enter announcement title minimum 5 character long.")
        .max(255, "Please enter announcement title maximum 255 character long.")
        .required("Please enter the announcement title."),
      announcement_category: Yup.array()
        .min(1, "Please select at least one announcement category.")
        .required("Please select the announcement category."),
      announcement_description: Yup.string()
        .min(
          10,
          "Please enter announcement description minimum 10 character long."
        )
        .max(
          255,
          "Please enter announcement description maximum 255 character long."
        )
        .required("Please enter the announcement description."),
    }),

    onSubmit: (values) => {
      // id ? updateAnnouncement(values) : addAnnouncement(values);
      addAnnouncement(values);
    },
  });

  const handleSelectChange = (selectedOptions) => {
    validation.setFieldValue(
      "announcement_category",
      selectedOptions.map((item) => item.value)
    );
    setSelectedOptions(selectedOptions);
  };

  const handleDateChange = (selectedDates) => {
    if (selectedDates[1] !== undefined) {
      setSelectedDate(selectedDates);
    }
  };

  const clearState = () => {
    setId("");

    validation.setValues({
      announcement_title: "",
      announcement_category: null,
      announcement_description: "",
    });
    setSelectedOptions([]);
  };

  const handleClose = () => {
    validation.resetForm();
    setShow(false);
    clearState();
  };

  const handleCategoryChange = (item) => {
    if (!selectedCategory.find((option) => option.id === item.id)) {
      setSelectedCategory([...selectedCategory, item]);
      setPage(1);
    }
  };

  const handleOptionRemove = (itemToRemove) => {
    // Filter out the option to be removed from the selected options
    const updatedItems = selectedCategory.filter(
      (item) => item.id !== itemToRemove.id
    );
    setSelectedCategory(updatedItems);
  };

  const handleReset = () => {
    if (
      query !== "" ||
      selectedCategory.length > 0 ||
      selectedDate.length > 0
    ) {
      setQuery("");
      setSelectedCategory([]);
      refComp.current.flatpickr.clear();
      setSelectedDate([]);
    }
    setPage(1);
  };

  const handleLoadMore = async () => {
    const nextPageOrders = await fetchNextPage();
    setAnnouncementsData((prevData) => [
      ...prevData,
      ...nextPageOrders?.data?.rows,
    ]);
    setTotalRows(nextPageOrders?.data?.count);
  };

  const getData = useCallback(
    async (page, limit, isDelete = false) => {
      let modifiedCategoryArray = selectedCategory.map((item) => item.value);
      try {
        setIsLoading(true);

        let userData = {};

        if (isDelete) {
          userData = {
            page: page * limit,
            limit: 1,
          };
        } else {
          userData = { page: page, limit: limit };
        }

        if (query !== "") {
          userData.searchQuery = query;
        }

        if (startDate && endDate) {
          userData.startDate = startDate;
          userData.endDate = endDate;
        }

        if (selectedCategory && selectedCategory.length > 0) {
          userData.category = modifiedCategoryArray;
        }

        let response = await axios.post(
          `announcements/get-announcements`,
          userData
        );

        if (isDelete) {
          if (response.code === 403) {
            navigate("/auth-404-cover");
          }
          if (response.status === "success") {
            setIsLoading(false);
            const decryptedData = common.decrypt(response.data);
            return decryptedData;
          }
          if (response.status === "fail") {
            setTotalRows(0);
            setAnnouncementsData([]);
            setIsLoading(false);
          }
        } else {
          if (response.code === 403) {
            navigate("/auth-404-cover");
          }
          if (response.status === "success") {
            const responseData = common.decrypt(response.data);
            setTotalRows(responseData.count);
            setAnnouncementsData(responseData.rows);

            setIsLoading(false);
          }
          if (response.status === "fail") {
            setTotalRows(0);
            setAnnouncementsData([]);
            setIsLoading(false);
          }
        }
      } catch (error) {
        setTotalRows(0);
        setAnnouncementsData([]);
        setIsLoading(false);

        return null;
      }
    },
    [endDate, navigate, query, selectedCategory, startDate]
  );

  useEffect(() => {
    async function fetchInitialData() {
      await getData(1, 5);
    }

    fetchInitialData();
    return () => {
      setPage(1);
    };
  }, [selectedDate, query, selectedCategory, getData, defaultSelectedChip]);

  const fetchNextPage = async () => {
    let modifiedCategoryArray = selectedCategory.map((item) => item.value);
    try {
      setIsLoading(true);

      let userData = { page: page + 1, limit: 5 };

      if (query !== "") {
        userData.searchQuery = query;
      }

      if (startDate && endDate) {
        userData.startDate = startDate;
        userData.endDate = endDate;
      }

      if (selectedCategory && selectedCategory.length > 0) {
        userData.category = modifiedCategoryArray;
      }

      let response = await axios.post(
        `announcements/get-announcements`,
        userData
      );
      const responseData = common.decrypt(response.data);
      response.data = responseData;

      if (response.code === 403) {
        navigate("/auth-404-cover");
      }
      if (response.status === "success") {
        setIsLoading(false);
        setPage((prevPage) => prevPage + 1);
        return response;
      }
      if (response.status === "fail") {
        setTotalRows(0);
        setAnnouncementsData([]);
        setIsLoading(false);
      }
    } catch (error) {
      setTotalRows(0);
      setAnnouncementsData([]);
      setIsLoading(false);
    }
  };

  /////////////////////////////////// POST DATA and update data ///////////////////////
  const updateData = (item) => {
    try {
      let newArray = websiteCategories.filter((option) =>
        item.announcement_category
          .split(",")
          .filter((item) => item.trim() !== "")
          .map(Number)
          .filter((value) => !isNaN(value))
          .includes(option.value)
      );

      setSelectedOptions(newArray);
      validation.setValues({
        user_id: parseInt(userId),
        announcement_title: item.announcement_title,
        announcement_category: item.announcement_category
          .split(",")
          .map(Number),
        announcement_description: item.announcement_description,
      });

      setId(item.id);
      setShow(true);
    } catch (error) {
      console.error(error.message);
    }
  };

  const deleteAnnouncement = async (announcementID) => {
    try {
      let response = await axios.post(
        `announcements/delete-announcement/${announcementID}`
      );

      if (response.code === 403) {
        navigate("/auth-404-cover");
      }
      if (response.status === "success") {
        const newRecordAfterDelete = await getData(page, 5, true);

        setTotalRows(newRecordAfterDelete.count);

        setAnnouncementsData((prevData) => {
          const updatedListAfterDelete = prevData.filter(
            (item) => item.id !== announcementID
          );
          return [...updatedListAfterDelete, ...newRecordAfterDelete.rows];
        });
        socket.emit("deleteAnnouncement");
        ToastMessage.Success("Announcement has been successfully deleted");
      }

      if (response.status === "fail") {
        ToastMessage.Error(response.message);
      }
    } catch (err) {
      console.error(err.message);
    }
  };

  const handleDeleteAlert = (id) => {
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: "btn btn-dark mx-2",
        cancelButton: "btn btn-primary mx-2",
      },
      buttonsStyling: false,
    });

    swalWithBootstrapButtons
      .fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
        cancelButtonText: "No, cancel!",
        reverseButtons: true,
      })
      .then((result) => {
        if (result.isConfirmed) {
          deleteAnnouncement(id);
          swalWithBootstrapButtons.fire(
            "Deleted!",
            "announcement has deleted.",
            "success"
          );
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          swalWithBootstrapButtons.fire(
            "Cancelled",
            "Announcement is safe :)",
            "error"
          );
        }
      });
  };

  const addAnnouncement = async (values) => {
    setPostIsLoading(true);
    try {
      let url = id
        ? `announcements/post-announcements/${id}`
        : `announcements/post-announcements`;

      const encryptedData = common.encrypt({
        ...values,
        user_id: userId,
        announcement_category: values.announcement_category?.join(),
      });

      let response = await axios.post(url, encryptedData);
      const decryptedData = common.decrypt(response.data);

      if (response.code === 403) {
        navigate("/auth-404-cover");
      }

      if (response.status === "success") {
        id
          ? setAnnouncementsData((prevData) => {
              const newUpdatedData = prevData.map((announcement) =>
                announcement.id === decryptedData.id
                  ? decryptedData
                  : announcement
              );

              return newUpdatedData;
            })
          : setAnnouncementsData((prevData) => [
              decryptedData,
              ...prevData.slice(0, prevData.length),
            ]);

        setShow(false);
        navigate("/announcements");
        validation.resetForm();
        socket.emit(id ? "updateAnnouncement" : "addAnnouncement");
        clearState();
        setPostIsLoading(false);
        ToastMessage.Success(
          id
            ? "Announcement has been updated successfully"
            : "Announcement has been added successfully"
        );
      } else if (response.status === "fail") {
        ToastMessage.Error(response.message);
      }
    } catch (error) {
      ToastMessage.Error("Something went wrong.. Please try again later.");
    } finally {
      setPostIsLoading(false);
    }
  };

  const displayCategory = (announcementsCategories) => {
    let newArray = [];

    newArray = announcementsCategories
      .split(",")
      .filter((item) => item.trim() !== "")
      .map(Number)
      .filter((value) => !isNaN(value));

    let newResult = websiteCategories
      .filter((option) => {
        return newArray.includes(option.value);
      })
      .map((item) => item.label);

    return newResult;
  };

  const generalSettingData = useSelector(
    (state) => state.settingsData.settingsData
  );

  const otherSettingsData = generalSettingData?.filter(
    (item) => item.settings_category === "other"
  );

  const dateFormat = otherSettingsData?.find(
    (date) => date.field_name === "DEFAULT_DATE_FORMAT"
  )?.field_value;

  const timeZone = otherSettingsData?.find(
    (time) => time.field_name === "DEFAULT_TIME_FORMAT"
  )?.field_value;

  const timeFormat = otherSettingsData?.find(
    (zone) => zone.field_name === "DEFAULT_TIME_ZONE"
  )?.field_value;

  const getDepartmentName = async (id) => {
    try {
      let response = await axios.get(
        `websiteCategories/get-category-name/${id}`
      );
      const decryptedData = common.decrypt(response.data);
      response.data = decryptedData;
      if (response.status === "success") {
        const finalObject = {
          ...response.data,
          value: response.data.id,
        };

        setDefaultSelectedChip(finalObject);
        if (!selectedCategory.find((option) => option.id === finalObject.id)) {
          setSelectedCategory([...selectedCategory, finalObject]);
        }
      }
    } catch (error) {
      console.log("Error ->", error.message);
    }
  };

  useEffect(() => {
    if (role === "Super Admin" || role === "Admin") {
      return;
    } else {
      getDepartmentName(userObject.department_id);
    }
  }, []);

  return (
    <Box className="annoucements-page">
      <Box className="d-flex flex-wrap align-items-end justify-content-between gap-3 mb-4">
        <Box className="search-box">
          <input
            id="search-filter"
            type="text"
            className="form-control bg-white"
            placeholder="Search for title..."
            value={query}
            onChange={(e) => {
              setQuery(e.target.value);
              setPage(1);
            }}
          />
          <i className="ri-search-line search-icon"></i>
        </Box>
        {hasPermission && hasPermission?.canCreate != null && (
          <button
            type="button"
            onClick={() => {
              setShow(true);
            }}
            className="btn btn-primary"
          >
            <span>Add Announcements</span>
          </button>
        )}
      </Box>
      <Box className="row g-3">
        <Box className="col-xl-3 col-lg-4">
          <Card className="h-auto">
            <Card.Header>
              <Box className="d-flex mb-3">
                <Box className="flex-grow-1">
                  <h5 className="fs-16">Filters</h5>
                </Box>
                <Box className="flex-shrink-0">
                  <Link
                    onClick={handleReset}
                    className="text-muted text-decoration-underline"
                  >
                    Clear All
                  </Link>
                </Box>
              </Box>
              {/* <Stack direction="row" flexWrap="wrap">
                {selectedCategory.map((selectedItem) => (
                  <Chip
                    sx={{
                      "&.MuiChip-root": {
                        margin: "1px",
                        background: "#337ab7",
                        color: "#fff",
                      },
                      "& .MuiSvgIcon-root": {
                        color: "#fff",
                      },
                    }}
                    key={selectedItem.id}
                    label={selectedItem.label}
                    onDelete={() => handleOptionRemove(selectedItem)}
                  />
                ))}
              </Stack> */}
              <Stack direction="row" flexWrap="wrap">
                {selectedCategory.map((selectedItem) => (
                  <Chip
                    sx={{
                      "&.MuiChip-root": {
                        margin: "1px",
                        background: "#337ab7",
                        color: "#fff",
                      },
                      "& .MuiSvgIcon-root": {
                        color: "#fff",
                      },
                    }}
                    key={selectedItem.id}
                    label={selectedItem.label}
                    onDelete={() => handleOptionRemove(selectedItem)}
                  />
                ))}
              </Stack>
            </Card.Header>
            <Box className="accordion accordion-flush">
              <Box className="card-body border-bottom">
                <Box>
                  <p className="text-muted text-uppercase fs-12 fw-medium mb-2">
                    Categories
                  </p>
                  <Stack direction="row" flexWrap="wrap">
                    {websiteCategories.map((item) => (
                      <Chip
                        key={item.id}
                        label={item.label}
                        variant="outlined"
                        onClick={() => handleCategoryChange(item)}
                        sx={{
                          "&.MuiChip-root": {
                            height: "26px",
                            borderColor: "#CED4DA",
                            color: "#1a2232",
                            margin: "2px",
                          },
                        }}
                      />
                    ))}
                  </Stack>
                </Box>
              </Box>
              <Box className="card-body">
                <p className="text-muted fs-12 fw-medium mb-2">Select Date</p>
                <Flatpickr
                  ref={refComp}
                  placeholder="Select Date Range"
                  className="form-control date-box"
                  options={{
                    dateFormat: `${dateFormat}`,
                    mode: "range",
                  }}
                  value={selectedDate}
                  onChange={handleDateChange}
                />
              </Box>
            </Box>
          </Card>
        </Box>
        <Box className="col-xl-9 col-lg-8">
          <Card className="announcement-list">
            <Card.Body>
              <ul className="list-group list-group-flush border-dashed">
                {announcementsData.length > 0
                  ? announcementsData.map((item) => {
                      const formattedDateTime = formatDateTime(
                        item?.createdAt,
                        dateFormat,
                        timeZone,
                        timeFormat
                      );

                      const categories = displayCategory(
                        item.announcement_category
                      );

                      return (
                        <li className="list-group-item" key={item.id}>
                          <Box className="row g-3">
                            <Box className="col">
                              <h4 className="title">
                                {item.announcement_title}
                                <span>
                                  {
                                    formattedDateTime?.timeZone
                                      ?.formattedDateFinal
                                  }
                                </span>
                              </h4>
                              <p className="description">
                                {item.announcement_description}
                              </p>

                              <Stack direction="row" flexWrap="wrap">
                                {categories.map((category, idx) => (
                                  <Chip
                                    sx={{
                                      "&.MuiChip-root": {
                                        margin: "2px",
                                        border: 0,
                                        background: "#ced4da",
                                        color: "#1a2232",
                                      },
                                    }}
                                    variant="outlined"
                                    key={idx}
                                    label={category}
                                  />
                                ))}
                              </Stack>
                            </Box>
                            {(hasPermission &&
                              hasPermission?.canDelete != null) ||
                            hasPermission?.canModify != null ? (
                              <Box className="more-btn">
                                <span
                                  id="dropdownMenuLink2"
                                  data-bs-toggle="dropdown"
                                  aria-expanded="false"
                                  className="cursor-pointer"
                                >
                                  <MoreVertOutlinedIcon />
                                </span>
                                <ul
                                  className="dropdown-menu dropdown-menu-end"
                                  aria-labelledby="dropdownMenuLink2"
                                >
                                  {hasPermission &&
                                  hasPermission?.canModify != null ? (
                                    <li>
                                      <span
                                        className="dropdown-item cursor-pointer"
                                        onClick={() => updateData(item)}
                                      >
                                        <span className="me-2 align-middle">
                                          <EditOutlinedIcon />
                                        </span>
                                        Edit
                                      </span>
                                    </li>
                                  ) : (
                                    ""
                                  )}
                                  {hasPermission &&
                                  hasPermission?.canDelete != null ? (
                                    <li>
                                      <span
                                        className="dropdown-item cursor-pointer"
                                        onClick={() => {
                                          handleDeleteAlert(item.id);
                                        }}
                                      >
                                        <span className="me-2 align-middle">
                                          <DeleteIcon />
                                        </span>
                                        Delete
                                      </span>
                                    </li>
                                  ) : (
                                    ""
                                  )}
                                </ul>
                              </Box>
                            ) : (
                              ""
                            )}
                          </Box>
                        </li>
                      );
                    })
                  : !isLoading && (
                      <li className="list-group-item text-center h5">
                        No Record Found
                      </li>
                    )}
              </ul>
              {isLoading && <Loader />}

              {!isLoading &&
                announcementsData.length > 0 &&
                announcementsData.length < totalRows && (
                  <button
                    type="button"
                    onClick={handleLoadMore}
                    className="btn btn-primary mt-3 mx-auto d-block btn-sm"
                  >
                    Load More
                  </button>
                )}
            </Card.Body>
          </Card>
        </Box>
      </Box>

      <Offcanvas
        show={show}
        onHide={handleClose}
        placement="end"
        backdrop="static"
      >
        <Offcanvas.Header>
          <Offcanvas.Title>
            {id ? <>Update Announcements </> : <>Add Announcements</>}{" "}
          </Offcanvas.Title>{" "}
        </Offcanvas.Header>
        <Offcanvas.Body>
          <Form onSubmit={validation.handleSubmit}>
            <Box className="mb-3">
              <TextField
                fullWidth
                label="Title *"
                name="announcement_title"
                id="outlined-size-small"
                placeholder="Enter title"
                value={validation.values.announcement_title}
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
              />
              {validation.errors.announcement_title &&
                validation.touched.announcement_title && (
                  <span className="error-text">
                    {validation.errors.announcement_title}
                  </span>
                )}
            </Box>
            <Box className="mb-3">
              <FormControl fullWidth>
                <InputLabel>Select Categories *</InputLabel>
                <Select
                  multiple
                  value={selectedOptions}
                  onChange={(e) => handleSelectChange(e.target.value)}
                  label="Select Categories *"
                  MenuProps={MenuHeight}
                  renderValue={(selected) =>
                    selected.map((item) => item.label).join(", ")
                  }
                >
                  {websiteCategories.map((category) => (
                    <MenuItem key={category.id} value={category}>
                      {category.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              {validation.errors.announcement_category &&
              validation.touched.announcement_category ? (
                <span className="error-text">
                  {validation.errors.announcement_category}
                </span>
              ) : null}
            </Box>
            <Box className="mb-3">
              <TextField
                fullWidth
                multiline
                label="Descriprtion *"
                name="announcement_description"
                id="outlined-size-small"
                placeholder="Enter descriprtion"
                value={validation.values.announcement_description}
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
              />
              {validation.errors.announcement_description &&
                validation.touched.announcement_description && (
                  <span className="error-text">
                    {validation.errors.announcement_description}
                  </span>
                )}
            </Box>
            <Box className="d-flex align-items-center">
              <Button
                className="btn-dark"
                type="submit"
                disabled={postIsLoading}
              >
                {postIsLoading ? (
                  <Spinner size="sm" variant="light" />
                ) : (
                  "Submit"
                )}
              </Button>
              <Button className="btn-primary ms-2" onClick={handleClose}>
                Close
              </Button>
            </Box>
          </Form>
        </Offcanvas.Body>
      </Offcanvas>
    </Box>
  );
};

export default Announcements;

import { API_ROUTES } from "../../ApiRoutes";
import axios from "axios";

export const fetchOnlineAssetsReq = (props) => {
  const { params } = props;
  let url = API_ROUTES.GET_ONLINE_ASSETS;
  const reqData = {
    assets_id: params.id,
  };
  let userData = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
    body: JSON.stringify(reqData),
  };

  return { url, userData };
};

export const refreshTestApiReq = (props) => {
  const { assetID, userID, token } = props;

  let url = API_ROUTES.GET_GOOGLE_IMMUNI_WEB_DATA;

  const reqData = {
    assets_id: parseInt(assetID),
    user_id: parseInt(userID),
  };

  let userData = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(reqData),
  };

  return { url, userData };
};

export const postScheduleDataReq = (props) => {
  const { token, userName, userId, params, scheduleDate, recurringScan } =
    props;

  let url = API_ROUTES.ADD_SCHEDULE_SECURITY;

  let formattedDate =
    scheduleDate.getFullYear() +
    "-" +
    ("0" + (scheduleDate.getMonth() + 1)).slice(-2) +
    "-" +
    ("0" + scheduleDate.getDate()).slice(-2) +
    " " +
    ("0" + scheduleDate.getHours()).slice(-2) +
    ":" +
    ("0" + scheduleDate.getMinutes()).slice(-2) +
    ":" +
    ("0" + scheduleDate.getSeconds()).slice(-2);

  let reqData = JSON.stringify({
    tested_by: userName,
    user_id: userId,
    online_asset_id: params.id,
    schedule_date_time: formattedDate,
    recurring_scan: recurringScan,
  });

  let userData = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: reqData,
  };

  return { url, userData };
};

export const fetchSecurityHistoryReq = (props) => {
  const { params, userId } = props;

  let url = API_ROUTES.GET_SCHEDULE_SECURITY + `?page=${1}&perPage=${100}`;

  let reqData = {
    online_asset_id: params.id,
    user_id: userId,
  };

  let userData = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
    body: JSON.stringify(reqData),
  };

  return { url, userData };
};

export const fetchSecurityScanTimeReq = (props) => {
  const { params, userId, token } = props;

  let url = API_ROUTES.GET_SCHEDULE_SECURITY_SCANTIME;

  let reqData = {
    online_asset_id: params.id,
    user_id: userId,
  };

  let userData = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(reqData),
  };

  return { url, userData };
};

export const getVisitorData = async (id, response_filter) => {
    const { data } = await axios.post(
      `asset/analytics-visitors-data/${id}`,
      JSON.stringify({ response_filter })
    );
    return data;
};

import { API_ROUTES } from "../ApiRoutes";

export const ForgetPasswordReq = (values) => {
    let url = API_ROUTES.RESET_PASSWORD
    let userData = {
        email: values.email
    };

    let reqData = {
        method: "post",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify(userData),
    };

    return { url: url, reqData };
}

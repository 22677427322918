import React, { useState, useEffect } from "react";
import { IssueTablistStyle } from "../constants";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Chip,
  List,
  ListItem,
  Tab,
} from "@mui/material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import ArrowDropDownRoundedIcon from "@mui/icons-material/ArrowDropDownRounded";
import SimpleBar from "simplebar-react";
import DataTable from "react-data-table-component";

const StructureTab = ({ auditReport, maxItems, isSummary = false }) => {
  const [issues, setIssues] = useState("1");
  const [filteredData, setFilteredData] = useState([]);

  const filterCategory = (category) => {
    if (category === "1") {
      setFilteredData(transformAuditReportData(auditReport));
    } else {
      const categoryMap = {
        2: "FCP",
        3: "LCP",
        4: "TBT",
        5: "CLS",
      };
      const filteredIssue = auditReport.filter((report) =>
        report.categories.includes(categoryMap[category])
      );
      setFilteredData(transformAuditReportData(filteredIssue));
    }
  };

  const handleChangeIssues = (event, value) => {
    setIssues(value);
    filterCategory(value);
  };

  const transformAuditReportData = (report) => {
    // return report?.map((item, index) => ({
    return report?.slice(0, maxItems).map((item, index) => ({
      id: index + 1,
      statusChip: item.riskLevel || "-",
      title: item.title || "-",
      tags: item.categories && item.categories.length > 0 && item.categories,
      describe: item.displayValue || "",
      statusBg: getStatusBg(item.riskLevel), // Optional: Implement a function to return a color based on riskLevel
      issueDetails:
        item.contents && item.contents.length > 0 ? (
          <IssueDetailsTable
            contents={item.contents}
            description={item.description}
          />
        ) : null,
    }));
  };

  useEffect(() => {
    setFilteredData(transformAuditReportData(auditReport));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auditReport]);

  const formatCacheTTL = (ms) => {
    const totalSeconds = Math.floor(ms / 1000);
    const hours = Math.floor(totalSeconds / 3600);
    const minutes = Math.floor((totalSeconds % 3600) / 60);
    const seconds = totalSeconds % 60;

    let formatted = "";
    if (hours > 0) formatted += `${hours}h `;
    if (minutes > 0) formatted += `${minutes}m `;
    if (seconds > 0 || formatted === "") formatted += `${seconds}s`;

    return formatted.trim();
  };

  const formatMilliseconds = (ms, showSeconds = false) => {
    if (showSeconds) {
      return (ms / 1000)?.toFixed(2) + " s";
    } else {
      return ms?.toFixed(2) + " ms";
    }
  };

  const formatSize = (value) => {
    if (value >= 1024 * 1024) {
      return (value / (1024 * 1024))?.toFixed(2) + " MB";
    } else if (value >= 1024) {
      return (value / 1024)?.toFixed(2) + " KB";
    } else {
      return value?.toFixed(2) + " Bytes";
    }
  };

  const IssueDetailsTable = ({ contents, description }) => {
    const formatFunctions = {
      "Time Spent": formatMilliseconds,
      "Cache TTL": formatCacheTTL,
      "Total CPU Time": formatMilliseconds,
      "Script Evaluation": formatMilliseconds,
      "Script Parse": formatMilliseconds,
      "Start Time": formatMilliseconds,
      Duration: formatMilliseconds,
      "Main-Thread Blocking Time": formatMilliseconds,
      "CLS Contribution": (value) => value?.toFixed(2),
      // "Potential Savings": (value) => (value / 1024)?.toFixed(2) + " KB",
      // "Resource Size": (value) => (value / 1024)?.toFixed(2) + " KB",
      // "Transfer Size": (value) => (value / 1024)?.toFixed(2) + " KB",
      "Potential Savings": formatSize,
      "Resource Size": formatSize,
      "Transfer Size": formatSize,
    };

    const columns = Object.keys(contents[0]).map((key) => ({
      name: key,
      selector: (row) => {
        if (typeof row[key] === "object") {
          if (key === "Source") {
            const { url } = row[key];
            return `${url}`;
          }
          return key === "Value" ? row[key].value : row[key].nodeLabel;
        } else {
          const formatFunction = formatFunctions[key];
          return formatFunction ? formatFunction(row[key]) : row[key];
        }
      },
      sortable: true,
      minWidth: "160px",
    }));

    return (
      <React.Fragment>
        <p>{description}</p>
        <DataTable
          columns={columns}
          data={contents}
          pagination
          paginationPerPage={5}
          className="table-design"
        />
      </React.Fragment>
    );
  };

  const getStatusBg = (riskLevel) => {
    switch (riskLevel) {
      case "High":
        return "#ec685d";
      case "Med-High":
        return "#c48a8a";
      case "Med-Low":
        return "#99c144";
      case "Med":
        return "#f6ab34";
      case "Low":
        return "#36a927";
      case "None":
        return "#1a7f0c";
      case "Out of Range":
      default:
        return "#CCCCCC";
    }
  };

  const renderTopIssues =
    filteredData &&
    filteredData?.map((data) => {
      const {
        id,
        statusChip,
        title,
        tags,
        describe,
        statusBg,
        statusColor,
        issueDetails,
      } = data;
      return (
        <ListItem key={id}>
          <Chip
            label={statusChip}
            className="status-chip"
            sx={{ background: statusBg, color: `${statusColor} !important` }}
          />
          <Accordion className="issue-accordian">
            <AccordionSummary
              // expandIcon={<ArrowDropDownRoundedIcon />}
              expandIcon={issueDetails ? <ArrowDropDownRoundedIcon /> : null}
              aria-controls={`${id}-content`}
              id={`${id}-header`}
            >
              <span className="text-line">
                {title}{" "}
                {tags?.length > 0 &&
                  tags?.map((tag, index) => (
                    <Chip key={index} label={tag} />
                  ))}{" "}
                {/* {description} */}
              </span>
              <span className="text-line">{describe}</span>
            </AccordionSummary>
            {issueDetails && (
              <AccordionDetails>{issueDetails}</AccordionDetails>
            )}
            {/* <AccordionDetails>{issueDetails}</AccordionDetails> */}
          </Accordion>
        </ListItem>
      );
    });

  return (
    <Box className="structure-tab">
      {renderTopIssues?.length > 0 ? (
        <>
          <TabContext value={issues}>
            <Box className="tab-head">
              {!isSummary && (
                <Box className="d-flex me-auto">
                  <p className="mb-0 ps-3" style={{ width: "100px" }}>
                    IMPACT
                  </p>
                  <p className="mb-0 ps-3">AUDIT</p>
                </Box>
              )}
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: "20px",
                  justifyContent: "end",
                }}
              >
                {!isSummary && (
                  <span className="d-none d-md-block">
                    Show Audits Relevant to
                  </span>
                )}
                <TabList
                  onChange={handleChangeIssues}
                  // variant="scrollable"
                  // scrollButtons
                  // allowScrollButtonsMobile
                  sx={IssueTablistStyle}
                >
                  <Tab label="All" value="1" />
                  <Tab label="FCP" value="2" />
                  <Tab label="LCP" value="3" />
                  <Tab label="TBT" value="4" />
                  <Tab label="CLS" value="5" />
                </TabList>
              </Box>
            </Box>
            <TabPanel value="1" className="issue-tabpanel">
              <SimpleBar style={{ maxHeight: "500px" }} autoHide={false}>
                <List className="issue-list">{renderTopIssues}</List>
              </SimpleBar>
            </TabPanel>
            <TabPanel value="2" className="issue-tabpanel">
              <SimpleBar style={{ maxHeight: "500px" }} autoHide={false}>
                <List className="issue-list">{renderTopIssues}</List>
              </SimpleBar>
            </TabPanel>
            <TabPanel value="3" className="issue-tabpanel">
              <SimpleBar style={{ maxHeight: "500px" }} autoHide={false}>
                <List className="issue-list">{renderTopIssues}</List>
              </SimpleBar>
            </TabPanel>
            <TabPanel value="4" className="issue-tabpanel">
              <SimpleBar style={{ maxHeight: "500px" }} autoHide={false}>
                <List className="issue-list">{renderTopIssues}</List>
              </SimpleBar>
            </TabPanel>
            <TabPanel value="5" className="issue-tabpanel">
              <SimpleBar style={{ maxHeight: "500px" }} autoHide={false}>
                <List className="issue-list">{renderTopIssues}</List>
              </SimpleBar>
            </TabPanel>
          </TabContext>
        </>
      ) : null}
    </Box>
  );
};

export default StructureTab;

import { socket } from "../../../Components/Common/socket";

export const AddEditWebsiteGuidelineRes = (props) => {
  const {
    response,
    navigate,
    getData,

    setShow,
    ToastMessage,
    validation,
    setId,
    id,
  } = props;
  if (response.code === "403") {
    navigate("/auth-404-cover");
  }
  if (response.status === "success" || response.status === "fail") {
    navigate("/website-guideline");
    id
      ? socket.emit("updateWebsiteGuideline")
      : socket.emit("addWebsiteGuideline");
    getData();
    setShow(false);

    ToastMessage[response.status === "success" ? "Success" : "Error"](
      response.message
    );
    setTimeout(() => {
      validation.resetForm();
    }, 1000);
    setId("");
  }
};

export const ListWebsiteGuidelineRes = (
  response,
  navigate,
  setData,
  setIsLoading,
  responseData
) => {
  switch (response.code) {
    case "401":
    case "403":
      navigate("/auth-404-cover");
      break;
    case "404":
      navigate("/auth-404-cover");
      break;
    case "200":
      setData(responseData);
      setIsLoading(false);
      break;
    default:
      console.error("something went wrong. please try again later.");
  }
};

export const DeleteWebsiteGuidelineRes = (response, navigate, getData) => {
  switch (response.code) {
    case "401":
    case "403":
    case "404":
      navigate("/auth-404-cover");
      break;
    case "200":
      getData();
      socket.emit("deleteWebsiteGuideline");
      break;
    default:
      console.error("something went wrong. please try again later.");
  }
  // if (response.status === "fail") {
  //     ToastMessage.Error(response.message);
  // }
};

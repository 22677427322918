import * as types from "./actionType";

// Live Users By Country for Map.

export const fetchAnalyticsRealtimeData = (payload) => ({
  type: types.FETCH_ANALYTICS_REALTIME_DATA_REQUEST,
  payload: payload,
});

export const fetchAnalyticsRealtimeDataSuccess = (data) => ({
  type: types.FETCH_ANALYTICS_REALTIME_SUCCESS,
  payload: data,
});

export const fetchAnalyticsRealtimeDatafailure = (error) => ({
  type: types.FETCH_ANALYTICS_REALTIME_FAILURE,
  payload: error,
});

// Live Users By Country for duration wise.

export const fetchAnalyticsRealtimeDurationData = (payload) => ({
  type: types.FETCH_ANALYTICS_REALTIME_DURATION_DATA_REQUEST,
  payload: payload,
});

export const fetchAnalyticsRealtimeDurationDataSuccess = (data) => ({
  type: types.FETCH_ANALYTICS_REALTIME_DURATION_DATA_SUCCESS,
  payload: data,
});

export const fetchAnalyticsRealtimeDurationDatafailure = (error) => ({
  type: types.FETCH_ANALYTICS_REALTIME_DURATION_DATA_FAILURE,
  payload: error,
});

// Analytics All Data.

export const fetchAnalyticsData = (payload) => ({
  type: types.FETCH_ANALYTICS_DATA_REQUEST,
  payload: payload,
});

export const fetchAnalyticsDataSuccess = (data) => ({
  type: types.FETCH_ANALYTICS_DATA_SUCCESS,
  payload: data,
});

export const fetchAnalyticsDatafailure = (error) => ({
  type: types.FETCH_ANALYTICS_DATA_FAILURE,
  payload: error,
});

// Analytics All Data.

export const fetchUserSessionChartData = (payload) => ({
  type: types.FETCH_USER_SESSION_CHART_REQUEST,
  payload: payload,
});

export const fetchUserSessionChartDataSuccess = (data) => ({
  type: types.FETCH_USER_SESSION_CHART_SUCCESS,
  payload: data,
});

export const fetchUserSessionChartDatafailure = (error) => ({
  type: types.FETCH_USER_SESSION_CHART_FAILURE,
  payload: error,
});

// Analytics chart data

export const fetchAnalyticsChartData = (payload) => ({
  type: types.FETCH_CHART_DATA_REQUEST,
  payload: payload,
});

export const fetchChartDataSuccess = (data) => ({
  type: types.FETCH_CHART_DATA_SUCCESS,
  payload: data,
});

export const fetchChartDatafailure = (error) => ({
  type: types.FETCH_CHART_DATA_FAILURE,
  payload: error,
});

//Hit Reports data
export const fetchHitReportData = (payload) => ({
  type: types.FETCH_HIT_REPORT_REQUEST,
  payload: payload,
});

export const fetchHitReportDataSuccess = (data) => ({
  type: types.FETCH_HIT_REPORT_SUCCESS,
  payload: data,
});

export const fetchHitReportDatafailure = (error) => ({
  type: types.FETCH_HIT_REPORT_FAILURE,
  payload: error,
});

// visitors data
export const fetchVisitorsData = (payload) => ({
  type: types.FETCH_VISITORS_DATA_REQUEST,
  payload: payload,
});

export const fetchVisitorsDataSuccess = (data) => ({
  type: types.FETCH_VISITORS_DATA_SUCCESS,
  payload: data,
});

export const fetchVisitorsDatafailure = (error) => ({
  type: types.FETCH_VISITORS_DATA_FAILURE,
  payload: error,
});

export const setInitialState = () => ({
  type: types.SET_INITIAL_STATE,
});

import { createContext, useContext, useState } from 'react';

const UserContext = createContext();

export function UserProvider({ children }) {
  const [userRole, setUserRole] = useState('Admin'); // Default role is guest

  const setUserRoleValue = (role) => {
    setUserRole(role);
  };

  return (
    <UserContext.Provider value={{ userRole, setUserRoleValue }}>
      {children}
    </UserContext.Provider>
  );
}

export function useUserRole() {
  const context = useContext(UserContext);
  if (!context) {
    throw new Error('useUserRole must be used within a UserProvider');
  }
  return context;
}

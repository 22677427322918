import { Offcanvas } from "react-bootstrap";
import {
  Button,
  Col,
  Row,
  Form,
  Label,
  Input,
  FormFeedback,
  FormGroup,
  Spinner,
} from "reactstrap";
import Select from "react-select";
import {
  Box,
  IconButton,
  InputAdornment,
  // Select,
  TextField,
  Tooltip,
} from "@mui/material";
import { useEffect, useState } from "react";
import { Visibility, VisibilityOff } from "@mui/icons-material";
// import { useSelector } from "react-redux";
import axios from "axios";
import common from "../../../Components/Common/common";

const InternalUsersModal = ({
  validation,
  show,
  handleClose,
  id,
  selectedImage,
  statusSelectOpt,
  setStatusSelectOpt,
  options,
  checkboxes,
  handleCheckboxChange,
  selectedOptions,
  handleSelectChange,
  allOptions,
  selectAllWebsites,
  noSelectedImage,
  handleChangeImage,
  roleOptions,
  setRoleSelectOpt,
  roleSelectOpt,
  departmentOptions,
  setDepartmentSelectOpt,
  fetchAssetsByDepartment,
  departmentSelectOpt,
  setSelectedOptions,
  setSelectAllWebsites,
  postIsLoading,
  userData,
}) => {
  const user_id = Number(localStorage.getItem("user_id"));
  const logoTooltip =
    "Only JPEG, JPG, PNG, and SVG images are allowed. Maximum size of the Image is 1MB.";
  const handleRoleChange = (option) => {
    validation.setFieldValue("roleSelect", option.value);
    setRoleSelectOpt(option);
    fetchAssetsByDepartment(departmentSelectOpt, option.value);
  };

  const handleDepartmentChange = (option) => {
    const value = option ? option.value : ""; // If option is removed, set an empty string
    validation.setFieldValue("departmentSelect", value);
    validation.setFieldTouched("departmentSelect", true, true); // Mark as touched
    validation.validateField("departmentSelect"); // Manually trigger validation
    setDepartmentSelectOpt(option);
    setSelectAllWebsites(false);
    setSelectedOptions([]);
    fetchAssetsByDepartment(option, validation.values.roleSelect);
  };

  useEffect(() => {
    if (allOptions.length > 0 && allOptions.length === selectedOptions.length) {
      setSelectAllWebsites(true);
    } else {
      setSelectAllWebsites(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allOptions, selectedOptions]);

  const [showPassword, setShowPassword] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState(false);
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleClickShowConfirmPassword = () => {
    setConfirmPassword(!confirmPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  const handleMouseDownConfirmPassword = (event) => {
    event.preventDefault();
  };

  const customStyles = {
    menu: (provided) => ({
      ...provided,
      zIndex: 9999,
    }),
  };

  const getDepartmentName = async (id) => {
    try {
      let response = await axios.get(
        `websiteCategories/get-category-name/${id}`
      );
      const decryptedData = common.decrypt(response.data);
      response.data = decryptedData;

      if (userData?.role !== "Admin" && userData?.role !== "Super Admin") {
        const value = response.data ? response.data.id : "";
        validation.setFieldValue("departmentSelect", value);
        setDepartmentSelectOpt({
          value: response.data.id,
          label: response.data.label,
        });
      }
    } catch (error) {
      return null;
    }
  };

  useEffect(() => {
    if (userData?.role !== "Admin" && userData?.role !== "Super Admin") {
      getDepartmentName(userData?.department_id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show]);

  return (
    <>
      <Offcanvas
        style={{ width: "31rem" }}
        show={show}
        onHide={handleClose}
        placement="end"
        backdrop="static"
      >
        <Offcanvas.Header>
          <Offcanvas.Title>
            {id ? <> Edit Core Users </> : <> Add Core Users </>}{" "}
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <Form onSubmit={validation.handleSubmit}>
            <Row>
              <Col lg={12} className="mb-3">
                <Row>
                  <Label className="d-flex align-items-center">
                    Upload Image
                    <Tooltip title={logoTooltip} placement="top-start" arrow>
                      <i
                        className="ri-information-line fs-18 cursor-pointer ms-2"
                        style={{
                          color: "#ced4da",
                        }}
                      ></i>
                    </Tooltip>
                  </Label>
                </Row>

                <Box className="profile-user position-relative d-inline-block mx-auto mb-4">
                  {selectedImage ? (
                    <img
                      name="departmentImage1"
                      src={selectedImage}
                      className="avatar-xl rounded-circle flex-shrink-0 border"
                      alt="user-profile"
                    />
                  ) : (
                    <img
                      name="departmentImage2"
                      src={
                        validation.values.departmentImage != null
                          ? `${process.env.REACT_APP_BASE_URL}${validation.values.departmentImage}`
                          : noSelectedImage
                      }
                      className="avatar-xl rounded-circle flex-shrink-0 border"
                      alt="user-profile"
                    />
                  )}

                  <Box className="avatar-xs p-0 rounded-circle profile-photo-edit">
                    <Input
                      id="profile-img-file-input"
                      type="file"
                      accept="image/*"
                      name="departmentImage"
                      onChange={(e) => {
                        handleChangeImage(e);
                        validation.setFieldTouched(
                          "departmentImage",
                          true,
                          false
                        );
                      }}
                      onBlur={validation.handleBlur}
                      className="profile-img-file-input"
                    />
                    <Label
                      htmlFor="profile-img-file-input"
                      className="profile-photo-edit avatar-xs"
                    >
                      <span className="avatar-title rounded-circle bg-light text-body">
                        <i className="ri-camera-fill"></i>
                      </span>
                    </Label>
                  </Box>
                </Box>

                {validation.errors.departmentImage &&
                validation.touched.departmentImage ? (
                  <span style={{ color: "red", float: "right" }}>
                    {validation.errors.departmentImage}
                  </span>
                ) : null}
              </Col>

              <Col lg={12} className="mb-3">
                <TextField
                  fullWidth
                  label="Full name *"
                  name="fullName"
                  id="outlined-size-small"
                  placeholder="Enter your Full Name"
                  value={validation.values.fullName}
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                />
                {validation.errors.fullName && validation.touched.fullName && (
                  <span className="error-text">
                    {validation.errors.fullName}
                  </span>
                )}
              </Col>

              <Col lg={12} className="mb-3">
                <Label>Role *</Label>
                <Select
                  name="asset_url_management"
                  options={roleOptions}
                  onChange={handleRoleChange}
                  value={
                    validation.values.roleSelect === "Super Admin"
                      ? { value: "Super Admin", label: "Super Admin" }
                      : roleSelectOpt
                  }
                  onBlur={validation.handleBlur}
                  styles={customStyles}
                  invalid={
                    validation.errors.roleSelect &&
                    validation.touched.roleSelect
                      ? true
                      : false
                  }
                  isDisabled={validation.values.roleSelect === "Super Admin"}
                />
                {validation.errors.roleSelect &&
                  validation.touched.roleSelect && (
                    <span className="error-text">
                      {validation.errors.roleSelect}
                    </span>
                  )}
              </Col>

              {validation.values.roleSelect !== "Super Admin" && (
                <>
                  {validation.values.roleSelect !== "Admin" && (
                    <Col lg={12} className="mb-3">
                      <Label>Department *</Label>
                      <Select
                        isClearable={
                          userData?.role === "Admin" ||
                          userData?.role === "Super Admin"
                        }
                        name="departmentSelect"
                        options={departmentOptions}
                        isSearchable={true}
                        onChange={
                          userData?.role === "Admin" ||
                          userData?.role === "Super Admin"
                            ? handleDepartmentChange
                            : null
                        }
                        value={departmentSelectOpt}
                        onBlur={validation.handleBlur}
                        styles={customStyles}
                        isDisabled={
                          !(
                            userData?.role === "Admin" ||
                            userData?.role === "Super Admin"
                          )
                        }
                        invalid={
                          validation.errors.departmentSelect &&
                          validation.touched.departmentSelect
                            ? true
                            : false
                        }
                      />
                      {validation.errors.departmentSelect &&
                        validation.touched.departmentSelect && (
                          <span className="error-text">
                            {validation.errors.departmentSelect}
                          </span>
                        )}
                    </Col>
                  )}

                  <Col lg={12} className="mb-3">
                    <Label className="mr-5" style={{ marginRight: "40px" }}>
                      Website
                    </Label>
                    <Label className="mr-3">
                      <FormGroup switch>
                        <Label check>Select All Websites</Label>
                        <Input
                          type="switch"
                          role="switch"
                          name="selectAllWebsites"
                          checked={selectAllWebsites}
                          onChange={handleCheckboxChange}
                        />
                      </FormGroup>
                    </Label>
                    <Select
                      options={allOptions}
                      placeholder="Select an option"
                      isSearchable={true}
                      isMulti
                      value={selectAllWebsites ? allOptions : selectedOptions}
                      onChange={handleSelectChange}
                      onBlur={validation.handleBlur}
                      styles={customStyles}
                      // Make website required for non-Admin roles
                      isInvalid={
                        validation.errors.asset_url_management &&
                        validation.touched.asset_url_management
                      }
                    />
                    {validation.errors.asset_url_management &&
                      validation.touched.asset_url_management && (
                        <FormFeedback
                          style={{ color: "red", display: "block" }}
                        >
                          {validation.errors.asset_url_management}
                        </FormFeedback>
                      )}
                  </Col>

                  {validation.values.roleSelect !== "Admin" &&
                    selectedOptions.length > 0 && (
                      <Box id="checkBoxes">
                        <Box className="col-lg-12 mb-3">
                          <label>Website Security : &nbsp;</label>
                          <Box className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              name="web_security_read"
                              checked={checkboxes.web_security_read}
                              onChange={handleCheckboxChange}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="web_security_read"
                            >
                              Can Read
                            </label>
                          </Box>
                          <Box className="form-check form-check-inline d-none">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              name="web_security_execute"
                              checked={checkboxes.web_security_execute}
                              onChange={handleCheckboxChange}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="web_security_execute"
                            >
                              Can Execute
                            </label>
                          </Box>
                        </Box>
                        <Box className="col-lg-12 mb-3">
                          <label>
                            Create Notes : &nbsp; &nbsp; &nbsp; &nbsp;
                          </label>
                          <Box className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              name="notes_read"
                              checked={checkboxes.notes_read}
                              onChange={handleCheckboxChange}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="notes_read"
                            >
                              Can Read
                            </label>
                          </Box>
                          <Box className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              name="notes_execute"
                              checked={checkboxes.notes_execute}
                              onChange={handleCheckboxChange}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="notes_execute"
                            >
                              Can Execute
                            </label>
                          </Box>
                        </Box>
                      </Box>
                    )}
                </>
              )}

              <Col lg={12} className="mb-3">
                {/* <Label>Email ID *</Label> */}
                <TextField
                  fullWidth
                  label="Email *"
                  name="email"
                  placeholder="Enter your Email"
                  value={validation.values.email}
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                />
                {validation.errors.email && validation.touched.email && (
                  <span className="error-text">{validation.errors.email}</span>
                )}
              </Col>

              <Col lg={12} className="mb-3" hidden>
                <Label>Password</Label>
                <Input
                  readOnly
                  type="password"
                  name="hiddenPassword"
                  placeholder="Enter your password"
                  value={validation.values.hiddenPassword}
                />
              </Col>

              <Col lg={12} className="mb-3">
                <TextField
                  fullWidth
                  label="Password"
                  name="password"
                  type={showPassword ? "text" : "password"}
                  placeholder="Enter your password"
                  value={validation.values.password}
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
                {validation.errors.password && validation.touched.password && (
                  <span className="error-text">
                    {validation.errors.password}
                  </span>
                )}
              </Col>

              {/* Confirm Password Field */}
              <Col lg={12} className="mb-3">
                <TextField
                  fullWidth
                  label="Confirm Password"
                  name="confirmPassword"
                  type={confirmPassword ? "text" : "password"}
                  placeholder="Confirm your password"
                  value={validation.values.confirmPassword}
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  // error={Boolean(
                  //   validation.errors.confirmPassword &&
                  //     validation.touched.confirmPassword
                  // )}
                  // helperText={
                  //   validation.errors.confirmPassword &&
                  //   validation.touched.confirmPassword
                  //     ? validation.errors.confirmPassword
                  //     : ""
                  // }
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowConfirmPassword}
                          onMouseDown={handleMouseDownConfirmPassword}
                          edge="end"
                        >
                          {confirmPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
                {validation.errors.confirmPassword &&
                  validation.touched.confirmPassword && (
                    <span className="error-text">
                      {validation.errors.confirmPassword}
                    </span>
                  )}
              </Col>

              <Col lg={12} className="mb-3">
                {/* <Label>Phone No.</Label> */}
                <TextField
                  fullWidth
                  label="Phone No."
                  name="phone"
                  placeholder="Enter your phone"
                  value={validation.values.phone}
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  error={Boolean(
                    validation.errors.phone && validation.touched.phone
                  )}
                  helperText={
                    validation.errors.phone && validation.touched.phone
                      ? validation.errors.phone
                      : ""
                  }
                  // invalid={
                  //   validation.errors.phone && validation.touched.phone
                  //     ? true
                  //     : false
                  // }
                />
                {/* {validation.errors.phone && validation.touched.phone && (
                  <span className="error-text">{validation.errors.phone}</span>
                )} */}
              </Col>

              <Col lg={12}>
                <Box className="mb-3">
                  <Label htmlFor="Status" className="form-label">
                    Status
                  </Label>
                  <Select
                    options={
                      id !== user_id
                        ? options
                        : [{ value: "Active", label: "Active" }]
                    }
                    placeholder="Select an option"
                    isSearchable={true}
                    value={statusSelectOpt}
                    onChange={(option) => {
                      validation.setFieldValue("statusOption", option.value);
                      setStatusSelectOpt(option);
                    }}
                  />
                  {/* This for commented long */}
                  {/* <FormControl fullWidth>
                    <InputLabel>Status</InputLabel>
                    <Select
                      value={statusSelectOpt ? statusSelectOpt.value : ""}
                      onChange={handleStatusChange}
                      label="Status"
                    >
                      {options.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl> */}
                  {validation.errors.statusOption &&
                    validation.touched.statusOption && (
                      <span
                        type="d-block"
                        style={{
                          color: "red",
                          display: "block",
                        }}
                      >
                        {validation.errors.statusOption}
                      </span>
                    )}
                </Box>
              </Col>

              <Col lg={12} style={{ alignItems: "center" }}>
                <Button
                  className="btn-dark"
                  type="submit"
                  // style={!postIsLoading ? { width: "27%" } : {}}
                  disabled={postIsLoading}
                >
                  {postIsLoading ? (
                    <Spinner size="sm" variant="light" />
                  ) : (
                    "Submit"
                  )}
                </Button>
                <Button className="btn-primary ms-2" onClick={handleClose}>
                  Cancel
                </Button>
              </Col>
            </Row>
          </Form>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

export default InternalUsersModal;

import { useCallback, useEffect, useMemo, useState } from "react";
import { Button, Card, Nav } from "react-bootstrap";
// import { useSelector } from "react-redux";
// import { Link } from "react-router-dom";
// import axios from "axios";
import { Box, Skeleton } from "@mui/material";
import {
  BrowserIcon,
  DashboardIcon,
  DiscoverOutlineIcon,
} from "../../../../Components/Common/SvgIcons";
import { Spinner } from "reactstrap";
import common from "../../../../Components/Common/common";
import { ToastMessage } from "../../../../Components/Common/ToastMessage";
import { getPerformanceData } from "../../../../helpers/Help/CommomApi";
import { formatDateTime } from "../../../../Components/Common/FormatDateTIme";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

const Performance = (props) => {
  const token = localStorage.getItem("token");
  const role = localStorage.getItem("role");
  const params = useParams();

  const { apiDataLoading, apiLoadingAsset } = useSelector(
    (state) => state.onlineAssets
  );

  const generalSettingData = useSelector(
    (state) => state.settingsData.settingsData
  );
  const otherSettingsData = generalSettingData?.filter(
    (item) => item.settings_category === "other"
  );
  const dateFormat = otherSettingsData?.find(
    (date) => date.field_name === "DEFAULT_DATE_FORMAT"
  )?.field_value;
  const timeZone = otherSettingsData?.find(
    (time) => time.field_name === "DEFAULT_TIME_FORMAT"
  )?.field_value;
  const timeFormat = otherSettingsData?.find(
    (zone) => zone.field_name === "DEFAULT_TIME_ZONE"
  )?.field_value;

  const [scores, setGtmetrixData] = useState("");
  const [typedata, setType] = useState("1");
  const [timeFilter, setTimeFilter] = useState("year");
  const [isLoading, setIsLoading] = useState(false);
  const [skeleton, setSkeleton] = useState(false);

  const handleClickDays = (days) => {
    if (days) {
      setTimeFilter(days);
    }
  };

  const requestOptions = useMemo(() => {
    var urlencoded = new URLSearchParams();
    urlencoded.append("assets_id", props.assetId);
    if (typedata !== undefined) {
      urlencoded.append("system_type", typedata.toString());
    }
    if (timeFilter !== undefined) {
      urlencoded.append("time_filter", timeFilter);
    }
    return {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      body: urlencoded,
      redirect: "follow",
    };
  }, [props.assetId, timeFilter, typedata, token]);

  const fetchGtmetrix = useCallback(async () => {
    try {
      setSkeleton(true);
      let url = `${process.env.REACT_APP_BASE_URL}asset/performance`;
      let gtmetrix = await fetch(url, requestOptions);
      let data1 = await gtmetrix.json();
      const decryptedData = common.decrypt(data1.data);
      data1.data = decryptedData;

      if (data1.status === "success") {
        setGtmetrixData(data1.data);
        setSkeleton(false);
      } else if (data1.status === "fail") {
        throw new Error("Failed to fetch GTmetrix data");
      }
    } catch (error) {
      console.error("Error in fetchGtmetrix:", error);
    } finally {
      setSkeleton(false);
    }
  }, [requestOptions]);

  useEffect(() => {
    fetchGtmetrix();
  }, [fetchGtmetrix]);

  const getRunTestApiData = () => {
    setIsLoading(true);
    getPerformanceData({
      assetId: props.assetId,
    })
      .then((response) => {
        fetchGtmetrix();
        ToastMessage.Success("Data fetched successfully!");
      })
      .catch((error) => {
        ToastMessage.Error("Failed to fetch data. Please try again.");
        console.error(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <>
      <Card className="main-card">
        <Card.Header>
          <h4 className="card-title">Performance</h4>
        </Card.Header>
        <Card.Body className="nv_tab_com">
          {apiDataLoading &&
          apiLoadingAsset === (params?.id && JSON.parse(params?.id)) ? (
            <div className="d-flex flex-column flex-grow-1 justify-content-center align-items-center mt-5">
              <h5 className="card-body text-center">
                We are currently assessing the website's performance. The data
                will be displayed shortly.
              </h5>
            </div>
          ) : (
            <>
              <Nav
                className="nav nav-pills nav-justified mb-3 w-100"
                role="tablist"
              >
                <Nav.Item>
                  <button
                    onClick={() => handleClickDays("week")}
                    className="nav-link text-truncate"
                    title="7d"
                    data-bs-toggle="tab"
                    role="tab"
                  >
                    Weekly
                  </button>
                </Nav.Item>
                <Nav.Item className="nav-item waves-effect waves-light">
                  <button
                    onClick={() => handleClickDays("month")}
                    className="nav-link text-truncate"
                    title="30d"
                    data-bs-toggle="tab"
                    role="tab"
                  >
                    Monthly
                  </button>
                </Nav.Item>
                <Nav.Item>
                  <button
                    onClick={() => handleClickDays("year")}
                    className="nav-link text-truncate active"
                    title="1year"
                    data-bs-toggle="tab"
                    role="tab"
                  >
                    Yearly
                  </button>
                </Nav.Item>
              </Nav>

              <Box className="nv_tab_com">
                <Nav
                  className="nav nav-pills nav-justified mb-3 w-100"
                  role="tablist"
                >
                  <Nav.Item className="w-100">
                    <button
                      className="nav-link btn btn-primary w-100"
                      title="desktop"
                      data-bs-toggle="tab"
                      role="tab"
                      onClick={() => setType(1)}
                    >
                      Desktop
                    </button>
                  </Nav.Item>
                </Nav>
                <Box className="tab-content mt-2">
                  <Box
                    className="tab-pane active"
                    id="pill-justified-home-1"
                    role="tabpanel"
                  >
                    <Box>
                      <h6 className="fs-16 fw-semibold text-primary">
                        Page Speed Score
                      </h6>
                      <Box className="progress animated-progress custom-progress progress-label">
                        <Box
                          className="progress-bar"
                          role="progressbar"
                          style={{
                            width: `${scores ? scores?.pagespeed_score : 0}%`,
                          }}
                          aria-valuenow={scores ? scores?.pagespeed_score : 0}
                          aria-valuemin="0"
                          aria-valuemax="100"
                        >
                          <Box className="label">
                            {scores ? scores?.pagespeed_score : 0}{" "}
                            <span>%</span>
                          </Box>
                        </Box>
                      </Box>
                      <Box className="d-flex align-items-center justify-content-between text-muted fw-normal mt-3">
                        <span>Last Test</span>
                        <span>
                          {
                            formatDateTime(
                              scores?.day,
                              dateFormat,
                              timeZone,
                              timeFormat
                            ).timeZone.formattedDateFinal
                          }
                          {/* {scores?.day} */}
                        </span>
                      </Box>
                    </Box>
                    <Box className="d-flex align-content-start justify-content-between gap-1 mt-3">
                      <Box className="pagespeed-card">
                        <DashboardIcon />
                        {skeleton ? (
                          <Box
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                            height={40}
                          >
                            <Skeleton variant="text" width={70} height={30} />
                          </Box>
                        ) : (
                          <h4 className="time">
                            {scores?.onload_time} <br /> ms
                          </h4>
                        )}

                        <p className="title">Onload Time</p>
                      </Box>
                      <Box className="pagespeed-card">
                        <DiscoverOutlineIcon />
                        {skeleton ? (
                          <Box
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                            height={40}
                          >
                            <Skeleton variant="text" width={70} height={30} />
                          </Box>
                        ) : (
                          <h4 className="time">
                            {scores?.page_load_time} <br /> ms
                          </h4>
                        )}
                        <p className="title"> Page Load Time</p>
                      </Box>
                      <Box className="pagespeed-card">
                        <BrowserIcon />
                        {skeleton ? (
                          <Box
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                            height={40}
                          >
                            <Skeleton variant="text" width={70} height={30} />
                          </Box>
                        ) : (
                          <h4 className="time">
                            {scores?.connect_duration} <br /> ms
                          </h4>
                        )}
                        <p className="title">Connect Duration</p>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </>
          )}

          {role === "Super Admin" && (
            // border-top border-top-dashed pt-2 mt-3 mb-0 --- remove class from below Box
            <Box className="text-center"> 
              <Button
                title="Run Test"
                onClick={getRunTestApiData}
                style={{ width: "100%", marginTop: "20px" }}
                disabled={isLoading}
              >
                {isLoading ? (
                  <>
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />{" "}
                    Running Scan...
                  </>
                ) : (
                  "Run Scan"
                )}
              </Button>
            </Box>
          )}
          {isLoading && (
            <Box
              className="alert alert-borderless alert-warning text-center mb-2 mx-2 mt-4"
              role="alert"
            >
              Please wait sometimes as we are running a scan
            </Box>
          )}
        </Card.Body>
      </Card>
    </>
  );
};

export default Performance;

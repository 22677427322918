import {
  ADD_COMPLIANCE,
  ADD_COMPLIANCE_SUCCESS,
  ADD_COMPLIANCE_FAIL,
} from "./actionType";


// Add Developers profile

export const addCompliance = (complianceData) => ({
  type: ADD_COMPLIANCE,
  payload: complianceData,
});

export const addComplianceSuccess = () => ({
  type: ADD_COMPLIANCE_SUCCESS,
});

export const addComplianceFail = (error) => ({
  type: ADD_COMPLIANCE_FAIL,
  payload: error,
});
